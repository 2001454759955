import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const saintLuciaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-LC',
  isoCode: 'LCA',
  name: 'St. Lucia',
  currency: 'XCD',
  flag: 'flag-st-lucia',
};
