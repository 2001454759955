import { DotBadge } from '../DataDisplay';
import { getAvatarLetters } from '@/utils';
import {
  AvatarContainer,
  AvatarProgress,
  AvatarProgressContainer,
  StyledAvatar,
} from './User.styled';
import { IconBadge } from '../DataDisplay/DataDisplay.styled';
import { Icon } from '../Icon';
import { useTheme } from '@mui/material';
import { AvatarProps } from './User.interface';

export function Avatar({
  withIndicator,
  dotVariant,
  big,
  icon,
  iconColor,
  children,
  progress,
  progressColor,
  ...props
}: AvatarProps) {
  const theme = useTheme();
  const letters =
    typeof children === 'string' ? getAvatarLetters(children) : undefined;

  return (
    <IconBadge
      invisible={!icon}
      badgeContent={<Icon name={icon || 'question-circle'} />}
      bgColor={iconColor?.(theme)}
      big={big}
    >
      <DotBadge
        darkBorder={dotVariant === 'dark-border'}
        invisible={!withIndicator}
        big={big}
      >
        <AvatarContainer>
          <StyledAvatar big={big} {...props}>
            {letters}
          </StyledAvatar>
          {typeof progress === 'number' && (
            <AvatarProgressContainer>
              <AvatarProgress
                value={100}
                progressColor={theme.palette.grey[400]}
              />
              <AvatarProgress
                value={progress}
                progressColor={progressColor?.(theme)}
              />
            </AvatarProgressContainer>
          )}
        </AvatarContainer>
      </DotBadge>
    </IconBadge>
  );
}
