import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export function PasswordPolicy(props: TypographyProps) {
  return (
    <Typography
      variant="body2"
      color={theme => theme.palette.grey[800]}
      textAlign="center"
      {...props}
    >
      When creating a password, use at least <B>8 characters</B>, include{' '}
      <B>uppercase</B>, <B>lowercase</B>, <B>numbers</B>,{' '}
      <B>special characters</B>, and avoid common words, patterns, and personal
      information.
    </Typography>
  );
}

function B({ children }: PropsWithChildren) {
  return (
    <Typography variant="body2Bold" component="span">
      {children}
    </Typography>
  );
}
