import { Menu, styled } from '@mui/material';

export const StyledMenu = styled(Menu)(() => ({}));
StyledMenu.defaultProps = {
  slotProps: {
    root: {
      sx: theme => ({
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
      }),
    },
    paper: {
      sx: theme => ({
        minWidth: 150,
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: 0,
        '.MuiList-root': {
          padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,

          '.MuiMenuItem-root': {
            borderRadius: '10px',
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            minHeight: 0,
            padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,

            ':not(:last-of-type)': {
              marginBottom: theme.spacing(1),
            },

            '.MuiListItemIcon-root': {
              minWidth: 0,
              marginRight: theme.spacing(0.75),
            },
          },
        },
      }),
    },
  },
};
