import { Document, DocumentStatus, IconName, Option } from '@/types';
import {
  AccordionSummary,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Icon } from '../Icon';
import { StatusIcon } from './StatusIcon';
import { DocumentSubTypeText, StatusText } from '@/constants';
import { formatTime, getDataTestId } from '@/utils';
import { Status } from './Status';
import {
  DocumentAccordionDetails,
  DocumentAccordionItem,
  StyledAccordion,
} from './DataDisplay.styled';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { MenuButton } from '../Action';
import { useDownloadDocument } from '@/hooks';
import { Can } from '../Can';

type DocumentAccordionProps = {
  title: string;
  description?: string;
  documents?: Document[];
  status?: DocumentStatus;
  statusIcon?: IconName;
  onReviewClick?: (document: Document) => void;
  defaultExpanded?: boolean;
  renderDocumentItem?: (document: Document) => ReactNode;
  renderDocumentItemTitle?: (document: Document) => ReactNode;
  renderDocumentItemTime?: (document: Document) => ReactNode;
  renderDocumentItemMenuButton?: (document: Document) => ReactNode;
  disabled?: boolean;
  disableDocumentCache?: boolean;
} & PropsWithChildren;

export function DocumentAccordion({
  title,
  description,
  documents,
  status,
  statusIcon,
  children,
  onReviewClick,
  defaultExpanded,
  disabled,
  renderDocumentItem,
  renderDocumentItemTitle,
  renderDocumentItemTime,
  renderDocumentItemMenuButton,
  disableDocumentCache = false,
}: DocumentAccordionProps) {
  const downloadDocument = useDownloadDocument({
    disabledCache: disableDocumentCache,
  });

  const defaultExpandedValue = useMemo(
    () => !!documents?.length || defaultExpanded,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const options: Option[] = [
    ...(onReviewClick
      ? [
          {
            text: 'Review',
            icon: 'search-doc' as IconName,
            value: 'review',
          },
        ]
      : []),
    {
      text: 'Download',
      icon: 'download',
      value: 'download',
    },
  ];

  const onOptionSelect = (document: Document, option: Option) => {
    switch (option.value) {
      case 'review':
        onReviewClick?.(document);
        break;
      case 'download':
        downloadDocument(document);
        break;
    }
  };

  return (
    <StyledAccordion
      {...getDataTestId(`accordion-${title}`)}
      defaultExpanded={defaultExpandedValue}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={
          <Icon name="arrow-down" color={theme => theme.palette.grey[700]} />
        }
      >
        <ListItem>
          <StatusIcon
            value={status || DocumentStatus.Pending}
            iconName={statusIcon}
          />
          <ListItemText>
            <Typography variant="body2Bold" mb={0.5}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color={theme => theme.palette.grey[700]}
            >
              {description || StatusText[status || ''] || status}
            </Typography>
          </ListItemText>
        </ListItem>
      </AccordionSummary>
      <DocumentAccordionDetails>
        {children}
        {(documents || []).map(item =>
          renderDocumentItem ? (
            renderDocumentItem(item)
          ) : (
            <DocumentAccordionItem key={item.id}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  {renderDocumentItemTitle ? (
                    renderDocumentItemTitle(item)
                  ) : (
                    <>
                      <Typography variant="body2Medium" mb={0.75}>
                        {DocumentSubTypeText[item.docType as string] ||
                          item.docType ||
                          'Document'}
                      </Typography>
                      <Status
                        size="small"
                        value={item.status}
                        display="block"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={4.5}>
                  {renderDocumentItemTime ? (
                    renderDocumentItemTime(item)
                  ) : (
                    <>
                      {item.reviewDueDate && (
                        <>
                          <Typography
                            variant="caption"
                            color={theme => theme.palette.grey[700]}
                          >
                            Due date
                          </Typography>
                          <Typography variant="body2">
                            {formatTime(item.reviewDueDate)}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={1.5} textAlign="right">
                  {renderDocumentItemMenuButton ? (
                    renderDocumentItemMenuButton(item)
                  ) : (
                    <Can do="view" on="document">
                      <MenuButton
                        options={options}
                        onSelect={option => onOptionSelect(item, option)}
                      />
                    </Can>
                  )}
                </Grid>
              </Grid>
            </DocumentAccordionItem>
          ),
        )}
      </DocumentAccordionDetails>
    </StyledAccordion>
  );
}
