import { BoxProps, Typography, useTheme } from '@mui/material';
import { ProgressStepsBar, ProgressStepsContainer } from './DataDisplay.styled';
import { ColorExtractFn } from '@/types';
import { getDataTestId } from '@/utils';

type ProgressBarProps = {
  color?: ColorExtractFn;
  value: number;
  total: number;
} & Omit<BoxProps, 'color'>;

export function ProgressSteps({
  color,
  value,
  total,
  ...props
}: ProgressBarProps) {
  const theme = useTheme();

  const calculatedTotal = value > total ? value : total;

  return (
    <ProgressStepsContainer {...getDataTestId(`progress-steps`)} {...props}>
      {value ? (
        <ProgressStepsBar
          width={
            calculatedTotal ? `${(value / calculatedTotal) * 100}%` : undefined
          }
          color={color?.(theme)}
        >
          <Typography
            variant="caption2Bold"
            color={theme => theme.palette.background.paper}
          >
            {value}/{total}
          </Typography>
        </ProgressStepsBar>
      ) : (
        <Typography
          variant="caption2Bold"
          color={theme => theme.palette.text.primary}
          pl={1}
        >
          {value}/{total}
        </Typography>
      )}
    </ProgressStepsContainer>
  );
}
