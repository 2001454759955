import { createSlice } from '@reduxjs/toolkit';
import { RoleName, User } from '@/types';
import {
  notifyUserChanged,
  notifyUserChangedSolved,
  resetAuth,
  resetLoginResponseError,
  setLoggedInUser,
  setLoginResponseError,
} from './actions';

interface AuthState {
  user?: User;
  loginResponseError?: string;
  initialized: boolean;
  shouldRefreshUser: boolean;
}

const initialState: AuthState = {
  initialized: false,
  shouldRefreshUser: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setLoggedInUser, (state, { payload }) => {
      state.user = payload || undefined;
      if (state.user) {
        state.user.isAdmin = payload?.roles?.includes(RoleName.Admin) || false;
        state.loginResponseError = initialState.loginResponseError;
      }
      if (!state.initialized) {
        state.initialized = true;
      }
      if (state.shouldRefreshUser) {
        state.shouldRefreshUser = false;
      }
    });
    builder.addCase(notifyUserChanged, state => {
      state.shouldRefreshUser = true;
      state.loginResponseError = initialState.loginResponseError;
    });
    builder.addCase(notifyUserChangedSolved, state => {
      state.shouldRefreshUser = false;
    });
    builder.addCase(setLoginResponseError, (state, { payload }) => {
      state.loginResponseError = payload;
    });
    builder.addCase(resetLoginResponseError, state => {
      state.loginResponseError = initialState.loginResponseError;
    });
    builder.addCase(resetAuth, state => {
      state.shouldRefreshUser = true;
      state.initialized = false;
      state.user = undefined;
      state.loginResponseError = initialState.loginResponseError;
    });
  },
});

export const authReducer = authSlice.reducer;
