import { Box, Stack, Typography } from '@mui/material';
import { Status } from './Status';
import { ReactNode } from 'react';
import { StatusProps } from '@/types';
import { FieldActionSlot } from './DataDisplay.styled';

type FieldProps = {
  label: ReactNode;
  content?: ReactNode;
  actionSlot?: ReactNode;
  statusProps?: StatusProps;
};

export function Field({ label, content, statusProps, actionSlot }: FieldProps) {
  return (
    <Stack direction="column" alignItems="flex-start" gap={0.5}>
      <Stack gap={1} alignItems="flex-start" position="relative">
        <Typography variant="caption" color={theme => theme.palette.grey[700]}>
          {label}
        </Typography>
        {statusProps && (
          <Box mt={-0.4}>
            <Status {...statusProps} />
          </Box>
        )}
        <FieldActionSlot>{actionSlot}</FieldActionSlot>
      </Stack>
      <Typography variant="body2Medium">{content}</Typography>
    </Stack>
  );
}
