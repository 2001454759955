import { downloadFile, getAPIErrorMessage, sleep } from '@/utils';
import {
  getLocalDocumentUrl,
  getLocalDocumentUrlFromS3,
  getLocalPrivateDocumentUrl,
} from './documents';
import { axios } from './axios';
import { REQUEST } from '@/constants';
import { Client, DocumentType } from '@/types';

export const shortPolling = async <T>(
  callback: () => Promise<T>,
  validate: (value: T) => boolean,
  options: {
    immediate?: boolean;
    maxAttempts?: number;
    intervalDurationMs?: number;
    stopOnError?: boolean;
  } = {},
) => {
  const {
    immediate = true,
    maxAttempts = 10,
    intervalDurationMs = 5000,
    stopOnError = true,
  } = options;
  let attempts = 0;

  while (maxAttempts || attempts < maxAttempts) {
    if (attempts > 0 || !immediate) {
      await sleep(intervalDurationMs);
    }

    try {
      const result = await callback();

      if (validate(result)) {
        return result;
      }
    } catch (e) {
      console.error(e);
      if (stopOnError) {
        return null;
      }
    } finally {
      attempts++;
    }
  }

  return null;
};

export const downloadFileByUri = async (
  uri: string,
  fileName: string,
  onSuccess?: (result: string) => void,
  onError?: (error: string) => void,
) => {
  try {
    const url = await getLocalDocumentUrl(uri);
    downloadFile(url, fileName);
    onSuccess?.(url);
  } catch (e) {
    onError?.(getAPIErrorMessage(e));
  }
};

export const downloadPrivateFileByUri = async (
  uri: string,
  fileName: string,
  onSuccess?: (result: string) => void,
  onError?: (error: string) => void,
) => {
  try {
    const url = await getLocalPrivateDocumentUrl(uri);
    downloadFile(url, fileName);
    onSuccess?.(url);
  } catch (e) {
    onError?.(getAPIErrorMessage(e));
  }
};

export const downloadFileByS3Uri = async (
  uri: string,
  fileName: string,
  onSuccess?: (result: string) => void,
  onError?: (error: string) => void,
) => {
  try {
    const url = await getLocalDocumentUrlFromS3(uri);
    downloadFile(url, fileName);
    onSuccess?.(url);
  } catch (e) {
    onError?.(getAPIErrorMessage(e));
  }
};

export const getMessageTemplate = async ({
  documentType,
  clientId,
}: {
  documentType: DocumentType;
  clientId: Client['id'];
}) => {
  const result = await axios.get<{ requestTemplate: string }>(
    REQUEST.CLIENT.REQUEST_DOCUMENT(clientId),
    {
      params: {
        type: documentType,
      },
    },
  );

  return result.data.requestTemplate;
};
