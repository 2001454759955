import { Document } from '@/types';
import { FileViewerAction, Lightbox, ViewerCenterSlot } from './File.styled';
import { Icon, Loader } from '../Icon';
import { PDFRenderer } from './PDFRenderer';
import { IconButton, Portal } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { ImageViewer } from './ImageViewer';
import {
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
} from '@/utils';
import { ExcelRenderer, ExcelFileViewerContainer } from './ExcelRenderer';
import { useDispatch, useDocument } from '@/hooks';
import { hideFileViewer } from '@/redux';

type FileViewerProps = {
  document: Document;
  disabledCache?: boolean;
};

export function FileViewer({ document, disabledCache }: FileViewerProps) {
  const [imgRotateDegree, setImgRotateDegree] = useState(0);
  const { data, download } = useDocument({ document, disabledCache });
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(hideFileViewer());
  }, [dispatch]);

  const render = useCallback(
    (children: ReactNode) => {
      return (
        <Portal>
          <Lightbox>{children}</Lightbox>
          <FileViewerAction
            buttonColor={
              isExcelContentType(document.contentType) ? 'primary' : 'white'
            }
          >
            <IconButton onClick={onClose} title="Minimize">
              <Icon name="zoom-out" />
            </IconButton>
            <IconButton onClick={download} title="Download">
              <Icon name="download" />
            </IconButton>
            {isImageContentType(document.contentType) && (
              <>
                <IconButton
                  onClick={() => setImgRotateDegree(prev => prev + 90)}
                  title="Rotate clockwise"
                >
                  <Icon name="arrow-arc-right" />
                </IconButton>
                <IconButton
                  onClick={() => setImgRotateDegree(prev => prev - 90)}
                  title="Rotate anti-clockwise"
                >
                  <Icon name="arrow-arc-left" />
                </IconButton>
              </>
            )}
          </FileViewerAction>
        </Portal>
      );
    },
    [document.contentType, download, onClose],
  );

  if (data?.isLoading) {
    return (
      <Portal>
        <Lightbox>
          <ViewerCenterSlot>
            <Loader />
          </ViewerCenterSlot>
        </Lightbox>
      </Portal>
    );
  }

  if (!data) return null;

  if (isImageContentType(document.contentType)) {
    return render(
      <ImageViewer
        url={data.localUrl || data.uri || ''}
        rotateDegree={imgRotateDegree}
      />,
    );
  }

  if (isPDFContentType(document.contentType)) {
    return render(
      <PDFRenderer
        file={data.localUrl || document.uri || ''}
        loader={
          <Loader
            justifyContent="center"
            iconProps={{
              size: 32,
              color: theme => theme.palette.common.white,
            }}
          />
        }
      />,
    );
  }

  if (isExcelContentType(document.contentType)) {
    return render(
      <ExcelFileViewerContainer>
        <ExcelRenderer file={data.localUrl || document.uri || ''} />
      </ExcelFileViewerContainer>,
    );
  }

  return null;
}
