import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const dominicaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-DM',
  isoCode: 'DMA',
  currency: 'XCD',
  name: 'Dominica',
  flag: 'flag-dominica',
};
