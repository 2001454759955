import { elevationLevel } from '@/styles';
import { Accordion, AccordionDetails, styled } from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: theme.shadows[elevationLevel.m],
  marginBottom: theme.spacing(1),

  '&.Mui-expanded': {
    marginBottom: theme.spacing(1),
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  '.MuiTypography-root': {
    wordBreak: 'break-word',
  },
}));
