import { Can, DocumentAccordion, Status, UploadButton } from '@/components';
import { useDispatch } from '@/hooks';
import {
  fetchClientDetails,
  showDocumentReviewDrawer,
  showToast,
} from '@/redux';
import {
  Client,
  Document,
  DocumentStatus,
  DocumentSubType,
  DocumentType,
} from '@/types';
import { CircularProgress, Typography } from '@mui/material';
import { UploadBox, UploaderLoadingContainer } from './ClientDetails.styled';
import {
  DocumentSubTypeText,
  MAX_DOCUMENT_SIZE,
  SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS,
} from '@/constants';
import { useEffect } from 'react';
import { formatTime, getAPIErrorMessage, getFileExtension } from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { useMutation } from '@tanstack/react-query';
import { addClientDocument } from '@/api';

type AdditionalDocumentAccordionProps = {
  client: Client;
  documents?: Document[];
};

export function AdditionalDocumentAccordion({
  client,
  documents,
}: AdditionalDocumentAccordionProps) {
  const {
    value: isDraggingOn,
    setTrue: showDraggingOn,
    setFalse: hideDraggingOn,
  } = useBoolean(false);
  const dispatch = useDispatch();

  const {
    mutate: upload,
    isPending,
    isSuccess,
    reset,
    error,
  } = useMutation({
    mutationFn: addClientDocument,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchClientDetails(client.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onFileSelect = async (files: FileList | null) => {
    reset();

    if (!files || !files.length) return;

    const file = files[0];
    const ext = getFileExtension(file);

    if (!SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.includes(ext)) {
      dispatch(
        showToast({
          message: 'Extension not supported',
          severity: 'error',
        }),
      );
      return;
    }

    if (file.size > MAX_DOCUMENT_SIZE) {
      dispatch(
        showToast({
          message: 'File size exceeded',
          severity: 'error',
        }),
      );
      return;
    }

    upload({
      clientId: client.id,
      file: files[0],
      type: DocumentType.Internal,
      subType: files[0].name as DocumentSubType,
    });
  };

  const onReviewClick = (document: Document) =>
    dispatch(
      showDocumentReviewDrawer({
        client,
        document,
      }),
    );

  const internalDocs = (client.documents || []).filter(
    item => item.type === DocumentType.Internal,
  );

  return (
    <DocumentAccordion
      title="Branch approval documents"
      onReviewClick={onReviewClick}
      status={
        internalDocs.length ? DocumentStatus.Approved : DocumentStatus.Pending
      }
      defaultExpanded
      documents={documents}
      renderDocumentItemTitle={item => (
        <>
          <Typography variant="body2Medium" mb={0.75}>
            {item.fileName ||
              DocumentSubTypeText[item.docType || ''] ||
              'Document'}
          </Typography>
          <Status size="small" value={item.status} display="block" />
        </>
      )}
      renderDocumentItemTime={item => (
        <>
          <Typography
            variant="caption"
            color={theme => theme.palette.grey[700]}
          >
            Last updated
          </Typography>
          <Typography variant="body2">{formatTime(item.updatedAt)}</Typography>
        </>
      )}
    >
      <Can do="edit" on="onboarding_application">
        <UploadBox
          isHighlighted={isDraggingOn}
          onDragEnter={showDraggingOn}
          onDragLeave={hideDraggingOn}
          onDrop={hideDraggingOn}
        >
          {isDraggingOn ? (
            <Typography
              variant="captionBold"
              textAlign="center"
              color={theme => theme.palette.primary.main}
            >
              Drop here
            </Typography>
          ) : (
            <Typography
              variant="caption"
              textAlign="center"
              color={theme => theme.palette.grey[700]}
            >
              <Typography
                variant="captionBold"
                component="span"
                color={theme => theme.palette.grey[1000]}
              >
                Select file
              </Typography>{' '}
              to upload or drag it here
              <br />
              Only {SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.join(', ')}, 50 MB or
              less
            </Typography>
          )}
          {isPending && (
            <UploaderLoadingContainer>
              <CircularProgress size={20} />
            </UploaderLoadingContainer>
          )}
          <UploadButton
            onChange={onFileSelect}
            accept={SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.join(',')}
            disabled={isPending}
          >
            Upload
          </UploadButton>
        </UploadBox>
      </Can>
    </DocumentAccordion>
  );
}
