import {
  ClientAddressFields,
  DocumentTypeText,
  NATIONALITY_BY_VALUE,
} from '@/constants';
import {
  Client,
  ClientStatus,
  DocumentStatus,
  DocumentType,
  EmploymentStatus,
  Feature,
  FeatureName,
  Profession,
  User,
} from '@/types';
import { Theme } from '@mui/material';
import { AppAbility } from './defineAbility';

export function isNationalClient(client: Client, tenantISO: string) {
  if (!client) return false;

  return [client.citizenship, client.citizenship2]
    .filter(i => !!i)
    .map(i => i?.toUpperCase())
    .includes(tenantISO.toUpperCase() || 'unknown-iso-code');
}

export function getDisplayedCitizenship(client?: Client) {
  if (!client) return '';

  return [client.citizenship, client.citizenship2]
    .filter(i => !!i)
    .map(code => NATIONALITY_BY_VALUE[String(code)] || code)
    .join('; ');
}

export function isActiveClient(client: Client) {
  return ![ClientStatus.Approved, ClientStatus.Rejected].includes(
    client.status,
  );
}

export function getBasicInfoStatus(client?: Client) {
  return !client?.pepAnswer
    ? DocumentStatus.Pending
    : client.pepAnswer.approved
      ? DocumentStatus.Approved
      : DocumentStatus.InReview;
}

export function getRequiredDocumentTypes(
  client: Client,
  tenantISO: string,
  features: Feature[] = [],
) {
  if (!client) return [];

  const isNational = isNationalClient(client, tenantISO);

  const documentTypes: DocumentType[] = [
    DocumentType.ProofOfAddress,
    DocumentType.ProofOfEmployment,
    ...(!isNational ? [DocumentType.BankReferenceLetter] : []),
    ...(features.find(item => item.feature === FeatureName.SecondID)
      ? [DocumentType.SecondID]
      : []),
  ];

  switch (client.employmentStatus) {
    case EmploymentStatus.Employed:
      documentTypes.push(...getEmployedRequiredDocuments());
      break;
    case EmploymentStatus.SelfEmployed:
      documentTypes.push(...getSelfEmployedRequiredDocuments(client));
      break;
    case EmploymentStatus.Unemployed:
      documentTypes.push(...getUnemployedRequiredDocuments(client));
      break;
  }

  documentTypes.sort((a, b) =>
    (DocumentTypeText[a] || a).localeCompare(DocumentTypeText[b] || b),
  );

  documentTypes.unshift(DocumentType.Selfie);
  documentTypes.unshift(DocumentType.IdentityBack);
  documentTypes.unshift(DocumentType.Identity);
  documentTypes.push(DocumentType.DocumentSign);

  return documentTypes;
}

function getEmployedRequiredDocuments() {
  return [];
}

export function getSelfEmployedRequiredDocuments(client: Client) {
  switch (client.employmentDesc) {
    case Profession.SoleTrader:
      return [
        DocumentType.ProofOfIncome,
        DocumentType.FinancialStatementsProjections,
      ];
    case Profession.PartnershipLimitedLiability:
      return [
        DocumentType.ProofOfIncome,
        DocumentType.CertificateOfIncorporation,
        DocumentType.MemorandumOfArticlesAndAssociation,
        DocumentType.ListOfDirectorsMembers,
        DocumentType.CertificateOfGoodStanding,
        DocumentType.RegisteredAgentCertificate,
      ];
    case Profession.Unincorporated:
      return [
        DocumentType.ProofOfIncome,
        DocumentType.NonprofitOrgRegistrationCertificate,
        DocumentType.LastGroupMeetingMinutes,
      ];
  }

  return [];
}

export function getUnemployedRequiredDocuments(client: Client) {
  const list: DocumentType[] = [];

  switch (client.employmentDesc) {
    case Profession.Student:
      list.push(
        DocumentType.SchoolLetterOfAttendance,
        DocumentType.SupportLetterFromParent,
        DocumentType.ParentID,
        DocumentType.AuthorisationLetter,
      );
      break;
    case Profession.Pensioner:
      list.push(DocumentType.SocialSecurityLetter);
      break;
    case Profession.Housewife:
      list.push(DocumentType.SupportLetterFromHusband);
      break;
    case Profession.Unemployed:
      list.push(DocumentType.SupportLetterFromCaretaker);
      break;
  }

  return list;
}

export function getDisplayedAddress(client: Client) {
  if (!client.addressDetailed) return '';

  const parts = Object.entries(client.addressDetailed)
    .filter(([key, value]) => !!value && ClientAddressFields.includes(key))
    .map(([, value]) => value);
  if (!parts.length) return '';

  let result = '';
  parts.forEach((item, index, array) => {
    if (!result) {
      result += item;
    } else if (array[index - 1] && /^\d+$/.test(array[index - 1])) {
      result += ` ${item}`;
    } else {
      result += `, ${item}`;
    }
  });

  return result;
}

export function isInactiveClient(client: Client) {
  return [
    ClientStatus.Archived,
    ClientStatus.Approved,
    ClientStatus.Rejected,
  ].includes(client.status);
}

export const getStatusIcon = (client: Client) => {
  if (client.status === ClientStatus.Approved) {
    return {
      icon: 'check',
      iconColor: (theme: Theme) =>
        theme.palette.status[ClientStatus.Approved]?.main,
    };
  } else if (client.status === ClientStatus.Rejected) {
    return {
      icon: 'block',
      iconColor: (theme: Theme) =>
        theme.palette.status[ClientStatus.Rejected]?.main,
    };
  } else if (client.eligible === false) {
    return {
      icon: 'pending-dots',
      iconColor: (theme: Theme) => theme.palette.warning.main,
    };
  } else {
    return {};
  }
};

export const hasMandateDocument = (client: Client) => {
  return (client.documents || []).some(
    doc =>
      /mandate/i.test('' + doc.docType) &&
      [DocumentStatus.InReview, DocumentStatus.Approved].includes(doc.status),
  );
};

export function shouldShowAssignButton({
  client,
  loggedInUser,
  ability,
}: {
  client?: Client;
  loggedInUser?: User;
  ability: AppAbility;
}) {
  if (!client || !loggedInUser) return false;

  return (
    ability.can('assign', 'onboarding_application') ||
    (loggedInUser?.id !== client.reviewBy &&
      ability.can('assign', 'onboarding_application_self')) ||
    (!!client.reviewBy && ability.can('unassign', 'onboarding_application')) ||
    (!!client.reviewBy &&
      client.reviewBy === loggedInUser.id &&
      ability.can('unassign', 'onboarding_application_self'))
  );
}
