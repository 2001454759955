import { getDataTestId } from '@/utils';
import { Box, Modal, Typography } from '@mui/material';
import { FormAction, FormCard } from '../Layout';
import { LoadingButton } from '../Form';

type ApprovePEPConfirmModalProps = {
  onApprove: () => void;
  open: boolean;
  onClose: () => void;
};

export function ApprovePEPConfirmModal({
  onApprove,
  open,
  onClose,
}: ApprovePEPConfirmModalProps) {
  const handleApprove = () => {
    onApprove();
    onClose();
  };

  return (
    <Modal {...getDataTestId('confirm-approve-pep-modal')} open={open}>
      <Box>
        <FormCard title="Approve PEP and AML" onClose={onClose} center>
          <Typography variant="body2" mb={4}>
            Are you sure you want to approve the PEP and AML status of this
            account?
          </Typography>
          <FormAction>
            <LoadingButton
              variant="outlined"
              {...getDataTestId(`confirm-approve-pep-modal-cancel-button`)}
              onClick={onClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleApprove}
              {...getDataTestId(`confirm-approve-pep-modal-approve-button`)}
            >
              Approve
            </LoadingButton>
          </FormAction>
        </FormCard>
      </Box>
    </Modal>
  );
}
