import { BoxProps, Stack, Typography, useTheme } from '@mui/material';
import {
  ChartIndicatorContainer,
  ChartIndicatorDot,
} from './DataDisplay.styled';
import { ColorExtractFn } from '@/types';

type ChartIndicatorProps = {
  text: string;
  color?: ColorExtractFn;
} & Omit<BoxProps, 'color'>;

export function ChartIndicator({ color, text, ...props }: ChartIndicatorProps) {
  const theme = useTheme();

  return (
    <ChartIndicatorContainer {...props}>
      <ChartIndicatorDot color={color?.(theme)} />
      <Typography
        variant="caption2Bold"
        color={theme => theme.palette.grey[600]}
      >
        {text}
      </Typography>
    </ChartIndicatorContainer>
  );
}

type ChartIndicatorStackProps = {
  indicators: ChartIndicatorProps[];
};

export function ChartIndicatorStack({ indicators }: ChartIndicatorStackProps) {
  return (
    <Stack gap={3}>
      {indicators.map(({ color, text }) => (
        <ChartIndicator key={text} text={text} color={color} />
      ))}
    </Stack>
  );
}
