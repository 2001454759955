import { Box, MenuProps, Stack } from '@mui/material';
import { MenuButton, MenuDivider, StyledMenu } from './User.styled';
import { Icon } from '../Icon';
import { UserMenuOption } from './User.interface';
import { ClientBlock } from '.';
import { getDataTestId } from '@/utils';
import { User } from '@/types';

type UserMenuProps = {
  onOptionClick: (option: UserMenuOption) => void;
  loggedInUser: User;
} & MenuProps;

export function UserMenu({
  onOptionClick,
  loggedInUser,
  ...props
}: UserMenuProps) {
  return (
    <StyledMenu {...getDataTestId('user-header-menu')} {...props}>
      <Box p={2} pb={0}>
        <ClientBlock
          avatar={loggedInUser.picture}
          name={loggedInUser.fullName}
          description={loggedInUser.email}
        />
      </Box>
      <Stack direction="column" alignItems="stretch" p={2} pt={1.5} gap={0.5}>
        <MenuButton
          onClick={() => onOptionClick('change-avatar')}
          {...getDataTestId('change-avatar-button')}
        >
          Upload picture
        </MenuButton>
        <MenuButton
          onClick={() => onOptionClick('change-password')}
          {...getDataTestId('change-password-button')}
        >
          Change password
        </MenuButton>
        <MenuDivider />
        <MenuButton
          onClick={() => onOptionClick('sign-out')}
          startIcon={<Icon name="sign-out" />}
          {...getDataTestId('sign-out-button')}
        >
          Sign out
        </MenuButton>
      </Stack>
    </StyledMenu>
  );
}
