import {
  clearModalRefreshState,
  fetchClientDetails,
  selectApproveAccountModalState,
  selectAssignClientModalState,
  selectClientDetails,
  selectDocumentReviewState,
  selectManageDocumentsState,
  selectPEPReportDrawerState,
} from '@/redux';
import { useDispatch, useSelector } from './redux';
import { useEffect } from 'react';
import { ModalName } from '@/types';

export function useClientDetailsRefresh() {
  const client = useSelector(selectClientDetails);
  const { shouldRefreshData: shouldRefreshAfterAssigned } = useSelector(
    selectAssignClientModalState,
  );
  const { shouldRefreshData: shouldRefreshAfterReviewed } = useSelector(
    selectDocumentReviewState,
  );
  const { shouldRefreshData: shouldRefreshAfterUploaded } = useSelector(
    selectManageDocumentsState,
  );
  const { shouldRefreshData: shouldRefreshAfterApproved } = useSelector(
    selectApproveAccountModalState,
  );

  const { shouldRefreshData: shouldRefreshAfterApprovedPEP } = useSelector(
    selectPEPReportDrawerState,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!client?.id) return;

    if (
      shouldRefreshAfterAssigned ||
      shouldRefreshAfterReviewed ||
      shouldRefreshAfterUploaded ||
      shouldRefreshAfterApproved ||
      shouldRefreshAfterApprovedPEP
    ) {
      dispatch(fetchClientDetails(String(client.id)));
      dispatch(
        clearModalRefreshState(
          shouldRefreshAfterAssigned
            ? ModalName.AssignClient
            : shouldRefreshAfterReviewed
              ? ModalName.ReviewDocument
              : shouldRefreshAfterUploaded
                ? ModalName.ManageDocuments
                : shouldRefreshAfterApproved
                  ? ModalName.ApproveAccount
                  : shouldRefreshAfterApprovedPEP
                    ? ModalName.PEPReport
                    : undefined,
        ),
      );
    }
  }, [
    shouldRefreshAfterAssigned,
    shouldRefreshAfterReviewed,
    shouldRefreshAfterApproved,
    client,
    dispatch,
    shouldRefreshAfterApprovedPEP,
    shouldRefreshAfterUploaded,
  ]);
}
