import { Tooltip, useTheme } from '@mui/material';
import { Chip, StatusBlock } from './DataDisplay.styled';
import { ClientStatusText, StatusText, UserStatusText } from '@/constants';
import { StatusProps } from '@/types';

export function Status({
  value,
  label,
  color: colorFn,
  bgColor: bgColorFn,
  borderColor: borderColorFn,
  size,
  display = 'block',
  title,
}: StatusProps) {
  const theme = useTheme();

  const text =
    label ||
    StatusText[value] ||
    ClientStatusText[value] ||
    UserStatusText[value] ||
    value;
  const color = colorFn
    ? colorFn(theme)
    : theme.palette.status[value]
      ? theme.palette.status[value].text
      : theme.palette.grey[700];
  const backgroundColor = bgColorFn
    ? bgColorFn(theme)
    : theme.palette.status[value]
      ? theme.palette.status[value].background
      : theme.palette.grey[200];
  const borderColor = borderColorFn
    ? borderColorFn(theme)
    : theme.palette.status[value]
      ? theme.palette.status[value].border
      : undefined;

  const renderChip = () => {
    const props = {
      size,
      label: text,
      color,
      bgColor: backgroundColor,
      borderColor,
    };

    if (title) {
      return (
        <Tooltip title={title} placement="top" arrow>
          <span>
            <Chip {...props} />
          </span>
        </Tooltip>
      );
    }

    return <Chip {...props} />;
  };

  if (display === 'block') {
    return <StatusBlock>{renderChip()}</StatusBlock>;
  }

  return renderChip();
}
