import { DocumentStatus } from '@/types';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

export const EditableText = styled(Typography)(() => ({
  position: 'relative',
  paddingRight: 30,
  wordBreak: 'break-word',

  ':hover': {
    '.MuiButtonBase-root': {
      display: 'inline-flex',
    },
  },
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '50%',
  transform: `translate(${theme.spacing(1)}, calc(-50% + ${theme.spacing(-0.25)}))`,
  display: 'none',
}));
EditButton.defaultProps = {
  size: 'small',
};

export const StyledTextField = styled(TextField)(({ theme }) => ({
  paddingTop: 0,
  height: 30,
  '.MuiInputBase-input': {
    fontSize: theme.typography.body2.fontSize,
    paddingBottom: 0,
  },
}));
StyledTextField.defaultProps = {
  size: 'small',
  variant: 'standard',
};

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<Date>>(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
    '.MuiInputBase-root': {
      height: 30,
      '.MuiInputBase-input': {
        padding: 0,
        fontSize: theme.typography.body2.fontSize,
        width: 90,
      },
    },
  }),
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  maxWidth: 150,
  height: 30,
  '.MuiSelect-select': {
    paddingTop: theme.spacing(1),
    '.MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));
StyledSelect.defaultProps = {
  variant: 'standard',
};

export const DropdownOption = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: 32,
  fontSize: theme.typography.caption.fontSize,

  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },

  '.MuiListItemIcon-root': {
    minWidth: 0,

    ':first-of-type': {
      marginRight: theme.spacing(1),
    },
  },

  '.MuiListItemText-root': {
    flex: 1,
    minWidth: 0,
    wordWrap: 'break-word',
    whiteSpace: 'wrap',
  },

  '&.Mui-selected': {
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    pointerEvents: 'none',

    ':hover, :focus': {
      backgroundColor: 'transparent',
    },
  },

  ':first-of-type': {
    marginTop: theme.spacing(1),
  },
  ':not(:first-of-type)': {
    marginTop: theme.spacing(0.25),
  },
}));

export const EditableFieldDropdownOption = styled(DropdownOption)(() => ({
  width: 300,
  wordWrap: 'break-word',
}));

export const FieldError = styled(Typography)(({ theme }) => ({
  color: theme.palette.status[DocumentStatus.Rejected].text,
  display: 'flex',
  alignItems: 'center',

  '.MuiSvgIcon-root': {
    height: 18,
    width: 18,
    fill: theme.palette.status[DocumentStatus.Rejected].text,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.2),
  },
}));
FieldError.defaultProps = {
  variant: 'body2',
};

export const FieldValueEndDecorator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: '50%',
  transform: `translateY(calc(-50% + ${theme.spacing(-0.15)}))`,
  display: 'inline-block',
  lineHeight: 0,
}));
