import { Components, createTheme } from '@mui/material';
import { layoutConfigs } from '../layout';

const theme = createTheme({
  ...layoutConfigs,
});

export const containerConfigs: Components['MuiContainer'] = {
  styleOverrides: {
    root: {
      maxWidth: 900,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      [theme.breakpoints.up('md')]: {
        maxWidth: 900,
        padding: 0,
      },

      [theme.breakpoints.up('lg')]: {
        maxWidth: 1000,
        padding: 0,
      },

      [theme.breakpoints.up('xl')]: {
        maxWidth: 1128,
      },
    },
  },
};
