import { FilterBar } from '@/components';
import { useMemo } from 'react';
import { ClientFilter } from './Clients.interface';
import { ClientStatusText } from '@/constants';
import { ClientStatus, Option, User } from '@/types';
import { useSelector } from '@/hooks';
import { selectLoggedInUser, selectPicklists } from '@/redux';
import { formatTime, getDisplayField } from '@/utils';
import { UseFormSetValue } from 'react-hook-form';

type ClientsFilterBarProps = {
  currentFilters: ClientFilter;
  onSetFilters: UseFormSetValue<ClientFilter>;
};

export function ClientsFilterBar({
  currentFilters,
  onSetFilters,
}: ClientsFilterBarProps) {
  const loggedInUser = useSelector(selectLoggedInUser);
  const { reviewerById } = useSelector(selectPicklists);

  const filterOptions = useMemo(() => {
    const result: Option[] = [];

    if (currentFilters.applicationStatuses?.length) {
      result.push(
        ...currentFilters.applicationStatuses.map(
          status =>
            ({
              value: status,
              text: `Status: ${ClientStatusText[status as ClientStatus] || status}`,
              group: 'applicationStatuses',
            }) as Option,
        ),
      );
    }

    if (currentFilters.assignees?.length) {
      result.push(
        ...currentFilters.assignees.map(
          id =>
            ({
              value: id,
              text: `Assignee: ${
                reviewerById[id as string]
                  ? getDisplayField(reviewerById[id as string])
                  : id
              }`,
              group: 'assignees',
            }) as Option,
        ),
      );
    }

    if (currentFilters.selfAssigned) {
      result.push({
        value: (loggedInUser as User).id,
        text: `Assignee: ${getDisplayField(loggedInUser as User)}`,
        group: 'selfAssigned',
      });
    }

    if (currentFilters.dueFrom) {
      result.push({
        value: currentFilters.dueFrom,
        text: `Due from: ${formatTime(currentFilters.dueFrom, { timeFormat: 'HH:mm' })}`,
        group: 'dueFrom',
      });
    }

    if (currentFilters.dueTo) {
      result.push({
        value: currentFilters.dueTo,
        text: `Due to: ${formatTime(currentFilters.dueTo, { timeFormat: 'HH:mm' })}`,
        group: 'dueTo',
      });
    }

    if (currentFilters.from) {
      result.push({
        value: currentFilters.from,
        text: `From: ${formatTime(currentFilters.from, { timeFormat: 'HH:mm' })}`,
        group: 'from',
      });
    }

    if (currentFilters.to) {
      result.push({
        value: currentFilters.to,
        text: `To: ${formatTime(currentFilters.to, { timeFormat: 'HH:mm' })}`,
        group: 'to',
      });
    }

    if (currentFilters.keyword) {
      result.push({
        value: currentFilters.keyword,
        text: `Keyword: ${currentFilters.keyword}`,
        group: 'keyword',
      });
    }

    return result;
  }, [currentFilters, loggedInUser, reviewerById]);

  const onClearFilter = (filter: Option['value'], group?: Option['group']) => {
    if (!group) return;

    const key = group as keyof ClientFilter;

    if (Array.isArray(currentFilters[key])) {
      onSetFilters(
        key,
        currentFilters[key].filter(i => i !== filter),
      );
    } else if (typeof currentFilters[key] === 'boolean') {
      onSetFilters(key, false);
    } else if (typeof currentFilters[key] === 'string') {
      onSetFilters(key, '');
    } else {
      onSetFilters(key, undefined);
    }
  };

  const onClearAllFilters = () => {
    onSetFilters('applicationStatuses', []);
    onSetFilters('assignees', []);
    onSetFilters('dueFrom', '');
    onSetFilters('dueTo', '');
    onSetFilters('keyword', '');
    onSetFilters('selfAssigned', false);
    onSetFilters('from', '');
    onSetFilters('to', '');
  };

  return (
    <FilterBar
      appliedFilters={filterOptions}
      onClear={onClearFilter}
      onClearAll={onClearAllFilters}
      containerProps={{
        mt: 1.25,
        mb: 1,
      }}
    />
  );
}
