import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectUIState = createSelector(
  (state: RootState) => state.ui,
  data => ({
    ...data,
  }),
);

export const selectManageDocumentsState = createSelector(
  (state: RootState) => state.ui,
  data => data.manageDocumentsDrawer,
);

export const selectDocumentRequestState = createSelector(
  (state: RootState) => state.ui,
  data => data.documentRequestDrawer,
);

export const selectDocumentReviewState = createSelector(
  (state: RootState) => state.ui,
  data => data.documentReviewDrawer,
);

export const selectFileViewerState = createSelector(
  (state: RootState) => state.ui,
  data => data.fileViewer,
);

export const selectAssignClientModalState = createSelector(
  (state: RootState) => state.ui,
  data => data.assignClientModal,
);

export const selectApproveAccountModalState = createSelector(
  (state: RootState) => state.ui,
  data => data.approveAccountDrawer,
);

export const selectPEPReportDrawerState = createSelector(
  (state: RootState) => state.ui,
  data => data.pepReportDrawer,
);

export const selectToastState = createSelector(
  (state: RootState) => state.ui,
  data => data.toast,
);

export const selectAddUserModalState = createSelector(
  (state: RootState) => state.ui,
  data => data.addUserDrawer,
);
