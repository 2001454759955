import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const saintVincentConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-VC',
  isoCode: 'VCT',
  currency: 'XCD',
  name: 'St. Vincent and the Grenadines',
  flag: 'flag-st-vincent-and-the-grenadines',
};
