import {
  FormAction,
  FormCard,
  Icon,
  LoadingButton,
  MetadataViewer,
} from '@/components';
import { Job } from '@/types';
import { Alert, Box, Typography } from '@mui/material';
import { useBoolean } from 'usehooks-ts';
import { ConfirmReRunModal, StatusActionButton } from './ActivityLogs.styled';
import { getAPIErrorMessage, getDataTestId } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { runJobById } from '@/api/job';
import { useEffect } from 'react';

type ReRunJobButtonProps = {
  job: Job;
  onSuccess?: () => void;
};

export function ReRunJobButton({ job, onSuccess }: ReRunJobButtonProps) {
  const {
    value: isConfirming,
    setTrue: showConfirm,
    setFalse: hideConfirm,
  } = useBoolean(false);

  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: () => runJobById(job.id),
  });

  useEffect(() => {
    onSuccess?.();
    hideConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onConfirm = () => {
    reset();
    mutate();
  };

  return (
    <>
      <StatusActionButton onClick={showConfirm} title="Re-run">
        <Icon
          name="reset"
          size={20}
          color={theme => theme.palette.primary.main}
        />
      </StatusActionButton>
      {isConfirming && (
        <ConfirmReRunModal open>
          <Box minWidth={400}>
            <FormCard title="Re-run job" onClose={hideConfirm} center>
              <Typography variant="body2" mb={2}>
                Are you sure you want to re-run this job?
                <br />
                Make sure you have checked its status carefully.
              </Typography>
              <Box mb={4}>
                <MetadataViewer>{JSON.stringify(job, null, 2)}</MetadataViewer>
              </Box>
              {error && (
                <Box mb={2}>
                  <Alert
                    icon={<Icon name="alert" />}
                    color="error"
                    variant="standard"
                  >
                    {getAPIErrorMessage(error)}
                  </Alert>
                </Box>
              )}
              <FormAction>
                <LoadingButton
                  variant="outlined"
                  {...getDataTestId(`confirm-rerun-job-cancel-button`)}
                  onClick={hideConfirm}
                  disabled={isPending}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  onClick={onConfirm}
                  {...getDataTestId(`confirm-rerun-job-approve-button`)}
                  isLoading={isPending}
                >
                  Run
                </LoadingButton>
              </FormAction>
            </FormCard>
          </Box>
        </ConfirmReRunModal>
      )}
    </>
  );
}
