import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const barbadosConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-BB',
  isoCode: 'BRB',
  name: 'Barbados',
  currency: 'BBD',
  flag: 'flag-barbados',
};
