import { styled } from '@mui/material';
import SimpleBar from 'simplebar-react';

export const StyledSimpleBar = styled(SimpleBar)(({ theme }) => ({
  '.simplebar-scrollbar': {
    '::before': {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));
