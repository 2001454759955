import { ThemeOptions } from '@mui/material';
import { fontFamily, fontWeight } from './constants/fonts';
import { CSSProperties } from 'react';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightSemiBold?: number;
  }

  interface Typography {
    fontWeightSemiBold: number;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: CSSProperties;
    body1Medium: CSSProperties;
    body2Bold: CSSProperties;
    body2Medium: CSSProperties;
    captionBold: CSSProperties;
    caption2: CSSProperties;
    caption2Bold: CSSProperties;
  }

  interface TypographyVariantsOptions {
    body1Bold: CSSProperties;
    body1Medium: CSSProperties;
    body2Bold: CSSProperties;
    body2Medium: CSSProperties;
    captionBold: CSSProperties;
    caption2: CSSProperties;
    caption2Bold: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
    body1Medium: true;
    body2Bold: true;
    body2Medium: true;
    captionBold: true;
    caption2: true;
    caption2Bold: true;
  }
}

export const typographyConfigs: ThemeOptions['typography'] = {
  fontFamily: fontFamily.primary,
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightSemiBold: fontWeight.semiBold,
  fontWeightBold: fontWeight.bold,
  allVariants: {
    lineHeight: 1.3,
  },
  h1: {
    fontSize: '3rem',
    fontWeight: fontWeight.bold,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: fontWeight.bold,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: fontWeight.bold,
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: fontWeight.bold,
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: fontWeight.semiBold,
  },
  body1: {
    fontSize: '1rem',
  },
  body1Medium: {
    fontSize: '1rem',
    fontWeight: fontWeight.medium,
  },
  body1Bold: {
    fontSize: '1rem',
    fontWeight: fontWeight.semiBold,
  },
  body2: {
    fontSize: '0.875rem',
  },
  body2Medium: {
    fontSize: '0.875rem',
    fontWeight: fontWeight.medium,
  },
  body2Bold: {
    fontSize: '0.875rem',
    fontWeight: fontWeight.semiBold,
  },
  caption: {
    fontSize: '0.75rem',
  },
  captionBold: {
    fontSize: '0.75rem',
    fontWeight: fontWeight.semiBold,
  },
  caption2: {
    fontSize: '0.625rem',
  },
  caption2Bold: {
    fontSize: '0.625rem',
    fontWeight: fontWeight.semiBold,
  },
};
