export enum AuditActorType {
  Onboarding = 'CLIENT',
  BackOffice = 'BO_USER',
  System = 'SYSTEM',
  ScheduleJob = 'SCHEDULE_JOB',
}

export type AuditFilter = {
  keyword?: string;
  sortBy?: string;
  success?: string[];
};

export enum AuditLogStatus {
  Failure = 'failure',
  Success = 'success',
}

export type AuditLog = {
  id: string;
  timestamp: string;
  event: string;
  event_type: string;
  actor: string;
  actor_type: string;
  actor_id: string;
  object: string;
  object_id: string;
  source: string;
  success: boolean;
  metadata: object;
};
