export const headerHeight = {
  xs: 72,
};

export const breakpoints = {
  xs: 0,
  md: 1080,
  lg: 1200,
  xl: 1304,
};

export const spacing = 8;
