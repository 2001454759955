import { Option } from '@/types';
import { Button, IconButton, Stack } from '@mui/material';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Icon } from '../Icon';
import {
  FilterMenu,
  FilterOption,
  FilterOptions,
  SearchTextField,
} from './Form.styled';
import { Checkbox } from './Checkbox';
import { FormAction } from '..';
import { getDataTestId } from '@/utils';

type FilterButtonProps = {
  className?: string;
  name?: string;
  options: Option[];
  defaultOptionValues?: Option['value'][];
  values?: (string | number)[];
  onChange?: (value: (string | number)[]) => void;
};

type FilterProps<FormPayload extends FieldValues> = {
  control?: Control<FormPayload>;
  name?: Path<FormPayload>;
} & FilterButtonProps;

export function Filter<FormPayload extends FieldValues>({
  name,
  control,
  values,
  onChange,
  options,
  defaultOptionValues,
}: FilterProps<FormPayload>) {
  if (control && name) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FilterButton
            values={field.value}
            onChange={field.onChange}
            options={options}
          />
        )}
      />
    );
  }

  return (
    <FilterButton
      values={values}
      onChange={onChange}
      options={options}
      defaultOptionValues={defaultOptionValues}
    />
  );
}

export function FilterButton({
  className,
  name,
  options,
  values,
  onChange,
  defaultOptionValues,
}: FilterButtonProps) {
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tempCheckeds, setTempCheckeds] = useState<
    Record<string | number, boolean>
  >({});

  const onTriggerButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTempCheckeds(
      (values || []).reduce(
        (acc, item) => ({
          ...acc,
          [item]: true,
        }),
        {},
      ),
    );
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value || '');
  };

  const onOptionCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setTempCheckeds(prev => ({
      ...prev,
      [e.target.value]: e.target.checked,
    }));
  };

  const onReset = () => {
    const defaultCheckeds = (defaultOptionValues || []).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: (defaultOptionValues || []).includes(cur),
      }),
      {},
    );
    setTempCheckeds(defaultCheckeds);
  };

  const onOk = () => {
    onChange?.(
      Object.entries(tempCheckeds)
        .filter(([, value]) => !!value)
        .map(([key]) => key),
    );
    onClose();
  };

  const filteredOptions = options.filter(option =>
    ('' + (option.text || option.value))
      .toLowerCase()
      .includes(searchText.toLowerCase().trim()),
  );

  return (
    <>
      <IconButton
        {...getDataTestId(`${name}-filter-button`)}
        onClick={onTriggerButton}
        size="small"
        className={className}
      >
        <Icon
          name={values?.length ? 'filter' : 'filter-outline'}
          size={16}
          color={theme => theme.palette.grey[700]}
        />
      </IconButton>
      <FilterMenu
        {...getDataTestId(`filter-menu`)}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <Stack direction="column" gap={3} alignItems="stretch">
          <SearchTextField value={searchText} onChange={onSearchChange} />
          <FilterOptions {...getDataTestId(`filter-menu`)}>
            {filteredOptions.map(({ text, value }) => (
              <FilterOption key={`filter-${value}`}>
                <Checkbox
                  value={value}
                  onChange={onOptionCheck}
                  checked={!!tempCheckeds[value]}
                  label={text}
                />
              </FilterOption>
            ))}
          </FilterOptions>
          <FormAction align="left">
            <Button
              {...getDataTestId(`filter-action-reset-button`)}
              variant="outlined"
              onClick={onReset}
            >
              Reset
            </Button>
            <Button
              {...getDataTestId(`filter-action-ok-button`)}
              onClick={onOk}
            >
              Ok
            </Button>
          </FormAction>
        </Stack>
      </FilterMenu>
    </>
  );
}
