import { Box, Grid, styled, Typography } from '@mui/material';

export const TabNoteItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(1.5),
  alignItems: 'center',
  boxShadow: theme.shadows[1],
}));

export const TabNoteItemFooter = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

export const NoteContent = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  paddingBottom: theme.spacing(2),
  whiteSpace: 'pre-wrap',
}));
