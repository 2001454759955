import { ButtonProps, CircularProgress } from '@mui/material';
import { ButtonLoaderWrapper, StyledLoadingButton } from './Form.styled';

type LoadingButtonProps = {
  isLoading?: boolean;
} & ButtonProps;

export function LoadingButton({
  isLoading,
  children,
  variant = 'contained',
  disabled,
  ...props
}: LoadingButtonProps) {
  return (
    <StyledLoadingButton
      isLoading={isLoading}
      variant={variant}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && (
        <ButtonLoaderWrapper>
          <CircularProgress color="inherit" size={20} />
        </ButtonLoaderWrapper>
      )}
      {children}
    </StyledLoadingButton>
  );
}
