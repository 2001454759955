import { doNotForwardTheseProps } from '@/styles';
import {
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  '::placeholder': {
    color: theme.palette.grey[700],
    opacity: 1,
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiFormHelperText-root': {
    fontSize: theme.typography.captionBold.fontSize,
    marginLeft: 0,
    marginRight: 0,

    '&.Mui-error': {
      fontWeight: theme.typography.captionBold.fontWeight,
    },
  },
}));

export const DropdownOption = styled(MenuItem, {
  shouldForwardProp: doNotForwardTheseProps('multiple'),
})<{ multiple?: boolean }>(({ theme, multiple }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: 32,
  fontSize: theme.typography.body2.fontSize,
  marginTop: theme.spacing(1),

  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },

  '.MuiListItemIcon-root': {
    minWidth: 0,

    ':first-of-type': {
      marginRight: theme.spacing(1),
    },
  },

  '.MuiListItemText-root': {
    flex: 1,
    minWidth: 0,
    wordWrap: 'break-word',
    whiteSpace: 'wrap',
  },

  '&.Mui-selected': {
    backgroundColor: 'transparent',

    ...(multiple
      ? {
          ':hover, :focus': {
            backgroundColor: theme.palette.grey[100],
          },
        }
      : {
          pointerEvents: 'none',
          ':hover, :focus': {
            backgroundColor: 'transparent',
          },
        }),
  },
}));

export const OptionCheckIcon = styled(ListItemIcon)(() => ({
  '&.MuiListItemIcon-root': {
    minWidth: 0,
  },
}));

export const SelectedOption = styled(DropdownOption)(({ theme }) => ({
  backgroundColor: 'unset',
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: 0,

  '.MuiListItemIcon-root': {
    marginRight: theme.spacing(1),
  },
  '.MuiListItemText-root': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ':hover': {
    backgroundColor: 'unset',
  },
}));

export const SelectDrawerSearchBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  position: 'sticky',
  left: 0,
  top: 0,
  zIndex: 1,

  [theme.breakpoints.up('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: 0,
  },
}));

export const SelectGroupName = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ':nth-of-type(2)': {
      marginTop: theme.spacing(1),
    },
  },
}));
SelectGroupName.defaultProps = {
  variant: 'body2Medium',
};

export const Label = styled(InputLabel)(({ theme }) => ({
  marginTop: -4,
  '&.MuiInputLabel-shrink': {
    marginTop: 0,
    marginLeft: 0,
  },
  color: theme.palette.grey[700],
}));

export const AutocompleteOption = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  minHeight: 32,
  fontSize: theme.typography.body2.fontSize,
  wordWrap: 'break-word',
  whiteSpace: 'wrap',

  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const MultipleSelectedOptions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}));
