import { Box, Stack, Typography } from '@mui/material';
import { Avatar } from './Avatar';
import { AvatarButton } from './User.styled';
import { getDataTestId } from '@/utils';

type UserBlockProps = {
  avatar?: string;
  headline?: string;
  name: string;
  email:string;
  description?: string;
  onAvatarClick?: () => void;
  onClick?: () => void;
};

export function CommentAuthor({ avatar, name, email }: UserBlockProps) {
  return (
    <Stack direction="row" gap={2} {...getDataTestId('user-block')}>
      <AvatarButton {...getDataTestId('avatar-edit-button')}>
        <Avatar src={avatar}>
          {name}
        </Avatar>
      </AvatarButton>
      <Box>
        <Stack direction={'row'} gap={0.5}>
          <Typography color={theme => theme.palette.grey[700]} variant="body2">
            Created by
          </Typography>
          <Typography variant="body2Bold">{name}</Typography>
        </Stack>
        <Typography variant="caption" mt={.5} color={theme => theme.palette.grey[700]}>
          {email}
        </Typography>
      </Box>
    </Stack>
  );
}
