import {
  Document,
  DocumentStatus,
  DocumentSubType,
  DocumentType,
  EmploymentStatus,
  Tenant,
} from '@/types';
import { DocumentSubTypeText, DocumentTypeText, StatusText } from '@/constants';
import { useMemo } from 'react';
import {
  getBasicInfoStatus,
  getGeneralDocumentStatus,
  getRequiredDocumentTypes,
  getSignedDocuments,
} from '@/utils';
import { useSelector } from './redux';
import {
  selectClientAccountTypes,
  selectClientDetails,
  selectConfigsState,
  selectSigningDocuments,
} from '@/redux';

export function useClientCalculation() {
  const { isoCode, features, tenant, confirmationOfAddressRequired } =
    useSelector(selectConfigsState);
  const client = useSelector(selectClientDetails);
  const accountTypes = useSelector(selectClientAccountTypes);
  const formsToSign = useSelector(selectSigningDocuments(accountTypes));
  const requiredDocumentTypes = useMemo(
    () => (client ? getRequiredDocumentTypes(client, isoCode, features) : []),
    [client, features, isoCode],
  );

  const { idDocument, groupByType, groups, forms } = useMemo(() => {
    let idDocument: Document | undefined;
    const groups: Partial<
      Record<
        DocumentType,
        {
          type: DocumentType;
          title: string;
          items: Document[];
          generalStatus: DocumentStatus;
          description?: string;
        }
      >
    > = {};

    requiredDocumentTypes.forEach(type => {
      groups[type] = {
        type: type,
        title: DocumentTypeText[type] || type,
        items: [],
        generalStatus: DocumentStatus.Pending,
      };
    });

    (client?.documents || []).forEach(item => {
      if (
        item.type === DocumentType.Identity &&
        item.status !== DocumentStatus.Rejected
      ) {
        idDocument = item;
      }

      if (!groups[item.type]) {
        groups[item.type] = {
          type: item.type,
          title: DocumentTypeText[item.type] || item.type,
          items: [],
          generalStatus: DocumentStatus.Pending,
        };
      }

      if (groups[item.type]) {
        groups[item.type]?.items.push(item);
      }
    });

    Object.values(groups).forEach(group => {
      group.generalStatus = getGeneralDocumentStatus({
        documentsWithSameType: group.items,
        formsToSign,
        confirmationOfAddressRequired,
      });
      switch (group.type) {
        case DocumentType.ProofOfEmployment:
          switch (client?.employmentStatus) {
            case EmploymentStatus.Employed:
              group.title = 'Proof of Employment';
              break;
            case EmploymentStatus.SelfEmployed:
              group.title = 'Proof of Self-Employment';
              break;
            case EmploymentStatus.Unemployed:
              group.title = 'Proof of Unemployment';
              break;
          }

          group.description = StatusText[group.generalStatus];

          break;
        case DocumentType.DocumentSign:
          if (formsToSign.length) {
            const signedDocs = getSignedDocuments(group.items, formsToSign);
            const remainingDocsToSign = Math.max(
              formsToSign.length - signedDocs.length,
              0,
            );

            group.items = group.items.map(doc => {
              const mappedForm = formsToSign.find(
                item => item.formName === doc.docType,
              );
              return {
                ...doc,
                formName: mappedForm?.name,
              };
            });

            group.description =
              StatusText[group.generalStatus] || group.generalStatus;
            if (remainingDocsToSign) {
              group.description += ` - ${remainingDocsToSign} document${remainingDocsToSign >= 2 ? 's' : ''} to sign`;
            }
          }
          break;
        case DocumentType.IdentityBack:
          if (!group.items.length) {
            delete groups[DocumentType.IdentityBack];
          }
          break;
        case DocumentType.ProofOfAddress:
          if (tenant === Tenant.Grenada) {
            const approvalStatus = group.items.reduce(
              (prev, curr) => {
                if (
                  curr.docType === DocumentSubType.BankStatement &&
                  curr.status === DocumentStatus.Approved
                ) {
                  prev[DocumentSubType.BankStatement] = true;
                }
                if (
                  curr.docType === DocumentSubType.UtilityBill &&
                  curr.status === DocumentStatus.Approved
                ) {
                  prev[DocumentSubType.UtilityBill] = true;
                }
                if (
                  curr.docType === DocumentSubType.ConfirmationOfAddress &&
                  curr.status === DocumentStatus.Approved
                ) {
                  prev[DocumentSubType.ConfirmationOfAddress] = true;
                }
                return prev;
              },
              {
                [DocumentSubType.BankStatement]: false,
                [DocumentSubType.UtilityBill]: false,
                [DocumentSubType.ConfirmationOfAddress]: false,
              },
            );
            if (
              approvalStatus[DocumentSubType.ConfirmationOfAddress] &&
              (approvalStatus[DocumentSubType.BankStatement] ||
                approvalStatus[DocumentSubType.UtilityBill])
            ) {
              group.generalStatus = DocumentStatus.Approved;
            }
          }
          break;
      }
    });

    return {
      idDocument,
      groupByType: groups,
      groups: Object.values(groups).map(group => ({
        ...group,
        items: group.items.sort((a, b) => {
          if (group.type === DocumentType.DocumentSign) {
            return (
              DocumentSubTypeText[a.docType as string] ||
              a.docType ||
              'Document'
            ).localeCompare(
              DocumentSubTypeText[b.docType as string] ||
                b.docType ||
                'Document',
            );
          }

          const timeA = a.reviewDueDate
            ? new Date(a.reviewDueDate).getTime()
            : 0;
          const timeB = b.reviewDueDate
            ? new Date(b.reviewDueDate).getTime()
            : 0;

          return timeB - timeA;
        }),
      })),
      forms: formsToSign.map(form => ({
        ...form,
        isSigned: groups[DocumentType.DocumentSign]?.items.find(
          i =>
            i.adobeSignWidgetId === form.id &&
            i.status === DocumentStatus.Approved,
        ),
      })),
    };
  }, [
    tenant,
    client,
    confirmationOfAddressRequired,
    formsToSign,
    requiredDocumentTypes,
  ]);

  const basicInfoStatus = useMemo(() => getBasicInfoStatus(client), [client]);

  const onboardProgress = useMemo(() => {
    const statuses = [basicInfoStatus, ...groups.map(i => i.generalStatus)];
    return {
      finished: statuses.filter(status => status === DocumentStatus.Approved)
        .length,
      total: statuses.length,
    };
  }, [basicInfoStatus, groups]);

  const idDocuments =
    client?.documents?.filter(
      item =>
        ![DocumentStatus.Rejected].includes(item.status) &&
        [
          DocumentType.Identity,
          DocumentType.IdentityBack,
          DocumentType.Selfie,
        ].includes(item.type),
    ) || [];

  const allIdDocsSubmitted =
    !!idDocuments.find(item => item.type === DocumentType.Selfie) &&
    !!idDocuments.find(item => item.type === DocumentType.Identity);

  return {
    isIDVerified:
      allIdDocsSubmitted &&
      idDocuments.every(item => item.status === DocumentStatus.Approved),
    idDocument,
    documentsByType: groupByType,
    documentsGroups: groups,
    forms,
    basicInfoStatus,
    onboardProgress,
  };
}
