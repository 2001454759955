import { Components, createTheme } from '@mui/material';
import { spacing } from '../constants/layout';
import { paletteConfigs } from '../palette';

const theme = createTheme({
  spacing,
  palette: paletteConfigs,
});

export const switchConfigs: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      borderRadius: 86,
      width: 40,
      height: 24,
      padding: 0,

      '.MuiSwitch-switchBase': {
        padding: theme.spacing(0.5),

        ':hover': {
          backgroundColor: 'transparent',
        },

        '+ .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
        },
        '.MuiSwitch-thumb': {
          boxShadow: 'none',
          height: 16,
          width: 16,
        },

        '&.Mui-checked': {
          transform: 'translateX(16px)',

          '.MuiSwitch-thumb': {
            backgroundColor: theme.palette.common.white,
          },
          '+ .MuiSwitch-track': {
            backgroundColor: theme.palette.primaries[400],
            opacity: 1,
          },
        },

        '&.Mui-disabled': {
          '.MuiSwitch-thumb': {
            backgroundColor: theme.palette.common.white,
          },
          '+ .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
          },

          '&.Mui-checked': {
            '+ .MuiSwitch-track': {
              backgroundColor: theme.palette.grey[300],
            },
          },
        },
      },
    },
  },
};
