import {
  ABOUT_TO_EXPIRE_DURATION_MILISECONDS,
  DOCUMENT_STATUS_STEP,
  DocumentSubTypeText,
} from '@/constants';
import {
  AccountTypeDetails,
  Document,
  DocumentStatus,
  DocumentSubType,
  DocumentType,
  SigningDocument,
} from '@/types';
import { getTimeDuration } from './time';
import { createMockDocument } from '@/test-utils/createMock';
import {
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
} from './string';
import { grenadaConfigs } from '@/constants';

export function standardizeClientDocuments(documents?: Document[]) {
  return (documents || []).map(item => {
    let status = item.status;
    let overdueText: string | undefined;

    if (item.expiryDate && !isInactiveDocument(item)) {
      const timeLeft =
        new Date(item.expiryDate).getTime() - new Date().getTime();

      if (timeLeft <= 0) {
        status = DocumentStatus.Expired;
      } else if (timeLeft <= ABOUT_TO_EXPIRE_DURATION_MILISECONDS) {
        status = DocumentStatus.AboutToExpire;
      }
    }

    if (
      [DocumentStatus.Pending, DocumentStatus.InReview].includes(status) &&
      item.reviewDueDate
    ) {
      const timeLeft =
        new Date(item.reviewDueDate).getTime() - new Date().getTime();

      if (timeLeft <= 0) {
        overdueText = `${getTimeDuration(item.reviewDueDate, new Date().getTime())} overdue`;
      }
    }

    return {
      ...item,
      status,
      overdueText,
    };
  });
}

export function getGeneralDocumentStatus({
  documentsWithSameType: documents,
  formsToSign,
  confirmationOfAddressRequired,
}: {
  documentsWithSameType: Document[];
  confirmationOfAddressRequired?: boolean;
  formsToSign?: SigningDocument[];
}) {
  if (!documents.length) return DocumentStatus.Pending;

  const generalType = documents[0].type;

  if (
    generalType === DocumentType.ProofOfAddress &&
    confirmationOfAddressRequired
  ) {
    return getProofOfAddressStatusWithConfirmation(documents);
  } else if (generalType === DocumentType.DocumentSign) {
    return getDocumentTypeStatus(documents, formsToSign?.length || 1);
  }

  return getDocumentTypeStatus(documents);
}

export function getHigherStatusDocument(
  documents: Document[],
  order: 'approved-first' | 'reject-first' = 'approved-first',
) {
  let result: Document | undefined;

  for (const doc of documents) {
    if (!result) {
      result = doc;
      continue;
    }
    const currentStatus = result.status;
    const placeholder = order === 'approved-first' ? 99 : 0;
    const currentStep = DOCUMENT_STATUS_STEP[currentStatus] || placeholder;
    const step = DOCUMENT_STATUS_STEP[String(doc.status)] || placeholder;

    const difference =
      order === 'approved-first' ? step - currentStep : currentStep - step;

    if (difference < 0) {
      result = doc;
    }
  }

  return result as Document;
}

export function getHigherStatus(
  statuses: (DocumentStatus | undefined)[],
  order: 'approved-first' | 'reject-first' = 'approved-first',
) {
  let result: DocumentStatus | undefined;

  for (const status of statuses) {
    if (!result) {
      result = status;
      continue;
    }
    const currentStatus = result;
    const currentStep = DOCUMENT_STATUS_STEP[currentStatus] || 99;
    const step = DOCUMENT_STATUS_STEP[String(status)] || 99;

    const difference =
      order === 'approved-first' ? step - currentStep : currentStep - step;

    if (difference < 0) {
      result = status;
    }
  }

  return result as DocumentStatus;
}

export function groupDocuments(
  documents: Document[],
  groupBy: 'sub-type' | 'form-id' = 'sub-type',
) {
  const documentByGroup: Record<string, Document> = {};

  for (const doc of documents) {
    const groupKey =
      (groupBy === 'sub-type' ? doc.docType : doc.adobeSignWidgetId) ||
      'untitled';

    if (!documentByGroup[groupKey]) {
      documentByGroup[groupKey] = doc;
    } else {
      documentByGroup[groupKey] = getHigherStatusDocument(
        [doc, documentByGroup[groupKey]],
        'approved-first',
      );
    }
  }

  return documentByGroup;
}

export function getDocumentGroupStatuses(
  documents: Document[],
  groupBy?: 'sub-type' | 'form-id',
) {
  const result: Record<string, DocumentStatus> = {};
  const documentBySubType = groupDocuments(documents, groupBy);
  for (const key in documentBySubType) {
    result[key] = documentBySubType[key].status;
  }
  return result;
}

export function getDocumentTypeStatus(
  documentsWithSameType: Document[] = [],
  requiredAmount = 1,
) {
  if (!documentsWithSameType.length) return DocumentStatus.Pending;

  const documentBySubType = groupDocuments(documentsWithSameType);
  const nonDuplicatedDocuments = Object.values(documentBySubType);

  if (requiredAmount < 1) return DocumentStatus.Pending;

  if (requiredAmount === 1) {
    return getHigherStatusDocument(nonDuplicatedDocuments, 'approved-first')
      ?.status;
  }

  while (nonDuplicatedDocuments.length < requiredAmount) {
    nonDuplicatedDocuments.push(
      createMockDocument({
        status: DocumentStatus.Empty,
      }),
    );
  }

  const status = getHigherStatusDocument(
    nonDuplicatedDocuments,
    'reject-first',
  )?.status;

  return [DocumentStatus.Empty, DocumentStatus.Signing].includes(
    status as DocumentStatus,
  )
    ? DocumentStatus.InReview
    : status;
}

export function getProofOfAddressStatusWithConfirmation(
  proofOfAddressDocuments: Document[],
) {
  const validDocuments = proofOfAddressDocuments.filter(item =>
    [
      DocumentSubType.ConfirmationOfAddress,
      DocumentSubType.BankStatement,
      DocumentSubType.UtilityBill,
    ].includes(item.docType as DocumentSubType),
  );

  const statusBySubType = getDocumentGroupStatuses(validDocuments, 'sub-type');

  if (
    statusBySubType[DocumentSubType.ConfirmationOfAddress] &&
    (statusBySubType[DocumentSubType.BankStatement] ||
      statusBySubType[DocumentSubType.UtilityBill])
  ) {
    return getHigherStatus(
      [
        statusBySubType[DocumentSubType.ConfirmationOfAddress],
        getHigherStatus(
          [
            statusBySubType[DocumentSubType.BankStatement],
            statusBySubType[DocumentSubType.UtilityBill],
          ],
          'approved-first',
        ),
      ],
      'reject-first',
    );
  }

  return DocumentStatus.Pending;
}

export function getSignedDocuments(
  documentsByType: Document[] = [],
  formsToSign: SigningDocument[] = [],
) {
  if (!formsToSign.length) return [];

  const formIdsToSign = formsToSign.map(doc => doc.id);

  const result: Document[] = [];

  for (const doc of documentsByType) {
    if (
      !doc.adobeSignWidgetId ||
      !formIdsToSign.includes(doc.adobeSignWidgetId)
    )
      continue;

    if (
      [
        DocumentStatus.InReview,
        DocumentStatus.Pending,
        DocumentStatus.Signing,
        DocumentStatus.Approved,
      ].includes(doc.status)
    ) {
      result.push(doc);
    }
  }

  return result;
}

export function isInactiveDocument(document: Document) {
  return (
    document.type === DocumentType.Internal ||
    [
      DocumentStatus.Approved,
      DocumentStatus.Rejected,
      DocumentStatus.Requested,
    ].includes(document.status) ||
    [DocumentType.Generated].includes(document.type)
  );
}

export function standardizeDocumentsToSign(
  signingDocs: SigningDocument[],
  accountTypes: AccountTypeDetails[],
) {
  const authForm = signingDocs.find(item => /authorization/i.test(item.name));
  if (!authForm || !accountTypes.length) return signingDocs;

  const nonAuthForms = signingDocs.filter(
    item => !/authorization/i.test(item.name),
  );

  return [
    ...(authForm && accountTypes.length
      ? accountTypes.map(
          accountType =>
            ({
              ...authForm,
              name: `${authForm.name} (${accountType.currency} ${accountType.name})`,
              formName: `${authForm.name}|${accountType.type}-${accountType.classCode}-${accountType.currency}`,
            }) as SigningDocument,
        )
      : []),
    ...nonAuthForms.map(item => ({
      ...item,
      formName: item.name,
    })),
  ];
}

export function isViewSupportedDocument(doc: Document) {
  return (
    isImageContentType(doc.contentType) ||
    isPDFContentType(doc.contentType) ||
    isExcelContentType(doc.contentType)
  );
}

export function getDocSubTypesByType(
  type: DocumentType,
  options: { tenantISO?: string; formsToSign?: SigningDocument[] } = {},
) {
  const { formsToSign = [], tenantISO = '' } = options;

  if (type === DocumentType.DocumentSign) {
    return formsToSign
      .map(form => ({
        value: form.formName || form.name,
        text: form.name,
      }))
      .sort((a, b) => (a.text || '').localeCompare(b.text || ''));
  }

  let subTypes: DocumentSubType[] = [];
  const defaultSubTypes: DocumentSubType[] = [
    DocumentSubType.None,
    DocumentSubType.BankReceipt,
    DocumentSubType.BankStatement,
    DocumentSubType.LetterOfEmployment,
    DocumentSubType.SecurityCard,
    DocumentSubType.UtilityBill,
  ];

  if (type === DocumentType.Identity) {
    subTypes = [
      DocumentSubType.DrivingLicense,
      DocumentSubType.Id,
      DocumentSubType.Passport,
      ...([grenadaConfigs.isoCode].includes(tenantISO)
        ? [DocumentSubType.Nis]
        : []),
    ];
  } else if ([DocumentType.IdentityBack, DocumentType.Selfie].includes(type)) {
    subTypes = [DocumentSubType.None];
  } else if (type === DocumentType.ProofOfAddress) {
    subTypes = [
      ...defaultSubTypes,
      ...([grenadaConfigs.isoCode].includes(tenantISO)
        ? [DocumentSubType.ConfirmationOfAddress]
        : []),
    ];
  } else {
    subTypes = defaultSubTypes;
  }

  return subTypes
    .map(subType => ({
      value: subType,
      text: DocumentSubTypeText[subType],
      groupOrder: subType === DocumentSubType.None ? 0 : 1,
    }))
    .sort((a, b) =>
      a.groupOrder !== b.groupOrder
        ? a.groupOrder - b.groupOrder
        : (a.text || '').localeCompare(b.text || ''),
    );
}
