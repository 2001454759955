import { Box, Container, styled } from '@mui/material';
import { BasicDrawer } from './BasicDrawer';

export const UserDrawer = styled(BasicDrawer)(() => ({
  '.simplebar-content': {
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const UserDrawerFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(2),
  alignItems: 'flex-end',
}));

export const UserPermissionContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowY: 'auto',
}));
