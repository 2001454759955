import { approvePEPCheck, getPEPInfo } from '@/api';
import { ApprovePEPConfirmModal, MenuButton } from '@/components';
import { FullPageLoaderOverlay } from '@/components';
import { useDispatch } from '@/hooks';
import { fetchClientDetails, showPEPReportDrawer, showToast } from '@/redux';
import { AMLStatus, Client, Option, PEPStatus } from '@/types';
import { getAPIErrorMessage, isActiveClient } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

type PEPReviewButtonProps = {
  client: Client;
};

export function PEPReviewButton({ client }: PEPReviewButtonProps) {
  const dispatch = useDispatch();
  const shouldDisplay =
    client.pepStatus?.pepCheckStatus === PEPStatus.Exposed ||
    client.pepStatus?.amlCheckStatus === AMLStatus.Sanctioned;

  const {
    value: isConfirming,
    setTrue: showConfirmModal,
    setFalse: hideConfirmModal,
  } = useBoolean(false);

  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: async (clientId: Client['id']) => {
      const details = await getPEPInfo({ clientId });
      return await approvePEPCheck({
        clientId,
        pepRecordId: details.id,
      });
    },
  });

  const pepReviewOptions: Option[] = [
    {
      value: 'review',
      text: 'Review',
      icon: 'search-doc',
    },
    ...(isActiveClient(client)
      ? [
          {
            value: 'approve',
            text: 'Approve',
            icon: 'check-circle-outline',
          } as Option,
        ]
      : []),
  ];

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: `PEP status verified successfully!`,
          severity: 'success',
        }),
      );
      dispatch(fetchClientDetails(String(client.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error, {
            defaultMessage: 'PEP status change failed. Please try again.',
          }),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onOptionSelect = useCallback(
    (selected: Option) => {
      switch (selected.value) {
        case 'review':
          dispatch(showPEPReportDrawer(client));
          break;
        case 'approve':
          showConfirmModal();
          break;
      }
    },
    [client, dispatch, showConfirmModal],
  );

  const onApprove = () => {
    reset();
    mutate(client.id);
  };

  if (shouldDisplay) {
    return (
      <>
        <MenuButton options={pepReviewOptions} onSelect={onOptionSelect} />
        <ApprovePEPConfirmModal
          open={isConfirming}
          onApprove={onApprove}
          onClose={hideConfirmModal}
        />
        {isPending && (
          <FullPageLoaderOverlay
            bgColor={theme => theme.palette.grey.overlayOnLight}
          />
        )}
      </>
    );
  }

  return null;
}
