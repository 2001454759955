import { doNotForwardTheseProps } from '@/styles';
import { Button, Card, styled } from '@mui/material';

const RoleCard = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  borderRadius: 12,
  height: 240,
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
}));

export const UserCountContainer = styled(Button, {
  shouldForwardProp: doNotForwardTheseProps('isViewUserPermission'),
})<{
  isViewUserPermission?: boolean;
}>(({ isViewUserPermission }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  cursor: isViewUserPermission ? 'pointer' : 'default',
  ...(!isViewUserPermission && {
    ':hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
      textDecoration: 'inherit',
    },
  }),
}));

export default RoleCard;
