import { ColorExtractFn } from '@/types';
import { Typography, useTheme } from '@mui/material';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarProps,
  ResponsiveContainerProps,
  Tooltip,
} from 'recharts';
import { ChartTooltipContent } from './DataDisplay.styled';

type BarChartProps = {
  data: Record<string, string | number>[];
  categoryNameKey: string;
  categories: {
    dataKey: string;
    color?: ColorExtractFn;
  }[];
  containerProps?: Omit<ResponsiveContainerProps, 'children'>;
};

export function BarChart({
  data,
  categories,
  categoryNameKey,
  containerProps,
}: BarChartProps) {
  const theme = useTheme();

  const axisProps = {
    tickSize: 0,
    tick: {
      fontSize: theme.typography.caption2Bold.fontSize,
      fontWeight: theme.typography.caption2Bold.fontWeight,
    },
    tickMargin: 16,
    axisLine: false,
  };

  return (
    <ResponsiveContainer width="100%" height="100%" {...containerProps}>
      <ReBarChart
        data={data}
        margin={{
          top: 16,
        }}
        barGap={0}
        barCategoryGap={0}
        barSize={8}
      >
        <CartesianGrid stroke={theme.palette.grey[300]} vertical={false} />
        <XAxis
          {...axisProps}
          dataKey={categoryNameKey}
          padding={{
            left: 0,
            right: 0,
          }}
        />
        <YAxis {...axisProps} tickCount={7} />
        {categories.map(({ dataKey, color }) => (
          <Bar
            key={dataKey}
            dataKey={dataKey}
            fill={color?.(theme)}
            shape={BarShape}
            background={{ fill: 'transparent' }}
          />
        ))}
        <Tooltip
          cursor={false}
          isAnimationActive={false}
          content={({ payload, label }) => (
            <ChartTooltipContent>
              <Typography
                variant="caption"
                mb={1}
                color={theme => theme.palette.grey[700]}
              >
                {label}
              </Typography>
              {(payload || []).map((item, index) => (
                <Typography
                  key={`tooltip-item-${index}`}
                  variant="caption"
                  mt={index > 0 ? 0.5 : 0}
                >
                  <Typography variant="captionBold" component="span">
                    {item?.value}
                  </Typography>{' '}
                  {item?.name}
                </Typography>
              ))}
            </ChartTooltipContent>
          )}
        />
      </ReBarChart>
    </ResponsiveContainer>
  );
}

function BarShape({ x, y, width, height, fill }: BarProps) {
  return <rect x={x} y={y} width={width} height={height} fill={fill} rx={4} />;
}
