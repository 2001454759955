import { Box } from '@mui/material';
import { AddCommentSection } from '@/pages/clients/ClientDetails/TabNotes/AddCommentSection';
import { Client, Note } from '@/types';
import { NoteItem } from './NoteItem';
import { Can, Loader } from '@/components';

type TabNotesProps = {
  client: Client;
  notes: Note[];
  isFetching: boolean;
  onRefetch: () => void;
};

export function TabNotes({
  isFetching,
  notes,
  client,
  onRefetch,
}: TabNotesProps) {
  isFetching && (
    <Loader
      text="Loading"
      justifyContent="center"
      iconProps={{
        size: 32,
      }}
    />
  );

  return (
    <Box>
      <Can do="edit" on="onboarding_application_notes">
        <AddCommentSection client={client as Client} onSuccess={onRefetch} />
      </Can>
      <Box mt={4}>
        {(notes || []).map(note => (
          <NoteItem
            key={`note-item-${note.id}`}
            client={client as Client}
            note={note}
            refresh={onRefetch}
          />
        ))}
      </Box>
    </Box>
  );
}
