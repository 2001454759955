import {
  SwitchProps as MUISwitchProps,
  Stack,
  Typography,
  Switch as MUISwitch,
} from '@mui/material';
import { useCallback } from 'react';
import { getDataTestId } from '@/utils';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';

type SwitchProps<FormPayload extends FieldValues> = {
  control?: Control<FormPayload>;
  label?: string;
  name?: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
} & Omit<MUISwitchProps, 'variant' | 'name'>;

export function Switch<FormPayload extends FieldValues>({
  control,
  name,
  rules,
  label,
  value,
  onChange,
  onBlur,
  ...props
}: SwitchProps<FormPayload>) {
  const render = useCallback(
    (
      value: MUISwitchProps['value'],
      onChange: MUISwitchProps['onChange'],
      onBlur: MUISwitchProps['onBlur'],
      defaultValue?: MUISwitchProps['value'],
    ) => (
      <Stack gap={1.5}>
        <MUISwitch
          {...getDataTestId(`${label}-switch`)}
          checked={
            typeof value === 'boolean'
              ? value
              : (defaultValue as boolean) || false
          }
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
        {label && (
          <Typography component="span" variant="body2">
            {label}
          </Typography>
        )}
      </Stack>
    ),
    [label, props],
  );

  if (control && name) {
    return (
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, formState }) =>
          render(value, onChange, onBlur, formState.defaultValues?.[name])
        }
      />
    );
  }

  return render(value, onChange, onBlur);
}
