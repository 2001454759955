import {
  ApproveAccountDrawer,
  AssignClientModal,
  DocumentReviewDrawer,
  FileViewer,
  Header,
  PEPReportDrawer,
  Toast,
  AddUserDrawer,
  AbilityContext,
  ManageDocumentsDrawer,
  RequestDocumentDrawer,
  ExportClientDrawer,
} from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import {
  fetchSigningForms,
  fetchReviewers,
  selectUIState,
  hideModal,
  fetchRiskLevels,
  fetchUserRoles,
  fetchPermissions,
  selectLoggedInUser,
  fetchFeatures,
  fetchTerritories,
} from '@/redux';
import { Client, ModalName, PermissionName, User } from '@/types';
import {
  Navigate,
  RouteObject,
  useLocation,
  useRoutes,
} from 'react-router-dom';
import { AuthRootContainer } from './Root.styled';
import { ROUTE } from '@/constants';
import { HomePage } from '../home';
import { ClientDetailsPage, ClientsPage } from '../clients';
import { ChangePasswordPage } from '../change-password';
import { useEffect } from 'react';
import { DocumentsPage } from '../documents';
import { buildAbilityFor } from '@/utils/defineAbility';
import { AdministrationPage } from '../administration';
import { RoleDetail } from '../administration/RoleDetail';
import { AbilityRouteHandler } from './AbilityRouteHandler';
import { ActivityLogsPage } from '../activity-logs';

const authorizedRoutes: RouteObject[] = [
  {
    path: ROUTE.HOME,
    element: <HomePage />,
  },
  {
    path: ROUTE.CLIENTS,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'client',
        }}
      >
        <ClientsPage />
      </AbilityRouteHandler>
    ),
  },
  {
    path: ROUTE.CLIENT_DETAILS,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'client',
        }}
      >
        <ClientDetailsPage />
      </AbilityRouteHandler>
    ),
  },
  {
    path: ROUTE.DOCUMENTS,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'document',
        }}
      >
        <DocumentsPage />
      </AbilityRouteHandler>
    ),
  },
  {
    path: ROUTE.ACTIVITY_LOGS,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'report',
        }}
      >
        <ActivityLogsPage />
      </AbilityRouteHandler>
    ),
  },
  {
    path: ROUTE.CHANGE_PASSWORD,
    element: <ChangePasswordPage />,
  },
  {
    path: ROUTE.ADMINISTRATION,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'administration',
        }}
      >
        <AdministrationPage />
      </AbilityRouteHandler>
    ),
  },
  {
    path: ROUTE.ADMINISTRATION_ROLE_DETAIL,
    element: (
      <AbilityRouteHandler
        permission={{
          do: 'view',
          on: 'administration',
        }}
      >
        <RoleDetail />
      </AbilityRouteHandler>
    ),
  },
  {
    path: '*',
    element: <Navigate to={ROUTE.HOME} />,
  },
];

export function AuthenticatedPage() {
  const {
    manageDocumentsDrawer,
    documentRequestDrawer,
    documentReviewDrawer,
    addUserDrawer,
    approveAccountDrawer,
    assignClientModal,
    exportClientDrawer,
    pepReportDrawer,
    fileViewer,
    toast,
  } = useSelector(selectUIState);
  const dispatch = useDispatch();
  const authElements = useRoutes(authorizedRoutes);
  const user = useSelector(selectLoggedInUser);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchFeatures());
    dispatch(fetchReviewers());
    dispatch(fetchTerritories());
    dispatch(fetchRiskLevels());
    dispatch(fetchSigningForms());
    if (user?.permissions?.includes(PermissionName.RoleView)) {
      dispatch(fetchUserRoles());
      dispatch(fetchPermissions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (manageDocumentsDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ManageDocuments }));
    }
    if (documentRequestDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.RequestDocument }));
    }
    if (documentReviewDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ReviewDocument }));
    }
    if (addUserDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.AddUser }));
    }
    if (pepReportDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.PEPReport }));
    }
    if (approveAccountDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ApproveAccount }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AbilityContext.Provider value={buildAbilityFor(user as User)}>
      <AuthRootContainer>
        <Header />
        {authElements}
        <ManageDocumentsDrawer
          open={manageDocumentsDrawer.isOpen}
          payload={manageDocumentsDrawer.payload}
        />
        <RequestDocumentDrawer
          open={documentRequestDrawer.isOpen}
          payload={documentRequestDrawer.payload}
        />
        <DocumentReviewDrawer
          open={documentReviewDrawer.isOpen}
          client={documentReviewDrawer.payload?.client}
          document={documentReviewDrawer.payload?.document}
        />
        <AddUserDrawer
          open={addUserDrawer.isOpen}
          user={addUserDrawer.payload?.user}
          isEdit={addUserDrawer.payload?.isEdit}
          isChangeRole={addUserDrawer.payload?.isChangeRole}
        />
        {fileViewer.document && <FileViewer document={fileViewer.document} />}
        <AssignClientModal
          open={assignClientModal.isOpen}
          client={assignClientModal.payload}
        />
        <ExportClientDrawer open={exportClientDrawer.isOpen} />
        <ApproveAccountDrawer
          open={approveAccountDrawer.isOpen}
          client={approveAccountDrawer.payload}
        />
        <PEPReportDrawer
          open={pepReportDrawer.isOpen}
          client={pepReportDrawer.payload as Client}
        />
        <Toast
          open={toast.isOpen}
          message={toast.message}
          severity={toast.severity}
          autoHide={toast.autoHide}
        />
      </AuthRootContainer>
    </AbilityContext.Provider>
  );
}
