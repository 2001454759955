import {
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Avatar } from './Avatar';
import { ClientBlockAvatar, ClientBlockRow } from './User.styled';
import { AvatarProps } from './User.interface';
import { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { ROUTE } from '@/constants';
import { Client } from '@/types';

type ClientBlockProps = {
  className?: string;
  clientId?: Client['id'];
  avatar?: string;
  avatarProps?: AvatarProps;
  name: ReactNode;
  nameProps?: TypographyProps;
  description?: ReactNode;
  descriptionProps?: TypographyProps;
  direction?: 'row' | 'column';
  center?: boolean;
  onClick?: () => void;
};

export function ClientBlock({
  className,
  clientId,
  avatar,
  avatarProps,
  name,
  nameProps,
  description,
  descriptionProps,
  direction = 'row',
  center = true,
  onClick,
}: ClientBlockProps) {
  if (direction === 'row') {
    return (
      <ClientBlockAnchor
        onClick={onClick}
        href={clientId ? `${ROUTE.CLIENTS}/${clientId}` : undefined}
        center={center}
        className={className}
      >
        <ClientBlockAvatar big={avatarProps?.big}>
          <Avatar src={avatar} {...avatarProps}>
            {name}
          </Avatar>
        </ClientBlockAvatar>
        <ListItemText
          primary={
            typeof name === 'string' ? (
              <span title={name}>
                <Typography variant="body2" mb={0.5} {...nameProps}>
                  {name}
                </Typography>
              </span>
            ) : (
              name
            )
          }
          secondary={
            typeof description === 'string' ? (
              <Typography
                variant="caption"
                color={theme => theme.palette.grey[700]}
                {...descriptionProps}
              >
                {description}
              </Typography>
            ) : (
              description
            )
          }
        />
      </ClientBlockAnchor>
    );
  }

  if (direction === 'column') {
    return (
      <Stack direction="column" gap={0}>
        <Avatar src={avatar} {...avatarProps}>
          {name}
        </Avatar>
        {typeof name === 'string' ? (
          <Typography variant="body2" mt={1.5} {...nameProps}>
            {name}
          </Typography>
        ) : (
          name
        )}
        {typeof description === 'string' ? (
          <Typography
            variant="caption"
            color={theme => theme.palette.grey[700]}
            mt={0.5}
            {...descriptionProps}
          >
            {description}
          </Typography>
        ) : (
          description
        )}
      </Stack>
    );
  }

  return null;
}

type ClientBlockAnchorProps = {
  onClick?: () => void;
  href?: string;
  center?: boolean;
  className?: string;
} & PropsWithChildren;

export function ClientBlockAnchor({
  onClick,
  href,
  children,
  center = true,
  className,
}: ClientBlockAnchorProps) {
  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick?.();
  };

  return (
    <ClientBlockRow
      className={className}
      onClick={handleOnClick}
      href={href}
      center={center}
    >
      {children}
    </ClientBlockRow>
  );
}
