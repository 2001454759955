import { DATE_FORMAT_TEMPLATE } from '@/constants';
import { format, intervalToDuration, isToday } from 'date-fns';

export function formatDateValue(value: string, template: string) {
  if (!value) return '';

  const parts = value.split('-');
  if (parts.length < 3) return '';

  const [year, month, day] = parts;
  if (year.length !== 4 || month.length !== 2 || day.length !== 2) return '';

  return template
    .replaceAll('dd', day)
    .replaceAll('MM', month)
    .replaceAll('yyyy', year);
}

export function formatDate(
  timestamp: Date | string | number,
  template: string,
) {
  if (!timestamp) return '';

  const original = timestamp instanceof Date ? timestamp : new Date(timestamp);
  const isoDate = new Date(
    original.valueOf() + original.getTimezoneOffset() * 60 * 1000,
  );

  try {
    return format(isoDate, template);
  } catch {
    return '';
  }
}

export function formatTime(
  timestamp?: string | number,
  options: {
    timeOnOtherDay?: boolean;
    dateFormat?: string;
    timeFormat?: string;
  } = {},
) {
  if (!timestamp) return '';

  const {
    timeOnOtherDay = true,
    dateFormat = DATE_FORMAT_TEMPLATE,
    timeFormat = 'hh:mm aa',
  } = options;

  return isToday(timestamp || '')
    ? `Today, ${format(timestamp || '', timeFormat)}`
    : format(
        timestamp || '',
        `${dateFormat}${timeOnOtherDay ? `, ${timeFormat}` : ''}`,
      );
}

export function getTimeDuration(
  start?: number | Date | string,
  end?: number | Date | string,
) {
  if (!start || !end) return '';

  const timeDuration = intervalToDuration({
    start,
    end,
  });

  let amount = 0;
  let unit = '';

  if (timeDuration.years) {
    amount = timeDuration.years;
    unit = 'year';
  } else if (timeDuration.months) {
    amount = timeDuration.months;
    unit = 'month';
  } else if (timeDuration.weeks) {
    amount = timeDuration.weeks;
    unit = 'week';
  } else if (timeDuration.days) {
    amount = timeDuration.days;
    unit = 'day';
  } else if (timeDuration.hours) {
    amount = timeDuration.hours;
    unit = 'hour';
  } else {
    amount = timeDuration.minutes || 0;
    unit = 'minute';
  }

  if (amount > 1) {
    unit += 's';
  }

  return `${amount} ${unit}`;
}
