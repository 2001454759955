import { Box, styled, Tabs } from '@mui/material';

export const RolePermissionsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: 0,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowY: 'auto',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar-track': {
    background: theme.palette.grey[500],
  },
}));

export const RolePermissionsTab = styled(Tabs)(() => ({
  marginBottom: 0,
}));
