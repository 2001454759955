import { SvgIcon, useTheme } from '@mui/material';
import { IconProps } from './Icon.interface';
import { getIconComponent } from '@/utils';

export function Icon({ name, color, size = 24, ...props }: IconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      data-test-name={name}
      component={getIconComponent(name)}
      htmlColor={color ? color(theme) : theme.palette.text.primary}
      inheritViewBox
      sx={{ width: size, height: size }}
      {...props}
    />
  );
}
