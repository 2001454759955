import { Button, ButtonProps } from '@mui/material';
import { VisuallyHiddenFileInput } from './Form.styled';
import { ChangeEvent } from 'react';

type UploadButtonProps = {
  accept?: string;
  multiple?: boolean;
  onChange?: (files: FileList | null) => void;
  isRounded?: boolean;
} & Omit<ButtonProps, 'onChange'>;

export function UploadButton({
  children,
  accept,
  multiple,
  onChange,
  disabled,
  ...props
}: UploadButtonProps) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.currentTarget.files);
  };

  return (
    <Button
      component="label"
      variant="contained"
      tabIndex={-1}
      fullWidth
      {...props}
    >
      <VisuallyHiddenFileInput
        multiple={multiple}
        accept={accept}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {children}
    </Button>
  );
}
