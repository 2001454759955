import { AppConfigurations, Tenant } from '@/types';
import { bviConfigs } from './bvi';
import { barbadosConfigs } from './barbados';
import { saintLuciaConfigs } from './st-lucia';
import { caymanConfigs } from './cayman';
import { dominicaConfigs } from './dominica';
import { ghanaConfigs } from './ghana';
import { grenadaConfigs } from './grenada';
import { guyanaConfigs } from './guyana';
import { sintMaartenConfigs } from './sint-maarten';
import { saintKittsConfigs } from './st-kitts';
import { saintVincentConfigs } from './st-vincent';
import { surinameConfigs } from './suriname';
import { anguillaConfigs } from './anguilla';

export { bviConfigs } from './bvi';
export { barbadosConfigs } from './barbados';
export { saintLuciaConfigs } from './st-lucia';
export { caymanConfigs } from './cayman';
export { dominicaConfigs } from './dominica';
export { ghanaConfigs } from './ghana';
export { grenadaConfigs } from './grenada';
export { guyanaConfigs } from './guyana';
export { sintMaartenConfigs } from './sint-maarten';
export { saintKittsConfigs } from './st-kitts';
export { saintVincentConfigs } from './st-vincent';
export { surinameConfigs } from './suriname';
export { anguillaConfigs } from './anguilla';
export { defaultConfigs } from './default';

export const appConfigs: Record<Tenant, AppConfigurations> = {
  [Tenant.Anguilla]: anguillaConfigs,
  [Tenant.BVI]: bviConfigs,
  [Tenant.Barbados]: barbadosConfigs,
  [Tenant.Cayman]: caymanConfigs,
  [Tenant.Dominica]: dominicaConfigs,
  [Tenant.Ghana]: ghanaConfigs,
  [Tenant.Grenada]: grenadaConfigs,
  [Tenant.Guyana]: guyanaConfigs,
  [Tenant.SintMaarten]: sintMaartenConfigs,
  [Tenant.StKitts]: saintKittsConfigs,
  [Tenant.StLucia]: saintLuciaConfigs,
  [Tenant.StVincent]: saintVincentConfigs,
  [Tenant.Suriname]: surinameConfigs,
};
