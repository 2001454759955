import { ROUTE } from '@/constants';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { LoginPage } from '../login';

const unauthorizedRoutes: RouteObject[] = [
  {
    path: ROUTE.LOGIN,
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <Navigate to={ROUTE.LOGIN} />,
  },
];

export function UnauthenticatedPage() {
  const unauthElements = useRoutes(unauthorizedRoutes);

  return unauthElements;
}
