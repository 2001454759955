import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { Icon } from '../Icon';
import { InputValueHelperRule } from '@/types';

type InputRulesHelperTextProps = {
  currentValue?: string | number;
  rules: InputValueHelperRule[];
} & BoxProps;

export function InputRulesHelperText({
  currentValue,
  rules,
  ...props
}: InputRulesHelperTextProps) {
  if (!rules?.length) return null;

  return (
    <Box component="span" display="block" {...props}>
      {rules.map(rule => {
        const isMatched = rule.isMatched(currentValue);

        return (
          <Stack
            key={rule.text}
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={0.25}
            mb={0.5}
            component="span"
          >
            <Icon
              name={isMatched ? 'check' : 'close'}
              color={theme =>
                isMatched ? theme.palette.green[500] : theme.palette.grey[700]
              }
              size={12}
            />
            <Typography
              variant="caption2Bold"
              color={theme =>
                isMatched ? theme.palette.green[500] : theme.palette.grey[700]
              }
              component="span"
            >
              {rule.text}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
}
