import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';

export function useResponsive() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    theme,
    isDesktop,
    isMobile: !isDesktop,
  };
}
