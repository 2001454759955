import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { Icon } from '../../Icon';
import { useBoolean, useDebounceValue } from 'usehooks-ts';
import { AutocompleteOption } from './Select.styled';
import { DataList, Option } from '@/types';
import { useQuery } from '@tanstack/react-query';

type SearchSelectProps<FormPayload extends FieldValues> = {
  control: Control<FormPayload>;
  name: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  disabled?: boolean;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  searchQueryKey: string;
  searchFn: (search: string) => Promise<DataList<unknown>>;
  valueToOption: (value: unknown) => Option;
  onSelected?: (value: string, item?: unknown) => void;
  defaultValues?: unknown[];
  renderOption?: (option: Option, item: unknown) => ReactNode;
};

export function SearchSelect<FormPayload extends FieldValues>({
  control,
  rules,
  name,
  disabled,
  placeholder,
  textFieldProps,
  searchQueryKey,
  searchFn,
  valueToOption,
  onSelected,
  defaultValues = [],
  renderOption,
}: SearchSelectProps<FormPayload>) {
  const {
    value: shouldShowOptions,
    setTrue: showOptions,
    setFalse: hideOptions,
  } = useBoolean(false);
  const [searchResult, setSearchResult] = useState<unknown[]>();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounceValue(searchText, 500);
  const { data, isFetching } = useQuery({
    queryKey: [searchQueryKey, debouncedSearchText],
    queryFn: () => searchFn(debouncedSearchText.trim()),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!debouncedSearchText,
  });

  useEffect(() => {
    if (data) {
      setSearchResult(data.data);
    }
  }, [data]);

  const { values, optionByValue, itemByValue } = useMemo(() => {
    const values: string[] = [];
    const options: Option[] = [];
    const optionByValue: Record<string, Option> = {};
    const itemByValue: Record<string, unknown> = {};

    (searchResult || defaultValues || []).forEach((item: unknown) => {
      const option = valueToOption(item);
      values.push(String(option.value));
      options.push(option);
      optionByValue[option.value] = option;
      itemByValue[option.value] = item;
    });

    return {
      values,
      optionByValue,
      options,
      itemByValue,
    };
  }, [defaultValues, searchResult, valueToOption]);

  const { inputProps, ...restTextFieldProps } = textFieldProps || {};

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Autocomplete<string>
            fullWidth
            options={values}
            open={shouldShowOptions}
            onOpen={showOptions}
            onClose={hideOptions}
            value={value ? String(value) : ''}
            getOptionLabel={option => optionByValue[option]?.text || option}
            isOptionEqualToValue={(option, value) => option === value}
            filterOptions={option => option}
            slotProps={{
              paper: {
                sx: theme => ({
                  ...(!values.length
                    ? {
                        display: 'none',
                      }
                    : {
                        border: `1px solid ${theme.palette.grey[200]}`,
                        padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
                      }),
                }),
              },
            }}
            onChange={(_event, newValue) => {
              onChange(newValue || '');
              onSelected?.(
                newValue || '',
                newValue ? itemByValue[newValue] : undefined,
              );
            }}
            onBlur={onBlur}
            onInputChange={(event, newInputValue) => {
              const isInputTarget = event?.currentTarget.tagName === 'INPUT';

              if (isInputTarget) {
                setSearchText(newInputValue);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={placeholder}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null,
                }}
                inputProps={{
                  ...params.inputProps,
                  ...inputProps,
                }}
                {...restTextFieldProps}
                helperText={
                  textFieldProps?.error ? (
                    <Stack gap={0.5} component="span">
                      <Icon
                        name="alert"
                        size={20}
                        color={theme => theme.palette.error.main}
                      />
                      {textFieldProps.helperText}
                    </Stack>
                  ) : (
                    textFieldProps?.helperText
                  )
                }
              />
            )}
            renderOption={(props, option) => {
              return (
                <AutocompleteOption {...props} key={option}>
                  {renderOption
                    ? renderOption(optionByValue[option], itemByValue[option])
                    : optionByValue[option].text || option}
                </AutocompleteOption>
              );
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
