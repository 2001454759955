import { Icon } from '@/components';
import { Stack, Typography } from '@mui/material';
import {
  Background,
  LoginContainer,
  LoginFormCard,
  Logo,
  TitleContainer,
} from './Login.styled';
import { LoginForm } from './LoginForm';
import { useCallback, useState } from 'react';
import { SignInStep } from '@/types';
import { NewPasswordRequiredForm } from './NewPasswordRequiredForm';
import { useBoolean } from 'usehooks-ts';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export function LoginPage() {
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<SignInStep>(SignInStep.Login);
  const { value: isBlocked, setTrue: setBlocked } = useBoolean(false);

  const onSetLoginStep = (value: SignInStep, payload?: { email: string }) => {
    setStep(value);
    if (payload) {
      setEmail(payload.email);
    }
  };

  const renderStep = useCallback(() => {
    switch (step) {
      case SignInStep.AmplifyNewPasswordRequired:
        return (
          <NewPasswordRequiredForm email={email} onSetStep={onSetLoginStep} />
        );
      case SignInStep.ForgotPassword:
        return <ForgotPasswordForm onSetStep={onSetLoginStep} />;
      case SignInStep.Login:
      default:
        return <LoginForm onSetStep={onSetLoginStep} onBlocked={setBlocked} />;
    }
  }, [email, setBlocked, step]);

  return (
    <Background isBlocked={isBlocked}>
      <LoginContainer>
        <Stack
          gap={4}
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <TitleContainer>
            <Typography variant="h3">Back Office</Typography>
            <Typography variant="h1">Welcome to Republic Bank</Typography>
          </TitleContainer>
          {isBlocked && (
            <Icon
              name={'reject'}
              size={96}
              color={theme => theme.palette.red[400]}
            />
          )}
        </Stack>
        <LoginFormCard>
          <Logo />
          {renderStep()}
        </LoginFormCard>
      </LoginContainer>
    </Background>
  );
}
