import { Client } from './client';

export enum DocumentType {
  BasicInfo = 'Basic_Info',
  TinNumber = 'TIN_Number',
  Identity = 'Identity',
  IdentityBack = 'Identity_Back',
  Selfie = 'SN',
  ProofOfAddress = 'Proof_of_Address',
  ProofOfEmployment = 'Proof_of_Employment',
  ProofOfIncome = 'Proof_of_Income',
  DocumentSign = 'Document_Sign',
  SecondID = 'Second_ID',
  BankReferenceLetter = 'Bank_Reference_Letter',

  FinancialStatementsProjections = 'Financial_Statements_Projections',
  CertificateOfIncorporation = 'Certificate_of_Incorporation',
  MemorandumOfArticlesAndAssociation = 'Memorandum_of_Articles_and_Association',
  ListOfDirectorsMembers = 'List_of_Directors_Members',
  CertificateOfGoodStanding = 'Certificate_of_Good_Standing',
  RegisteredAgentCertificate = 'Registered_Agent_Certificate',
  NonprofitOrgRegistrationCertificate = 'Nonprofit_Org_Registration_Certificate',
  LastGroupMeetingMinutes = 'LastGroupMeetingMinutes',

  SchoolLetterOfAttendance = 'School_letter_of_Attendance',
  SupportLetterFromParent = 'Support_letter_from_Parent',
  ParentID = 'Parent_ID',
  AuthorisationLetter = 'Authorisation_Letter',
  SocialSecurityLetter = 'Social_Security_Letter',
  SupportLetterFromHusband = 'Support_letter_from_Husband',
  SupportLetterFromCaretaker = 'Support_letter_from_Caretaker',
  Internal = 'Internal_Document',
  Generated = 'Generated_Document',
  SelfAttestationForProofOfIncome = 'Self_Attestation_Income',
}

export enum DocumentStatus {
  AboutToExpire = 'ABOUT_TO_EXPIRE',
  Requested = 'REQUESTED',
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Approved = 'APPROVED',
  Signing = 'SIGNING',
  Empty = 'EMPTY',
  Generated = 'GENERATED',
}

export enum SourceType {
  Web = 'web',
  Image = 'img',
  PDF = 'pdf',
}

export enum DocumentSubType {
  UtilityBill = 'Utility_Bill',
  BankReceipt = 'Bank_Receipt',
  BankStatement = 'Bank_Statement',
  LetterOfEmployment = 'Letter_Of_Employment',
  ConfirmationOfAddress = 'Confirmation_Of_Address',
  Id = 'ID',
  DrivingLicense = 'D',
  SecurityCard = 'Security_card',
  Passport = 'Passport',
  None = 'None',
  Nis = 'NIS',
  TinCertificate = 'Tin_Certificate',
}

export type Document = {
  id: number | string;
  fileName?: string;
  operationId?: number | string;
  status: DocumentStatus;
  type: DocumentType;
  vuOperationGuid?: number | string;
  vuOperationId?: number | string;
  adobeSignWidgetId?: string;
  docType?: string;
  createdAt?: string;
  docNumber?: string;
  expiryDate?: string;
  contentType?: string;
  uri?: string;
  reviewDueDate?: string;
  overdueText?: string;
  vuResponseCode?: number;
  clientName: string;
  clientId: string;
  updatedAt?: string;
  reviewMessage?: string;
  owner?: Client;
  message?: string;
  formName?: string;
};

export type SigningDocument = {
  id: string;
  name: string;
  url: string;
  javascript: string;
  sender: string;
  status?: DocumentStatus;
  formName?: string;
};
