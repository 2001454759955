import { AuditActorType } from '@/types';
import { AuditLogs } from './AuditLogs';
import { ScheduleJobs } from './ScheduleJobs';
import { useState } from 'react';

export function ActivityLogsPage() {
  const [type, setType] = useState<AuditActorType>(AuditActorType.BackOffice);
  if (type === AuditActorType.ScheduleJob) {
    return <ScheduleJobs onActorTypeChange={setType} />;
  }

  return <AuditLogs actorType={type} onActorTypeChange={setType} />;
}
