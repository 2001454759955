import { triggerPepCheck } from '@/api';
import { Icon } from '@/components';
import { useDispatch } from '@/hooks';
import { fetchClientDetails, showToast } from '@/redux';
import { Client } from '@/types';
import { getAPIErrorMessage } from '@/utils';
import { CircularProgress, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

type PEPCheckTriggerButtonProps = {
  client: Client;
};

export function PEPCheckTriggerButton({ client }: PEPCheckTriggerButtonProps) {
  const shouldDisplay =
    client.firstName &&
    client.lastName &&
    client.birthday &&
    (!client.pepStatus?.pepCheckStatus || !client.pepStatus.amlCheckStatus);

  const dispatch = useDispatch();

  const {
    mutate: doPEPCheck,
    isSuccess,
    isPending,
    error,
    reset: resetPEPCheck,
  } = useMutation({
    mutationFn: () => triggerPepCheck(String(client.id)),
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchClientDetails(client.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onTriggerPepCheck = () => {
    resetPEPCheck();
    doPEPCheck();
  };

  if (shouldDisplay) {
    return (
      <IconButton
        size="small"
        title="Trigger check"
        onClick={onTriggerPepCheck}
        disabled={isPending}
      >
        {isPending ? (
          <CircularProgress color="inherit" size={18} />
        ) : (
          <Icon
            name="reset"
            size={18}
            color={theme => theme.palette.grey[700]}
          />
        )}
      </IconButton>
    );
  }
  return null;
}
