import { confirmResetPassword, resetPassword as resetPasswordFn } from '@/api';
import {
  Form,
  FormAction,
  Icon,
  InputRulesHelperText,
  LoadingButton,
  TextInput,
} from '@/components';
import { EMAIL_PATTERN, PASSWORD_PATTERN, PASSWORD_RULES } from '@/constants';
import { useForm } from '@/hooks';
import {
  ConfirmResetPasswordForm,
  ResetPasswordStep,
  SignInStep,
} from '@/types';
import {
  getAPIErrorMessage,
  getDataTestId,
  passwordNotIncludeEmail,
} from '@/utils';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

type ForgotPasswordFormProps = {
  onSetStep: (value: SignInStep) => void;
};

export function ForgotPasswordForm({ onSetStep }: ForgotPasswordFormProps) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
    visibleErrors: errors,
    watch,
  } = useForm<ConfirmResetPasswordForm>({
    defaultValues: {
      email: '',
      newPassword: '',
      confirmationCode: '',
    },
  });
  const email = watch('email');
  const currentPassword = watch('newPassword');

  const {
    data: resetPasswordOutput,
    mutate: resetPassword,
    isPending: isPasswordResetting,
    error: resetPasswordError,
  } = useMutation({
    mutationFn: resetPasswordFn,
  });

  const {
    isSuccess: isConfirmPasswordSuccess,
    mutate: confirmPassword,
    isPending: isPasswordConfirming,
    error: confirmPasswordError,
  } = useMutation({
    mutationFn: confirmResetPassword,
  });

  const onEmailSubmit = handleSubmit(async payload => {
    resetPassword(payload);
  });

  const onConfirmResetPassword = handleSubmit(async payload => {
    confirmPassword(payload);
  });

  const onReturnToLogin = () => {
    onSetStep(SignInStep.Login);
  };

  if (isConfirmPasswordSuccess) {
    return (
      <Form alignItems="stretch">
        <Stack direction="column" gap={1.5}>
          <Typography variant="h3">Reset password</Typography>
          <Typography
            variant="body2"
            color={theme => theme.palette.grey[800]}
            textAlign="center"
          >
            Your password has been reset successfully.
          </Typography>
        </Stack>
        <FormAction align="stretch">
          <Button
            {...getDataTestId(`reset-password-form-action-return-login-button`)}
            onClick={onReturnToLogin}
          >
            Return to login
          </Button>
        </FormAction>
      </Form>
    );
  }

  if (
    resetPasswordOutput?.nextStep.resetPasswordStep ===
    ResetPasswordStep.AmplifyConfirmCode
  ) {
    return (
      <Form alignItems="stretch" onSubmit={onConfirmResetPassword}>
        <Stack direction="column" gap={1.5}>
          <Typography variant="h3">Reset password</Typography>
          <Typography
            variant="body2"
            color={theme => theme.palette.grey[800]}
            textAlign="center"
          >
            Confirmation code is sent to <b>{email}</b>. Please fill below form
            to complete resetting your password.
          </Typography>
        </Stack>
        <TextInput
          key="confirmation-code-input"
          placeholder="Confirmation code"
          name="confirmationCode"
          control={control}
          error={!!errors.confirmationCode}
          helperText={errors.confirmationCode?.message}
          rules={{
            required: 'Confirmation code is required',
          }}
          disabled={isPasswordConfirming}
          autoComplete="off"
        />
        <TextInput
          type="password"
          placeholder="New password"
          name="newPassword"
          control={control}
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          rules={{
            required: 'Password is required',
            pattern: {
              value: new RegExp(PASSWORD_PATTERN, 'g'),
              message: 'Incorrect password format',
            },
            validate: {
              notIncludeUsername: value =>
                passwordNotIncludeEmail(value, email),
            },
          }}
          disabled={isPasswordConfirming}
          autoComplete="off"
        />
        <InputRulesHelperText
          mt={-2}
          currentValue={currentPassword}
          rules={PASSWORD_RULES}
        />
        {confirmPasswordError && (
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(confirmPasswordError)}
          </Alert>
        )}
        <Stack direction="column" alignItems="stretch">
          <LoadingButton
            {...getDataTestId(`reset-password-form-action-reset-button`)}
            type="submit"
            disabled={!isValid}
            isLoading={isPasswordConfirming}
          >
            Reset password
          </LoadingButton>
          <Button
            {...getDataTestId(`reset-password-form-action-return-login-button`)}
            onClick={onReturnToLogin}
            variant="outlined"
            disabled={isPasswordConfirming}
          >
            Return to login
          </Button>
        </Stack>
      </Form>
    );
  }

  return (
    <Form alignItems="stretch" onSubmit={onEmailSubmit}>
      <Stack direction="column" gap={1.5}>
        <Typography variant="h3">Reset password</Typography>
        <Typography
          variant="body2"
          color={theme => theme.palette.grey[800]}
          textAlign="center"
        >
          We need to verify your email address.
        </Typography>
      </Stack>
      <TextInput
        key="email-input"
        placeholder="Email"
        name="email"
        control={control}
        error={!!errors.email}
        helperText={errors.email?.message}
        rules={{
          required: 'Email is required',
          pattern: {
            value: new RegExp(EMAIL_PATTERN, 'g'),
            message: 'Invalid email format',
          },
        }}
        disabled={isPasswordResetting}
        autoComplete="off"
      />
      {resetPasswordError && (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(resetPasswordError)}
        </Alert>
      )}
      <Stack direction="column" alignItems="stretch">
        <LoadingButton
          {...getDataTestId(`reset-password-form-action-send-code-button`)}
          type="submit"
          disabled={!isValid}
          isLoading={isPasswordResetting}
        >
          Send code
        </LoadingButton>
        <Button
          {...getDataTestId(`reset-password-form-action-return-login-button`)}
          onClick={onReturnToLogin}
          variant="outlined"
          disabled={isPasswordResetting}
        >
          Return to login
        </Button>
      </Stack>
    </Form>
  );
}
