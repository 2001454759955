import { useCallback } from 'react';
import { useDispatch, useSelector } from './redux';
import { selectLocalDocuments, setLocalDocument, showToast } from '@/redux';
import { Document } from '@/types';
import {
  downloadFileByS3Uri,
  downloadFileByUri,
  downloadPrivateFileByUri,
} from '@/api';
import { downloadFile } from '@/utils';
import { VITE_BASE_API_URL } from '@/constants';

export function useDownloadDocument({
  disabledCache = false,
}: { disabledCache?: boolean } = {}) {
  const localDocuments = useSelector(selectLocalDocuments);
  const dispatch = useDispatch();

  return useCallback(
    (document: Document) => {
      if (document.uri) {
        const localUrl = localDocuments[document.uri]?.localUrl;

        dispatch(
          showToast({
            message: 'Downloading...',
            severity: 'info',
          }),
        );

        const args: [
          string,
          string,
          (result: string) => void,
          (error: string) => void,
        ] = [
          document.uri,
          document.fileName || 'Document',
          (result: string) => {
            if (!disabledCache) {
              dispatch(
                setLocalDocument({
                  uri: document.uri!,
                  localUrl: result,
                }),
              );
            }
          },
          (error: string) => {
            dispatch(
              showToast({
                message: error,
                severity: 'error',
              }),
            );
          },
        ];

        if (localUrl && !disabledCache) {
          downloadFile(localUrl, document.fileName || 'Document');
        } else {
          if (document.uri.startsWith('s3://')) {
            downloadFileByS3Uri(...args);
          } else if (document.uri.startsWith(VITE_BASE_API_URL)) {
            downloadPrivateFileByUri(...args);
          } else {
            downloadFileByUri(...args);
          }
        }
      }
    },
    [disabledCache, dispatch, localDocuments],
  );
}
