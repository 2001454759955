import { Document, FileFrameActionItem } from '@/types';
import {
  FileFrameAction,
  FileThumbnailContainer,
  FileViewerContainer,
  ViewerCenterSlot,
} from './File.styled';
import { Image } from './Image';
import { Icon, Loader } from '../Icon';
import { useDispatch, useDocument } from '@/hooks';
import { showFileViewer } from '@/redux';
import { IconButton } from '@mui/material';
import {
  getDataTestId,
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
} from '@/utils';
import { PDFRenderer } from './PDFRenderer';
import { ExcelRenderer } from './ExcelRenderer';

type FileFrameProps = {
  document: Document;
  disabledCache?: boolean;
  actionItems?:
    | FileFrameActionItem[]
    | ((defaultActions: FileFrameActionItem[]) => FileFrameActionItem[]);
};

export function FileFrame({
  document,
  disabledCache,
  actionItems,
}: FileFrameProps) {
  const { data, download } = useDocument({ document, disabledCache });
  const dispatch = useDispatch();

  const viewFile = () => {
    dispatch(showFileViewer(document));
  };

  const renderViewer = () => {
    if (!data) return null;

    if (isImageContentType(document.contentType)) {
      return <Image src={data.localUrl || data.uri} />;
    } else if (isPDFContentType(document.contentType)) {
      return (
        <PDFRenderer
          file={data.localUrl || data.uri || ''}
          renderTextLayer={false}
        />
      );
    } else if (isExcelContentType(document.contentType)) {
      return <ExcelRenderer file={data.localUrl || data.uri || ''} />;
    }
  };

  const renderActions = () => {
    const defaultActions: FileFrameActionItem[] = [
      {
        icon: 'zoom-in',
        title: 'Maximize',
        onClick: viewFile,
      },
      {
        icon: 'download',
        title: 'Download',
        onClick: download,
      },
    ];
    const actions = !actionItems
      ? defaultActions
      : Array.isArray(actionItems)
        ? actionItems
        : actionItems(defaultActions);

    return (
      <FileFrameAction>
        {actions.map(({ title, icon, onClick }) => (
          <IconButton
            key={`file-frame-action-${title}`}
            onClick={onClick}
            title={title}
          >
            <Icon name={icon} />
          </IconButton>
        ))}
      </FileFrameAction>
    );
  };

  if (data?.isLoading) {
    return (
      <FileViewerContainer>
        <FileThumbnailContainer
          {...getDataTestId(`file-thumbnail-${document.id}`)}
        >
          <ViewerCenterSlot>
            <Loader />
          </ViewerCenterSlot>
        </FileThumbnailContainer>
      </FileViewerContainer>
    );
  }

  return (
    <FileViewerContainer>
      <FileThumbnailContainer
        {...getDataTestId(`file-thumbnail-${document.id}`)}
      >
        {renderViewer()}
      </FileThumbnailContainer>
      {renderActions()}
    </FileViewerContainer>
  );
}
