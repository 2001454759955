import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { getDataTestId } from '@/utils';
import { elevationLevel } from '../constants';
import { layoutConfigs } from '../layout';
import { typographyConfigs } from '../typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const selectConfigs: Components['MuiSelect'] = {
  defaultProps: {
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
    MenuProps: {
      autoFocus: false,
      transitionDuration: 0,
      slotProps: {
        paper: {
          ...getDataTestId(`select-menu`),
          elevation: elevationLevel.m,
          sx: theme => ({
            border: `1px solid ${theme.palette.grey[200]}`,
            padding: `0 ${theme.spacing(1)} ${theme.spacing(2)}`,
            maxHeight: 200,
            marginTop: theme.spacing(0.5),

            '.MuiList-root': {
              minWidth: 125,
              padding: 0,
            },

            [theme.breakpoints.up('lg')]: {
              maxHeight: 280,
              maxWidth: 360,
            },
          }),
        },
      },
    },
  },
  styleOverrides: {
    root: {
      minWidth: 0,
      height: 44,
      paddingRight: theme.spacing(0.5),
      transition: theme.transitions.create('background-color'),

      '&.MuiInputBase-multiline': {
        height: 'auto',
      },

      '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        paddingRight: 0,
      },

      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.grey[600]}`,
        transition: theme.transitions.create(['border-color', 'color']),
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[1000],
        borderWidth: 1,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: 1,
      },

      '&.primary': {
        backgroundColor: theme.palette.primaries[500],
        color: theme.palette.common.white,
        ':hover': {
          backgroundColor: theme.palette.primaries[400],
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '.MuiSvgIcon-root': {
          fill: theme.palette.common.white,
        },
      },

      '&.default': {
        backgroundColor: theme.palette.background.paper,
        ':hover': {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
  },
};
