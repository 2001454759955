import { AuditLog } from '@/types';
import { Divider, ListItemIcon, ListItemText, Typography } from '@mui/material';
import {
  ActivityItemContainer,
  ActivityListContainer,
} from './DataDisplay.styled';
import { Fragment } from 'react';
import { getDataTestId, getIconByUserAction } from '@/utils';
import { Icon } from '../Icon';

type ActivityListProps = {
  activities: AuditLog[];
};

export function ActivityList({ activities }: ActivityListProps) {
  const renderActivity = (
    { id, event, event_type, actor }: AuditLog,
    index: number,
  ) => {
    return (
      <Fragment key={id}>
        <ActivityItemContainer {...getDataTestId(`activity-item-${id}`)}>
          <ListItemIcon>
            <Icon {...getIconByUserAction(event_type)} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                <Typography
                  variant="body2Bold"
                  component="span"
                  textTransform="capitalize"
                >
                  {actor}
                </Typography>{' '}
                {event}
              </Typography>
            }
          />
        </ActivityItemContainer>
        {index < activities.length - 1 && <Divider />}
      </Fragment>
    );
  };

  return (
    <ActivityListContainer>
      {activities.map(renderActivity)}
    </ActivityListContainer>
  );
}
