import { useSelector } from '@/hooks';
import { selectClientDetails, selectLoggedInUser } from '@/redux';
import { Grid, Stack } from '@mui/material';
import { SectionAccountDetails } from './SectionAccountDetails';
import { SectionOnboarding } from './SectionOnboarding';

export function TabClientInfo() {
  const user = useSelector(selectLoggedInUser);
  const client = useSelector(selectClientDetails);

  if (!user || !client) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <Stack direction="column" alignItems="stretch" gap={2}>
          <SectionAccountDetails />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SectionOnboarding />
      </Grid>
    </Grid>
  );
}
