import { axios } from '@/api';
import { appConfigs, LS_TENANT_KEY, ROUTE } from '@/constants';
import { useDispatch, useForm, usePrevious } from '@/hooks';
import { resetAuth, setTenant } from '@/redux';
import { Option, Tenant, User } from '@/types';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { RegionSelectContainer } from './Header.styled';
import { Select } from '../Form';

type TenantSelectProps = {
  user: User;
};

export function TenantSelect({ user }: TenantSelectProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [savedTenant, setSavedTenant] = useLocalStorage(
    LS_TENANT_KEY,
    user.tenants?.[0] || '',
  );
  const { control, watch } = useForm<{
    tenant: Option['value'];
  }>({
    defaultValues: {
      tenant: savedTenant,
    },
  });

  const tenant = watch('tenant');
  const prevTenant = usePrevious(tenant);

  useEffect(() => {
    if (tenant && prevTenant && tenant !== prevTenant) {
      axios.defaults.headers['x-tenant'] = tenant;
      setSavedTenant(tenant as Tenant);
      dispatch(setTenant(tenant as Tenant));
      dispatch(resetAuth());
      navigate(ROUTE.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const tenantOptions = useMemo(() => {
    return (user?.tenants || []).map(
      item =>
        ({
          value: item,
          text: appConfigs[item].name,
          icon: appConfigs[item].flag,
        }) as Option,
    );
  }, [user?.tenants]);

  return (
    <RegionSelectContainer>
      <Select
        control={control}
        name="tenant"
        color="primary"
        options={tenantOptions}
        fullWidth
      />
    </RegionSelectContainer>
  );
}
