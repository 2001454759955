import { ResourcesConfig } from 'aws-amplify';
import {
  VITE_COGNITO_CLIENT_ID,
  VITE_COGNITO_EXTERNAL_PROVIDER_DOMAIN,
  VITE_COGNITO_USER_POOL_ID,
} from './constants';

export const AMPLIFY_CONFIGS: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: VITE_COGNITO_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: VITE_COGNITO_EXTERNAL_PROVIDER_DOMAIN,
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
          scopes: [
            'openid',
            'phone',
            'email',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
        },
      },
    },
  },
};
