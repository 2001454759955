import { ModalName } from '@/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from './redux';
import { hideModal } from '@/redux';

type UseModalProps = {
  name?: ModalName;
} & (
  | {
      open: boolean;
      onClosed?: () => void;
    }
  | {
      open?: boolean;
      onClosed?: never;
    }
);

export function useModal({ name, open, onClosed }: UseModalProps) {
  const [key, setKey] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setKey(Date.now().toString());
        onClosed?.();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const hide = useCallback(() => {
    if (!name) return;

    dispatch(
      hideModal({
        modalName: name,
      }),
    );
  }, [dispatch, name]);

  const hideAndRefresh = useCallback(() => {
    if (!name) return;

    dispatch(
      hideModal({
        modalName: name,
        shouldRefreshData: true,
      }),
    );
  }, [dispatch, name]);

  return {
    resetKey: key,
    hide,
    hideAndRefresh,
  };
}
