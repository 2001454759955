import { ActivityList, Can, Heading, Icon } from '@/components';
import { DashboardBlock } from './Home.styled';
import { useQuery } from '@tanstack/react-query';
import { getAuditLogs } from '@/api';
import { DEFAULT_PAGINATION } from '@/constants';
import { AuditActorType } from '@/types';
import { Alert } from '@mui/material';
import { getAPIErrorMessage } from '@/utils';

export function SectionActivity() {
  const { data, error } = useQuery({
    queryKey: ['recent-activity'],
    queryFn: () =>
      getAuditLogs({
        pagination: DEFAULT_PAGINATION,
        filter: {
          actor_type: [AuditActorType.BackOffice, AuditActorType.System],
        },
      }),
    retry: false,
  });

  return (
    <Can do="view" on="report">
      <DashboardBlock>
        <Heading title="Recent Activity" isSubSection />
        {error && (
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        )}
        <ActivityList activities={data?.data || []} />
      </DashboardBlock>
    </Can>
  );
}
