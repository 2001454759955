import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectClientDetailsState = createSelector(
  (state: RootState) => state.clientDetails,
  data => ({ ...data }),
);

export const selectClientDetails = createSelector(
  (state: RootState) => state.clientDetails,
  data => data.clientDetails,
);

export const selectClientPEPResult = createSelector(
  (state: RootState) => state.clientDetails,
  data => data.pepResult,
);

export const selectClientAccountTypes = createSelector(
  (state: RootState) => state.clientDetails,
  data => data.accountTypes || [],
);
