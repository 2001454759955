import { BarChart, ChartIndicatorStack, Heading, Icon } from '@/components';
import { Alert, Box, Theme } from '@mui/material';
import { DashboardBlock } from './Home.styled';
import { useQuery } from '@tanstack/react-query';
import { getAccountOpeningTrend } from '@/api';
import { getAPIErrorMessage } from '@/utils';

const categories = [
  {
    dataKey: 'requested',
    color: (theme: Theme) => theme.palette.secondaryPurple[200],
  },
  {
    dataKey: 'opened',
    color: (theme: Theme) => theme.palette.secondaryPurple[500],
  },
];

export function SectionAccountTrend() {
  const { data, error } = useQuery({
    queryKey: ['account-open-trend'],
    queryFn: getAccountOpeningTrend,
    retry: false,
  });

  const indicators = categories.map(({ dataKey, color }) => ({
    text: dataKey,
    color,
  }));

  return (
    <DashboardBlock>
      <Heading
        title="Account opening trend"
        isSubSection
        actionSlot={<ChartIndicatorStack indicators={indicators} />}
      />
      {error && (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      )}
      <Box flex={1}>
        <BarChart
          categoryNameKey="month"
          data={data || []}
          categories={categories}
          containerProps={{
            height: 274,
          }}
        />
      </Box>
    </DashboardBlock>
  );
}
