import { Status } from '../../DataDisplay';
import { Option, StatusProps } from '@/types';
import {
  Box,
  IconButton,
  SelectChangeEvent,
  SelectProps,
  Stack,
  StackProps,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useToggle } from 'usehooks-ts';
import {
  DropdownOption,
  EditableFieldDropdownOption,
  EditableText,
  EditButton,
  StyledDatePicker,
  StyledSelect,
  StyledTextField,
} from './EditableField.styled';
import { SelectDrawerSearchBar } from '../Select/Select.styled';
import { Icon, Spinner } from '../../Icon';
import { TextInput } from '../TextInput';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from '@/hooks';
import { showToast } from '@/redux';
import { elevationLevel } from '@/styles';
import { Can } from '../../Can';
import { getAPIErrorMessage } from '@/utils';
import { DatePickerProps } from '@mui/x-date-pickers';
import { DATE_VALUE_FORMAT } from '@/constants';
import { format, parseISO } from 'date-fns';

type EditableFieldProps = {
  label?: string;
  value?: string;
  displayValue?: (value: string) => string;
  saveFn?: (value: string) => Promise<unknown>;
  onSuccess?: (value: string) => void;
  containerProps?: StackProps;
  statusProps?: StatusProps;
  inputType?: 'text' | 'select' | 'date';
  options?: Option[];
  readOnly?: boolean;
  errorField?: string;
  textFieldProps?: TextFieldProps;
  selectProps?: SelectProps;
  datePickerProps?: DatePickerProps<Date>;
  endDecorator?: ReactNode;
  startDecorator?: ReactNode;
  withSearchBar?: boolean;
};

export function EditableField({
  label,
  value,
  displayValue,
  saveFn,
  onSuccess,
  inputType = 'text',
  options = [],
  containerProps,
  statusProps,
  readOnly,
  errorField,
  textFieldProps,
  selectProps,
  datePickerProps,
  endDecorator,
  startDecorator,
  withSearchBar,
}: EditableFieldProps) {
  const [isEditing, toggleEdit] = useToggle(false);
  const [search, setSearch] = useState('');
  const [tempValue, setTempValue] = useState<string>('');
  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: saveFn,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      setTempValue(value || '');
    }
  }, [inputType, isEditing, value]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.(tempValue);
      toggleEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error, { errorField }),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredOptions = useMemo(() => {
    const searchValue = search.trim();
    if (searchValue) {
      return options.filter(option =>
        (option.text || String(option.value))
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      );
    }
    return options;
  }, [options, search]);

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTempValue(e.target.value);
  };

  const onDateChange = (date: Date | null) => {
    setTempValue(date ? format(date, DATE_VALUE_FORMAT) : '');
  };

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    setTempValue(String(event.target.value));
  };

  const onSubmit = () => {
    reset();
    mutate(tempValue);
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap={0.5}
      position="relative"
      {...containerProps}
    >
      {label && (
        <Stack gap={1} alignItems="flex-start">
          <Typography
            variant="caption"
            color={theme => theme.palette.grey[700]}
          >
            {label}
          </Typography>
          {statusProps && (
            <Box mt={-0.4}>
              <Status {...statusProps} />
            </Box>
          )}
        </Stack>
      )}
      {isEditing ? (
        <Stack gap={0.5} alignItems="center" justifyContent="flex-start">
          {inputType === 'text' ? (
            <StyledTextField
              value={tempValue}
              onChange={onTextChange}
              disabled={isPending}
              {...textFieldProps}
            />
          ) : inputType === 'date' ? (
            <StyledDatePicker
              value={parseISO(tempValue)}
              format="dd/MM/yyyy"
              onChange={onDateChange}
              slotProps={{
                desktopPaper: {
                  elevation: elevationLevel.l,
                },
                textField: {
                  variant: 'standard',
                },
              }}
              disabled={isPending}
              {...datePickerProps}
            />
          ) : inputType === 'select' ? (
            <StyledSelect
              value={tempValue}
              onChange={onSelectChange}
              disabled={isPending}
              renderValue={value => (
                <Typography variant="body2">
                  {displayValue
                    ? displayValue(value as string)
                    : (value as string)}
                </Typography>
              )}
              {...selectProps}
            >
              {withSearchBar && (
                <SelectDrawerSearchBar width={300}>
                  <TextInput
                    template="search"
                    value={search}
                    onKeyDown={e => e.stopPropagation()}
                    onClick={e => e.stopPropagation()}
                    autoFocus
                    onChange={onSearchChange}
                  />
                </SelectDrawerSearchBar>
              )}
              {withSearchBar
                ? filteredOptions.map(option => (
                    <EditableFieldDropdownOption
                      key={option.value}
                      value={option.value}
                      selected={option.value === tempValue}
                    >
                      {option.text || option.value}
                    </EditableFieldDropdownOption>
                  ))
                : options.map(option => (
                    <DropdownOption
                      key={option.value}
                      value={option.value}
                      selected={option.value === tempValue}
                    >
                      {option.text || option.value}
                    </DropdownOption>
                  ))}
            </StyledSelect>
          ) : null}
          {isPending ? (
            <IconButton size="small" disabled>
              <Spinner
                name="spinner-alt"
                size={18}
                color={theme => theme.palette.grey[500]}
              />
            </IconButton>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={onSubmit}
                disabled={value === tempValue}
              >
                <Icon
                  name="check"
                  size={18}
                  color={theme =>
                    value === tempValue
                      ? theme.palette.grey[300]
                      : theme.palette.green[500]
                  }
                />
              </IconButton>
              <IconButton size="small" onClick={toggleEdit}>
                <Icon
                  name="close"
                  size={18}
                  color={theme => theme.palette.grey[700]}
                />
              </IconButton>
            </>
          )}
        </Stack>
      ) : (
        <EditableText variant="body2Medium">
          {startDecorator}
          {(displayValue && value ? displayValue(value) : value) ||
            (!readOnly ? (
              <Can do="edit" on="onboarding_application">
                <IconButton onClick={toggleEdit} size="small">
                  <Icon
                    name="edit-outline"
                    size={18}
                    color={theme => theme.palette.grey[700]}
                  />
                </IconButton>
              </Can>
            ) : (
              ''
            ))}
          {value && !readOnly && (
            <Can do="edit" on="onboarding_application">
              <EditButton onClick={toggleEdit}>
                <Icon
                  name="edit-outline"
                  size={18}
                  color={theme => theme.palette.grey[700]}
                />
              </EditButton>
            </Can>
          )}
          {endDecorator}
        </EditableText>
      )}
    </Stack>
  );
}
