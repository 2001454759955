import {
  AuditActorType,
  AuditFilter,
  AuditLog,
  AuditLogStatus,
  Client,
} from '@/types';
import { formatTime, getAPIErrorMessage } from '@/utils';
import { Alert, Box, Stack, Tooltip } from '@mui/material';
import {
  Filter,
  Icon,
  MetadataViewer,
  SectionCard,
  SectionCardCallout,
  Table,
  ToggleGroup,
} from '@/components';
import { AUDIT_ACTOR_TYPES, AUDIT_LOG_STATUSES } from '@/constants';
import { MouseEvent, useMemo, useState } from 'react';
import { getAuditLogs } from '@/api';
import { useInfiniteTable } from '@/hooks';

type TabActivitiesProps = {
  client: Client;
};

export function TabActivities({ client }: TabActivitiesProps) {
  const [actorType, setActorType] = useState<AuditActorType>(
    AuditActorType.BackOffice,
  );
  const {
    rows: logs,
    control,
    debouncedFilter,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteTable<AuditLog, AuditFilter>({
    queryFn: getAuditLogs,
    getQueryFilter: filter => ({
      filter: {
        object_id: ['' + client.id, client.email, client.phoneNumber],
        success: filter.success || [],
        actor_type: actorType
          ? [
              actorType,
              ...(actorType === AuditActorType.BackOffice
                ? [AuditActorType.System]
                : []),
            ]
          : [],
      },
    }),
    doNotPassFilterToUrl: true,
    queryKeys: [actorType],
    onModifyFilter: filter => ({
      success:
        !filter.success?.length || filter.success.length >= 2
          ? undefined
          : filter.success.every(item => item === AuditLogStatus.Success)
            ? ['true']
            : ['false'],
    }),
  });
  const sortBy = watch('sortBy');

  const onActorChange = (_: MouseEvent<HTMLElement>, value: AuditActorType) => {
    setActorType(value);
  };

  const newFilter = useMemo(
    () => ({
      actorType,
      ...debouncedFilter,
    }),
    [actorType, debouncedFilter],
  );

  return (
    <>
      {error && (
        <Box mb={3}>
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        </Box>
      )}
      <SectionCard>
        <Stack justifyContent="space-between" gap={2} mb={1}>
          <ToggleGroup
            name="actor-type"
            value={actorType}
            onChange={onActorChange}
            options={AUDIT_ACTOR_TYPES}
          />
        </Stack>
        <SectionCardCallout>
          <Table
            sortBy={sortBy}
            isLoading={isFetching || isFetchingNextPage}
            rows={logs || []}
            onSortChange={onSortChange}
            withColumnFilter
            filter={newFilter}
            defaultFilteredColumns={[
              'timestamp',
              'actor',
              'event',
              'object',
              'status',
            ]}
            columns={[
              {
                header: 'Time',
                dataKey: 'timestamp',
                allowSort: true,
                id: 'timestamp',
                cell: row => formatTime(row.timestamp),
              },
              {
                header: 'Actor',
                dataKey: 'actor',
                allowSort: true,
                id: 'actor',
              },
              {
                header: 'Actor ID',
                dataKey: 'actor_id',
                id: 'actor_id',
              },
              {
                header: 'Event',
                dataKey: 'event',
                allowSort: true,
                id: 'event',
                widthRatio: 30,
              },
              {
                header: 'Event type',
                dataKey: 'event_type',
                id: 'event_type',
              },
              {
                header: 'Object',
                dataKey: 'object',
                id: 'object',
              },
              {
                header: 'Object ID',
                dataKey: 'object_id',
                id: 'object_id',
              },
              {
                header: 'Source',
                dataKey: 'source',
                id: 'source',
                widthRatio: 30,
              },
              {
                header: 'Status',
                id: 'status',
                filter: (
                  <Filter
                    name="success"
                    options={AUDIT_LOG_STATUSES}
                    control={control}
                  />
                ),
                cell: row => (
                  <Tooltip
                    title={row.success ? 'Success' : 'Error'}
                    placement="top"
                    arrow
                    enterDelay={500}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 8],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span>
                      {row.success ? (
                        <Icon
                          name="check-mini"
                          color={theme => theme.palette.success.main}
                        />
                      ) : (
                        <Icon
                          name="warning"
                          color={theme => theme.palette.error.main}
                        />
                      )}
                    </span>
                  </Tooltip>
                ),
              },
            ]}
            renderRowExpand={row => (
              <MetadataViewer>{JSON.stringify(row, null, 2)}</MetadataViewer>
            )}
          />
          <Box ref={nextPageTriggerRef} />
        </SectionCardCallout>
      </SectionCard>
    </>
  );
}
