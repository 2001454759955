import { FormAction } from '../../Layout';
import { DrawerProps } from '../Modals.interface';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { Client, ModalName } from '@/types';
import { Icon, Loader } from '../../Icon';
import {
  downloadFile,
  downloadZip,
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  isActiveClient,
} from '@/utils';
import { useEffect } from 'react';
import { useDispatch, useModal } from '@/hooks';
import { showToast } from '@/redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { approvePEPCheck, getPEPInfo } from '@/api';
import { LoadingButton } from '../../Form';
import { useBoolean } from 'usehooks-ts';
import { ApprovePEPConfirmModal } from '../ApprovePEPConfirmModal';
import {
  LoaderContainer,
  PEPReportContainer,
  RelativeContainer,
} from './PEPReportDrawer.styled';
import { DocumentDrawer } from '../DocumentDrawer.styled';
import { Form } from '../Modals.styled';
import { PEPHitAccordion } from '../../DataDisplay';
import { FileFrameAction } from '../../File';
import { PEP_AML_REPORT_HIT_LIMIT } from '@/constants';
import { pdfComlinkWorker } from './pdf-comlink-worker';

type PEPReportDrawerProps = {
  client: Client;
} & DrawerProps;

export function PEPReportDrawer({ client, ...props }: PEPReportDrawerProps) {
  const {
    value: isConfirming,
    setTrue: showConfirmModal,
    setFalse: hideConfirmModal,
  } = useBoolean(false);
  const dispatch = useDispatch();
  const {
    data: pepDetails,
    isPending: isLoadingPepDetails,
    error: pepDetailsError,
  } = useQuery({
    queryKey: ['client-pep-details', client],
    queryFn: () => getPEPInfo({ clientId: client.id }),
    enabled: props.open,
    retry: false,
  });
  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: approvePEPCheck,
  });
  const { value: isDownloading, setValue: setDownloading } = useBoolean(false);
  const { hide, hideAndRefresh } = useModal({ name: ModalName.PEPReport });

  useEffect(() => {
    reset();
    hideConfirmModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: `PEP status verified successfully!`,
          severity: 'success',
        }),
      );

      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onApprove = () => {
    hideConfirmModal();
    reset();
    if (pepDetails) {
      mutate({ clientId: client.id, pepRecordId: pepDetails.id });
    }
  };

  const onDownloadReport = async () => {
    if (pepDetails?.response?.found_records.length) {
      setDownloading(true);
      dispatch(
        showToast({
          message: 'Downloading...',
          severity: 'info',
        }),
      );

      try {
        const hits = pepDetails.response.found_records;
        const clientName = getDisplayField(client)
          .toLowerCase()
          .replace(/@|\s|\./g, '_');

        if (hits.length <= PEP_AML_REPORT_HIT_LIMIT) {
          const blobUrl = await pdfComlinkWorker.getPEPReportPDF({
            client,
            pepHits: hits,
          });

          if (typeof blobUrl === 'string') {
            const fileName = `${clientName}-pep-aml-report.pdf`;

            downloadFile(blobUrl, fileName);
          } else {
            console.error(blobUrl);
          }
        } else {
          let startIndex = 0;
          const blobs: Blob[] = [];

          while (startIndex < hits.length) {
            const limitedHits = hits.slice(
              startIndex,
              Math.min(startIndex + PEP_AML_REPORT_HIT_LIMIT, hits.length),
            );

            const blob = await pdfComlinkWorker.getPEPReportPDFBlob({
              client,
              pepHits: limitedHits,
            });

            if (blob instanceof Blob) {
              blobs.push(blob);
            } else {
              console.error(blob);
            }

            startIndex += PEP_AML_REPORT_HIT_LIMIT;
          }

          const files = blobs.map((blob, index) => {
            const partSuffix = `-part-${index + 1}`;
            const fileName = `${clientName}-pep-aml-report${partSuffix}.pdf`;

            return {
              fileName,
              data: blob,
            };
          });

          downloadZip(files, `${clientName}-pep-aml-report`);
        }
      } finally {
        setDownloading(false);
      }
    }
  };

  return (
    <DocumentDrawer
      {...getDataTestId('pep-aml-report-drawer')}
      {...props}
      onClose={hide}
      title="PEP and AML reports"
    >
      <Form>
        <Typography
          variant="body2"
          textAlign="center"
          color={theme => theme.palette.grey[700]}
        >
          {!pepDetails ? null : !pepDetails.response?.found_records?.length ? (
            'No PEP or AML report found for this client.'
          ) : (
            <>
              Please review{' '}
              {pepDetails.response.found_records.length > 1
                ? `these ${pepDetails.response.found_records.length}`
                : 'this'}{' '}
              <b>
                PEP and AML report
                {pepDetails.response.found_records.length > 1 ? 's' : ''}
              </b>{' '}
              and decide whether to approve it based on its content, accuracy,
              and compliance
            </>
          )}
        </Typography>
        {pepDetailsError ? (
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(pepDetailsError)}
          </Alert>
        ) : isLoadingPepDetails ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <RelativeContainer>
            <PEPReportContainer>
              {(pepDetails?.response.found_records || [])
                .slice(0, 10)
                .map((hit, index) => (
                  <PEPHitAccordion key={`pep-hit-${index}`} data={hit} />
                ))}
            </PEPReportContainer>
            <FileFrameAction>
              <IconButton
                onClick={onDownloadReport}
                title={`Download all ${pepDetails.response.found_records.length} report${pepDetails.response.found_records.length > 1 ? 's' : ''}`}
                disabled={isDownloading}
              >
                {isDownloading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Icon name="download" />
                )}
              </IconButton>
            </FileFrameAction>
          </RelativeContainer>
        )}
        {error && (
          <Box mb={2}>
            <Alert
              icon={<Icon name="alert" />}
              color="error"
              variant="standard"
            >
              {error.message}
            </Alert>
          </Box>
        )}
        {client &&
          isActiveClient(client) &&
          pepDetails &&
          pepDetails?.response.found_records.length > 0 && (
            <FormAction align="stretch">
              <Button
                onClick={hide}
                variant="outlined"
                {...getDataTestId(`pep-review-action-cancel-button`)}
              >
                Cancel
              </Button>

              <LoadingButton
                startIcon={<Icon name="check" />}
                isLoading={isPending}
                onClick={showConfirmModal}
                {...getDataTestId(`pep-review-action-approve-button`)}
              >
                Approve
              </LoadingButton>
            </FormAction>
          )}
      </Form>
      <ApprovePEPConfirmModal
        open={isConfirming}
        onApprove={onApprove}
        onClose={hideConfirmModal}
      />
    </DocumentDrawer>
  );
}
