import { Components } from '@mui/material';

export const toggleButtonConfigs: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      '&.MuiToggleButton-root': {
        border: '1px solid transparent',
      },
    },
  },
};
