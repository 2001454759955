import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const anguillaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-AI',
  isoCode: 'AIA',
  currency: "XCD",
  name: 'Anguilla',
  flag: 'flag-anguilla',
};
