import {
  Table as MUITable,
  TableRow,
  TableBody,
  TableCellProps,
  Stack,
  TableFooter,
  TableHead,
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ColumnFilterButton,
  ExpandButton,
  ExpandContainer,
  LoaderCell,
  RelativeBox,
  SortLabel,
  TableCell,
  TableContainer,
} from './Table.styled';
import { Loader, Icon } from '@/components';
import { Option } from '@/types';

type TableProps<T extends Record<string, unknown>> = {
  isLoading?: boolean;
  sortBy?: string;
  filterBy?: string[];
  onSortChange?: (dataKey: string) => void;
  columns: {
    id?: string;
    dataKey?: keyof T;
    header?: string;
    filter?: ReactNode;
    cell?: (row: T) => ReactNode;
    cellProps?: TableCellProps;
    allowSort?: boolean;
    widthRatio?: number;
  }[];
  rows: T[];
  expandColumnHeader?: string;
  renderRowExpand?: (row: T) => ReactNode;
  withColumnFilter?: boolean;
  defaultFilteredColumns?: (string | number)[];
  filter?: unknown;
};

export function Table<T extends Record<string, unknown>>({
  sortBy,
  filterBy,
  onSortChange,
  isLoading,
  columns,
  rows,
  expandColumnHeader,
  renderRowExpand,
  withColumnFilter,
  defaultFilteredColumns,
  filter: hasNewFilter,
}: TableProps<T>) {
  const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});
  const tableRef = useRef<HTMLElement>(null);
  const [selectedCols, setSelectedCols] = useState<(string | number)[]>(
    defaultFilteredColumns as string[],
  );

  useEffect(() => {
    setRowOpen({});
  }, [hasNewFilter]);

  const columnOptions = useMemo(() => {
    const options = columns
      .filter(col => col.id)
      .map(
        col =>
          ({
            value: col.id,
            text: col.header,
          }) as Option,
      );
    return options;
  }, [columns]);

  const onHeaderClick = (key: string) => {
    let newValue = key;

    if (sortBy === key) {
      newValue = `-${key}`;
    } else if (sortBy === `-${key}`) {
      newValue = '';
    }

    onSortChange?.(newValue);
  };

  const mapWidthRatio = (ratio?: number) => {
    if (!ratio || !tableRef.current) return undefined;

    const { width: tableWidth } = tableRef.current.getBoundingClientRect();
    const actualWidth = tableWidth - 32;

    return `${(ratio * actualWidth) / tableWidth}%`;
  };

  const filteredCols = selectedCols?.length
    ? columns.filter(col => selectedCols.includes(col.id as string))
    : columns;

  return (
    <RelativeBox ref={tableRef}>
      {withColumnFilter && columnOptions.length && (
        <ColumnFilterButton
          values={selectedCols}
          onChange={value => setSelectedCols(value)}
          options={columnOptions}
          defaultOptionValues={defaultFilteredColumns}
        />
      )}
      <TableContainer>
        <MUITable stickyHeader>
          <TableHead>
            <TableRow>
              <BlankCell component="th" />
              {filteredCols.map(
                (
                  { header, filter, cellProps, dataKey, allowSort, widthRatio },
                  index,
                ) => (
                  <TableCell
                    component="th"
                    key={`header-${index}`}
                    width={mapWidthRatio(widthRatio)}
                    {...cellProps}
                  >
                    <Stack gap={1.5} justifyContent="flex-start">
                      {dataKey && onSortChange && allowSort ? (
                        <SortLabel
                          active={allowSort}
                          onClick={() => onHeaderClick(dataKey as string)}
                          IconComponent={() =>
                            sortBy === dataKey ||
                            sortBy === `-${dataKey as string}` ? (
                              <Box component="span" ml={1.5} mt={0.4}>
                                <Icon
                                  name={
                                    sortBy === dataKey
                                      ? 'sort-asc'
                                      : 'sort-desc'
                                  }
                                  size={16}
                                  color={theme => theme.palette.grey[700]}
                                />
                              </Box>
                            ) : null
                          }
                        >
                          {header}
                        </SortLabel>
                      ) : dataKey && filterBy?.includes(dataKey as string) ? (
                        <b>{header}</b>
                      ) : (
                        header
                      )}
                      {filter}
                    </Stack>
                  </TableCell>
                ),
              )}
              {renderRowExpand && (
                <TableCell component="th">{expandColumnHeader}</TableCell>
              )}
              <BlankCell component="th" />
            </TableRow>
          </TableHead>
          <TableBody>
            {!rows.length && !isLoading ? (
              <TableRow>
                <BlankCell component="td" />
                <TableCell
                  noBorder
                  component="td"
                  scope="row"
                  colSpan={columns.length}
                >
                  <Typography
                    variant="body2"
                    color={theme => theme.palette.grey[500]}
                    mt={1}
                  >
                    No results
                  </Typography>
                </TableCell>
                <BlankCell component="td" />
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <Fragment key={`row-${index}`}>
                  <TableRow>
                    <BlankCell component="td" />
                    {filteredCols.map(
                      ({ cell, dataKey, cellProps, widthRatio }, cIndex) => (
                        <TableCell
                          key={`cell-${index}-${cIndex}`}
                          component="td"
                          scope="row"
                          width={mapWidthRatio(widthRatio)}
                          isExpanded={rowOpen[index]}
                          {...cellProps}
                        >
                          {cell ? cell(row) : dataKey ? '' + row[dataKey] : ''}
                        </TableCell>
                      ),
                    )}
                    {renderRowExpand && (
                      <TableCell
                        component="td"
                        scope="row"
                        isExpanded={rowOpen[index]}
                      >
                        <ExpandButton
                          isExpanded={rowOpen[index]}
                          onClick={() =>
                            setRowOpen(prev => ({
                              ...prev,
                              [index]: prev[index] ? false : true,
                            }))
                          }
                        >
                          <Icon name="arrow-down" size={20} />
                        </ExpandButton>
                      </TableCell>
                    )}
                    <BlankCell component="td" />
                  </TableRow>
                  {renderRowExpand && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 3}>
                        <Collapse
                          in={rowOpen[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ExpandContainer>
                            {renderRowExpand(row)}
                          </ExpandContainer>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))
            )}
          </TableBody>
          {isLoading && (
            <TableFooter>
              <TableRow>
                <LoaderCell colSpan={columns.length + 2}>
                  <Loader
                    text="Loading"
                    justifyContent="center"
                    iconProps={{
                      size: 32,
                    }}
                  />
                </LoaderCell>
              </TableRow>
            </TableFooter>
          )}
        </MUITable>
      </TableContainer>
    </RelativeBox>
  );
}

function BlankCell(props: TableCellProps) {
  return <TableCell {...props} />;
}
