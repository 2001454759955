import {
  ChangeEventHandler,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';

import { approveClient, rejectClient, updateRiskLevel } from '@/api';
import { COUNTRY_NAME_BY_VALUE, DocumentTypeText } from '@/constants';
import {
  useClientCalculation,
  useDispatch,
  useModal,
  useSelector,
} from '@/hooks';
import { patchClientDetails, selectRiskLevels } from '@/redux';
import { Client, GenericStatus, ModalName, OperationReviewForm } from '@/types';
import {
  getDataTestId,
  getDisplayedCitizenship,
  getDisplayField,
} from '@/utils';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Can } from '../../Can';
import { Status } from '../../DataDisplay';
import { EditableField } from '../../Form';
import { FormAction } from '../../Layout';
import { BasicDrawer } from '../BasicDrawer';
import { DrawerProps } from '../Modals.interface';
import { ApproveAccountBrief, ApproveAccountComment } from '../Modals.styled';
import { ReviewButton } from './ReviewButton';

type ApproveAccountDrawerProps = {
  client?: Client;
} & DrawerProps;

export function ApproveAccountDrawer({
  client,
  ...props
}: ApproveAccountDrawerProps) {
  const [payload, setPayload] = useState<OperationReviewForm>({
    clientId: client?.id || '',
    operationId: client?.operationId || '',
    message: '',
  });
  const { riskLevelOptions } = useSelector(selectRiskLevels);
  const dispatch = useDispatch();
  const { hide, hideAndRefresh } = useModal({ name: ModalName.ApproveAccount });
  const { isIDVerified, documentsGroups, basicInfoStatus, forms } =
    useClientCalculation();

  const onSetPayload = (value: Partial<OperationReviewForm>) => {
    setPayload(prev => ({
      ...prev,
      ...value,
    }));
  };

  const onRiskLevelChange = (value: string) =>
    updateRiskLevel({
      clientId: client?.id || '',
      riskLevel: value,
    });

  const onRiskLevelChangeSuccess = (value: string) =>
    dispatch(
      patchClientDetails({
        id: client?.id || '',
        riskLevel: value,
      }),
    );

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    onSetPayload({
      message: event.target.value,
    });
  };

  const handleApprove = async (payload: OperationReviewForm) => {
    await approveClient(payload);
    hideAndRefresh();
  };

  const handleReject = async (payload: OperationReviewForm) => {
    await rejectClient(payload);
    hideAndRefresh();
  };

  return (
    <Can do="review" on="onboarding_application">
      <BasicDrawer
        {...getDataTestId('approve-account-drawer')}
        {...props}
        onClose={hide}
        title="Approve account"
      >
        <Stack alignItems="stretch" direction="column" gap={3}>
          <Typography
            variant="body2"
            textAlign="center"
            color={theme => theme.palette.grey[700]}
          >
            Please review this application and decide whether to approve or
            reject it based on its content, accuracy, and compliance
          </Typography>
          {client && (
            <>
              <ApproveAccountBrief>
                <Typography variant="h5" mb={0.5}>
                  {getDisplayField(client)}
                </Typography>
                <Stack gap={1} mb={2}>
                  <Label>Risk level:</Label>
                  <EditableField
                    value={client.riskLevel}
                    displayValue={value =>
                      COUNTRY_NAME_BY_VALUE[value] || value
                    }
                    saveFn={onRiskLevelChange}
                    onSuccess={onRiskLevelChangeSuccess}
                    inputType="select"
                    options={riskLevelOptions}
                  />
                </Stack>
                <Divider />
                <Grid container my={2} rowGap={2}>
                  <Field
                    label="National:"
                    content={getDisplayedCitizenship(client)}
                    ratio={[2, 10]}
                  />
                  <Field
                    label="Resident:"
                    content={
                      client.country
                        ? COUNTRY_NAME_BY_VALUE[client.country]
                        : client.country
                    }
                    ratio={[2, 10]}
                  />
                  <Field
                    label="Persona:"
                    content={client.personaType}
                    ratio={[2, 10]}
                  />
                </Grid>
                <Divider />
                <Grid container my={2} rowGap={2}>
                  <Field
                    label="KYC:"
                    content={
                      <Status
                        value={
                          isIDVerified
                            ? GenericStatus.Verified
                            : GenericStatus.Unverified
                        }
                      />
                    }
                    ratio={[7, 5]}
                  />
                  <Field
                    label="PEP:"
                    content={
                      client.pepStatus?.pepCheckStatus ? (
                        <Status
                          value={client.pepStatus?.pepCheckStatus}
                          label=""
                        />
                      ) : null
                    }
                    ratio={[7, 5]}
                  />
                  <Field
                    label="AML:"
                    content={
                      client.pepStatus?.amlCheckStatus ? (
                        <Status value={client.pepStatus?.amlCheckStatus} />
                      ) : null
                    }
                    ratio={[7, 5]}
                  />
                  <Field
                    label="Basic Info:"
                    content={<Status value={basicInfoStatus} />}
                    ratio={[7, 5]}
                  />
                  {documentsGroups.map(documentGroup => (
                    <Field
                      key={documentGroup.type}
                      label={`${DocumentTypeText[documentGroup.type]}:`}
                      content={<Status value={documentGroup.generalStatus} />}
                      ratio={[7, 5]}
                    />
                  ))}
                </Grid>
                <Divider />
                <Grid container my={2} rowGap={2}>
                  {forms.map(form => (
                    <Field
                      key={form.id}
                      label={`${form.name}:`}
                      content={
                        <Status
                          value={
                            form.isSigned
                              ? GenericStatus.Verified
                              : GenericStatus.Unverified
                          }
                          label={form.isSigned ? 'Signed' : 'Not signed'}
                        />
                      }
                      ratio={[7, 5]}
                    />
                  ))}
                </Grid>
              </ApproveAccountBrief>
              <ApproveAccountComment
                placeholder="Write your message"
                onChange={onMessageChange}
                value={payload.message}
              />
              <FormAction align="stretch">
                <ReviewButton
                  text="Reject"
                  type="reject"
                  variant="outlined"
                  client={client}
                  onClick={handleReject}
                />

                <span>
                  <ReviewButton
                    text="Approve"
                    type="approve"
                    color="primary"
                    variant="contained"
                    client={client}
                    onClick={handleApprove}
                    fullWidth
                  />
                </span>
              </FormAction>
            </>
          )}
        </Stack>
      </BasicDrawer>
    </Can>
  );
}

const Label = ({ children }: PropsWithChildren) => (
  <Typography
    variant="body2"
    component="span"
    color={theme => theme.palette.grey[700]}
  >
    {children}
  </Typography>
);

type FieldProps = {
  label: string;
  content: ReactNode;
  ratio: [number, number];
};

const Field = ({ label, content, ratio }: FieldProps) => (
  <>
    <Grid item xs={ratio[0]}>
      <Label>{label}</Label>
    </Grid>
    <Grid item xs={ratio[1]}>
      {typeof content === 'string' ? (
        <Typography variant="body2Medium" component="span">
          {content}
        </Typography>
      ) : (
        content
      )}
    </Grid>
  </>
);
