import { getDataTestId } from '@/utils';
import { Box, Modal, Typography } from '@mui/material';
import { FormAction, FormCard } from '../Layout';
import { LoadingButton } from '../Form';

type ConfirmationModalProps = {
  open: boolean;
  title: string;
  message: string;
  extraData: unknown;
  onOk: (extraData: unknown) => void;
  onClose: () => void;
};

export function ConfirmationModal({
  open,
  title,
  message,
  onOk,
  onClose,
  extraData,
}: ConfirmationModalProps) {
  const onConfirm = () => {
    onOk(extraData);
    onClose();
  };

  return (
    <Modal {...getDataTestId('confirm-approve-pep-modal')} open={open}>
      <Box>
        <FormCard title={title} onClose={onClose} center>
          <Typography variant="body2" mb={4}>
            {message}
          </Typography>
          <FormAction>
            <LoadingButton
              variant="outlined"
              {...getDataTestId(`confirmation-modal-cancel-button`)}
              onClick={onClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={onConfirm}
              {...getDataTestId(`confirmation-modal-ok-button`)}
            >
              Ok
            </LoadingButton>
          </FormAction>
        </FormCard>
      </Box>
    </Modal>
  );
}
