import { Box, styled } from '@mui/material';
import { OverlayScroller } from '../../Layout';
import { DocumentDrawer } from '../DocumentDrawer.styled';

export const Drawer = styled(DocumentDrawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    '.FormHeader': {
      marginBottom: theme.spacing(1),
    },
    '.FormDivider': {
      display: 'none',
    },
  },
}));

export const FieldsContainer = styled(OverlayScroller)(() => ({
  minHeight: 0,
  flex: 1,

  '.simplebar-content': {
    height: 'unset',
  },
}));

export const Field = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderTop: `1px solid ${theme.palette.grey[300]}`,

  '.MuiFormControl-root': {
    width: '50%',
  },
}));
