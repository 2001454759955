import {
  Can,
  Filter,
  Icon,
  InfiniteTablePage,
  MetadataViewer,
  Table,
  ToggleGroup,
} from '@/components';
import { AuditActorType, Job, JobStatus, JobType } from '@/types';
import { Stack, Tooltip } from '@mui/material';
import {
  AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS,
  JobStatusText,
  SCHEDULE_JOB_STATUSES,
  SCHEDULE_JOB_TYPES,
  ScheduleJobTypeText,
} from '@/constants';
import { formatTime } from '@/utils';
import { useInfiniteTable } from '@/hooks';
import { TooltipChild } from './ActivityLogs.styled';
import { getJobs } from '@/api/job';
import { ReRunJobButton } from './ReRunJobButton';
import { MouseEvent } from 'react';

type JobFilter = {
  keyword?: string;
  sortBy?: string;
  status?: JobStatus[];
  jobType?: JobType[];
  actorType?: AuditActorType;
};

type ScheduleJobsProps = {
  onActorTypeChange: (value: AuditActorType) => void;
};

export const ScheduleJobs = function ScheduleJobs({
  onActorTypeChange,
}: ScheduleJobsProps) {
  const {
    rows: logs,
    control,
    debouncedFilter,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteTable<Job, JobFilter>({
    queryFn: getJobs,
    getQueryFilter: filter => ({
      filter: {
        jobType: filter.jobType?.map(i => String(i)) || [],
        status: filter.status?.map(i => String(i)) || [],
      },
    }),
    doNotPassFilterToUrl: true,
  });
  const sortBy = watch('sortBy');

  const onActorChange = (_: MouseEvent<HTMLElement>, value: AuditActorType) => {
    onActorTypeChange(value);
  };

  return (
    <InfiniteTablePage
      title="Activity logs"
      filterControl={control}
      error={error}
      fetchTriggerRef={nextPageTriggerRef}
      slots={{
        quickFilter: (
          <ToggleGroup
            name="actor-type"
            value={AuditActorType.ScheduleJob}
            onChange={onActorChange}
            options={AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS}
          />
        ),
      }}
    >
      <Table
        sortBy={sortBy}
        isLoading={isFetching || isFetchingNextPage}
        rows={logs || []}
        onSortChange={onSortChange}
        withColumnFilter
        defaultFilteredColumns={['jobType', 'startTime', 'payload', 'status']}
        filter={debouncedFilter}
        columns={[
          {
            header: 'Type',
            dataKey: 'jobType',
            id: 'jobType',
            filter: (
              <Filter
                name="jobType"
                options={SCHEDULE_JOB_TYPES}
                control={control}
              />
            ),
            cell: row => ScheduleJobTypeText[row.jobType] || row.jobType,
            widthRatio: 17,
          },
          {
            header: 'Start at',
            dataKey: 'startTime',
            id: 'startTime',
            widthRatio: 18,
            cell: row =>
              formatTime(row.startTime, {
                timeFormat: 'HH:mm:ss',
              }),
          },
          {
            header: 'End at',
            dataKey: 'endTime',
            id: 'endTime',
            widthRatio: 18,
            cell: row =>
              formatTime(row.endTime, {
                timeFormat: 'HH:mm:ss',
              }),
          },
          {
            header: 'Payload',
            dataKey: 'payload',
            id: 'payload',
            widthRatio: 30,
            cell: row =>
              typeof row.payload !== 'string'
                ? JSON.stringify(row.payload, null, 2)
                : row.payload,
          },
          {
            header: 'Response',
            dataKey: 'response',
            id: 'response',
            widthRatio: 30,
            cell: row =>
              typeof row.response !== 'string'
                ? JSON.stringify(row.response, null, 2)
                : row.response,
          },
          {
            header: 'Status',
            dataKey: 'status',
            id: 'status',
            filter: (
              <Filter
                name="status"
                options={SCHEDULE_JOB_STATUSES}
                control={control}
              />
            ),
            cell: row => (
              <Stack>
                <Tooltip
                  title={JobStatusText[row.status]}
                  placement="top"
                  arrow
                  enterDelay={500}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 4],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <TooltipChild>
                    {row.status === JobStatus.Success ? (
                      <Icon
                        name="check-mini"
                        color={theme => theme.palette.success.main}
                      />
                    ) : row.status === JobStatus.Pending ? (
                      <Icon
                        name="clock"
                        color={theme => theme.palette.grey[400]}
                      />
                    ) : row.status === JobStatus.Processing ? (
                      <Icon
                        name="hammer"
                        color={theme => theme.palette.yellow[500]}
                        size={20}
                      />
                    ) : (
                      <Icon
                        name="warning"
                        color={theme => theme.palette.error.main}
                      />
                    )}
                  </TooltipChild>
                </Tooltip>
                {row.status === JobStatus.Error && (
                  <Can do="run" on="schedule_job">
                    <ReRunJobButton job={row} onSuccess={refetch} />
                  </Can>
                )}
              </Stack>
            ),
          },
        ]}
        renderRowExpand={row => (
          <MetadataViewer>{JSON.stringify(row, null, 2)}</MetadataViewer>
        )}
      />
    </InfiniteTablePage>
  );
};
