import { ApiFilter, ICBResponseCode } from '@/types';
import { AxiosError } from 'axios';
import { isNil } from './common';

export const sleep = (duration: number) =>
  new Promise(resolve => setTimeout(resolve, duration));

export type CommonAPIError = {
  causedBy?: {
    code: number;
    message: string;
  };
  error: string;
  message: string;
};

export const getDisplayField = <
  T extends {
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    id?: string | number;
  },
>(
  data?: T,
  options: {
    nameOnly?: boolean;
    fallbackValue?: string;
  } = {},
) => {
  const { nameOnly, fallbackValue } = options;

  if (!data) return fallbackValue || '';

  if (data.fullName) return data.fullName;

  if (data.firstName && data.lastName)
    return `${data.firstName} ${data.lastName}`;

  if (data.firstName) return data.firstName;
  if (data.lastName) return data.lastName;

  if (nameOnly) {
    return fallbackValue || '';
  }

  if (data.email) return data.email;
  if (data.id) return String(data.id);

  return fallbackValue || '';
};

export const getRequestFilterParams = (
  {
    pagination,
    query,
    sort,
    filter,
    excludeFields,
    fields,
    ...rest
  }: ApiFilter,
  databaseFiledsMapper?: Record<string, string>,
) => {
  const sortField = sort
    ? sort.startsWith('-')
      ? sort.slice(1)
      : sort
    : undefined;

  const sortKey = sortField
    ? databaseFiledsMapper
      ? databaseFiledsMapper[sortField]
      : sortField
    : undefined;

  const filters = filter
    ? Object.entries(filter)
        .filter(([, values]) => values.length)
        .map(
          ([key, values]) =>
            `${databaseFiledsMapper ? databaseFiledsMapper[key] || key : key}:${values.join('|')}`,
        )
    : [];

  Object.entries(rest as Record<string, unknown>).map(([key, value]) => {
    if (
      (Array.isArray(value) && !value.length) ||
      value === '' ||
      isNil(value)
    ) {
      delete (rest as Record<string, unknown>)[key];
    }
  });

  return {
    page: pagination?.page,
    size: pagination?.size,
    ...(query ? { query } : {}),
    ...(sort
      ? {
          sort: sort.startsWith('-') ? `${sortKey}:desc` : `${sortKey}:asc`,
        }
      : {}),
    ...(filters?.length
      ? {
          filter: filters.join(','),
        }
      : {}),
    ...(excludeFields?.length
      ? {
          excludeFields: excludeFields
            .map(key =>
              databaseFiledsMapper ? databaseFiledsMapper[key] || key : key,
            )
            .join(','),
        }
      : {}),
    ...(fields?.length
      ? {
          fields: fields
            .map(key =>
              databaseFiledsMapper ? databaseFiledsMapper[key] || key : key,
            )
            .join(','),
        }
      : {}),
    ...rest,
  };
};

export const getAPIErrorMessage = (
  e: unknown,
  options?: {
    errorField?: string;
    defaultMessage?: string;
  },
) => {
  const error = e as AxiosError<CommonAPIError>;

  const errorTarget =
    error?.response?.data?.causedBy?.message ||
    error?.response?.data?.message ||
    error?.message;

  if (errorTarget) {
    if (typeof errorTarget === 'string') {
      return errorTarget;
    } else if (options?.errorField && errorTarget[options.errorField]) {
      return String(errorTarget[options.errorField]);
    }
  }

  return (
    options?.defaultMessage || 'Something went wrong, please try again later.'
  );
};

export const getIcbErrorMessage = (
  icbCode?: ICBResponseCode,
  defaultMessage = 'Unknown error',
) => {
  if (isNil(icbCode) || icbCode === ICBResponseCode.Success) return '';

  switch (icbCode) {
    case ICBResponseCode.CreateRIMError:
      return 'Cannot create RIM';
    case ICBResponseCode.CreateICBError:
      return 'Cannot create ICB profile';
    case ICBResponseCode.BirthDateRequired:
      return 'No date of birth';
    case ICBResponseCode.ClientInformationRequired:
      return 'No client information';
    case ICBResponseCode.IdentificationRequired:
      return 'No identification';
    case ICBResponseCode.MobilePhoneRequired:
      return 'No phone number';
    case ICBResponseCode.NameAndSurnameRequired:
      return 'No name and surname';
    case ICBResponseCode.UsernameError:
      return 'Invalid username';
    case ICBResponseCode.UsernameRequired:
      return 'No username';
    case ICBResponseCode.GenericError:
    default:
      return defaultMessage;
  }
};
