import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { spacing } from '../constants/layout';
import { typographyConfigs } from '../typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const tabConfigs: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.body2Bold.fontSize,
      fontWeight: theme.typography.body2Bold.fontWeight,
      paddingBottom: spacing * 1.5,
      paddingTop: spacing * 1.5,
      paddingLeft: spacing * 3,
      paddingRight: spacing * 3,
      textTransform: 'none',
      transition: theme.transitions.create('color'),

      '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2Bold.fontSize,
        fontWeight: theme.typography.body2Bold.fontWeight,
      },
    },
  },
};
