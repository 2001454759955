import { createSlice, Draft } from '@reduxjs/toolkit';
import {
  Client,
  Document,
  ModalName,
  ModalState,
  ToastState,
  User,
} from '@/types';
import {
  clearModalRefreshState,
  hideFileViewer,
  hideModal,
  hideToast,
  resetUI,
  showAddUserDrawer,
  showApproveAccountDrawer,
  showAssignClientModal,
  showChangeUserRoleDrawer,
  showDocumentRequestDrawer,
  showDocumentReviewDrawer,
  showExportClientDrawer,
  showFileViewer,
  showManageDocumentsDrawer,
  showPEPReportDrawer,
  showToast,
  showUpdateUserDrawer,
} from './actions';

interface UIState {
  approveAccountDrawer: ModalState<Client>;
  assignClientModal: ModalState<Client>;
  manageDocumentsDrawer: ModalState<Client>;
  documentRequestDrawer: ModalState<Client>;
  documentReviewDrawer: ModalState<{
    client?: Client;
    document: Document;
  }>;
  addUserDrawer: ModalState<{
    user?: User;
    isEdit?: boolean;
    isChangeRole?: boolean;
  }>;
  pepReportDrawer: ModalState<Client>;
  fileViewer: {
    document: Document | null;
  };
  toast: ToastState;
  exportClientDrawer: ModalState;
}

const defaultModalState = {
  isOpen: false,
};

const initialState: UIState = {
  approveAccountDrawer: defaultModalState,
  assignClientModal: defaultModalState,
  manageDocumentsDrawer: defaultModalState,
  documentRequestDrawer: defaultModalState,
  documentReviewDrawer: defaultModalState,
  exportClientDrawer: defaultModalState,
  addUserDrawer: defaultModalState,
  fileViewer: { document: null },
  pepReportDrawer: defaultModalState,
  toast: {
    isOpen: false,
    message: '',
    severity: 'info',
    autoHide: true,
  },
};

const MODAL_MAP: Record<ModalName, keyof UIState> = {
  [ModalName.AddUser]: 'addUserDrawer',
  [ModalName.ApproveAccount]: 'approveAccountDrawer',
  [ModalName.AssignClient]: 'assignClientModal',
  [ModalName.PEPReport]: 'pepReportDrawer',
  [ModalName.RequestDocument]: 'documentRequestDrawer',
  [ModalName.ReviewDocument]: 'documentReviewDrawer',
  [ModalName.ManageDocuments]: 'manageDocumentsDrawer',
  [ModalName.ExportClient]: 'exportClientDrawer',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers(builder) {
    function hideAllModals(state: Draft<UIState>, except?: keyof UIState) {
      const modals: (keyof UIState)[] = [
        'approveAccountDrawer',
        'assignClientModal',
        'manageDocumentsDrawer',
        'documentRequestDrawer',
        'documentReviewDrawer',
        'addUserDrawer',
        'pepReportDrawer',
        'exportClientDrawer',
      ];

      modals.forEach(key => {
        if (key === except) return;

        Object.assign(state, {
          ...state,
          [key]: defaultModalState,
        });
      });
    }

    builder.addCase(showManageDocumentsDrawer, (state, { payload }) => {
      state.manageDocumentsDrawer.isOpen = true;
      state.manageDocumentsDrawer.payload = payload;
      hideAllModals(state, 'manageDocumentsDrawer');
    });

    builder.addCase(showDocumentRequestDrawer, (state, { payload }) => {
      state.documentRequestDrawer.isOpen = true;
      state.documentRequestDrawer.payload = payload;
      hideAllModals(state, 'documentRequestDrawer');
    });

    builder.addCase(showDocumentReviewDrawer, (state, { payload }) => {
      state.documentReviewDrawer.payload = payload;
      state.documentReviewDrawer.isOpen = true;
      hideAllModals(state, 'documentReviewDrawer');
    });

    builder.addCase(showFileViewer, (state, { payload }) => {
      state.fileViewer.document = payload;
    });
    builder.addCase(hideFileViewer, state => {
      state.fileViewer.document = null;
    });

    builder.addCase(showAssignClientModal, (state, { payload }) => {
      state.assignClientModal.payload = payload;
      state.assignClientModal.isOpen = true;
      hideAllModals(state, 'assignClientModal');
    });

    builder.addCase(showExportClientDrawer, state => {
      state.exportClientDrawer.isOpen = true;
      hideAllModals(state, 'exportClientDrawer');
    });

    builder.addCase(showApproveAccountDrawer, (state, { payload }) => {
      state.approveAccountDrawer.payload = payload;
      state.approveAccountDrawer.isOpen = true;
      hideAllModals(state, 'approveAccountDrawer');
    });

    builder.addCase(showPEPReportDrawer, (state, { payload }) => {
      state.pepReportDrawer.payload = payload;
      state.pepReportDrawer.isOpen = true;
      hideAllModals(state, 'pepReportDrawer');
    });

    builder.addCase(showAddUserDrawer, (state, { payload }) => {
      state.addUserDrawer.isOpen = true;
      state.addUserDrawer.payload = {
        user: payload,
      };
      hideAllModals(state, 'addUserDrawer');
    });

    builder.addCase(showUpdateUserDrawer, (state, { payload }) => {
      state.addUserDrawer.isOpen = true;
      state.addUserDrawer.payload = payload;
      state.addUserDrawer.payload.isEdit = true;
      state.addUserDrawer.payload.isChangeRole = false;
    });

    builder.addCase(showChangeUserRoleDrawer, (state, { payload }) => {
      state.addUserDrawer.isOpen = true;
      state.addUserDrawer.payload = payload;
      state.addUserDrawer.payload.isEdit = false;
      state.addUserDrawer.payload.isChangeRole = true;
    });

    builder.addCase(showToast, (state, { payload }) => {
      state.toast = {
        isOpen: true,
        severity: 'info',
        autoHide: true,
        ...payload,
      };
    });
    builder.addCase(hideToast, state => {
      state.toast.isOpen = false;
    });
    builder.addCase(
      hideModal,
      (state, { payload: { modalName, shouldRefreshData } }) => {
        Object.assign(state, {
          [MODAL_MAP[modalName]]: {
            isOpen: false,
            shouldRefreshData: !!shouldRefreshData,
          },
        });
      },
    );
    builder.addCase(clearModalRefreshState, (state, { payload: modalName }) => {
      if (modalName) {
        const key = MODAL_MAP[modalName];
        Object.assign(state, {
          [key]: {
            ...state[key],
            shouldRefreshData: false,
          },
        });
      }
    });
    builder.addCase(resetUI, state => {
      state.approveAccountDrawer = initialState.approveAccountDrawer;
      state.assignClientModal = initialState.assignClientModal;
      state.manageDocumentsDrawer = initialState.manageDocumentsDrawer;
      state.documentRequestDrawer = initialState.documentRequestDrawer;
      state.documentReviewDrawer = initialState.documentReviewDrawer;
      state.fileViewer = initialState.fileViewer;
      state.pepReportDrawer = initialState.pepReportDrawer;
      state.addUserDrawer = initialState.addUserDrawer;
      state.toast = initialState.toast;
    });
  },
});

export const uiReducer = uiSlice.reducer;
