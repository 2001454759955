import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { spacing } from '../constants/layout';
import { typographyConfigs } from '../typography';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const toggleButtonGroupConfigs: Components['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 52,
      padding: spacing * 0.5,

      '.MuiToggleButton-root': {
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 32,
        color: theme.palette.grey[700],
        paddingTop: spacing * 0.5,
        paddingBottom: spacing * 0.5,
        paddingLeft: spacing * 1.5,
        paddingRight: spacing * 1.5,
        textTransform: 'none',
        fontSize: theme.typography.body2Medium.fontSize,
        fontWeight: theme.typography.body2Medium.fontWeight,
        transition: theme.transitions.create(['background-color', 'color']),

        ':hover': {
          backgroundColor: 'transparent',
        },

        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,

          ':hover': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  },
};
