import {
  ClientBlock,
  FullPageLoader,
  LoadingButton,
  SectionCard,
} from '@/components';
import { doNotForwardTheseProps, elevationLevel } from '@/styles';
import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  IconButton,
  styled,
} from '@mui/material';

export const DetailsCard = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  borderRadius: 12,
}));
DetailsCard.defaultProps = {
  elevation: elevationLevel.s,
};

export const DocumentItem = styled(DetailsCard)(({ theme, onClick }) => ({
  padding: theme.spacing(2),
  minHeight: '100%',
  cursor: onClick ? 'pointer' : 'default',

  '.FileName': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  ':hover': {
    '.MuiStack-root': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}));

export const FileThumbnail = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('isExpired', 'isAboutToExpired'),
})<{
  isExpired?: boolean;
  isAboutToExpired?: boolean;
}>(({ theme, isExpired, isAboutToExpired }) => ({
  aspectRatio: '328 / 140',
  backgroundColor: isExpired
    ? theme.palette.red[100]
    : isAboutToExpired
      ? theme.palette.orange[100]
      : theme.palette.grey[100],
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: isExpired
    ? theme.palette.red[400]
    : isAboutToExpired
      ? theme.palette.orange[400]
      : theme.palette.grey[200],
  borderRadius: 8,
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '> .MuiStack-root': {
    height: '100%',
    width: '100%',
    backgroundColor: isExpired
      ? alpha('' + theme.palette.red[100], 1)
      : isAboutToExpired
        ? alpha('' + theme.palette.orange[100], 1)
        : alpha('' + theme.palette.grey[100], 1),
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    visibility: 'hidden',
    transition: theme.transitions.create(['opacity', 'visibility']),

    '.MuiIconButton-root': {
      backgroundColor: 'transparent',
      borderRadius: 10,
      border: `1px solid ${theme.palette.text.primary}`,
      height: 44,
      width: 44,
      transition: theme.transitions.create('background-color'),

      '.MuiSvgIcon-root': {
        fill: theme.palette.text.primary,
      },

      ':hover': {
        backgroundColor: theme.palette.primaries[500],
        border: `1px solid ${theme.palette.primaries[500]}`,
        '.MuiSvgIcon-root': {
          fill: theme.palette.common.white,
        },
      },
    },
  },
}));

export const StyledLoader = styled(FullPageLoader)(() => ({
  backgroundColor: 'transparent',
  height: '100%',
}));

export const BasicInfoApproveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1.75),
  marginRight: theme.spacing(1.75),
  marginLeft: 'auto',
}));
BasicInfoApproveButton.defaultProps = {
  variant: 'outlined',
};

export const RetryRIMButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(-0.7),
}));

export const RetryICBButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.red[600],
  fontSize: theme.typography.captionBold.fontSize,
  marginTop: theme.spacing(-0.5),
  marginRight: theme.spacing(0.5),

  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },

  ':hover': {
    color: theme.palette.red[600],
    '.MuiButton-startIcon .MuiSvgIcon-root': {
      fill: theme.palette.red[600],
    },
  },
}));

export const TriggerICBButton = styled(RetryICBButton)(({ theme }) => ({
  color: theme.palette.orange[600],

  '.MuiButton-startIcon .MuiSvgIcon-root': {
    fill: theme.palette.orange[600],
  },

  ':hover': {
    color: theme.palette.orange[600],
    '.MuiButton-startIcon .MuiSvgIcon-root': {
      fill: theme.palette.orange[600],
    },
  },
}));

export const StyledClientBlock = styled(ClientBlock)(() => ({
  width: '100%',

  '.MuiListItemText-root': {
    margin: 0,

    '.MuiTypography-root': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
}));

const uploadBoxBorderRadius = 8;
export const UploadBox = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('isHighlighted'),
})<{ isHighlighted?: boolean }>(({ theme, isHighlighted }) => ({
  borderRadius: uploadBoxBorderRadius,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  padding: theme.spacing(0.75),
  flex: 1,
  justifyContent: 'center',
  height: 48,
  overflow: 'hidden',
  ...(isHighlighted
    ? {
        backgroundColor: theme.palette.primaries[100],
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${uploadBoxBorderRadius}' ry='${uploadBoxBorderRadius}' stroke='%23${theme.palette.primaries[500]?.replace('#', '')}' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }
    : {
        backgroundColor: theme.palette.grey[100],
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${uploadBoxBorderRadius}' ry='${uploadBoxBorderRadius}' stroke='%23${theme.palette.grey[500]?.replace('#', '')}' stroke-width='1' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }),

  '.MuiButton-root': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: 'transparent',
    color: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
      color: 'transparent',
    },

    input: {
      width: '100%',
      height: '100%',
    },
  },
}));

export const UploaderLoadingContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  backgroundColor: alpha('' + theme.palette.primaries[100], 0.8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PepAnswerDetails = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(2),
  borderRadius: 8,

  '.MuiTypography-root': {
    '&.MuiTypography-caption': {
      color: theme.palette.grey[700],
    },
  },
}));

export const OverlaySectionCard = styled(SectionCard)(({ theme }) => ({
  paddingTop: theme.spacing(4.5),
  position: 'relative',
}));

export const AbsoluteSearchBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  zIndex: 10,
}));
