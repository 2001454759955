import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { NoteContent, TabNoteItem, TabNoteItemFooter } from './TabNotes.styled';
import { CommentAuthor } from '@/components/User/CommentAuthor';
import { AddCommentForm, Client, Note, NoteType } from '@/types';
import { useBoolean } from 'usehooks-ts';
import { ConfirmationModal } from '@/components/Modals';
import { useMutation } from '@tanstack/react-query';
import { deleteComment, editComment } from '@/api';
import { Can, Form, Icon, TextInput } from '@/components';
import { useEffect } from 'react';
import { useForm, useSelector } from '@/hooks';
import { formatTime } from '@/utils';
import { selectLoggedInUser } from '@/redux';

type NoteItemProps = {
  note: Note;
  client: Client;
  refresh: () => void;
};

export function NoteItem({ note, client, refresh }: NoteItemProps) {
  const { setTrue, setFalse, value: showConfirmationModal } = useBoolean(false);
  const user = useSelector(selectLoggedInUser);

  const { toggle, value: isEdit } = useBoolean(false);

  const {
    control,
    formState: { isValid },
    visibleErrors: errors,
    getValues,
    reset,
  } = useForm<AddCommentForm>({
    defaultValues: {
      content: note.content,
      category: NoteType.BasicInfo,
    },
  });

  const {
    mutate: removeComment,
    isSuccess: isDeleted,
    isPending: isDeleting,
  } = useMutation({
    mutationFn: deleteComment,
  });

  const {
    mutate: updateComment,
    isSuccess: isUpdated,
    isPending: isUpdating,
  } = useMutation({
    mutationFn: editComment,
  });

  useEffect(() => {
    if (isDeleted || isUpdated) {
      refresh();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted, isUpdated]);

  const onUpdateComment = () => {
    toggle();
    updateComment({
      clientId: client.id,
      noteId: note.id,
      payload: getValues(),
    });
  };

  const revertEdit = () => {
    toggle();
    reset();
  };

  return (
    <TabNoteItem>
      <Grid item xs={12}>
        {!isEdit && <NoteContent>{note.content}</NoteContent>}
        {isEdit && (
          <Form>
            <TextInput
              type="text"
              placeholder="Content"
              name="content"
              control={control}
              error={!!errors.content}
              helperText={errors.content?.message}
              rules={{
                required: 'Content is required',
              }}
              disabled={isUpdating}
              multiline
              rows={4}
            />
          </Form>
        )}
        <Divider />
      </Grid>
      <TabNoteItemFooter container>
        <Grid item xs={5}>
          <CommentAuthor
            avatar={note.creatorAvatar}
            name={`${note.creatorFirstName} ${note.creatorLastName}`}
            email={note.creatorEmail}
          />
        </Grid>
        <Grid item xs={3} gap={3}>
          <Typography
            variant="caption"
            color={theme => theme.palette.grey[700]}
          >
            Last Updated
          </Typography>
          <Typography variant="body2Medium" mt={0.5}>
            {formatTime(note.updatedAt)}
          </Typography>
        </Grid>
        <Can do="edit" on="onboarding_application_notes">
          <Grid container direction={'row'} xs={4} justifyContent={'flex-end'}>
            {note.createdBy === user?.id && (
              <Stack direction={'row'} gap={2}>
                {isDeleting || isUpdating ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <>
                    <IconButton
                      disabled={isEdit && !isValid}
                      onClick={() => (isEdit ? onUpdateComment() : toggle())}
                    >
                      <Icon name={!isEdit ? 'edit-outline' : 'check'} />
                    </IconButton>
                    <IconButton
                      disabled={isEdit && !isValid}
                      onClick={() => (isEdit ? revertEdit() : setTrue())}
                    >
                      <Icon name={isEdit ? 'reset' : 'trash-simple'} />
                    </IconButton>
                  </>
                )}
              </Stack>
            )}
          </Grid>
        </Can>
      </TabNoteItemFooter>
      <ConfirmationModal
        open={showConfirmationModal}
        extraData={note}
        title="Delete message"
        message="Are you sure you want to delete this message?"
        onClose={setFalse}
        onOk={() =>
          removeComment({
            clientId: client.id,
            noteId: note.id,
          })
        }
      />
    </TabNoteItem>
  );
}
