import { SimilarAccount } from '@/types';
import { formatDate, getAPIErrorMessage, getDisplayField } from '@/utils';
import { Alert, Box } from '@mui/material';
import {
  ClientBlock,
  Icon,
  Loader,
  SectionCardCallout,
  Table,
  TextInput,
} from '@/components';
import { useForm } from '@/hooks';
import { AbsoluteSearchBar, OverlaySectionCard } from './ClientDetails.styled';
import { useMemo } from 'react';

type TabSimilarAccountsProps = {
  accounts?: SimilarAccount[];
  isLoading?: boolean;
  error: Error | null;
};

export function TabSimilarAccounts({
  accounts,
  isLoading,
  error,
}: TabSimilarAccountsProps) {
  const { control, setValue, watch } = useForm<{
    keyword: string;
    sortBy: string;
  }>({
    defaultValues: {
      keyword: '',
      sortBy: 'firstName',
    },
  });

  const sortBy = watch('sortBy');
  const keyword = watch('keyword');

  const similarAccounts = useMemo(() => {
    const searchValue = keyword.trim();

    const filtered = (accounts || [])
      .filter(
        item =>
          item.birthday.includes(searchValue) ||
          item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.rimNo.includes(searchValue),
      )
      .sort();

    filtered.sort((a, b) =>
      sortBy === '-firstName'
        ? b.firstName.localeCompare(a.firstName)
        : a.firstName.localeCompare(b.firstName),
    );

    return filtered;
  }, [accounts, keyword, sortBy]);

  const onSortChange = (dataKey: string) => {
    setValue('sortBy', dataKey || 'firstName');
  };

  return (
    <>
      {error && (
        <Box mb={3}>
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        </Box>
      )}
      {isLoading ? (
        <Loader justifyContent="center" />
      ) : (
        <OverlaySectionCard>
          <AbsoluteSearchBar>
            <Box width={260}>
              <TextInput
                disabled={!accounts?.length}
                control={control}
                name="keyword"
                template="search"
              />
            </Box>
          </AbsoluteSearchBar>
          <SectionCardCallout>
            <Table
              sortBy={sortBy}
              rows={similarAccounts || []}
              onSortChange={onSortChange}
              columns={[
                {
                  header: 'Client',
                  widthRatio: 40,
                  allowSort: true,
                  dataKey: 'firstName',
                  cell: row => (
                    <ClientBlock
                      name={getDisplayField(row)}
                      avatarProps={{
                        big: false,
                      }}
                      description={row.rimNo ? `RIM # ${row.rimNo}` : ''}
                    />
                  ),
                },
                {
                  header: 'Date of birth',
                  dataKey: 'birthday',
                  cell: row => formatDate(row.birthday, 'dd/MM/yyyy'),
                },
              ]}
            />
          </SectionCardCallout>
        </OverlaySectionCard>
      )}
    </>
  );
}
