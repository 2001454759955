import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { clientDetailsReducer } from './client-details';
import { documentsReducer } from './documents';
import { picklistsReducer } from './picklists';
import { uiReducer } from './ui';
import { usersReducer } from './users';
import { roleDetailsReducer } from './role-details';
import { configsReducer } from './configs';
import { permissionsReducer } from './role-permissions';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    clientDetails: clientDetailsReducer,
    configs: configsReducer,
    documents: documentsReducer,
    picklists: picklistsReducer,
    userRoles: usersReducer,
    roleDetails: roleDetailsReducer,
    permissions: permissionsReducer,
    ui: uiReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
