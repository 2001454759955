import { PropsWithChildren } from 'react';
import { Container } from './Layout.styled';
import { FormHeader } from './FormHeader';

type FormCardProps = {
  className?: string;
  title?: string;
  onClose?: () => void;
  center?: boolean;
} & PropsWithChildren;

export function FormCard({
  className,
  title,
  onClose,
  center,
  children,
}: FormCardProps) {
  return (
    <Container className={className} center={center}>
      {title && <FormHeader title={title} onClose={onClose} />}
      {children}
    </Container>
  );
}
