import {
  AddUserForm,
  ApiFilter,
  DataList,
  User,
} from '@/types';
import { axios } from './axios';
import { REQUEST } from '@/constants';
import { getRequestFilterParams } from '@/utils';
import { UserDBFields } from '@/constants/users';

export const getReviewers = async () => {
  const result = await axios.get<User[]>(REQUEST.REVIEWERS.ROOT);

  return result.data || [];
};

export const addUser = async (payload: AddUserForm) => {
  const result = await axios.post(REQUEST.USER.ROOT, payload);

  return result.data;
};

export const updateUser = async ({
  id,
  payload,
}: {
  id: string;
  payload: User;
}) => {
  const result = await axios.put(REQUEST.USER.UPDATE_USER(id), payload);

  return result.data;
};

export const updateUserRoles = async ({
  id,
  payload,
}: {
  id: string;
  payload: string[];
}) => {
  const result = await axios.post(REQUEST.USER.UPDATE_USER_ROLES(id), payload);

  return result.data;
};

export const toggleUserStatus = async ({
  id,
  payload,
}: {
  id: string;
  payload: User;
}) => {
  const result = await axios.put(REQUEST.USER.ENABLE_DISABLE(id), payload);

  return result.data;
};

export const getUsers = async (filter: ApiFilter) => {
  const params = getRequestFilterParams(filter, UserDBFields);
  const { data: usersResponse } = await axios.get<DataList<User>>(
    REQUEST.USER.ROOT,
    {
      params,
    },
  );
  return {
    data: usersResponse.data,
    pagination: {
      size: usersResponse.size,
      total: usersResponse.total,
      page: usersResponse.page,
    },
  };
};
