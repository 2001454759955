import { StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
  BackButton,
  HeadingActionSlot,
  HeadingContainer,
  HeadingTitleSlot,
} from './Layout.styled';
import { Icon } from '../Icon';

type HeadingProps = {
  title: ReactNode;
  actionSlot?: ReactNode;
  subActionSlot?: ReactNode;
  isSubSection?: boolean;
  onBack?: () => void;
  center?: boolean;
} & Omit<StackProps, 'title'>;

export function Heading({
  title,
  actionSlot,
  subActionSlot,
  isSubSection,
  onBack,
  center = true,
  ...containerProps
}: HeadingProps) {
  return (
    <HeadingContainer
      mb={isSubSection ? 2 : 3}
      center={center}
      {...containerProps}
    >
      {onBack && (
        <BackButton onClick={onBack}>
          <Icon name="arrow-left-alt" />
        </BackButton>
      )}
      <HeadingTitleSlot>
        {typeof title === 'string' ? (
          <Typography
            variant={isSubSection ? 'h4' : 'h2'}
            component={isSubSection ? 'h4' : 'h2'}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
        {subActionSlot}
      </HeadingTitleSlot>
      <HeadingActionSlot center={center}>{actionSlot}</HeadingActionSlot>
    </HeadingContainer>
  );
}
