import { ThemeOptions } from '@mui/material';
import { buttonConfigs } from './button';
import { cssBaseLineConfigs } from './cssBaseline';
import { buttonBaseConfigs } from './buttonBase';
import { textFieldConfigs } from './textField';
import { containerConfigs } from './container';
import { typographyConfigs } from './typography';
import { toggleButtonGroupConfigs } from './toggleButtonGroup';
import { dividerConfigs } from './divider';
import { formLabelConfigs } from './formLabel';
import { stackConfigs } from './stack';
import { listItemAvatarConfigs } from './listItemAvatar';
import { avatarConfigs } from './avatar';
import { menuConfigs } from './menu';
import { modalConfigs } from './modal';
import { tabConfigs } from './tab';
import { tabsConfigs } from './tabs';
import { accordionConfigs } from './accordion';
import { alertConfigs } from './alert';
import { listItemConfigs } from './listItem';
import { snackbarConfigs } from './snackbar';
import { drawerConfigs } from './drawer';
import { selectConfigs } from './select';
import { toggleButtonConfigs } from './toggleButton';
import { autocompleteConfigs } from './autocomplete';
import { switchConfigs } from './switch';

export const componentsConfigs: ThemeOptions['components'] = {
  MuiAccordion: accordionConfigs,
  MuiAlert: alertConfigs,
  MuiAutocomplete: autocompleteConfigs,
  MuiAvatar: avatarConfigs,
  MuiButton: buttonConfigs,
  MuiButtonBase: buttonBaseConfigs,
  MuiContainer: containerConfigs,
  MuiCssBaseline: cssBaseLineConfigs,
  MuiDivider: dividerConfigs,
  MuiDrawer: drawerConfigs,
  MuiFormLabel: formLabelConfigs,
  MuiListItem: listItemConfigs,
  MuiMenu: menuConfigs,
  MuiModal: modalConfigs,
  MuiListItemAvatar: listItemAvatarConfigs,
  MuiSnackbar: snackbarConfigs,
  MuiStack: stackConfigs,
  MuiSelect: selectConfigs,
  MuiSwitch: switchConfigs,
  MuiTab: tabConfigs,
  MuiTabs: tabsConfigs,
  MuiTextField: textFieldConfigs,
  MuiToggleButtonGroup: toggleButtonGroupConfigs,
  MuiToggleButton: toggleButtonConfigs,
  MuiTypography: typographyConfigs,
};
