import { Components } from '@mui/material';
import { elevationLevel } from '../constants';

export const drawerConfigs: Components['MuiDrawer'] = {
  defaultProps: {
    variant: 'persistent',
    anchor: 'right',
    hideBackdrop: true,
    PaperProps: {
      elevation: elevationLevel.l,
    },
  },
};
