import { v4 as uuid } from 'uuid';
import { BlobReader, BlobWriter, ZipWriter } from '@zip.js/zip.js';

export function getDataTestId(value: string) {
  return {
    'data-test-id': value
      .toLowerCase()
      .replace(/([/\s]|undefined|null)+/g, '-')
      .replace(/[-]+/g, '-')
      .replace(/(^-|-$)/g, ''),
  };
}

export function downloadFile(url: string, name?: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name || uuid();

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function downloadZip(
  files: { fileName?: string; data: Blob }[],
  name?: string,
) {
  const zipFileWriter = new BlobWriter('application/zip');
  const zipWriter = new ZipWriter(zipFileWriter, { bufferedWrite: true });

  files.forEach(file => {
    if (file) {
      zipWriter.add(file.fileName || uuid(), new BlobReader(file.data));
    }
  });

  const url = URL.createObjectURL(await zipWriter.close());
  downloadFile(url, name);
}
