import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from '@/hooks';
import { selectUserRoles } from '@/redux';
import { UserRole } from '@/types';
import { Tab } from '@mui/material';
import { getDataTestId } from '@/utils';
import {
  RolePermissionsContainer,
  RolePermissionsTab,
} from './DrawerRoleInfoContainer.styled';
import { EditUserDrawerRoleSection } from './EditUserDrawerRoleSection';
import { RoleNameText } from '@/constants';

type EditUserDrawerRoleInfoProps = {
  roles: string[];
  permissions: string[];
};

export function EditUserDrawerRoleInfo({ roles }: EditUserDrawerRoleInfoProps) {
  const [mappedRoles, setMappedRoles] = useState<UserRole[]>([]);

  const { roles: allRoles } = useSelector(selectUserRoles);
  useEffect(() => {
    setMappedRoles(allRoles.filter(roleItem => roles.includes(roleItem.name)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const [tab, setTab] = useState(0);

  const onTabChange = (_: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  if (mappedRoles.length === 1) {
    return <EditUserDrawerRoleSection role={mappedRoles[0]} />;
  }

  if (mappedRoles.length > 1) {
    return (
      <>
        <RolePermissionsTab
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          onChange={onTabChange}
          {...getDataTestId(`edit-user-roles-tab`)}
        >
          {mappedRoles.map((roleItem, itemIndex) => (
            <Tab
              key={`edit-user-tab-roles-${roleItem.name}`}
              {...getDataTestId(`edit-user-tab-roles-${roleItem.name}`)}
              label={RoleNameText[roleItem.name]}
              value={itemIndex}
            />
          ))}
        </RolePermissionsTab>
        <RolePermissionsContainer gap={0}>
          <EditUserDrawerRoleSection role={mappedRoles[tab]} />
        </RolePermissionsContainer>
      </>
    );
  }
}
