import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const saintKittsConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-KN',
  isoCode: 'KNA',
  currency: 'XCD',
  name: 'St. Kitts & Nevis',
  flag: 'flag-st-kitts-nevis',
};
