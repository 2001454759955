import {
  Box,
  Collapse,
  Divider,
  ListItem,
  styled,
  Typography,
} from '@mui/material';
import { Icon } from '../Icon';
import { Switch } from '../Form';

export const StyledCollapse = styled(Collapse)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

export const StyledCollapseSwitch = styled(Switch)(({ theme, disabled }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  cursor: disabled ? 'not-allowed' : 'default',
}));

export const CollapsibleItemDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const StyledCollapseListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(1.4),
  paddingBottom: theme.spacing(1.4),
}));

export const StyledCollapseListItemLabel = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const CollapseCategoryItemsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 0, 0.5, 0),
}));

export const StyledReadOnlyIcon = styled(Icon)(({ theme }) => ({
  marginRight: theme.spacing(1.8),
}));
