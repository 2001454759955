import {
  AMLStatus,
  ClientStatus,
  DocumentStatus,
  DocumentType,
  EmploymentStatus,
  Gender,
  GenericStatus,
  IconName,
  DocumentSubType,
  JobStatus,
  JobType,
  PEPStatus,
  Profession,
  RoleName,
  UserStatus,
  OptionValue,
  FundingSource,
  ClientTitle,
} from '@/types';

export const ClientTitleText: Record<string, string> = {
  [ClientTitle.MR]: 'Mr.',
  [ClientTitle.MS]: 'Ms.',
  [ClientTitle.MRS]: 'Mrs.',
};

export const ClientStatusText: Record<string, string> = {
  [ClientStatus.Draft]: 'Draft',
  [ClientStatus.InReview]: 'In review',
  [ClientStatus.Rejected]: 'Rejected',
  [ClientStatus.PendingApproval]: 'Pending approval',
  [ClientStatus.Approved]: 'Approved',
  [ClientStatus.Archived]: 'Archived',
};

export const StatusText: Record<string, string> = {
  [ClientStatus.Archived]: 'Archived',
  [DocumentStatus.AboutToExpire]: 'About to expire',
  [DocumentStatus.Rejected]: 'Rejected',
  [DocumentStatus.Approved]: 'Approved',
  [DocumentStatus.Expired]: 'Expired',
  [DocumentStatus.Requested]: 'Requested',
  [DocumentStatus.Pending]: 'Pending',
  [DocumentStatus.InReview]: 'In review',
  [DocumentStatus.Generated]: 'Generated',
  [PEPStatus.Exposed]: 'Exposed',
  [PEPStatus.Verified]: 'Verified',
  [PEPStatus.InProgress]: 'In progress',
  [PEPStatus.Approved]: 'Approved',
  [AMLStatus.Sanctioned]: 'Sanctioned',
  [AMLStatus.Verified]: 'Verified',
  [AMLStatus.InProgress]: 'In progress',
  [AMLStatus.Approved]: 'Approved',
  [GenericStatus.Verified]: 'Verified',
  [GenericStatus.Unverified]: 'Unverified',
};

export const StatusIcon: Record<string, IconName> = {
  [DocumentStatus.Pending]: 'file-dash',
  [DocumentStatus.InReview]: 'files',
  [DocumentStatus.Rejected]: 'reject',
  [DocumentStatus.AboutToExpire]: 'alert-outline',
  [DocumentStatus.Expired]: 'siren',
  [DocumentStatus.Approved]: 'check-fat-outline',
};

export const DocumentTypeText: Record<string, string> = {
  [DocumentType.AuthorisationLetter]: 'Authorisation Letter',
  [DocumentType.TinNumber]: 'TIN Number',
  [DocumentType.BankReferenceLetter]: 'Bank Reference Letter',
  [DocumentType.CertificateOfGoodStanding]: 'Certificate of Good Standing',
  [DocumentType.CertificateOfIncorporation]: 'Certificate of Incorporation',
  [DocumentType.DocumentSign]: 'Document Sign',
  [DocumentType.FinancialStatementsProjections]:
    'Financial Statements / Projections',
  [DocumentType.Identity]: 'Identity',
  [DocumentType.IdentityBack]: 'Identity Back',
  [DocumentType.LastGroupMeetingMinutes]: 'Last Group Meeting Minutes',
  [DocumentType.ListOfDirectorsMembers]: 'List of Directors and Members',
  [DocumentType.MemorandumOfArticlesAndAssociation]:
    'Memorandum of Articles and Association',
  [DocumentType.NonprofitOrgRegistrationCertificate]:
    'Nonprofit Org Registration Certificate',
  [DocumentType.ParentID]: 'Parent / Caretaker ID',
  [DocumentType.ProofOfAddress]: 'Proof of Address',
  [DocumentType.ProofOfEmployment]: 'Proof of Employment',
  [DocumentType.ProofOfIncome]: 'Proof of Income',
  [DocumentType.RegisteredAgentCertificate]: 'Registered Agent Certificate',
  [DocumentType.Selfie]: 'Selfie Photo',
  [DocumentType.SchoolLetterOfAttendance]: 'School Letter of Attendance',
  [DocumentType.SecondID]: 'Second ID',
  [DocumentType.SocialSecurityLetter]: 'Social Security Letter',
  [DocumentType.SupportLetterFromCaretaker]: 'Support Letter From Caretaker',
  [DocumentType.SupportLetterFromHusband]: 'Support Letter From Husband',
  [DocumentType.SupportLetterFromParent]:
    'Support Letter From Parent / Caretaker',
  [DocumentType.Internal]: 'Internal Document',
  [DocumentType.Generated]: 'Generated Document',
  [DocumentType.SelfAttestationForProofOfIncome]:
    'Self-Attestation for Proof of Income',
};

export const ProfessionText: Record<string, string> = {
  [Profession.Housewife]: 'Housewife',
  [Profession.PartnershipLimitedLiability]: 'Partnership/Limited Liability',
  [Profession.Pensioner]: 'Pensioner',
  [Profession.SoleTrader]: 'Sole Trader',
  [Profession.Student]: 'Student',
  [Profession.Unemployed]: 'Unemployed',
  [Profession.Unincorporated]: 'Unincorporated',
};

export const DocumentSubTypeText: Record<string, string> = {
  [DocumentSubType.None]: 'None',
  [DocumentSubType.UtilityBill]: 'Utility Bill',
  [DocumentSubType.BankReceipt]: 'Bank Receipt',
  [DocumentSubType.BankStatement]: 'Bank Statement',
  [DocumentSubType.ConfirmationOfAddress]: 'Confirmation of Address',
  [DocumentSubType.LetterOfEmployment]: 'Letter of Employment',
  [DocumentSubType.Id]: 'ID',
  [DocumentSubType.DrivingLicense]: 'Driving License',
  [DocumentSubType.Passport]: 'Passport',
  [DocumentSubType.SecurityCard]: 'Security Card',
  [DocumentSubType.Nis]: 'NIS',
  [DocumentSubType.TinCertificate]: 'TIN Certificate',
};

export const EmploymentStatusText: Record<EmploymentStatus, string> = {
  [EmploymentStatus.Employed]: 'Employed',
  [EmploymentStatus.SelfEmployed]: 'Self-employed',
  [EmploymentStatus.Unemployed]: 'Unemployed',
};

export const GenderText: Record<string, string> = {
  [Gender.Female]: 'Female',
  [Gender.Male]: 'Male',
};

export const JobStatusText: Record<JobStatus, string> = {
  [JobStatus.Pending]: 'Pending',
  [JobStatus.Processing]: 'Processing',
  [JobStatus.Error]: 'Error',
  [JobStatus.Success]: 'Success',
};

export const UserStatusText: Record<string, string> = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Locked]: 'Locked',
};

export const RoleNameText: Record<string, string> = {
  [RoleName.Admin]: 'Administrator',
  [RoleName.ProductionSupport]: 'Production Support User',
  [RoleName.Corporate]: 'Corporate Security User',
  [RoleName.Approver]: 'Manual Review Approver',
  [RoleName.ManualReviewer]: 'Manual Review User',
  [RoleName.ViewOnly]: 'View Only User',
  [RoleName.ManualReviewerSupervisor]: 'Manual Reviewer Supervisor',
};

export const ScheduleJobTypeText: Record<string, string> = {
  [JobType.CreateBankAccount]: 'Create Bank Account',
  [JobType.CreateICBProfile]: 'Create ICB Profile',
  [JobType.CreateRIM]: 'Create RIM',
  [JobType.FundAccount]: 'Fund Account',
  [JobType.UpdateRIM]: 'Update RIM',
};

export const OptionValueText: Record<string, string> = {
  [OptionValue.Yes]: 'Yes',
  [OptionValue.No]: 'No',
  [OptionValue.All]: 'All',
};

export const FundingSourceText = {
  [FundingSource.Salary]: 'Salary',
  [FundingSource.Savings]: 'Savings',
  [FundingSource.FundingFromSpouse]: 'Funds from Spouse',
  [FundingSource.FundingFromParents]: 'Funds from Parents',
  [FundingSource.BusinessProceeds]: 'Business Proceeds',
  [FundingSource.Investments]: 'Investments',
  [FundingSource.Other]: 'Other',
};
