import { AbilityContext, Heading, Icon, Loader, Tab } from '@/components';
import { Alert, Box, Container, Tabs, Typography } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { TabClientInfo } from './TabClientInfo';
import { TabAllDocuments } from './TabAllDocuments';
import {
  useClientDetailsRefresh,
  useDispatch,
  useNavigateBack,
  useSelector,
  useWindowFocus,
} from '@/hooks';
import {
  fetchClientDetails,
  resetClientDetails,
  selectClientDetailsState,
} from '@/redux';
import { getDataTestId, getDisplayField, getStatusIcon } from '@/utils';
import { ROUTE } from '@/constants';
import { ClientDetailsActionBar } from './ClientDetailsActionBar';
import { TabActivities } from './TabActivities';
import { AvatarProps } from '@/components/User/User.interface';
import { StyledAlert, StyledClientBlock } from './ClientDetails.styled';
import { TabNotes } from './TabNotes';
import { ClientStatus } from '@/types';
import { getComments, getSimilarAccounts } from '@/api';
import { NoteType } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { TabSimilarAccounts } from './TabSimilarAccounts';

enum TabValue {
  ClientInfo,
  AllDocuments,
  Notes,
  SimilarAccounts,
  Activities,
}

export function ClientDetailsPage() {
  const { id } = useParams();
  const [tab, setTab] = useState<TabValue>(TabValue.ClientInfo);
  const {
    clientDetails: client,
    initialized,
    error,
  } = useSelector(selectClientDetailsState);
  const goBack = useNavigateBack(ROUTE.CLIENTS);
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    dispatch(fetchClientDetails(String(id)));

    return () => {
      dispatch(resetClientDetails());
    };
  }, [dispatch, id]);

  useClientDetailsRefresh();

  useWindowFocus(() => {
    dispatch(fetchClientDetails(String(id)));
  });

  const {
    data: notes,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [id],
    queryFn: () => getComments(NoteType.BasicInfo, String(id)),
    retry: false,
  });

  const {
    data: similarAccounts,
    isLoading: isLoadingSimilarAccounts,
    error: similarAccountsError,
  } = useQuery({
    enabled: !!((client?.firstName || client?.lastName) && client.birthday),
    queryKey: ['similar-accounts', client],
    queryFn: () =>
      client
        ? getSimilarAccounts({
            firstName: client.firstName || '',
            lastName: client.lastName || '',
            birthday: client.birthday || '',
            selfRim: client.rim,
          })
        : undefined,
  });

  const onTabChange = (_: SyntheticEvent, newTab: TabValue) => {
    setTab(newTab);
  };

  const renderTab = useCallback(() => {
    if (!client) return null;

    switch (tab) {
      case TabValue.ClientInfo:
        return <TabClientInfo />;
      case TabValue.AllDocuments:
        return <TabAllDocuments />;
      case TabValue.Activities:
        return <TabActivities client={client} />;
      case TabValue.Notes:
        return (
          <TabNotes
            client={client}
            isFetching={isFetching}
            notes={notes || []}
            onRefetch={refetch}
          />
        );
      case TabValue.SimilarAccounts:
        return (
          <TabSimilarAccounts
            accounts={similarAccounts}
            isLoading={isLoadingSimilarAccounts}
            error={similarAccountsError}
          />
        );
    }
  }, [
    client,
    tab,
    isFetching,
    notes,
    refetch,
    similarAccounts,
    isLoadingSimilarAccounts,
    similarAccountsError,
  ]);

  if (error) {
    return (
      <Container>
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {error}
        </Alert>
      </Container>
    );
  }

  if (!initialized) {
    return (
      <Container>
        <Loader justifyContent="center" />
      </Container>
    );
  }

  if (!client) {
    return (
      <Container>
        <Typography variant="body1Bold">Client not found</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {client.eligible === false &&
        ![ClientStatus.Approved, ClientStatus.Rejected].includes(
          client.status,
        ) && (
          <Box mb={2}>
            <StyledAlert
              icon={<Icon name="info" />}
              color="info"
              variant="standard"
            >
              This customer has been advised to visit the branch to complete
              their application. Please await instructions from the Branch to
              approve the account.
            </StyledAlert>
          </Box>
        )}
      <Heading
        onBack={goBack}
        title={
          <StyledClientBlock
            avatar={client.picture}
            avatarProps={{
              big: true,
              ...(getStatusIcon(client) as AvatarProps),
            }}
            name={getDisplayField(client)}
            nameProps={{
              variant: 'h2',
            }}
            description={
              <Typography
                variant="body2"
                color={theme => theme.palette.grey[700]}
              >
                Assignee:{' '}
                <Typography
                  variant="body2Bold"
                  component="span"
                  color={theme => theme.palette.text.primary}
                >
                  {client.assignee || 'Unassigned'}
                </Typography>
              </Typography>
            }
          />
        }
        actionSlot={<ClientDetailsActionBar client={client} />}
      />
      <Tabs
        {...getDataTestId(`client-details-tab-bar`)}
        value={tab}
        onChange={onTabChange}
      >
        <Tab
          {...getDataTestId(`tab-client-info`)}
          label="Client info"
          value={TabValue.ClientInfo}
        />
        {ability.can('view', 'document') && (
          <Tab
            {...getDataTestId(`tab-all-documents`)}
            label="All documents"
            chipContent={client.documents?.length || 0}
            value={TabValue.AllDocuments}
          />
        )}
        {ability.can('view', 'onboarding_application_notes') && (
          <Tab
            {...getDataTestId(`tab-notes`)}
            value={TabValue.Notes}
            label="Notes"
            chipContent={notes?.length || 0}
          />
        )}
        <Tab
          {...getDataTestId(`tab-similar-accounts`)}
          value={TabValue.SimilarAccounts}
          label="Similar accounts"
          chipContent={similarAccounts?.length || 0}
          isLoading={isLoadingSimilarAccounts}
        />
        {ability.can('view', 'report') && (
          <Tab
            {...getDataTestId(`tab-activities`)}
            label={`Activities`}
            value={TabValue.Activities}
          />
        )}
      </Tabs>
      {renderTab()}
    </Container>
  );
}
