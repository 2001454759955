export enum JobType {
  CreateRIM = 'CREATE_RIM',
  UpdateRIM = 'UPDATE_RIM',
  CreateICBProfile = 'CREATE_ICB_PROFILE',
  CreateBankAccount = 'CREATE_BANK_ACCOUNT',
  FundAccount = 'FUND_ACCOUNT',
}

export enum JobStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export type Job = {
  id: string;
  jobType: string;
  payload: string;
  response: string;
  endTime: string;
  startTime: string;
  status: JobStatus;
  tenant: string;
};
