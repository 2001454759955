import { Box, Typography } from '@mui/material';
import { useBoolean } from 'usehooks-ts';
import { CollapsibleSwitch } from '@/types';
import { Icon } from '../Icon';
import {
  CollapsibleItemDivider,
  StyledCollapse,
  StyledCollapseListItem,
  StyledCollapseListItemLabel,
  StyledCollapseSwitch,
  StyledReadOnlyIcon,
} from './Collapse.styled';
import { useEffect } from 'react';

type CollapsibleGroupSwitchItemProps = {
  data: CollapsibleSwitch;
  canTogglePermission?: boolean;
  singleItemCategoryExpand?: boolean;
  onCategoryToggle: (isChecked: boolean, checkedItems: string[]) => void;
  onItemCheck: (value: string) => void;
};

export const CollapsibleGroupSwitchItem = ({
  data,
  onCategoryToggle,
  onItemCheck,
  canTogglePermission,
  singleItemCategoryExpand,
}: CollapsibleGroupSwitchItemProps) => {
  const { value, setTrue: expandCategory, toggle } = useBoolean(false);

  useEffect(() => {
    if (singleItemCategoryExpand && data.noOfCheckedItems > 0) {
      expandCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleItemCategoryExpand]);

  const toggleCategory = (checked: boolean) =>
    onCategoryToggle(
      checked,
      data.items.map(item => item.value),
    );

  return (
    <Box key={`collapsible-category-${data.categoryName}`}>
      <StyledCollapseListItem>
        <Icon
          name={value ? 'arrow-up' : 'arrow-down'}
          onClick={toggle}
          size={18}
          cursor={'pointer'}
        />
        <Typography flex={1} variant="body2Bold" paddingLeft={1}>
          {data.categoryName}
        </Typography>
        {!canTogglePermission || data.readOnly ? (
          <StyledReadOnlyIcon
            name={data.checked ? 'check-circle' : 'minus-circle'}
            color={theme =>
              data.checked ? theme.palette.green[400] : theme.palette.red[400]
            }
            size={24}
          />
        ) : (
          <StyledCollapseSwitch
            checked={data.checked}
            onChange={e => toggleCategory(e.currentTarget.checked)}
          />
        )}
      </StyledCollapseListItem>
      <StyledCollapse in={value} timeout="auto" unmountOnExit>
        {data.items.map(item => (
          <Box
            key={`collapsible-category-item-${data.categoryName}-${item.value}`}
          >
            <CollapsibleItemDivider />
            <StyledCollapseListItem>
              <StyledCollapseListItemLabel flex={1} variant="body2">
                {item.label}
              </StyledCollapseListItemLabel>
              {!canTogglePermission || item.readOnly ? (
                <StyledReadOnlyIcon
                  name={item.checked ? 'check-circle' : 'minus-circle'}
                  color={theme =>
                    item.checked
                      ? theme.palette.green[400]
                      : theme.palette.red[400]
                  }
                  size={24}
                />
              ) : (
                <StyledCollapseSwitch
                  checked={item.checked}
                  onChange={() => onItemCheck(item.value)}
                />
              )}
            </StyledCollapseListItem>
          </Box>
        ))}
      </StyledCollapse>
      <CollapsibleItemDivider />
    </Box>
  );
};
