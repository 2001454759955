export type AbilityAction =
  | 'assign'
  | 'unassign'
  | 'add'
  | 'run'
  | 'review'
  | 'view'
  | 'edit'
  | 'export'
  | 'request';
export type AbilitySubject =
  | 'onboarding_application'
  | 'onboarding_application_self'
  | 'onboarding_application_notes'
  | 'document'
  | 'client'
  | 'administration'
  | 'administration_roles'
  | 'administration_users'
  | 'report'
  | 'schedule_job';

export enum RoleName {
  Admin = 'admin',
  Approver = 'approver',
  ProductionSupport = 'production_support',
  ManualReviewer = 'manual_reviewer',
  Corporate = 'corporate_security',
  ViewOnly = 'view_only',
  ManualReviewerSupervisor = 'manual_reviewer_supervisor',
}

export enum PermissionName {
  ClientApprove = 'client_approve',
  ClientAssignBulk = 'client_assign_bulk',
  ClientAssignSelf = 'client_assign_self',
  ClientUnassign = 'client_unassign',
  ClientUnassignSelf = 'client_unassign_self',
  ClientViewNotes = 'client_view_notes',
  ClientEditNotes = 'client_edit_notes',
  ClientEdit = 'client_edit',
  ClientView = 'client_view',
  ClientExport = 'client_export',
  DocumentApprove = 'document_approve',
  DocumentRequest = 'document_request',
  DocumentEdit = 'document_edit',
  DocumentView = 'document_view',
  FeatureFlagAdd = 'feature_flag_add',
  FeatureFlagEdit = 'feature_flag_edit',
  AuditView = 'audit_view',
  ScheduleJobRun = 'schedule_job_run',
  ScheduleJobView = 'schedule_job_view',
  RoleAdd = 'role_add',
  RoleEdit = 'role_edit',
  RoleView = 'role_view',
  UserAdd = 'user_add',
  UserEdit = 'user_edit',
  UserView = 'user_view',
}

export type PermissionDetails = {
  id: string;
  name: PermissionName;
  display: string;
  component: string;
};

export type RoleDetails = {
  id: string;
  name: RoleName;
  display: string;
  description: string;
  permissions: string[];
};
