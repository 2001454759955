import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { typographyConfigs } from '../typography';
import { layoutConfigs } from '../layout';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
  ...layoutConfigs,
});

export const textFieldConfigs: Components['MuiTextField'] = {
  defaultProps: {
    fullWidth: true,
    size: 'small',
  },
  styleOverrides: {
    root: {
      '.MuiFormLabel-root': {
        marginBottom: 0,
        marginTop: theme.spacing(0.25),
      },
      '.MuiInputBase-root': {
        '&:not(.MuiInputBase-multiline)': {
          height: 44,
        },
      },
      '.MuiOutlinedInput-root': {
        backgroundColor: theme.palette.common.white,
        fontSize: 14,

        fieldset: {
          borderColor: theme.palette.grey[600],
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),

          ':hover': {
            borderColor: theme.palette.grey[1000],
          },
        },

        '&.Mui-focused fieldset': {
          borderColor: theme.palette.grey[1000],
          borderWidth: 1,
        },

        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.main,
        },

        '.MuiInputBase-input': {
          paddingBottom: theme.spacing(1.5),
          paddingTop: theme.spacing(1.5),
          paddingLeft: theme.spacing(1.75),
          paddingRight: theme.spacing(1.75),
          '::placeholder': {
            color: theme.palette.grey[700],
            opacity: 1,
          },
        },
        '.MuiInputBase-inputMultiline': {
          padding: 0,
        },
      },
      '.MuiFormHelperText-root': {
        fontSize: theme.typography.captionBold.fontSize,
        marginLeft: 0,
        marginRight: 0,

        '&.Mui-error': {
          fontWeight: theme.typography.captionBold.fontWeight,
        },
      },
    },
  },
};
