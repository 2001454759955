import { IconName } from '@/types';
import { Theme } from '@mui/material';

export function getColorByAction(action: string) {
  return (theme: Theme) => {
    switch (action) {
      default:
        return theme.palette.primaries[400];
    }
  };
}

export function getIconByUserAction(action: string) {
  let name: IconName = 'info-outline';

  switch (action) {
    case 'CLIENT_UPDATE':
      name = 'note';
      break;
    case 'DOCUMENT_REVIEW':
      name = 'search-doc';
      break;
  }

  return {
    name,
    color: getColorByAction(action),
  };
}
