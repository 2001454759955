import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Image } from './Image';
import { useRef } from 'react';
import { ImageViewerContainer, ViewerCenterSlot } from './File.styled';
import { Loader } from '../Icon';
import { useBoolean } from 'usehooks-ts';

type ImageViewerProps = {
  url: string;
  alt?: string;
  rotateDegree?: number;
};

export function ImageViewer({ url, alt, rotateDegree }: ImageViewerProps) {
  const { value: isLoaded, setTrue: setLoaded } = useBoolean(false);
  const containerRef = useRef<HTMLElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <ImageViewerContainer ref={containerRef}>
      {!isLoaded && (
        <ViewerCenterSlot>
          <Loader
            justifyContent="center"
            iconProps={{
              size: 32,
              color: theme => theme.palette.common.white,
            }}
          />
        </ViewerCenterSlot>
      )}
      <TransformWrapper centerZoomedOut minScale={0.1}>
        {({ centerView, zoomToElement }) => (
          <TransformComponent>
            <Image
              hideLoader
              ref={imgRef}
              src={url}
              alt={alt}
              onLoad={() => {
                setLoaded();
                centerView();
                if (imgRef.current) {
                  zoomToElement(imgRef.current, undefined, 0);
                }
              }}
              onError={setLoaded}
              rotateDegree={rotateDegree}
            />
          </TransformComponent>
        )}
      </TransformWrapper>
    </ImageViewerContainer>
  );
}
