import {
  AuditActorType,
  AuditLogStatus,
  ClientStatus,
  ClientTitle,
  DocumentStatus,
  DocumentSubType,
  DocumentType,
  Gender,
  JobStatus,
  JobType,
  Option,
  OptionValue,
  RoleName,
  SummaryPeriod,
  UserStatus,
} from '@/types';
import {
  DocumentTypeText,
  ClientStatusText,
  StatusText,
  GenderText,
  DocumentSubTypeText,
  RoleNameText,
  UserStatusText,
  ScheduleJobTypeText,
  OptionValueText,
  ClientTitleText,
} from './mapper';

export const USER_STATUSES: Option[] = [
  {
    value: UserStatus.Active,
    text: UserStatusText[UserStatus.Active],
  },
  {
    value: UserStatus.Locked,
    text: UserStatusText[UserStatus.Locked],
  },
];

export const CLIENT_STATUSES: Option[] = [
  {
    value: ClientStatus.Draft,
    text: ClientStatusText[ClientStatus.Draft],
  },
  {
    value: ClientStatus.InReview,
    text: ClientStatusText[ClientStatus.InReview],
  },
  {
    value: ClientStatus.PendingApproval,
    text: ClientStatusText[ClientStatus.PendingApproval],
  },
  {
    value: ClientStatus.Approved,
    text: ClientStatusText[ClientStatus.Approved],
  },
  {
    value: ClientStatus.Rejected,
    text: ClientStatusText[ClientStatus.Rejected],
  },
  {
    value: ClientStatus.Archived,
    text: ClientStatusText[ClientStatus.Archived],
  },
];

export const CLIENT_STATUSES_WITH_ALL: Option[] = [
  {
    value: OptionValue.All,
    text: 'All Statuses',
  },
  ...CLIENT_STATUSES,
];

export const DOCUMENT_TYPES: Option[] = [
  {
    value: DocumentType.Identity,
    text: DocumentTypeText[DocumentType.Identity],
  },
  {
    value: DocumentType.IdentityBack,
    text: DocumentTypeText[DocumentType.IdentityBack],
  },
  {
    value: DocumentType.Selfie,
    text: DocumentTypeText[DocumentType.Selfie],
  },
  {
    value: DocumentType.ProofOfAddress,
    text: DocumentTypeText[DocumentType.ProofOfAddress],
  },
  {
    value: DocumentType.ProofOfEmployment,
    text: DocumentTypeText[DocumentType.ProofOfEmployment],
  },
  {
    value: DocumentType.ProofOfIncome,
    text: DocumentTypeText[DocumentType.ProofOfIncome],
  },
  {
    value: DocumentType.BankReferenceLetter,
    text: DocumentTypeText[DocumentType.BankReferenceLetter],
  },
  {
    value: DocumentType.FinancialStatementsProjections,
    text: DocumentTypeText[DocumentType.FinancialStatementsProjections],
  },
  {
    value: DocumentType.CertificateOfIncorporation,
    text: DocumentTypeText[DocumentType.CertificateOfIncorporation],
  },
  {
    value: DocumentType.MemorandumOfArticlesAndAssociation,
    text: DocumentTypeText[DocumentType.MemorandumOfArticlesAndAssociation],
  },
  {
    value: DocumentType.ListOfDirectorsMembers,
    text: DocumentTypeText[DocumentType.ListOfDirectorsMembers],
  },
  {
    value: DocumentType.CertificateOfGoodStanding,
    text: DocumentTypeText[DocumentType.CertificateOfGoodStanding],
  },
  {
    value: DocumentType.RegisteredAgentCertificate,
    text: DocumentTypeText[DocumentType.RegisteredAgentCertificate],
  },
  {
    value: DocumentType.NonprofitOrgRegistrationCertificate,
    text: DocumentTypeText[DocumentType.NonprofitOrgRegistrationCertificate],
  },
  {
    value: DocumentType.LastGroupMeetingMinutes,
    text: DocumentTypeText[DocumentType.LastGroupMeetingMinutes],
  },
  {
    value: DocumentType.SchoolLetterOfAttendance,
    text: DocumentTypeText[DocumentType.SchoolLetterOfAttendance],
  },
  {
    value: DocumentType.SupportLetterFromParent,
    text: DocumentTypeText[DocumentType.SupportLetterFromParent],
  },
  {
    value: DocumentType.ParentID,
    text: DocumentTypeText[DocumentType.ParentID],
  },
  {
    value: DocumentType.AuthorisationLetter,
    text: DocumentTypeText[DocumentType.AuthorisationLetter],
  },
  {
    value: DocumentType.SocialSecurityLetter,
    text: DocumentTypeText[DocumentType.SocialSecurityLetter],
  },
  {
    value: DocumentType.SupportLetterFromHusband,
    text: DocumentTypeText[DocumentType.SupportLetterFromHusband],
  },
  {
    value: DocumentType.SupportLetterFromCaretaker,
    text: DocumentTypeText[DocumentType.SupportLetterFromCaretaker],
  },
  {
    value: DocumentType.SelfAttestationForProofOfIncome,
    text: DocumentTypeText[DocumentType.SelfAttestationForProofOfIncome],
  },
].sort((a, b) => a.text.localeCompare(b.text));

export const DOCUMENT_SUB_TYPES: Option[] = [
  {
    value: DocumentSubType.Id,
    text: DocumentSubTypeText[DocumentSubType.Id],
  },
  {
    value: DocumentSubType.Passport,
    text: DocumentSubTypeText[DocumentSubType.Passport],
  },
  {
    value: DocumentSubType.DrivingLicense,
    text: DocumentSubTypeText[DocumentSubType.DrivingLicense],
  },
  {
    value: DocumentSubType.UtilityBill,
    text: DocumentSubTypeText[DocumentSubType.UtilityBill],
  },
  {
    value: DocumentSubType.BankReceipt,
    text: DocumentSubTypeText[DocumentSubType.BankReceipt],
  },
  {
    value: DocumentSubType.BankStatement,
    text: DocumentSubTypeText[DocumentSubType.BankStatement],
  },
  {
    value: DocumentSubType.LetterOfEmployment,
    text: DocumentSubTypeText[DocumentSubType.LetterOfEmployment],
  },
  {
    value: DocumentSubType.ConfirmationOfAddress,
    text: DocumentSubTypeText[DocumentSubType.ConfirmationOfAddress],
  },
  {
    value: DocumentSubType.SecurityCard,
    text: DocumentSubTypeText[DocumentSubType.SecurityCard],
  },
].sort((a, b) => a.text.localeCompare(b.text));

export const ID_TYPES: Option[] = [
  {
    value: DocumentSubType.Id,
    text: DocumentSubTypeText[DocumentSubType.Id],
  },
  {
    value: DocumentSubType.Passport,
    text: DocumentSubTypeText[DocumentSubType.Passport],
  },
  {
    value: DocumentSubType.DrivingLicense,
    text: DocumentSubTypeText[DocumentSubType.DrivingLicense],
  },
  {
    value: DocumentSubType.Nis,
    text: DocumentSubTypeText[DocumentSubType.Nis],
  },
];

export const DOCUMENT_STATUSES: Option[] = [
  {
    value: DocumentStatus.InReview,
    text: StatusText[DocumentStatus.InReview],
  },
  {
    value: DocumentStatus.Approved,
    text: StatusText[DocumentStatus.Approved],
  },
  {
    value: DocumentStatus.AboutToExpire,
    text: StatusText[DocumentStatus.AboutToExpire],
  },
  {
    value: DocumentStatus.Expired,
    text: StatusText[DocumentStatus.Expired],
  },
  {
    value: DocumentStatus.Requested,
    text: StatusText[DocumentStatus.Requested],
  },
  {
    value: DocumentStatus.Rejected,
    text: StatusText[DocumentStatus.Rejected],
  },
  {
    value: DocumentStatus.Generated,
    text: StatusText[DocumentStatus.Generated],
  },
];

export const DOCUMENT_STATUSES_WITH_ALL: Option[] = [
  {
    value: OptionValue.All,
    text: 'All Statuses',
  },
  ...DOCUMENT_STATUSES,
];

export const TIME_PERIODS: Option[] = [
  {
    value: SummaryPeriod.Today,
    text: 'Today',
  },
  {
    value: SummaryPeriod.Month,
    text: 'This month',
  },
  {
    value: SummaryPeriod.Year,
    text: 'This year',
  },
];

export const TIME_PERIOD_TARGETS = {
  [SummaryPeriod.Today]: '1',
  [SummaryPeriod.Month]: '20',
  [SummaryPeriod.Year]: '240',
};

export const DOCUMENT_UNKNOWN_FILTER_OPTIONS: Option[] = [
  {
    value: OptionValue.All,
    text: 'All',
  },
  {
    value: 'onboarding',
    text: 'Onboarding',
  },
  // Temporarily hidden - GDO-1100
  // {
  //   value: 'credit-cards',
  //   text: 'Credit Cards',
  // },
];

export const AUDIT_ACTOR_TYPES: Option[] = [
  {
    value: AuditActorType.BackOffice,
    text: 'BackOffice',
  },
  {
    value: AuditActorType.Onboarding,
    text: 'Onboarding client',
  },
];

export const AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS: Option[] = [
  ...AUDIT_ACTOR_TYPES,
  {
    value: AuditActorType.ScheduleJob,
    text: 'Schedule jobs',
  },
];

export const GENDERS: Option[] = [
  {
    value: Gender.Female,
    text: GenderText[Gender.Female],
  },
  {
    value: Gender.Male,
    text: GenderText[Gender.Male],
  },
];

export const USER_ROLE_TYPES: Option[] = [
  {
    value: RoleName.Admin,
    text: RoleNameText[RoleName.Admin],
  },
  {
    value: RoleName.Corporate,
    text: RoleNameText[RoleName.Corporate],
  },
  {
    value: RoleName.Approver,
    text: RoleNameText[RoleName.Approver],
  },
  {
    value: RoleName.ManualReviewer,
    text: RoleNameText[RoleName.ManualReviewer],
  },
  {
    value: RoleName.ProductionSupport,
    text: RoleNameText[RoleName.ProductionSupport],
  },
  {
    value: RoleName.ViewOnly,
    text: RoleNameText[RoleName.ViewOnly],
  },
].sort((a, b) => a.text.localeCompare(b.text));

export const AUDIT_LOG_STATUSES: Option[] = [
  {
    value: AuditLogStatus.Success,
    text: 'Success',
  },
  {
    value: AuditLogStatus.Failure,
    text: 'Failure',
  },
];

export const SCHEDULE_JOB_STATUSES: Option[] = [
  {
    value: JobStatus.Pending,
    text: 'Pending',
  },
  {
    value: JobStatus.Processing,
    text: 'Processing',
  },
  {
    value: JobStatus.Success,
    text: 'Success',
  },
  {
    value: JobStatus.Error,
    text: 'Error',
  },
];

export const SCHEDULE_JOB_TYPES: Option[] = [
  {
    value: JobType.CreateBankAccount,
    text: ScheduleJobTypeText[JobType.CreateBankAccount],
  },
  {
    value: JobType.CreateICBProfile,
    text: ScheduleJobTypeText[JobType.CreateICBProfile],
  },
  {
    value: JobType.CreateRIM,
    text: ScheduleJobTypeText[JobType.CreateRIM],
  },
  {
    value: JobType.UpdateRIM,
    text: ScheduleJobTypeText[JobType.UpdateRIM],
  },
  {
    value: JobType.FundAccount,
    text: ScheduleJobTypeText[JobType.FundAccount],
  },
];

export const YES_NO_OPTIONS: Option[] = [
  {
    value: OptionValue.Yes,
    text: OptionValueText[OptionValue.Yes],
  },
  {
    value: OptionValue.No,
    text: OptionValueText[OptionValue.No],
  },
];

export const CLIENT_TITLE_OPTIONS: Option[] = [
  {
    value: ClientTitle.MR,
    text: ClientTitleText[ClientTitle.MR],
  },
  {
    value: ClientTitle.MS,
    text: ClientTitleText[ClientTitle.MS],
  },
  {
    value: ClientTitle.MRS,
    text: ClientTitleText[ClientTitle.MRS],
  },
];
