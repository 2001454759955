import { useContext } from 'react';

import { AbilityContext, Can } from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import {
  selectLoggedInUser,
  selectUIState,
  showApproveAccountDrawer,
  showAssignClientModal,
  showManageDocumentsDrawer,
  showToast,
  fetchClientDetails,
} from '@/redux';
import { Client, ClientStatus } from '@/types';
import {
  getAPIErrorMessage,
  getDataTestId,
  shouldShowAssignButton,
} from '@/utils';
import { Button, Stack } from '@mui/material';
import { reactivateApplication } from '@/api';
import { useMutation } from '@tanstack/react-query';

type ClientDetailsActionBarProps = {
  client: Client;
};

function useReactivateClient(client: Client) {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: () =>
      reactivateApplication({
        clientId: client.id,
        operationId: String(client.operationId),
      }),
    onSuccess: () => {
      dispatch(
        showToast({
          message: 'Client reactivated successfully!',
          severity: 'success',
        }),
      );
      dispatch(fetchClientDetails(String(client.id)));
    },
    onError: error => {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    },
  });

  return mutation;
}

export function ClientDetailsActionBar({
  client,
}: ClientDetailsActionBarProps) {
  const { approveAccountDrawer } = useSelector(selectUIState);
  const loggedInUser = useSelector(selectLoggedInUser);
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch();

  const { mutate: reactivate, isPending: isReactivatePending } =
    useReactivateClient(client);

  const onAssignClick = () => {
    if (client) {
      dispatch(showAssignClientModal(client));
    }
  };

  const showManageDocumentsForm = () =>
    dispatch(showManageDocumentsDrawer(client));

  const showApproveAccount = () => {
    dispatch(showApproveAccountDrawer(client));
  };

  if (
    client.status === ClientStatus.Approved ||
    client.status === ClientStatus.Rejected
  ) {
    return null;
  }

  if (client.status === ClientStatus.Archived) {
    return (
      <Stack gap={1}>
        <Can do="review" on="onboarding_application">
          <Button
            variant="outlined"
            onClick={() => reactivate()}
            disabled={isReactivatePending}
          >
            {isReactivatePending ? 'Reactivating...' : 'Reactivate'}
          </Button>
        </Can>
      </Stack>
    );
  }

  return (
    <Stack gap={1}>
      {shouldShowAssignButton({
        client,
        loggedInUser,
        ability,
      }) && (
        <Button
          {...getDataTestId(`assign-button`)}
          variant="outlined"
          onClick={onAssignClick}
          disabled={approveAccountDrawer.isOpen}
        >
          {client.reviewBy ? 'Reassign' : 'Assign'}
        </Button>
      )}
      <Can do="request" on="document">
        <Button
          {...getDataTestId(`manage-documents-button`)}
          onClick={showManageDocumentsForm}
          disabled={approveAccountDrawer.isOpen}
        >
          Manage documents
        </Button>
      </Can>
      <Can do="review" on="onboarding_application">
        <Button
          {...getDataTestId(`approve-account-button`)}
          onClick={showApproveAccount}
          disabled={approveAccountDrawer.isOpen}
        >
          Approve/Reject account
        </Button>
      </Can>
    </Stack>
  );
}
