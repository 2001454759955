import { createSlice } from '@reduxjs/toolkit';
import { fetchFeatures, setConfigs, setTenant } from './actions';
import { AppConfigurations, Feature, Tenant } from '@/types';
import { appConfigs, VITE_TENANT } from '@/constants';

type ConfigsState = {
  tenant: Tenant;
  features: Feature[];
} & AppConfigurations;

const initialState: ConfigsState = {
  tenant: VITE_TENANT as Tenant,
  features: [],
  ...appConfigs[VITE_TENANT as Tenant],
};

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setConfigs, (state, { payload }) => {
      Object.assign(state, {
        ...state,
        ...payload,
      });
    });
    builder.addCase(setTenant, (state, { payload }) => {
      Object.assign(state, {
        tenant: payload,
        features: [],
        ...appConfigs[payload],
      });
    });
    builder.addCase(fetchFeatures.fulfilled, (state, { payload }) => {
      state.features = payload;
    });
  },
});

export const configsReducer = configsSlice.reducer;
