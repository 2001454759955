import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { typographyConfigs } from '../typography';
import { spacing } from '../constants/layout';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const formLabelConfigs: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      marginBottom: spacing,
    },
  },
};
