import { Components, createTheme } from '@mui/material';
import { fontFamily, fontWeight } from '../constants/fonts';
import { paletteConfigs } from '../palette';
import { spacing } from '../constants/layout';
import { typographyConfigs } from '../typography';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primary1000: true;
    primary900: true;
    primary800: true;
  }
  interface ButtonPropsVariantOverrides {
    link: true;
    linkBold: true;
  }
}

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const buttonConfigs: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
    variant: 'contained',
    color: 'primary',
  },
  variants: [
    {
      props: { variant: 'link' },
      style: {
        padding: 0,
        height: 'unset',
        fontWeight: fontWeight.regular,

        '.MuiButton-startIcon': {
          marginRight: spacing * 2,
        },
        '.MuiButton-endIcon': {
          marginLeft: spacing * 2,
        },

        ':hover': {
          backgroundColor: 'unset',
          color: theme.palette.primary.main,
          textDecoration: `underline ${theme.palette.primary.main}`,

          '.MuiButton-startIcon, .MuiButton-endIcon': {
            '.MuiSvgIcon-root': {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
    {
      props: { variant: 'linkBold' },
      style: {
        padding: 0,
        height: 'unset',
        fontSize: theme.typography.body2Bold.fontSize,
        fontWeight: theme.typography.body2Bold.fontWeight,

        '.MuiButton-startIcon': {
          marginRight: spacing * 2,
        },
        '.MuiButton-endIcon': {
          marginLeft: spacing * 2,
        },

        ':hover': {
          backgroundColor: 'unset',
          color: theme.palette.primary.main,
          textDecoration: `underline ${theme.palette.primary.main}`,

          '.MuiButton-startIcon, .MuiButton-endIcon': {
            '.MuiSvgIcon-root': {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      paddingLeft: 18,
      paddingRight: 18,
      fontFamily: fontFamily.primary,
      fontWeight: fontWeight.semiBold,
      lineHeight: 1.3,
      textTransform: 'initial',
      height: 40,

      '.MuiButton-startIcon': {
        marginRight: spacing * 0.75,
        '.MuiButtonBase-root': {
          padding: 0,
        },
      },
      '.MuiButton-endIcon': {
        marginLeft: spacing * 0.75,
        '.MuiButtonBase-root': {
          padding: 0,
        },
      },
    },
    contained: {
      '.MuiButton-startIcon, .MuiButton-endIcon': {
        '.MuiSvgIcon-root': {
          fill: theme.palette.primary.contrastText,
        },
      },
      ':disabled': {
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          '.MuiSvgIcon-root': {
            fill: theme.palette.grey[400],
          },
        },
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.grey[400],
      color: theme.palette.text.primary,
    },
    text: {
      paddingLeft: spacing * 1.5,
      paddingRight: spacing * 1.5,
      paddingBottom: spacing * 0.75,
      paddingTop: spacing * 0.75,
      color: theme.palette.text.primary,
    },
    outlined: {
      paddingLeft: spacing * 1.5,
      paddingRight: spacing * 1.5,
      ':disabled': {
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          '.MuiSvgIcon-root': {
            fill: theme.palette.grey[400],
          },
        },
      },
    },
  },
};
