import { BoxProps, Typography, TypographyProps, useTheme } from '@mui/material';
import { FigureCard, WarningIcon } from './DataDisplay.styled';
import { ColorExtractFn } from '@/types';
import { getDataTestId } from '@/utils';

type FigureProps = {
  value: number;
  title: string;
  titleVariant?: TypographyProps['variant'];
  color?: ColorExtractFn;
  withWarningIcon?: boolean;
} & Omit<BoxProps, 'color'>;

export function Figure({
  color,
  value,
  title,
  titleVariant = 'caption',
  withWarningIcon,
  ...props
}: FigureProps) {
  const theme = useTheme();

  return (
    <FigureCard
      {...getDataTestId(`figure-${title}`)}
      bgColor={color?.(theme)}
      p={2}
      {...props}
    >
      <Typography variant="h4" mb={1}>
        {value}
      </Typography>
      <Typography variant={titleVariant}>{title}</Typography>
      {withWarningIcon && (
        <WarningIcon name="siren" color={theme => theme.palette.red[500]} />
      )}
    </FigureCard>
  );
}
