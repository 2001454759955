import { Components } from '@mui/material';
import { spacing } from '../constants/layout';

export const listItemAvatarConfigs: Components['MuiListItemAvatar'] = {
  styleOverrides: {
    root: {
      minWidth: 40 + spacing * 1.5,
    },
  },
};
