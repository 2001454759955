import { createSlice } from '@reduxjs/toolkit';
import { AccountTypeDetails, Client, PepCheckResult } from '@/types';
import {
  fetchClientAccountTypes,
  fetchClientDetails,
  fetchClientPEPResult,
  patchClientDetails,
  patchClientDocument,
  resetClientDetails,
} from './actions';

interface ClientDetailsState {
  clientDetails?: Client;
  pepResult?: PepCheckResult;
  accountTypes: AccountTypeDetails[];
  initialized: boolean;
  error?: string;
}

const initialState: ClientDetailsState = {
  initialized: false,
  accountTypes: [],
};

export const clientDetailsSlice = createSlice({
  name: 'client-details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchClientDetails.pending, state => {
      state.error = undefined;
    });
    builder.addCase(fetchClientDetails.fulfilled, (state, { payload }) => {
      state.clientDetails = payload;
      if (!state.initialized) {
        state.initialized = true;
      }
    });
    builder.addCase(fetchClientDetails.rejected, (state, { error }) => {
      state.error = error.message;
    });
    builder.addCase(fetchClientAccountTypes.fulfilled, (state, { payload }) => {
      state.accountTypes = payload;
    });
    builder.addCase(patchClientDetails, (state, { payload }) => {
      if (state.clientDetails && payload.id === state.clientDetails.id) {
        state.clientDetails = {
          ...state.clientDetails,
          ...payload,
        };
      }
    });
    builder.addCase(
      patchClientDocument,
      (state, { payload: { clientId, document } }) => {
        if (state.clientDetails && clientId === state.clientDetails.id) {
          state.clientDetails = {
            ...state.clientDetails,
            documents: (state.clientDetails.documents || []).map(doc =>
              doc.id === document.id ? document : doc,
            ),
          };
        }
      },
    );
    builder.addCase(fetchClientPEPResult.fulfilled, (state, { payload }) => {
      state.pepResult = payload;
    });
    builder.addCase(resetClientDetails, state => {
      state.clientDetails = initialState.clientDetails;
      state.pepResult = initialState.pepResult;
      state.initialized = initialState.initialized;
    });
  },
});

export const clientDetailsReducer = clientDetailsSlice.reducer;
