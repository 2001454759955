import { AppConfigurations, DocumentSubType } from '@/types';
import { DocumentSubTypeText } from '../mapper';

export const defaultConfigs: AppConfigurations = {
  currency: 'USD',
  locale: 'en-US',
  isoCode: '',
  dateFormat: 'dd/MM/yyyy',
  name: '',
  confirmationOfAddressRequired: false,
  idDocumentTypes: [
    {
      value: DocumentSubType.Id,
      text: DocumentSubTypeText[DocumentSubType.Id],
    },
    {
      value: DocumentSubType.Passport,
      text: DocumentSubTypeText[DocumentSubType.Passport],
    },
  ],
};
