import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectPicklists = createSelector(
  (state: RootState) => state.picklists,
  data => ({
    ...data,
  }),
);

export const selectReviewers = createSelector(
  (state: RootState) => state.picklists,
  data => data.reviewers,
);

export const selectTerritories = createSelector(
  (state: RootState) => state.picklists,
  data => data.territories,
);

export const selectRiskLevels = createSelector(
  (state: RootState) => state.picklists,
  data => ({
    riskLevels: data.riskLevels,
    riskLevelOptions: data.riskLevelOptions,
    riskLevelByCode: data.riskLevelByCode,
  }),
);
