import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const bviConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-VG',
  isoCode: 'VGB',
  currency: 'USD',  
  dateFormat: 'dd/MM/yyyy',
  name: 'British Virgin Islands',
  flag: 'flag-british-virgin-islands',
};
