import { AbilityContext, Can, Heading, Icon } from '@/components';
import { useDispatch } from '@/hooks';
import { showAddUserDrawer } from '@/redux';
import { getDataTestId } from '@/utils';
import { Button, Container, Tab, Tabs } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import RolesTab from './RolesTab';
import UsersTab from './UsersTab';

export const AdministrationPage = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const ability = useContext(AbilityContext);

  const onTabChange = (_: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (
      !ability.can('view', 'administration_roles') &&
      ability.can('view', 'administration_users')
    ) {
      setTab(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = useCallback(() => {
    switch (tab) {
      case 0:
        return <RolesTab />;
      case 1:
        return <UsersTab />;
    }
  }, [tab]);

  const showAddUser = () => dispatch(showAddUserDrawer());

  return (
    <Container>
      <Heading
        title="Administration"
        actionSlot={
          <Can do="add" on="administration_users">
            <Button onClick={showAddUser} startIcon={<Icon name="add" />}>
              Add User
            </Button>
          </Can>
        }
      />
      <Tabs
        {...getDataTestId(`administration-tab-bar`)}
        value={tab}
        onChange={onTabChange}
      >
        {ability.can('view', 'administration_roles') && (
          <Tab {...getDataTestId(`tab-groups`)} label={`Groups`} value={0} />
        )}

        {ability.can('view', 'administration_users') && (
          <Tab {...getDataTestId(`tab-all-users`)} label={`Users`} value={1} />
        )}
      </Tabs>
      {renderTab()}
    </Container>
  );
};
