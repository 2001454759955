import { doNotForwardTheseProps } from '@/styles';
import { Box, styled } from '@mui/material';

const uploadBoxBorderRadius = 12;
export const FileSelectorContainer = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('isHighlighted'),
})<{ isHighlighted?: boolean }>(({ theme, isHighlighted }) => ({
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  padding: 0,
  flex: 1,
  justifyContent: 'center',
  height: '100%',
  ...(isHighlighted
    ? {
        backgroundColor: theme.palette.primaries[100],
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${uploadBoxBorderRadius}' ry='${uploadBoxBorderRadius}' stroke='%23${theme.palette.primaries[500]?.replace('#', '')}' stroke-width='2' stroke-dasharray='4%2c ${uploadBoxBorderRadius}' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }
    : {
        backgroundColor: theme.palette.grey[100],
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='${uploadBoxBorderRadius}' ry='${uploadBoxBorderRadius}' stroke='%23${theme.palette.grey[500]?.replace('#', '')}' stroke-width='1' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }),

  '.MuiButton-root': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: 'transparent',
    color: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
      color: 'transparent',
    },

    input: {
      width: '100%',
      height: '100%',
    },
  },
}));
