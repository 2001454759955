import { Box, styled, Tabs } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  padding: theme.spacing(3),
  paddingTop: theme.spacing(10),
}));

export const SheetListBar = styled(Tabs)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
}));

export const SheetGrid = styled('table')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,

  tr: {
    td: {
      padding: theme.spacing(1),
      minWidth: 80,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

export const ExcelFileViewerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  height: '100%',
  width: '100%',
  position: 'relative',
}));
