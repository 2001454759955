import { axios } from './axios';
import { REQUEST } from '@/constants';
import {
  MyTasksStats,
  OpeningAccountStats,
  SummaryPeriod,
  SummaryStats,
} from '@/types';
import { format, set } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getSummary = async (period: SummaryPeriod) => {
  let fromTime: string = '';
  const now = new Date();

  switch (period) {
    case SummaryPeriod.Today: {
      const startTime = set(now, { hours: 0, minutes: 0, seconds: 0 });
      fromTime = formatInTimeZone(startTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");
      break;
    }
    case SummaryPeriod.Month: {
      const startTime = set(now, { date: 1, hours: 0, minutes: 0, seconds: 0 });
      fromTime = formatInTimeZone(startTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");
      break;
    }
    case SummaryPeriod.Year: {
      const startTime = set(now, {
        month: 0,
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      fromTime = formatInTimeZone(startTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");
      break;
    }
  }

  const result = await axios.get<SummaryStats>(
    REQUEST.STATS.SUMMARY,
    fromTime
      ? {
          params: {
            fromTime,
          },
        }
      : {},
  );

  return result.data;
};

export const getMyTasks = async () => {
  let todayUTC: string = '';
  const now = new Date();
  const startTime = set(now, { hours: 0, minutes: 0, seconds: 0 });
  todayUTC = formatInTimeZone(startTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX");

  const result = await axios.get<MyTasksStats>(
    REQUEST.STATS.MY_TASKS,
    todayUTC
      ? {
          params: {
            today: todayUTC,
          },
        }
      : {},
  );

  return result.data;
};

export const getAccountOpeningTrend = async () => {
  const result = await axios.get<OpeningAccountStats>(
    REQUEST.STATS.ACCOUNT_OPENING,
  );

  return result.data
    .map(item => ({
      ...item,
      month: item.time ? format(item.time, 'MMM').toUpperCase() : '',
    }))
    .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());
};
