import { getRoles } from '@/api';
import { CollapsibleGroupSwitch } from '@/components/CollapsibleGroupSwitch';
import { Loader } from '@/components/Icon';
import { RoleNameText } from '@/constants';
import { useSelector } from '@/hooks';
import { selectPermissions } from '@/redux';
import { UserRole } from '@/types';
import { getGroupedPermission } from '@/utils';
import { Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

type EditUserDrawerRoleSectionProps = {
  role: UserRole;
};

export function EditUserDrawerRoleSection({
  role,
}: EditUserDrawerRoleSectionProps) {
  const rolePermission = useSelector(selectPermissions);
  const { mutate, data, isPending } = useMutation({
    mutationFn: getRoles,
  });

  useEffect(() => {
    mutate(role.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  if (isPending) {
    return <Loader justifyContent="center" />;
  }

  return (
    <>
      <Typography variant="h5" mb={1}>
        {RoleNameText[String(data?.name)]}
      </Typography>
      <Typography
        variant="caption"
        mb={2}
        color={theme => theme.palette.grey[700]}
      >
        {data?.description}
      </Typography>
      <Typography variant="body1Bold" mb={2}>
        Permissions
      </Typography>
      <CollapsibleGroupSwitch
        data={getGroupedPermission(
          false,
          false,
          rolePermission.permissions,
          data?.permissions,
        )}
        singleItemCategoryExpand
        onCategoryToggle={() => {}}
        onToggleAllCategory={() => {}}
        onItemCheck={() => {}}
      />
    </>
  );
}
