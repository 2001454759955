import { createBankProfile } from '@/api';
import { Icon } from '@/components';
import { useDispatch } from '@/hooks';
import { fetchClientDetails, showToast } from '@/redux';
import { Client, ICBResponseCode } from '@/types';
import { getAPIErrorMessage, getIcbErrorMessage, isNil } from '@/utils';
import { Alert, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { RetryICBButton, TriggerICBButton } from './ClientDetails.styled';

type ICBAlertProps = {
  client: Client;
};

export function ICBAlert({ client }: ICBAlertProps) {
  const dispatch = useDispatch();

  const shouldShowRetry =
    client.username &&
    client.firstName &&
    client.lastName &&
    client.birthday &&
    ((client.icbResponseCode === ICBResponseCode.CreateRIMError &&
      !client.rim) ||
      ![ICBResponseCode.CreateRIMError, ICBResponseCode.Success].includes(
        client.icbResponseCode as ICBResponseCode,
      ));

  const {
    mutate: doCreateBankProfile,
    isSuccess: isDone,
    isPending: isCreating,
    error: createError,
    reset: resetCreateBankProfile,
  } = useMutation({
    mutationFn: () => createBankProfile(client.id),
  });

  useEffect(() => {
    if (isDone) {
      dispatch(fetchClientDetails(client.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  useEffect(() => {
    if (createError) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(createError),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createError]);

  const onRetryICB = () => {
    resetCreateBankProfile();
    doCreateBankProfile();
  };

  if (!shouldShowRetry) return null;

  if (isNil(client.icbResponseCode)) {
    return (
      <Box mt={-1} pb={2}>
        <Alert
          icon={<Icon name="warning" />}
          color="warning"
          variant="standard"
          action={
            <TriggerICBButton
              variant="linkBold"
              startIcon={<Icon name="reset" size={16} />}
              isLoading={isCreating}
              onClick={onRetryICB}
            >
              Trigger
            </TriggerICBButton>
          }
        >
          Create bank profile
        </Alert>
      </Box>
    );
  }

  return (
    <Box mt={-1} pb={2}>
      <Alert
        icon={<Icon name="alert" />}
        color="error"
        variant="standard"
        action={
          <RetryICBButton
            variant="linkBold"
            startIcon={<Icon name="reset" size={16} />}
            isLoading={isCreating}
            onClick={onRetryICB}
          >
            Retry
          </RetryICBButton>
        }
      >
        Bank profile error: {getIcbErrorMessage(client.icbResponseCode)}
      </Alert>
    </Box>
  );
}
