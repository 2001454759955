import { doNotForwardTheseProps, elevationLevel } from '@/styles';
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  StackProps,
  styled,
} from '@mui/material';

export const Container = styled(Paper, {
  shouldForwardProp: doNotForwardTheseProps('center'),
})<{ center?: boolean }>(({ theme, center }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 12,
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  width: 360,

  ...(center
    ? {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }
    : {}),
}));
Container.defaultProps = {
  elevation: elevationLevel.l,
};

export const FormHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}));
export const FormHeaderDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const Form = styled(Stack)<StackProps>(() => ({}));
Form.defaultProps = {
  direction: 'column',
  alignItems: 'flex-start',
  component: 'form',
  gap: 3,
};

export const FormAction = styled('div')<{
  align?: 'stretch' | 'center' | 'left' | 'right';
}>(({ theme, align = 'left' }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  width: '100%',

  ...(align === 'left'
    ? {
        justifyContent: 'flex-start',
        '.MuiButton-contained': {
          flex: 1,
          maxWidth: 220,
        },
      }
    : align === 'right'
      ? {
          justifyContent: 'flex-end',
          '.MuiButton-contained': {
            flex: 1,
            maxWidth: 220,
          },
        }
      : align === 'center'
        ? {
            justifyContent: 'center',
            '.MuiButton-contained': {
              flex: 1,
              maxWidth: 220,
            },
          }
        : align === 'stretch'
          ? {
              '> *': {
                flex: 1,
              },
            }
          : {}),
}));
FormAction.defaultProps = {
  className: 'FormAction',
};

export const SectionCard = styled(Paper)(({ theme }) => ({
  borderRadius: 12,
  padding: theme.spacing(2),
  height: '100%',
}));
SectionCard.defaultProps = {
  elevation: elevationLevel.s,
};

export const SectionCardCallout = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('isHidden'),
})<{ isHidden?: boolean }>(({ theme, isHidden }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  opacity: isHidden ? 0 : 1,
  transition: theme.transitions.create('opacity'),
}));

export const HeadingContainer = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('center'),
})<{ center?: boolean }>(({ theme, center }) => ({
  gap: theme.spacing(3),
  display: 'flex',
  alignItems: center ? 'center' : 'flex-start',
  justifyContent: 'space-between',
  position: 'relative',
}));

export const HeadingTitleSlot = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  minWidth: 0,
  flex: 1,
}));

export const HeadingActionSlot = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('center'),
})<{ center?: boolean }>(({ theme, center }) => ({
  paddingTop: center ? 0 : theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const BackButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  position: 'absolute',
  right: `calc(100% + ${theme.spacing(3)})`,
  top: theme.spacing(1),
  '.MuiSvgIcon-root': {
    fill: theme.palette.grey[700],
  },

  ':hover': {
    '.MuiSvgIcon-root': {
      fill: theme.palette.grey[1000],
    },
  },
}));

export const FullPageBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const FullPageOverlayBackground = styled(FullPageBackground, {
  shouldForwardProp: doNotForwardTheseProps('bgColor'),
})<{ bgColor?: string }>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || 'transparent',
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: theme.zIndex.tooltip + 1,
}));
