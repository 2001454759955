import { Button, Divider, Grid, Typography } from '@mui/material';
import RoleCard, { UserCountContainer } from './RoleCard.styled';
import { AbilityContext, Icon } from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import { selectUserRoles, showAddUserDrawer } from '@/redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoleNameText, ROUTE } from '@/constants';
import { useContext } from 'react';

const RolesTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);
  const { roles } = useSelector(selectUserRoles);
  const isViewUserPermission = ability.can('view', 'administration_users');

  const onRoleClick = (id: string, showUserTab?: boolean) => {
    navigate({
      pathname: generatePath(ROUTE.ADMINISTRATION_ROLE_DETAIL, {
        id,
      }),
      search: showUserTab ? '?activeTab=user' : undefined,
    });
  };

  const onAddUser = (role: string) => {
    ability.can('view', 'administration_roles') &&
      dispatch(
        showAddUserDrawer({
          email: '',
          id: '',
          firstName: '',
          lastName: '',
          roles: [role],
        }),
      );
  };

  return (
    <Grid container spacing={4}>
      {roles.map(item => (
        <Grid key={item.id} item xs={4}>
          <RoleCard onClick={() => onRoleClick(item.id)}>
            <Typography variant="body1Bold" mb={1}>
              {RoleNameText[item.name] || item.name}
            </Typography>
            <Typography variant="body2" flex={1}>
              {item.description}
            </Typography>
            <Divider />
            <Grid container alignItems={'center'} spacing={4} paddingTop={1}>
              <Grid item xs={6}>
                <UserCountContainer
                  variant="text"
                  isViewUserPermission={isViewUserPermission}
                  onClick={e => {
                    e.stopPropagation();
                    isViewUserPermission && onRoleClick(item.id, true);
                  }}
                >
                  <Typography variant="body1Bold" mt={2}>
                    {item.noUsers}
                  </Typography>
                  <Typography variant="caption" mb={2}>
                    Users assigned
                  </Typography>
                </UserCountContainer>
              </Grid>
              <Grid item xs={6} alignItems={'flex-end'}>
                {ability.can('add', 'administration_users') && (
                  <Button
                    startIcon={<Icon name="add" />}
                    variant="text"
                    onClick={e => {
                      e.stopPropagation();
                      onAddUser(item.name);
                    }}
                  >
                    Add User
                  </Button>
                )}
              </Grid>
            </Grid>
          </RoleCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default RolesTab;
