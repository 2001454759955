import { DrawerProps } from '../Modals.interface';
import { Client, ModalName } from '@/types';
import { getDataTestId } from '@/utils';
import { useModal } from '@/hooks';
import { Can } from '../../Can';
import { RequestDocumentForm } from './RequestDocumentForm';
import { SyntheticEvent, useCallback, useState } from 'react';
import { UploadDocumentForm } from './UploadDocumentForm';
import { Drawer, Tab, Tabs } from './ManageDocumentsDrawer.styled';
import { useBoolean } from 'usehooks-ts';

type ManageDocumentsDrawerProps = {
  payload?: Client;
} & DrawerProps;

enum TabValue {
  Request = 0,
  Upload = 1,
}

export function ManageDocumentsDrawer({
  payload,
  ...props
}: ManageDocumentsDrawerProps) {
  const [tab, setTab] = useState(TabValue.Request);
  const { resetKey, hide } = useModal({
    open: !!props.open,
    name: ModalName.ManageDocuments,
    onClosed: () => setTab(TabValue.Request),
  });
  const { value: disabled, setValue: setDisabled } = useBoolean(false);

  const renderTab = useCallback(() => {
    if (!payload) return null;

    switch (tab) {
      case TabValue.Request:
        return (
          <RequestDocumentForm
            client={payload}
            modalName={ModalName.ManageDocuments}
            onSetDisabled={setDisabled}
          />
        );
      case TabValue.Upload:
        return (
          <UploadDocumentForm
            client={payload}
            modalName={ModalName.ManageDocuments}
            onSetDisabled={setDisabled}
          />
        );
    }
  }, [payload, setDisabled, tab]);

  const onTabChange = (_: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  return (
    <Can do="request" on="document">
      <Drawer
        key={resetKey}
        {...getDataTestId('manage-document-drawer')}
        {...props}
        title="Manage Documents"
        onClose={hide}
        width={516}
        disabledCloseButton={disabled}
      >
        <Tabs
          {...getDataTestId(`client-details-tab-bar`)}
          value={tab}
          onChange={onTabChange}
          variant="fullWidth"
        >
          <Tab
            {...getDataTestId(`tab-request-document`)}
            label={`Request document`}
            value={TabValue.Request}
          />
          <Tab
            {...getDataTestId(`tab-upload-document`)}
            label={`Upload document`}
            value={TabValue.Upload}
          />
        </Tabs>
        {renderTab()}
      </Drawer>
    </Can>
  );
}
