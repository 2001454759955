import { getReviewers, getRiskLevels } from '@/api';
import { getTerritories } from '@/api/places';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchReviewers = createAsyncThunk(
  'picklists/get-reviewers',
  getReviewers,
);

export const fetchRiskLevels = createAsyncThunk(
  'picklists/get-risk-levels',
  getRiskLevels,
);

export const fetchTerritories = createAsyncThunk(
  'picklists/get-territories',
  getTerritories,
);

export const resetPicklists = createAction('picklists/reset');
