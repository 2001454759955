import { Theme, useTheme } from '@mui/material';
import { StatusIconContainer } from './DataDisplay.styled';
import { StatusIcon as StatusIconMap } from '@/constants';
import { ColorExtractFn, CommonStatus, IconName } from '@/types';
import { Icon } from '../Icon';
import { getDataTestId } from '@/utils';

export type StatusIconProps = {
  value: CommonStatus;
  iconName?: IconName;
  color?: ColorExtractFn;
  backgroundColor?: ColorExtractFn;
  borderColor?: ColorExtractFn;
};

export function StatusIcon({
  value,
  iconName,
  color: colorFn,
  backgroundColor: backgroundColorFn,
  borderColor: borderColorFn,
}: StatusIconProps) {
  const theme = useTheme();

  const icon = iconName || StatusIconMap[value] || 'question-circle';
  const color =
    colorFn ||
    ((theme: Theme) =>
      theme.palette.status[value]
        ? theme.palette.status[value].icon
        : theme.palette.grey[700]);
  const backgroundColor = backgroundColorFn
    ? backgroundColorFn(theme)
    : theme.palette.status[value]
      ? theme.palette.status[value].iconBackground
      : theme.palette.grey[200];
  const borderColor = borderColorFn
    ? borderColorFn(theme)
    : theme.palette.status[value]
      ? theme.palette.status[value].iconBorder
      : undefined;

  return (
    <StatusIconContainer
      {...getDataTestId(`status-icon`)}
      bgColor={backgroundColor}
      borderColor={borderColor}
    >
      <Icon name={icon} color={color} />
    </StatusIconContainer>
  );
}
