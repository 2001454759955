import { Alert, Box, Container, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Heading } from './Heading';
import { fontWeight } from '@/styles';
import { getAPIErrorMessage, getDataTestId, isNil } from '@/utils';
import { Icon } from '../Icon';
import { SectionCard, SectionCardCallout } from './Layout.styled';
import { TextInput } from '../Form';

type InfiniteTablePageProps = {
  title: string;
  total?: number;
  error?: Error | null;
  filterControl: Control<FieldValues>;
  fetchTriggerRef?: (node?: Element | null) => void;
  slots?: {
    headingAction?: ReactNode;
    headingSubAction?: ReactNode;
    quickFilter?: ReactNode;
    filterBar?: ReactNode;
  };
} & PropsWithChildren;

export function InfiniteTablePage({
  title,
  total,
  filterControl,
  error,
  fetchTriggerRef,
  children,
  slots = {},
}: InfiniteTablePageProps) {
  const {
    headingAction: headingActionSlot,
    headingSubAction: headingSubActionSlot,
    quickFilter: quickFilterSlot,
    filterBar: filterBarSlot,
  } = slots;

  return (
    <Container>
      <Heading
        title={
          <Typography variant="h2">
            {title}{' '}
            {!isNil(total) && (
              <Typography
                component="span"
                variant="h2"
                fontWeight={fontWeight.light}
                {...getDataTestId(`${title}-list-length`)}
              >
                ({total})
              </Typography>
            )}
          </Typography>
        }
        actionSlot={headingActionSlot}
        subActionSlot={headingSubActionSlot}
      />
      {error && (
        <Box mb={3}>
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        </Box>
      )}
      <SectionCard>
        <Stack justifyContent="space-between" mb={1}>
          {quickFilterSlot || <Box />}
          <Box width={260}>
            <TextInput
              control={filterControl}
              name="keyword"
              template="search"
            />
          </Box>
        </Stack>
        {filterBarSlot}
        <SectionCardCallout>{children}</SectionCardCallout>
        <Box ref={fetchTriggerRef} />
      </SectionCard>
    </Container>
  );
}
