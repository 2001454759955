import { Option } from '@/types';
import { BoxProps, Button, IconButton, Typography } from '@mui/material';
import { FilterBarContainer, FilterBarItem } from './Form.styled';
import { Icon } from '../Icon';

type FilterBarProps = {
  appliedFilters: Option[];
  onClear: (value: Option['value'], group?: string) => void;
  onClearAll: () => void;
  containerProps?: BoxProps;
};

export function FilterBar({
  appliedFilters,
  onClear,
  onClearAll,
  containerProps,
}: FilterBarProps) {
  if (!appliedFilters.length) return null;

  return (
    <FilterBarContainer {...containerProps}>
      {appliedFilters.map(item => (
        <FilterBarItem key={item.value}>
          <Typography
            variant="caption"
            color={theme => theme.palette.grey[800]}
          >
            {item.text || item.value}
          </Typography>
          <IconButton onClick={() => onClear(item.value, item.group)}>
            <Icon
              name="close"
              size={12}
              color={theme => theme.palette.grey[700]}
            />
          </IconButton>
        </FilterBarItem>
      ))}
      <Button variant="linkBold" onClick={onClearAll}>
        Clear all filters
      </Button>
    </FilterBarContainer>
  );
}
