import { Components } from '@mui/material';
import { spacing } from '../constants/layout';

export const listItemConfigs: Components['MuiListItem'] = {
  styleOverrides: {
    root: {
      padding: 0,

      '.MuiListItemIcon-root': {
        minWidth: 0,

        ':first-of-type': {
          marginRight: spacing * 2,
        },

        ':last-of-type': {
          marginLeft: spacing * 2,
        },
      },

      '.MuiListItemText-root': {
        margin: 0,
      },
    },
  },
};
