import { Tenant } from './common';

export enum UserStatus {
  Active = 'ACTIVE',
  Locked = 'INACTIVE',
}

export type UserRole = {
  id: string;
  name: string;
  display: string;
  description: string;
  noUsers: number;
  displayOrder: number;
};

export type User = {
  id: string;
  email: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  picture?: string;
  company?: string;
  position?: string;
  contact?: string;
  roles?: string[];
  status?: string;
  permissions?: string[];
  isAdmin?: boolean;
  tenants?: Tenant[];
};

export type RiskLevel = {
  code: string;
  value: string;
};
