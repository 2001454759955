import { styled } from '@mui/material';
import { BasicDrawer } from './BasicDrawer';

export const DocumentDrawer = styled(BasicDrawer)(() => ({
  '.simplebar-content': {
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
  },
}));
