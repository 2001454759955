import { IconName } from '@/types';
import { default as TrashSimpleIcon } from '@/assets/icons/trash-simple.svg?react';
import { default as MinusCircleIcon } from '@/assets/icons/minus-circle.svg?react';
import { default as UserGearIcon } from '@/assets/icons/user-gear.svg?react';
import { default as DeleteIcon } from '@/assets/icons/delete.svg?react';
import { default as LockIcon } from '@/assets/icons/lock.svg?react';
import { default as UnLockIcon } from '@/assets/icons/unlock.svg?react';
import { default as AddIcon } from '@/assets/icons/add.svg?react';
import { default as SwitchRoleIcon } from '@/assets/icons/switch-role.svg?react';
import { default as PasswordIcon } from '@/assets/icons/password.svg?react';
import { default as AlertIcon } from '@/assets/icons/alert.svg?react';
import { default as AlertOutlineIcon } from '@/assets/icons/alert-outline.svg?react';
import { default as ArrowArcLeftIcon } from '@/assets/icons/arrow-arc-left.svg?react';
import { default as ArrowArcRightIcon } from '@/assets/icons/arrow-arc-right.svg?react';
import { default as ArrowDownIcon } from '@/assets/icons/arrow-down.svg?react';
import { default as ArrowUpIcon } from '@/assets/icons/arrow-up.svg?react';
import { default as ArrowLeftIcon } from '@/assets/icons/arrow-left.svg?react';
import { default as ArrowLeftAltIcon } from '@/assets/icons/arrow-left-alt.svg?react';
import { default as ArrowRightIcon } from '@/assets/icons/arrow-right.svg?react';
import { default as BellIcon } from '@/assets/icons/bell.svg?react';
import { default as BlockIcon } from '@/assets/icons/block.svg?react';
import { default as CalendarBlankIcon } from '@/assets/icons/calendar-blank.svg?react';
import { default as CaretDownIcon } from '@/assets/icons/caret-down.svg?react';
import { default as CaretCircleLeftIcon } from '@/assets/icons/caret-circle-left.svg?react';
import { default as CaretCircleRightIcon } from '@/assets/icons/caret-circle-right.svg?react';
import { default as ChatDotsIcon } from '@/assets/icons/chat-dots.svg?react';
import { default as ChatEmptyIcon } from '@/assets/icons/chat-empty.svg?react';
import { default as ChatLinesIcon } from '@/assets/icons/chat-lines.svg?react';
import { default as CheckIcon } from '@/assets/icons/check.svg?react';
import { default as CheckCircleOutlineIcon } from '@/assets/icons/check-circle-outline.svg?react';
import { default as CheckCircleIcon } from '@/assets/icons/check-circle.svg?react';
import { default as CheckFatIcon } from '@/assets/icons/check-fat.svg?react';
import { default as CheckFatOutlineIcon } from '@/assets/icons/check-fat-outline.svg?react';
import { default as CheckMiniIcon } from '@/assets/icons/check-mini.svg?react';
import { default as CheckSquareOffsetIcon } from '@/assets/icons/check-square-offset.svg?react';
import { default as ClockIcon } from '@/assets/icons/clock.svg?react';
import { default as CloseIcon } from '@/assets/icons/close.svg?react';
import { default as DownloadIcon } from '@/assets/icons/download.svg?react';
import { default as EditOutlineIcon } from '@/assets/icons/edit-outline.svg?react';
import { default as EntraIdIcon } from '@/assets/icons/entra-id.svg?react';
import { default as EyeCloseIcon } from '@/assets/icons/eye-close.svg?react';
import { default as EyeOpenIcon } from '@/assets/icons/eye-open.svg?react';
import { default as FileDashIcon } from '@/assets/icons/file-dash.svg?react';
import { default as FileExcelIcon } from '@/assets/icons/file-excel.svg?react';
import { default as FileImageIcon } from '@/assets/icons/file-image.svg?react';
import { default as FilePDFIcon } from '@/assets/icons/file-pdf.svg?react';
import { default as FilePlusIcon } from '@/assets/icons/file-plus.svg?react';
import { default as FileXIcon } from '@/assets/icons/file-x.svg?react';
import { default as FilesIcon } from '@/assets/icons/files.svg?react';
import { default as FilterIcon } from '@/assets/icons/filter.svg?react';
import { default as FilterOutlineIcon } from '@/assets/icons/filter-outline.svg?react';
import { default as FireIcon } from '@/assets/icons/fire.svg?react';
import { default as GlobeIcon } from '@/assets/icons/globe.svg?react';
import { default as HammerIcon } from '@/assets/icons/hammer.svg?react';
import { default as IDCardIcon } from '@/assets/icons/id-card.svg?react';
import { default as InfoOutlineIcon } from '@/assets/icons/info-outline.svg?react';
import { default as InfoIcon } from '@/assets/icons/info.svg?react';
import { default as LogoIcon } from '@/assets/icons/logo.svg?react';
import { default as MoreIcon } from '@/assets/icons/more.svg?react';
import { default as NoteIcon } from '@/assets/icons/note.svg?react';
import { default as PaperPlaneIcon } from '@/assets/icons/paper-plane.svg?react';
import { default as QuestionCircleIcon } from '@/assets/icons/question-circle.svg?react';
import { default as ResetCounterClockwiseIcon } from '@/assets/icons/reset-counter-clockwise.svg?react';
import { default as ResetClockwiseIcon } from '@/assets/icons/reset-clockwise.svg?react';
import { default as RejectIcon } from '@/assets/icons/reject.svg?react';
import { default as SearchIcon } from '@/assets/icons/search.svg?react';
import { default as SearchDocIcon } from '@/assets/icons/search-doc.svg?react';
import { default as SearchTextIcon } from '@/assets/icons/search-text.svg?react';
import { default as SignInIcon } from '@/assets/icons/sign-in.svg?react';
import { default as SignOutIcon } from '@/assets/icons/sign-in.svg?react';
import { default as SirenIcon } from '@/assets/icons/siren.svg?react';
import { default as SortAscIcon } from '@/assets/icons/sort-asc.svg?react';
import { default as SortDescIcon } from '@/assets/icons/sort-desc.svg?react';
import { default as SpinnerIcon } from '@/assets/icons/spinner.svg?react';
import { default as SpinnerAltIcon } from '@/assets/icons/spinner-alt.svg?react';
import { default as UploadIcon } from '@/assets/icons/upload.svg?react';
import { default as UploadAltIcon } from '@/assets/icons/upload-alt.svg?react';
import { default as WarningIcon } from '@/assets/icons/warning.svg?react';
import { default as ZoomInIcon } from '@/assets/icons/zoom-in.svg?react';
import { default as ZoomOutIcon } from '@/assets/icons/zoom-out.svg?react';
import { default as PendingDotsIcon } from '@/assets/icons/pending-dots.svg?react';
//Flags
import { default as AbkhaziaIcon } from '@/assets/icons/flags/abkhazia.svg?react';
import { default as AfghanistanIcon } from '@/assets/icons/flags/afghanistan.svg?react';
import { default as AlandIslandsIcon } from '@/assets/icons/flags/aland-islands.svg?react';
import { default as AlbaniaIcon } from '@/assets/icons/flags/albania.svg?react';
import { default as AlgeriaIcon } from '@/assets/icons/flags/algeria.svg?react';
import { default as AmericanSamoaIcon } from '@/assets/icons/flags/american-samoa.svg?react';
import { default as AndorraIcon } from '@/assets/icons/flags/andorra.svg?react';
import { default as AngolaIcon } from '@/assets/icons/flags/angola.svg?react';
import { default as AnguillaIcon } from '@/assets/icons/flags/anguilla.svg?react';
import { default as AntiguaAndBarbudaIcon } from '@/assets/icons/flags/antigua-and-barbuda.svg?react';
import { default as ArgentinaIcon } from '@/assets/icons/flags/argentina.svg?react';
import { default as ArmeniaIcon } from '@/assets/icons/flags/armenia.svg?react';
import { default as ArubaIcon } from '@/assets/icons/flags/aruba.svg?react';
import { default as AustraliaIcon } from '@/assets/icons/flags/australia.svg?react';
import { default as AustriaIcon } from '@/assets/icons/flags/austria.svg?react';
import { default as AzerbaijanIcon } from '@/assets/icons/flags/azerbaijan.svg?react';
import { default as AzoresIslandsIcon } from '@/assets/icons/flags/azores-islands.svg?react';
import { default as BahamasIcon } from '@/assets/icons/flags/bahamas.svg?react';
import { default as BahrainIcon } from '@/assets/icons/flags/bahrain.svg?react';
import { default as BalearicIslandsIcon } from '@/assets/icons/flags/balearic-islands.svg?react';
import { default as BangladeshIcon } from '@/assets/icons/flags/bangladesh.svg?react';
import { default as BarbadosIcon } from '@/assets/icons/flags/barbados.svg?react';
import { default as BasqueCountryIcon } from '@/assets/icons/flags/basque-country.svg?react';
import { default as BelarusIcon } from '@/assets/icons/flags/belarus.svg?react';
import { default as BelgiumIcon } from '@/assets/icons/flags/belgium.svg?react';
import { default as BelizeIcon } from '@/assets/icons/flags/belize.svg?react';
import { default as BeninIcon } from '@/assets/icons/flags/benin.svg?react';
import { default as BermudaIcon } from '@/assets/icons/flags/bermuda.svg?react';
import { default as BhutanIcon } from '@/assets/icons/flags/bhutan.svg?react';
import { default as BoliviaIcon } from '@/assets/icons/flags/bolivia.svg?react';
import { default as BonaireIcon } from '@/assets/icons/flags/bonaire.svg?react';
import { default as BosniaAndHerzegovinaIcon } from '@/assets/icons/flags/bosnia-and-herzegovina.svg?react';
import { default as BotswanaIcon } from '@/assets/icons/flags/botswana.svg?react';
import { default as BrazilIcon } from '@/assets/icons/flags/brazil.svg?react';
import { default as BritishColumbiaIcon } from '@/assets/icons/flags/british-columbia.svg?react';
import { default as BritishIndianOceanTerritoryIcon } from '@/assets/icons/flags/british-indian-ocean-territory.svg?react';
import { default as BritishVirginIslandsIcon } from '@/assets/icons/flags/british-virgin-islands.svg?react';
import { default as BruneiIcon } from '@/assets/icons/flags/brunei.svg?react';
import { default as BulgariaIcon } from '@/assets/icons/flags/bulgaria.svg?react';
import { default as BurkinaFasoIcon } from '@/assets/icons/flags/burkina-faso.svg?react';
import { default as BurundiIcon } from '@/assets/icons/flags/burundi.svg?react';
import { default as CambodiaIcon } from '@/assets/icons/flags/cambodia.svg?react';
import { default as CameroonIcon } from '@/assets/icons/flags/cameroon.svg?react';
import { default as CanadaIcon } from '@/assets/icons/flags/canada.svg?react';
import { default as CanaryIslandsIcon } from '@/assets/icons/flags/canary-islands.svg?react';
import { default as CapeVerdeIcon } from '@/assets/icons/flags/cape-verde.svg?react';
import { default as CaymanIslandsIcon } from '@/assets/icons/flags/cayman-islands.svg?react';
import { default as CentralAfricanRepublicIcon } from '@/assets/icons/flags/central-african-republic.svg?react';
import { default as CeutaIcon } from '@/assets/icons/flags/ceuta.svg?react';
import { default as ChadIcon } from '@/assets/icons/flags/chad.svg?react';
import { default as ChileIcon } from '@/assets/icons/flags/chile.svg?react';
import { default as ChinaIcon } from '@/assets/icons/flags/china.svg?react';
import { default as CocosIslandIcon } from '@/assets/icons/flags/cocos-island.svg?react';
import { default as ColumbiaIcon } from '@/assets/icons/flags/columbia.svg?react';
import { default as ComorosIcon } from '@/assets/icons/flags/comoros.svg?react';
import { default as CookIslandsIcon } from '@/assets/icons/flags/cook-islands.svg?react';
import { default as CorsicaIcon } from '@/assets/icons/flags/corsica.svg?react';
import { default as CostaRicaIcon } from '@/assets/icons/flags/costa-rica.svg?react';
import { default as CroatiaIcon } from '@/assets/icons/flags/croatia.svg?react';
import { default as CubaIcon } from '@/assets/icons/flags/cuba.svg?react';
import { default as CuracaoIcon } from '@/assets/icons/flags/curacao.svg?react';
import { default as CyprusIcon } from '@/assets/icons/flags/cyprus.svg?react';
import { default as CzechRepublicIcon } from '@/assets/icons/flags/czech-republic.svg?react';
import { default as DemocraticRepublicOfCongoIcon } from '@/assets/icons/flags/democratic-republic-of-congo.svg?react';
import { default as DenmarkIcon } from '@/assets/icons/flags/denmark.svg?react';
import { default as DjiboutiIcon } from '@/assets/icons/flags/djibouti.svg?react';
import { default as DominicaIcon } from '@/assets/icons/flags/dominica.svg?react';
import { default as DominicanRepublicIcon } from '@/assets/icons/flags/dominican-republic.svg?react';
import { default as EastTimorIcon } from '@/assets/icons/flags/east-timor.svg?react';
import { default as EcuadorIcon } from '@/assets/icons/flags/ecuador.svg?react';
import { default as EgyptIcon } from '@/assets/icons/flags/egypt.svg?react';
import { default as ElSalvadorIcon } from '@/assets/icons/flags/el-salvador.svg?react';
import { default as EnglandIcon } from '@/assets/icons/flags/england.svg?react';
import { default as EquatorialGuineaIcon } from '@/assets/icons/flags/equatorial-guinea.svg?react';
import { default as EritreaIcon } from '@/assets/icons/flags/eritrea.svg?react';
import { default as EstoniaIcon } from '@/assets/icons/flags/estonia.svg?react';
import { default as EthiopiaIcon } from '@/assets/icons/flags/ethiopia.svg?react';
import { default as FalklandIslandsIcon } from '@/assets/icons/flags/falkland-islands.svg?react';
import { default as FaroeIslandsIcon } from '@/assets/icons/flags/faroe-islands.svg?react';
import { default as FijiIcon } from '@/assets/icons/flags/fiji.svg?react';
import { default as FinlandIcon } from '@/assets/icons/flags/finland.svg?react';
import { default as FranceIcon } from '@/assets/icons/flags/france.svg?react';
import { default as FrenchPolynesiaIcon } from '@/assets/icons/flags/french-polynesia.svg?react';
import { default as GabonIcon } from '@/assets/icons/flags/gabon.svg?react';
import { default as GalapagosIslandsIcon } from '@/assets/icons/flags/galapagos-islands.svg?react';
import { default as GambiaIcon } from '@/assets/icons/flags/gambia.svg?react';
import { default as GeorgiaIcon } from '@/assets/icons/flags/georgia.svg?react';
import { default as GermanyIcon } from '@/assets/icons/flags/germany.svg?react';
import { default as GhanaIcon } from '@/assets/icons/flags/ghana.svg?react';
import { default as GibraltarIcon } from '@/assets/icons/flags/gibraltar.svg?react';
import { default as GreeceIcon } from '@/assets/icons/flags/greece.svg?react';
import { default as GreenlandIcon } from '@/assets/icons/flags/greenland.svg?react';
import { default as GrenadaIcon } from '@/assets/icons/flags/grenada.svg?react';
import { default as GuamIcon } from '@/assets/icons/flags/guam.svg?react';
import { default as GuatemalaIcon } from '@/assets/icons/flags/guatemala.svg?react';
import { default as GuernseyIcon } from '@/assets/icons/flags/guernsey.svg?react';
import { default as GuineaBissauIcon } from '@/assets/icons/flags/guinea-bissau.svg?react';
import { default as GuineaIcon } from '@/assets/icons/flags/guinea.svg?react';
import { default as GuyanaIcon } from '@/assets/icons/flags/guyana.svg?react';
import { default as HaitiIcon } from '@/assets/icons/flags/haiti.svg?react';
import { default as HawaiiIcon } from '@/assets/icons/flags/hawaii.svg?react';
import { default as HondurasIcon } from '@/assets/icons/flags/honduras.svg?react';
import { default as HongKongIcon } from '@/assets/icons/flags/hong-kong.svg?react';
import { default as HungaryIcon } from '@/assets/icons/flags/hungary.svg?react';
import { default as IcelandIcon } from '@/assets/icons/flags/iceland.svg?react';
import { default as IndiaIcon } from '@/assets/icons/flags/india.svg?react';
import { default as IndonesiaIcon } from '@/assets/icons/flags/indonesia.svg?react';
import { default as IranIcon } from '@/assets/icons/flags/iran.svg?react';
import { default as IraqIcon } from '@/assets/icons/flags/iraq.svg?react';
import { default as IrelandIcon } from '@/assets/icons/flags/ireland.svg?react';
import { default as IsleOfManIcon } from '@/assets/icons/flags/isle-of-man.svg?react';
import { default as IsraelIcon } from '@/assets/icons/flags/israel.svg?react';
import { default as ItalyIcon } from '@/assets/icons/flags/italy.svg?react';
import { default as IvoryCoastIcon } from '@/assets/icons/flags/ivory-coast.svg?react';
import { default as JamaicaIcon } from '@/assets/icons/flags/jamaica.svg?react';
import { default as JapanIcon } from '@/assets/icons/flags/japan.svg?react';
import { default as JerseyIcon } from '@/assets/icons/flags/jersey.svg?react';
import { default as JordanIcon } from '@/assets/icons/flags/jordan.svg?react';
import { default as KazakhstanIcon } from '@/assets/icons/flags/kazakhstan.svg?react';
import { default as KenyaIcon } from '@/assets/icons/flags/kenya.svg?react';
import { default as KiribatiIcon } from '@/assets/icons/flags/kiribati.svg?react';
import { default as KosovoIcon } from '@/assets/icons/flags/kosovo.svg?react';
import { default as KuwaitIcon } from '@/assets/icons/flags/kuwait.svg?react';
import { default as KyrgyzstanIcon } from '@/assets/icons/flags/kyrgyzstan.svg?react';
import { default as LaosIcon } from '@/assets/icons/flags/laos.svg?react';
import { default as LatviaIcon } from '@/assets/icons/flags/latvia.svg?react';
import { default as LebanonIcon } from '@/assets/icons/flags/lebanon.svg?react';
import { default as LesothoIcon } from '@/assets/icons/flags/lesotho.svg?react';
import { default as LiberiaIcon } from '@/assets/icons/flags/liberia.svg?react';
import { default as LibyaIcon } from '@/assets/icons/flags/libya.svg?react';
import { default as LiechtensteinIcon } from '@/assets/icons/flags/liechtenstein.svg?react';
import { default as LithuaniaIcon } from '@/assets/icons/flags/lithuania.svg?react';
import { default as LuxembourgIcon } from '@/assets/icons/flags/luxembourg.svg?react';
import { default as MacaoIcon } from '@/assets/icons/flags/macao.svg?react';
import { default as MadagascarIcon } from '@/assets/icons/flags/madagascar.svg?react';
import { default as MadeiraIcon } from '@/assets/icons/flags/madeira.svg?react';
import { default as MalawiIcon } from '@/assets/icons/flags/malawi.svg?react';
import { default as MalaysiaIcon } from '@/assets/icons/flags/malaysia.svg?react';
import { default as MaldivesIcon } from '@/assets/icons/flags/maldives.svg?react';
import { default as MaliIcon } from '@/assets/icons/flags/mali.svg?react';
import { default as MaltaIcon } from '@/assets/icons/flags/malta.svg?react';
import { default as MarshallIslandIcon } from '@/assets/icons/flags/marshall-island.svg?react';
import { default as MartiniqueIcon } from '@/assets/icons/flags/martinique.svg?react';
import { default as MauritaniaIcon } from '@/assets/icons/flags/mauritania.svg?react';
import { default as MauritiusIcon } from '@/assets/icons/flags/mauritius.svg?react';
import { default as MelillaIcon } from '@/assets/icons/flags/melilla.svg?react';
import { default as MexicoIcon } from '@/assets/icons/flags/mexico.svg?react';
import { default as MicronesiaIcon } from '@/assets/icons/flags/micronesia.svg?react';
import { default as MoldovaIcon } from '@/assets/icons/flags/moldova.svg?react';
import { default as MonacoIcon } from '@/assets/icons/flags/monaco.svg?react';
import { default as MongoliaIcon } from '@/assets/icons/flags/mongolia.svg?react';
import { default as MontenegroIcon } from '@/assets/icons/flags/montenegro.svg?react';
import { default as MontserratIcon } from '@/assets/icons/flags/montserrat.svg?react';
import { default as MoroccoIcon } from '@/assets/icons/flags/morocco.svg?react';
import { default as MozambiqueIcon } from '@/assets/icons/flags/mozambique.svg?react';
import { default as MyanmarIcon } from '@/assets/icons/flags/myanmar.svg?react';
import { default as NamibiaIcon } from '@/assets/icons/flags/namibia.svg?react';
import { default as NauruIcon } from '@/assets/icons/flags/nauru.svg?react';
import { default as NepalIcon } from '@/assets/icons/flags/nepal.svg?react';
import { default as NetherlandsIcon } from '@/assets/icons/flags/netherlands.svg?react';
import { default as NewZealandIcon } from '@/assets/icons/flags/new-zealand.svg?react';
import { default as NicaraguaIcon } from '@/assets/icons/flags/nicaragua.svg?react';
import { default as NigerIcon } from '@/assets/icons/flags/niger.svg?react';
import { default as NigeriaIcon } from '@/assets/icons/flags/nigeria.svg?react';
import { default as NiueIcon } from '@/assets/icons/flags/niue.svg?react';
import { default as NorfolkIslandIcon } from '@/assets/icons/flags/norfolk-island.svg?react';
import { default as NorthKoreaIcon } from '@/assets/icons/flags/north-korea.svg?react';
import { default as NorthernCyprusIcon } from '@/assets/icons/flags/northern-cyprus.svg?react';
import { default as NorthernMarianasIslandsIcon } from '@/assets/icons/flags/northern-marianas-islands.svg?react';
import { default as NorwayIcon } from '@/assets/icons/flags/norway.svg?react';
import { default as OmanIcon } from '@/assets/icons/flags/oman.svg?react';
import { default as OrkneyIslandsIcon } from '@/assets/icons/flags/orkney-islands.svg?react';
import { default as OssetiaIcon } from '@/assets/icons/flags/ossetia.svg?react';
import { default as PakistanIcon } from '@/assets/icons/flags/pakistan.svg?react';
import { default as PalauIcon } from '@/assets/icons/flags/palau.svg?react';
import { default as PalestineIcon } from '@/assets/icons/flags/palestine.svg?react';
import { default as PanamaIcon } from '@/assets/icons/flags/panama.svg?react';
import { default as PapuaNewGuineaIcon } from '@/assets/icons/flags/papua-new-guinea.svg?react';
import { default as ParaguayIcon } from '@/assets/icons/flags/paraguay.svg?react';
import { default as PeruIcon } from '@/assets/icons/flags/peru.svg?react';
import { default as PhilippinesIcon } from '@/assets/icons/flags/philippines.svg?react';
import { default as PitcairnIslandsIcon } from '@/assets/icons/flags/pitcairn-islands.svg?react';
import { default as PolandIcon } from '@/assets/icons/flags/poland.svg?react';
import { default as PortugalIcon } from '@/assets/icons/flags/portugal.svg?react';
import { default as PuertoRicoIcon } from '@/assets/icons/flags/puerto-rico.svg?react';
import { default as QatarIcon } from '@/assets/icons/flags/qatar.svg?react';
import { default as RapaNuiIcon } from '@/assets/icons/flags/rapa-nui.svg?react';
import { default as RepublicOfMacedoniaIcon } from '@/assets/icons/flags/republic-of-macedonia.svg?react';
import { default as RepublicOfTheCongoIcon } from '@/assets/icons/flags/republic-of-the-congo.svg?react';
import { default as RomaniaIcon } from '@/assets/icons/flags/romania.svg?react';
import { default as RwandaIcon } from '@/assets/icons/flags/rwanda.svg?react';
import { default as SabaIslandIcon } from '@/assets/icons/flags/saba-island.svg?react';
import { default as SahrawiArabDemocraticRepublicIcon } from '@/assets/icons/flags/sahrawi-arab-democratic-republic.svg?react';
import { default as SamoaIcon } from '@/assets/icons/flags/samoa.svg?react';
import { default as SanMarinoIcon } from '@/assets/icons/flags/san-marino.svg?react';
import { default as SaoTomeAndPrinceIcon } from '@/assets/icons/flags/sao-tome-and-prince.svg?react';
import { default as SardiniaIcon } from '@/assets/icons/flags/sardinia.svg?react';
import { default as SaudiArabiaIcon } from '@/assets/icons/flags/saudi-arabia.svg?react';
import { default as ScotlandIcon } from '@/assets/icons/flags/scotland.svg?react';
import { default as SenegalIcon } from '@/assets/icons/flags/senegal.svg?react';
import { default as SerbiaIcon } from '@/assets/icons/flags/serbia.svg?react';
import { default as SeychellesIcon } from '@/assets/icons/flags/seychelles.svg?react';
import { default as SierraLeoneIcon } from '@/assets/icons/flags/sierra-leone.svg?react';
import { default as SingaporeIcon } from '@/assets/icons/flags/singapore.svg?react';
import { default as SintEustatiusIcon } from '@/assets/icons/flags/sint-eustatius.svg?react';
import { default as SintMaartenIcon } from '@/assets/icons/flags/sint-maarten.svg?react';
import { default as SlovakiaIcon } from '@/assets/icons/flags/slovakia.svg?react';
import { default as SloveniaIcon } from '@/assets/icons/flags/slovenia.svg?react';
import { default as SolomonIslandsIcon } from '@/assets/icons/flags/solomon-islands.svg?react';
import { default as SomaliaIcon } from '@/assets/icons/flags/somalia.svg?react';
import { default as SomalilandIcon } from '@/assets/icons/flags/somaliland.svg?react';
import { default as SouthAfricaIcon } from '@/assets/icons/flags/south-africa.svg?react';
import { default as SouthKoreaIcon } from '@/assets/icons/flags/south-korea.svg?react';
import { default as SouthSudanIcon } from '@/assets/icons/flags/south-sudan.svg?react';
import { default as SpainIcon } from '@/assets/icons/flags/spain.svg?react';
import { default as SriLankaIcon } from '@/assets/icons/flags/sri-lanka.svg?react';
import { default as StBartsIcon } from '@/assets/icons/flags/st-barts.svg?react';
import { default as StKittsNevisIcon } from '@/assets/icons/flags/st-kitts-nevis.svg?react';
import { default as StLuciaIcon } from '@/assets/icons/flags/st-lucia.svg?react';
import { default as StMaartenIcon } from '@/assets/icons/flags/st-maarten.svg?react';
import { default as StVincentAndTheGrenadinesIcon } from '@/assets/icons/flags/st-vincent-and-the-grenadines.svg?react';
import { default as SudanIcon } from '@/assets/icons/flags/sudan.svg?react';
import { default as SurinameIcon } from '@/assets/icons/flags/suriname.svg?react';
import { default as SwazilandIcon } from '@/assets/icons/flags/swaziland.svg?react';
import { default as SwedenIcon } from '@/assets/icons/flags/sweden.svg?react';
import { default as SwitzerlandIcon } from '@/assets/icons/flags/switzerland.svg?react';
import { default as SyriaIcon } from '@/assets/icons/flags/syria.svg?react';
import { default as TaiwanIcon } from '@/assets/icons/flags/taiwan.svg?react';
import { default as TajikistanIcon } from '@/assets/icons/flags/tajikistan.svg?react';
import { default as TanzaniaIcon } from '@/assets/icons/flags/tanzania.svg?react';
import { default as ThailandIcon } from '@/assets/icons/flags/thailand.svg?react';
import { default as TibetIcon } from '@/assets/icons/flags/tibet.svg?react';
import { default as TogoIcon } from '@/assets/icons/flags/togo.svg?react';
import { default as TokelauIcon } from '@/assets/icons/flags/tokelau.svg?react';
import { default as TongaIcon } from '@/assets/icons/flags/tonga.svg?react';
import { default as TransnistriaIcon } from '@/assets/icons/flags/transnistria.svg?react';
import { default as TrinidadAndTobagoIcon } from '@/assets/icons/flags/trinidad-and-tobago.svg?react';
import { default as TunisiaIcon } from '@/assets/icons/flags/tunisia.svg?react';
import { default as TurkeyIcon } from '@/assets/icons/flags/turkey.svg?react';
import { default as TurkmenistanIcon } from '@/assets/icons/flags/turkmenistan.svg?react';
import { default as TurksAndCaicosIcon } from '@/assets/icons/flags/turks-and-caicos.svg?react';
import { default as TuvaluIcon } from '@/assets/icons/flags/tuvalu.svg?react';
import { default as UgandaIcon } from '@/assets/icons/flags/uganda.svg?react';
import { default as UkraineIcon } from '@/assets/icons/flags/ukraine.svg?react';
import { default as UnitedArabEmiratesIcon } from '@/assets/icons/flags/united-arab-emirates.svg?react';
import { default as UnitedKingdomIcon } from '@/assets/icons/flags/united-kingdom.svg?react';
import { default as UnitedStatesIcon } from '@/assets/icons/flags/united-states.svg?react';
import { default as UruguayIcon } from '@/assets/icons/flags/uruguay.svg?react';
import { default as UzbekistanIcon } from '@/assets/icons/flags/uzbekistan.svg?react';
import { default as VanuatuIcon } from '@/assets/icons/flags/vanuatu.svg?react';
import { default as VaticanCityIcon } from '@/assets/icons/flags/vatican-city.svg?react';
import { default as VenezuelaIcon } from '@/assets/icons/flags/venezuela.svg?react';
import { default as VietnamIcon } from '@/assets/icons/flags/vietnam.svg?react';
import { default as VirginIslandsIcon } from '@/assets/icons/flags/virgin-islands.svg?react';
import { default as WalesIcon } from '@/assets/icons/flags/wales.svg?react';
import { default as YemenIcon } from '@/assets/icons/flags/yemen.svg?react';
import { default as ZambiaIcon } from '@/assets/icons/flags/zambia.svg?react';
import { default as ZimbabweIcon } from '@/assets/icons/flags/zimbabwe.svg?react';
import { default as EcIcon } from '@/assets/icons/flags/ec.svg?react';

export function getIconComponent(name: IconName) {
  switch (name) {
    case 'trash-simple':
      return TrashSimpleIcon;
    case 'minus-circle':
      return MinusCircleIcon;
    case 'delete':
      return DeleteIcon;
    case 'lock':
      return LockIcon;
    case 'unlock':
      return UnLockIcon;
    case 'user-gear':
      return UserGearIcon;
    case 'password':
      return PasswordIcon;
    case 'add':
      return AddIcon;
    case 'switch-role':
      return SwitchRoleIcon;
    case 'alert':
      return AlertIcon;
    case 'alert-outline':
      return AlertOutlineIcon;
    case 'arrow-arc-left':
      return ArrowArcLeftIcon;
    case 'arrow-arc-right':
      return ArrowArcRightIcon;
    case 'arrow-down':
      return ArrowDownIcon;
    case 'arrow-left':
      return ArrowLeftIcon;
    case 'arrow-left-alt':
      return ArrowLeftAltIcon;
    case 'arrow-right':
      return ArrowRightIcon;
    case 'arrow-up':
      return ArrowUpIcon;
    case 'bell':
      return BellIcon;
    case 'block':
      return BlockIcon;
    case 'calendar-blank':
      return CalendarBlankIcon;
    case 'caret-down':
      return CaretDownIcon;
    case 'caret-circle-left':
      return CaretCircleLeftIcon;
    case 'caret-circle-right':
      return CaretCircleRightIcon;
    case 'chat-dots':
      return ChatDotsIcon;
    case 'chat-empty':
      return ChatEmptyIcon;
    case 'chat-lines':
      return ChatLinesIcon;
    case 'check':
      return CheckIcon;
    case 'check-circle-outline':
      return CheckCircleOutlineIcon;
    case 'check-circle':
      return CheckCircleIcon;
    case 'check-fat':
      return CheckFatIcon;
    case 'check-fat-outline':
      return CheckFatOutlineIcon;
    case 'check-mini':
      return CheckMiniIcon;
    case 'check-square-offset':
      return CheckSquareOffsetIcon;
    case 'close':
      return CloseIcon;
    case 'clock':
      return ClockIcon;
    case 'download':
      return DownloadIcon;
    case 'edit-outline':
      return EditOutlineIcon;
    case 'entra-id':
      return EntraIdIcon;
    case 'eye-close':
      return EyeCloseIcon;
    case 'eye-open':
      return EyeOpenIcon;
    case 'file-plus':
      return FilePlusIcon;
    case 'file-x':
      return FileXIcon;
    case 'file-dash':
      return FileDashIcon;
    case 'file-excel':
      return FileExcelIcon;
    case 'file-image':
      return FileImageIcon;
    case 'file-pdf':
      return FilePDFIcon;
    case 'files':
      return FilesIcon;
    case 'filter':
      return FilterIcon;
    case 'filter-outline':
      return FilterOutlineIcon;
    case 'fire':
      return FireIcon;
    case 'globe':
      return GlobeIcon;
    case 'hammer':
      return HammerIcon;
    case 'id-card':
      return IDCardIcon;
    case 'info-outline':
      return InfoOutlineIcon;
    case 'info':
      return InfoIcon;
    case 'logo':
      return LogoIcon;
    case 'more':
      return MoreIcon;
    case 'note':
      return NoteIcon;
    case 'paper-plane':
      return PaperPlaneIcon;
    case 'question-circle':
      return QuestionCircleIcon;
    case 'reject':
      return RejectIcon;
    case 'reset':
      return ResetCounterClockwiseIcon;
    case 'reset-clockwise':
      return ResetClockwiseIcon;
    case 'search':
      return SearchIcon;
    case 'search-doc':
      return SearchDocIcon;
    case 'search-text':
      return SearchTextIcon;
    case 'sign-in':
      return SignInIcon;
    case 'sign-out':
      return SignOutIcon;
    case 'siren':
      return SirenIcon;
    case 'sort-asc':
      return SortAscIcon;
    case 'sort-desc':
      return SortDescIcon;
    case 'spinner':
      return SpinnerIcon;
    case 'spinner-alt':
      return SpinnerAltIcon;
    case 'upload':
      return UploadIcon;
    case 'upload-alt':
      return UploadAltIcon;
    case 'warning':
      return WarningIcon;
    case 'zoom-in':
      return ZoomInIcon;
    case 'zoom-out':
      return ZoomOutIcon;
    case 'pending-dots':
      return PendingDotsIcon;

    // Flags
    case 'flag-abkhazia':
      return AbkhaziaIcon;
    case 'flag-afghanistan':
      return AfghanistanIcon;
    case 'flag-aland-islands':
      return AlandIslandsIcon;
    case 'flag-albania':
      return AlbaniaIcon;
    case 'flag-algeria':
      return AlgeriaIcon;
    case 'flag-american-samoa':
      return AmericanSamoaIcon;
    case 'flag-andorra':
      return AndorraIcon;
    case 'flag-angola':
      return AngolaIcon;
    case 'flag-anguilla':
      return AnguillaIcon;
    case 'flag-antigua-and-barbuda':
      return AntiguaAndBarbudaIcon;
    case 'flag-argentina':
      return ArgentinaIcon;
    case 'flag-armenia':
      return ArmeniaIcon;
    case 'flag-aruba':
      return ArubaIcon;
    case 'flag-australia':
      return AustraliaIcon;
    case 'flag-austria':
      return AustriaIcon;
    case 'flag-azerbaijan':
      return AzerbaijanIcon;
    case 'flag-azores-islands':
      return AzoresIslandsIcon;
    case 'flag-bahamas':
      return BahamasIcon;
    case 'flag-bahrain':
      return BahrainIcon;
    case 'flag-balearic-islands':
      return BalearicIslandsIcon;
    case 'flag-bangladesh':
      return BangladeshIcon;
    case 'flag-barbados':
      return BarbadosIcon;
    case 'flag-basque-country':
      return BasqueCountryIcon;
    case 'flag-belarus':
      return BelarusIcon;
    case 'flag-belgium':
      return BelgiumIcon;
    case 'flag-belize':
      return BelizeIcon;
    case 'flag-benin':
      return BeninIcon;
    case 'flag-bermuda':
      return BermudaIcon;
    case 'flag-bhutan':
      return BhutanIcon;
    case 'flag-bolivia':
      return BoliviaIcon;
    case 'flag-bonaire':
      return BonaireIcon;
    case 'flag-bosnia-and-herzegovina':
      return BosniaAndHerzegovinaIcon;
    case 'flag-botswana':
      return BotswanaIcon;
    case 'flag-brazil':
      return BrazilIcon;
    case 'flag-british-columbia':
      return BritishColumbiaIcon;
    case 'flag-british-indian-ocean-territory':
      return BritishIndianOceanTerritoryIcon;
    case 'flag-british-virgin-islands':
      return BritishVirginIslandsIcon;
    case 'flag-brunei':
      return BruneiIcon;
    case 'flag-bulgaria':
      return BulgariaIcon;
    case 'flag-burkina-faso':
      return BurkinaFasoIcon;
    case 'flag-burundi':
      return BurundiIcon;
    case 'flag-cambodia':
      return CambodiaIcon;
    case 'flag-cameroon':
      return CameroonIcon;
    case 'flag-canada':
      return CanadaIcon;
    case 'flag-canary-islands':
      return CanaryIslandsIcon;
    case 'flag-cape-verde':
      return CapeVerdeIcon;
    case 'flag-cayman-islands':
      return CaymanIslandsIcon;
    case 'flag-central-african-republic':
      return CentralAfricanRepublicIcon;
    case 'flag-ceuta':
      return CeutaIcon;
    case 'flag-chad':
      return ChadIcon;
    case 'flag-chile':
      return ChileIcon;
    case 'flag-china':
      return ChinaIcon;
    case 'flag-cocos-island':
      return CocosIslandIcon;
    case 'flag-columbia':
      return ColumbiaIcon;
    case 'flag-comoros':
      return ComorosIcon;
    case 'flag-cook-islands':
      return CookIslandsIcon;
    case 'flag-corsica':
      return CorsicaIcon;
    case 'flag-costa-rica':
      return CostaRicaIcon;
    case 'flag-croatia':
      return CroatiaIcon;
    case 'flag-cuba':
      return CubaIcon;
    case 'flag-curacao':
      return CuracaoIcon;
    case 'flag-cyprus':
      return CyprusIcon;
    case 'flag-czech-republic':
      return CzechRepublicIcon;
    case 'flag-democratic-republic-of-congo':
      return DemocraticRepublicOfCongoIcon;
    case 'flag-denmark':
      return DenmarkIcon;
    case 'flag-djibouti':
      return DjiboutiIcon;
    case 'flag-dominica':
      return DominicaIcon;
    case 'flag-dominican-republic':
      return DominicanRepublicIcon;
    case 'flag-east-timor':
      return EastTimorIcon;
    case 'flag-ecuador':
      return EcuadorIcon;
    case 'flag-egypt':
      return EgyptIcon;
    case 'flag-el-salvador':
      return ElSalvadorIcon;
    case 'flag-england':
      return EnglandIcon;
    case 'flag-equatorial-guinea':
      return EquatorialGuineaIcon;
    case 'flag-eritrea':
      return EritreaIcon;
    case 'flag-estonia':
      return EstoniaIcon;
    case 'flag-ethiopia':
      return EthiopiaIcon;
    case 'flag-falkland-islands':
      return FalklandIslandsIcon;
    case 'flag-faroe-islands':
      return FaroeIslandsIcon;
    case 'flag-fiji':
      return FijiIcon;
    case 'flag-finland':
      return FinlandIcon;
    case 'flag-france':
      return FranceIcon;
    case 'flag-french-polynesia':
      return FrenchPolynesiaIcon;
    case 'flag-gabon':
      return GabonIcon;
    case 'flag-galapagos-islands':
      return GalapagosIslandsIcon;
    case 'flag-gambia':
      return GambiaIcon;
    case 'flag-georgia':
      return GeorgiaIcon;
    case 'flag-germany':
      return GermanyIcon;
    case 'flag-ghana':
      return GhanaIcon;
    case 'flag-gibraltar':
      return GibraltarIcon;
    case 'flag-greece':
      return GreeceIcon;
    case 'flag-greenland':
      return GreenlandIcon;
    case 'flag-grenada':
      return GrenadaIcon;
    case 'flag-guam':
      return GuamIcon;
    case 'flag-guatemala':
      return GuatemalaIcon;
    case 'flag-guernsey':
      return GuernseyIcon;
    case 'flag-guinea-bissau':
      return GuineaBissauIcon;
    case 'flag-guinea':
      return GuineaIcon;
    case 'flag-guyana':
      return GuyanaIcon;
    case 'flag-haiti':
      return HaitiIcon;
    case 'flag-hawaii':
      return HawaiiIcon;
    case 'flag-honduras':
      return HondurasIcon;
    case 'flag-hong-kong':
      return HongKongIcon;
    case 'flag-hungary':
      return HungaryIcon;
    case 'flag-iceland':
      return IcelandIcon;
    case 'flag-india':
      return IndiaIcon;
    case 'flag-indonesia':
      return IndonesiaIcon;
    case 'flag-iran':
      return IranIcon;
    case 'flag-iraq':
      return IraqIcon;
    case 'flag-ireland':
      return IrelandIcon;
    case 'flag-isle-of-man':
      return IsleOfManIcon;
    case 'flag-israel':
      return IsraelIcon;
    case 'flag-italy':
      return ItalyIcon;
    case 'flag-ivory-coast':
      return IvoryCoastIcon;
    case 'flag-jamaica':
      return JamaicaIcon;
    case 'flag-japan':
      return JapanIcon;
    case 'flag-jersey':
      return JerseyIcon;
    case 'flag-jordan':
      return JordanIcon;
    case 'flag-kazakhstan':
      return KazakhstanIcon;
    case 'flag-kenya':
      return KenyaIcon;
    case 'flag-kiribati':
      return KiribatiIcon;
    case 'flag-kosovo':
      return KosovoIcon;
    case 'flag-kuwait':
      return KuwaitIcon;
    case 'flag-kyrgyzstan':
      return KyrgyzstanIcon;
    case 'flag-laos':
      return LaosIcon;
    case 'flag-latvia':
      return LatviaIcon;
    case 'flag-lebanon':
      return LebanonIcon;
    case 'flag-lesotho':
      return LesothoIcon;
    case 'flag-liberia':
      return LiberiaIcon;
    case 'flag-libya':
      return LibyaIcon;
    case 'flag-liechtenstein':
      return LiechtensteinIcon;
    case 'flag-lithuania':
      return LithuaniaIcon;
    case 'flag-luxembourg':
      return LuxembourgIcon;
    case 'flag-macao':
      return MacaoIcon;
    case 'flag-madagascar':
      return MadagascarIcon;
    case 'flag-madeira':
      return MadeiraIcon;
    case 'flag-malawi':
      return MalawiIcon;
    case 'flag-malaysia':
      return MalaysiaIcon;
    case 'flag-maldives':
      return MaldivesIcon;
    case 'flag-mali':
      return MaliIcon;
    case 'flag-malta':
      return MaltaIcon;
    case 'flag-marshall-island':
      return MarshallIslandIcon;
    case 'flag-martinique':
      return MartiniqueIcon;
    case 'flag-mauritania':
      return MauritaniaIcon;
    case 'flag-mauritius':
      return MauritiusIcon;
    case 'flag-melilla':
      return MelillaIcon;
    case 'flag-mexico':
      return MexicoIcon;
    case 'flag-micronesia':
      return MicronesiaIcon;
    case 'flag-moldova':
      return MoldovaIcon;
    case 'flag-monaco':
      return MonacoIcon;
    case 'flag-mongolia':
      return MongoliaIcon;
    case 'flag-montenegro':
      return MontenegroIcon;
    case 'flag-montserrat':
      return MontserratIcon;
    case 'flag-morocco':
      return MoroccoIcon;
    case 'flag-mozambique':
      return MozambiqueIcon;
    case 'flag-myanmar':
      return MyanmarIcon;
    case 'flag-namibia':
      return NamibiaIcon;
    case 'flag-nauru':
      return NauruIcon;
    case 'flag-nepal':
      return NepalIcon;
    case 'flag-netherlands':
      return NetherlandsIcon;
    case 'flag-new-zealand':
      return NewZealandIcon;
    case 'flag-nicaragua':
      return NicaraguaIcon;
    case 'flag-niger':
      return NigerIcon;
    case 'flag-nigeria':
      return NigeriaIcon;
    case 'flag-niue':
      return NiueIcon;
    case 'flag-norfolk-island':
      return NorfolkIslandIcon;
    case 'flag-north-korea':
      return NorthKoreaIcon;
    case 'flag-northern-cyprus':
      return NorthernCyprusIcon;
    case 'flag-northern-marianas-islands':
      return NorthernMarianasIslandsIcon;
    case 'flag-norway':
      return NorwayIcon;
    case 'flag-oman':
      return OmanIcon;
    case 'flag-orkney-islands':
      return OrkneyIslandsIcon;
    case 'flag-ossetia':
      return OssetiaIcon;
    case 'flag-pakistan':
      return PakistanIcon;
    case 'flag-palau':
      return PalauIcon;
    case 'flag-palestine':
      return PalestineIcon;
    case 'flag-panama':
      return PanamaIcon;
    case 'flag-papua-new-guinea':
      return PapuaNewGuineaIcon;
    case 'flag-paraguay':
      return ParaguayIcon;
    case 'flag-peru':
      return PeruIcon;
    case 'flag-philippines':
      return PhilippinesIcon;
    case 'flag-pitcairn-islands':
      return PitcairnIslandsIcon;
    case 'flag-poland':
      return PolandIcon;
    case 'flag-portugal':
      return PortugalIcon;
    case 'flag-puerto-rico':
      return PuertoRicoIcon;
    case 'flag-qatar':
      return QatarIcon;
    case 'flag-rapa-nui':
      return RapaNuiIcon;
    case 'flag-republic-of-macedonia':
      return RepublicOfMacedoniaIcon;
    case 'flag-republic-of-the-congo':
      return RepublicOfTheCongoIcon;
    case 'flag-romania':
      return RomaniaIcon;
    case 'flag-rwanda':
      return RwandaIcon;
    case 'flag-saba-island':
      return SabaIslandIcon;
    case 'flag-sahrawi-arab-democratic-republic':
      return SahrawiArabDemocraticRepublicIcon;
    case 'flag-samoa':
      return SamoaIcon;
    case 'flag-san-marino':
      return SanMarinoIcon;
    case 'flag-sao-tome-and-prince':
      return SaoTomeAndPrinceIcon;
    case 'flag-sardinia':
      return SardiniaIcon;
    case 'flag-saudi-arabia':
      return SaudiArabiaIcon;
    case 'flag-scotland':
      return ScotlandIcon;
    case 'flag-senegal':
      return SenegalIcon;
    case 'flag-serbia':
      return SerbiaIcon;
    case 'flag-seychelles':
      return SeychellesIcon;
    case 'flag-sierra-leone':
      return SierraLeoneIcon;
    case 'flag-singapore':
      return SingaporeIcon;
    case 'flag-sint-eustatius':
      return SintEustatiusIcon;
    case 'flag-sint-maarten':
      return SintMaartenIcon;
    case 'flag-slovakia':
      return SlovakiaIcon;
    case 'flag-slovenia':
      return SloveniaIcon;
    case 'flag-solomon-islands':
      return SolomonIslandsIcon;
    case 'flag-somalia':
      return SomaliaIcon;
    case 'flag-somaliland':
      return SomalilandIcon;
    case 'flag-south-africa':
      return SouthAfricaIcon;
    case 'flag-south-korea':
      return SouthKoreaIcon;
    case 'flag-south-sudan':
      return SouthSudanIcon;
    case 'flag-spain':
      return SpainIcon;
    case 'flag-sri-lanka':
      return SriLankaIcon;
    case 'flag-st-barts':
      return StBartsIcon;
    case 'flag-st-kitts-nevis':
      return StKittsNevisIcon;
    case 'flag-st-lucia':
      return StLuciaIcon;
    case 'flag-st-maarten':
      return StMaartenIcon;
    case 'flag-st-vincent-and-the-grenadines':
      return StVincentAndTheGrenadinesIcon;
    case 'flag-sudan':
      return SudanIcon;
    case 'flag-suriname':
      return SurinameIcon;
    case 'flag-swaziland':
      return SwazilandIcon;
    case 'flag-sweden':
      return SwedenIcon;
    case 'flag-switzerland':
      return SwitzerlandIcon;
    case 'flag-syria':
      return SyriaIcon;
    case 'flag-taiwan':
      return TaiwanIcon;
    case 'flag-tajikistan':
      return TajikistanIcon;
    case 'flag-tanzania':
      return TanzaniaIcon;
    case 'flag-thailand':
      return ThailandIcon;
    case 'flag-tibet':
      return TibetIcon;
    case 'flag-togo':
      return TogoIcon;
    case 'flag-tokelau':
      return TokelauIcon;
    case 'flag-tonga':
      return TongaIcon;
    case 'flag-transnistria':
      return TransnistriaIcon;
    case 'flag-trinidad-and-tobago':
      return TrinidadAndTobagoIcon;
    case 'flag-tunisia':
      return TunisiaIcon;
    case 'flag-turkey':
      return TurkeyIcon;
    case 'flag-turkmenistan':
      return TurkmenistanIcon;
    case 'flag-turks-and-caicos':
      return TurksAndCaicosIcon;
    case 'flag-tuvalu':
      return TuvaluIcon;
    case 'flag-uganda':
      return UgandaIcon;
    case 'flag-ukraine':
      return UkraineIcon;
    case 'flag-united-arab-emirates':
      return UnitedArabEmiratesIcon;
    case 'flag-united-kingdom':
      return UnitedKingdomIcon;
    case 'flag-united-states':
      return UnitedStatesIcon;
    case 'flag-uruguay':
      return UruguayIcon;
    case 'flag-uzbekistan':
      return UzbekistanIcon;
    case 'flag-vanuatu':
      return VanuatuIcon;
    case 'flag-vatican-city':
      return VaticanCityIcon;
    case 'flag-venezuela':
      return VenezuelaIcon;
    case 'flag-vietnam':
      return VietnamIcon;
    case 'flag-virgin-islands':
      return VirginIslandsIcon;
    case 'flag-wales':
      return WalesIcon;
    case 'flag-yemen':
      return YemenIcon;
    case 'flag-zambia':
      return ZambiaIcon;
    case 'flag-zimbabwe':
      return ZimbabweIcon;
    case 'flag-ec':
      return EcIcon;

    default:
      return QuestionCircleIcon;
  }
}
