import {
  EditableField,
  Field,
  FieldValueEndDecorator,
  Heading,
  Icon,
} from '@/components';
import { useClientCalculation, useDispatch, useSelector } from '@/hooks';
import {
  patchClientDetails,
  patchClientDocument,
  selectClientDetailsState,
  selectConfigsState,
  selectTerritories,
  fetchClientDetails,
} from '@/redux';
import { Divider, Grid, Tooltip } from '@mui/material';
import { DetailsCard } from './ClientDetails.styled';
import {
  formatCurrency,
  formatDateValue,
  getDisplayedPhone,
  isActiveClient,
} from '@/utils';
import {
  ADDRESS_MAX_LENGTH,
  CLIENT_TITLE_OPTIONS,
  ClientTitleText,
  COUNTRIES,
  COUNTRY_NAME_BY_VALUE,
  CURRENCY_SIGN,
  DocumentSubTypeText,
  EmploymentStatusText,
  FundingSourceText,
  GENDERS,
  GenderText,
  NATIONALITIES,
  NATIONALITY_BY_VALUE,
  OptionValueText,
  YES_NO_OPTIONS,
} from '@/constants';
import {
  AddressDetails,
  Client,
  Document,
  DocumentSubType,
  FundingSource,
  GenericStatus,
  ICBResponseCode,
  OptionValue,
  Tenant,
} from '@/types';
import { updateClient, updateDocument } from '@/api';
import { ICBAlert } from './ICBAlert';
import { PEPCheckTriggerButton } from './PEPCheckTriggerButton';
import { PEPReviewButton } from './PEPReviewButton';
import { AccountTypeField } from './AccountTypeField';

export function SectionAccountDetails() {
  const { dateFormat, idDocumentTypes } = useSelector(selectConfigsState);
  const { clientDetails: client } = useSelector(selectClientDetailsState);
  const territories = useSelector(selectTerritories);
  const { isIDVerified, idDocument } = useClientCalculation();
  const dispatch = useDispatch();

  if (!client) return null;

  const fundingSource = Object.values(FundingSource)
    .filter(item => (item as FundingSource) !== FundingSource.Other)
    .includes(client?.fundingSource as FundingSource)
    ? FundingSourceText[client?.fundingSource as FundingSource]
    : `${FundingSourceText[FundingSource.Other]} (${client?.fundingSource})`;

  const isEditable = isActiveClient(client);

  const getUpdateClientFieldFn = (name: keyof Client) => (value: string) => {
    return updateClient({
      id: client.id,
      [name]:
        value === OptionValue.Yes
          ? true
          : value === OptionValue.No
            ? false
            : value,
    });
  };

  const getUpdateClientTinNumberFn = () => (value: string) => {
    return updateClient({
      id: client.id,
      tin: {
        ...client.tin,
        [Tenant.Guyana]: value,
      },
    });
  };

  const getFieldOnSuccessFn = (name: keyof Client) => (value: string) => {
    dispatch(
      patchClientDetails({
        id: client.id,
        [name]:
          value === OptionValue.Yes
            ? true
            : value === OptionValue.No
              ? false
              : value,
      }),
    );
    dispatch(fetchClientDetails(client.id));
  };

  const getUpdateIdDocumentFn = (name: keyof Document) =>
    idDocument
      ? (value: string) =>
          updateDocument({
            ...idDocument,
            [name]: value,
          })
      : undefined;

  const getDocumentFieldOnSuccessFn = (name: keyof Document) =>
    idDocument
      ? (value: string) =>
          dispatch(
            patchClientDocument({
              clientId: client.id,
              document: {
                ...idDocument,
                [name]: value,
              },
            }),
          )
      : undefined;

  const getUpdateAddressFieldFn =
    (name: keyof AddressDetails) => (value: string) =>
      updateClient({
        id: client.id,
        addressDetailed: {
          [name]: value,
        },
      });

  const getAddressFieldOnSuccessFn =
    (name: keyof AddressDetails) => (value: string) =>
      dispatch(
        patchClientDetails({
          id: client.id,
          addressDetailed: {
            ...(client.addressDetailed || {}),
            [name]: value,
          },
        }),
      );

  const territoryOptions = territories
    .map(item => ({
      text: item.branchName,
      value: item.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const getTerritoryText = (branchId: string) => {
    const branch = territories.find(item => item.id === branchId);
    return branch?.branchName || branchId;
  };

  return (
    <>
      <Heading isSubSection title="Account details" my={0} />
      <DetailsCard>
        <ICBAlert client={client} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EditableField
              label="First name"
              value={client.firstName}
              saveFn={getUpdateClientFieldFn('firstName')}
              onSuccess={getFieldOnSuccessFn('firstName')}
              readOnly={!isEditable}
              errorField="firstName"
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Middle name"
              value={client.middleName}
              saveFn={getUpdateClientFieldFn('middleName')}
              onSuccess={getFieldOnSuccessFn('middleName')}
              readOnly={!isEditable}
              errorField="middleName"
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Last name"
              value={client.lastName}
              saveFn={getUpdateClientFieldFn('lastName')}
              onSuccess={getFieldOnSuccessFn('lastName')}
              readOnly={!isEditable}
              errorField="lastName"
            />
          </Grid>
          <Grid item xs={6}>
            <Field label="RIM #" content={client.rim} />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Date of birth"
              value={client.birthday}
              inputType="date"
              displayValue={value => formatDateValue(value, dateFormat)}
              saveFn={getUpdateClientFieldFn('birthday')}
              onSuccess={getFieldOnSuccessFn('birthday')}
              readOnly={!isEditable}
              errorField="birthday"
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Sex"
              value={client.gender ? String(client.gender) : ''}
              inputType="select"
              displayValue={value => GenderText[value] || value}
              saveFn={getUpdateClientFieldFn('gender')}
              onSuccess={getFieldOnSuccessFn('gender')}
              options={GENDERS}
              readOnly={!isEditable}
              errorField="gender"
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Online banking username"
              value={client?.username}
              inputType="text"
              saveFn={getUpdateClientFieldFn('username')}
              onSuccess={getFieldOnSuccessFn('username')}
              readOnly={
                !isEditable ||
                client.icbResponseCode === ICBResponseCode.Success
              }
              errorField="username"
              endDecorator={
                client.icbResponseCode === ICBResponseCode.Success ? (
                  <Tooltip title="Bank profile created" placement="top" arrow>
                    <FieldValueEndDecorator>
                      <Icon
                        name="check-circle-outline"
                        size={18}
                        color={theme => theme.palette.green[400]}
                      />
                    </FieldValueEndDecorator>
                  </Tooltip>
                ) : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Branch"
              value={String(client.branchId)}
              inputType="select"
              displayValue={value => getTerritoryText(value)}
              saveFn={getUpdateClientFieldFn('branchId')}
              onSuccess={getFieldOnSuccessFn('branchId')}
              options={territoryOptions}
              readOnly={!isEditable}
              errorField="branch"
            />
          </Grid>
          <Grid item xs={6}>
            <AccountTypeField client={client} />
          </Grid>
          {client?.taxResidence !== Tenant.Guyana && (
            <Grid item xs={6}>
              <EditableField
                label="Tin Number"
                value={client.tin[Tenant.Guyana] || ''}
                saveFn={getUpdateClientTinNumberFn()}
                onSuccess={getFieldOnSuccessFn('tin')}
                readOnly={!isEditable}
                errorField="tin"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              label="Client's attestation"
              content={client.infoAccuracyConfirmed ? 'Yes' : 'Not yet'}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Employment status"
              content={
                client?.employmentStatus &&
                (EmploymentStatusText[client.employmentStatus] ||
                  client.employmentStatus)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Title"
              value={client?.title}
              inputType="select"
              displayValue={value => ClientTitleText[value] || value}
              saveFn={getUpdateClientFieldFn('title')}
              onSuccess={getFieldOnSuccessFn('title')}
              options={CLIENT_TITLE_OPTIONS}
              readOnly={!isEditable}
              errorField="title"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Field
              label={
                <>
                  Politically Exposed
                  <br />
                  Person (PEP)
                </>
              }
              statusProps={
                client.pepStatus?.pepCheckStatus
                  ? { value: client.pepStatus.pepCheckStatus }
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              label="AML check"
              statusProps={
                client.pepStatus?.amlCheckStatus
                  ? { value: client.pepStatus.amlCheckStatus }
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={2} textAlign="right">
            <PEPReviewButton client={client} />
            <PEPCheckTriggerButton client={client} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Field
              label={'Account Funded by'}
              content={client?.fundingSource ? fundingSource : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Expected Monthly Deposits"
              content={
                client?.monthlyIncome
                  ? `${formatCurrency(client.monthlyIncome)}${client.monthlyIncomeCurrency ? ` ${CURRENCY_SIGN[client.monthlyIncomeCurrency] || client.monthlyIncomeCurrency}` : ''}`
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              inputType="select"
              label="Identity type"
              value={idDocument?.docType}
              displayValue={value => DocumentSubTypeText[value] || value}
              saveFn={getUpdateIdDocumentFn('docType')}
              onSuccess={getDocumentFieldOnSuccessFn('docType')}
              options={idDocumentTypes}
              readOnly={!isEditable || !idDocument}
            />
          </Grid>

          <Grid item xs={6}>
            <EditableField
              label="Identity number"
              value={idDocument?.docNumber}
              saveFn={getUpdateIdDocumentFn('docNumber')}
              onSuccess={getDocumentFieldOnSuccessFn('docNumber')}
              readOnly={!isEditable || !idDocument}
              statusProps={
                idDocument?.docNumber
                  ? {
                      value: isIDVerified
                        ? GenericStatus.Verified
                        : GenericStatus.Unverified,
                    }
                  : undefined
              }
            />
          </Grid>
          {idDocument?.docType !== DocumentSubType.Nis && (
            <Grid item xs={6}>
              <EditableField
                inputType="date"
                label="Date of expiry"
                value={idDocument?.expiryDate}
                displayValue={value => formatDateValue(value, dateFormat)}
                saveFn={getUpdateIdDocumentFn('expiryDate')}
                onSuccess={getDocumentFieldOnSuccessFn('expiryDate')}
                readOnly={!isEditable}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              label="Phone number"
              content={getDisplayedPhone(client.phoneNumber)}
            />
          </Grid>
          <Grid item xs={12}>
            <Field label="Email" content={client.email} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Address"
              value={client.addressDetailed?.line1}
              saveFn={getUpdateAddressFieldFn('line1')}
              onSuccess={getAddressFieldOnSuccessFn('line1')}
              readOnly={!isEditable}
              errorField="addressDetailed.line1"
              textFieldProps={{
                inputProps: {
                  maxLength: ADDRESS_MAX_LENGTH.line1,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="City"
              value={client.addressDetailed?.city}
              saveFn={getUpdateAddressFieldFn('city')}
              onSuccess={getAddressFieldOnSuccessFn('city')}
              readOnly={!isEditable}
              errorField="addressDetailed.city"
              textFieldProps={{
                inputProps: {
                  maxLength: ADDRESS_MAX_LENGTH.city,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="State / Province"
              value={client.addressDetailed?.state}
              saveFn={getUpdateAddressFieldFn('state')}
              onSuccess={getAddressFieldOnSuccessFn('state')}
              readOnly={!isEditable}
              errorField="addressDetailed.state"
              textFieldProps={{
                inputProps: {
                  maxLength: ADDRESS_MAX_LENGTH.state,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="ZIP code"
              value={client.addressDetailed?.zip}
              saveFn={getUpdateAddressFieldFn('zip')}
              onSuccess={getAddressFieldOnSuccessFn('zip')}
              readOnly={!isEditable}
              errorField="addressDetailed.zip"
              textFieldProps={{
                inputProps: {
                  maxLength: ADDRESS_MAX_LENGTH.zip,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Country"
              value={client.country}
              displayValue={value => COUNTRY_NAME_BY_VALUE[value] || value}
              saveFn={getUpdateClientFieldFn('country')}
              onSuccess={getFieldOnSuccessFn('country')}
              inputType="select"
              options={COUNTRIES}
              readOnly={!isEditable}
              withSearchBar
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Tax residence"
              value={client.taxResidence}
              displayValue={value => COUNTRY_NAME_BY_VALUE[value] || value}
              saveFn={getUpdateClientFieldFn('taxResidence')}
              onSuccess={getFieldOnSuccessFn('taxResidence')}
              inputType="select"
              options={COUNTRIES}
              readOnly={!isEditable}
              errorField="country"
              withSearchBar
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="Citizenship"
              value={client.citizenship}
              displayValue={value => NATIONALITY_BY_VALUE[value] || value}
              saveFn={getUpdateClientFieldFn('citizenship')}
              onSuccess={getFieldOnSuccessFn('citizenship')}
              inputType="select"
              options={NATIONALITIES}
              readOnly={!isEditable}
              errorField="citizenship"
              withSearchBar
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="2nd citizenship"
              value={client.citizenship2}
              displayValue={value => NATIONALITY_BY_VALUE[value] || value}
              saveFn={getUpdateClientFieldFn('citizenship2')}
              onSuccess={getFieldOnSuccessFn('citizenship2')}
              inputType="select"
              options={NATIONALITIES}
              readOnly={!isEditable}
              errorField="citizenship2"
              withSearchBar
            />
          </Grid>
          <Grid item xs={6}>
            <EditableField
              label="US Green card or US Passport"
              value={
                client.usIndicia === false
                  ? OptionValue.No
                  : client.usIndicia
                    ? OptionValue.Yes
                    : undefined
              }
              displayValue={value => OptionValueText[value]}
              saveFn={getUpdateClientFieldFn('usIndicia')}
              onSuccess={getFieldOnSuccessFn('usIndicia')}
              inputType="select"
              options={YES_NO_OPTIONS}
              readOnly={!isEditable}
              errorField="usIndicia"
            />
          </Grid>
        </Grid>
      </DetailsCard>
    </>
  );
}
