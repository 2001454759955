import { Option } from '@/types';
import {
  FormHelperText,
  ListItemIcon,
  ListItemText,
  Select as MUISelect,
  Stack,
  Typography,
} from '@mui/material';
import { Icon } from '../../Icon';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DropdownOption,
  Label,
  MultipleSelectedOptions,
  OptionCheckIcon,
  SelectDrawerSearchBar,
  SelectedOption,
  SelectGroupName,
  StyledFormControl,
} from './Select.styled';
import { Controller, FieldValues } from 'react-hook-form';
import { getDataTestId } from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { SelectProps } from './Select.interface';
import { FieldArrow, FieldArrowButton } from '../Form.styled';
import { TextInput } from '../TextInput';
import { Chip } from '../../DataDisplay';

export function Select<FormPayload extends FieldValues>({
  options,
  control,
  name,
  rules,
  helperText,
  withSearchBar = false,
  color = 'default',
  renderMultipleSelectedOption,
  ...props
}: SelectProps<FormPayload>) {
  const { value: isOpen, setFalse: hide, setTrue: show } = useBoolean();
  const [search, setSearch] = useState('');
  const optionByValue: Record<string, Option> = useMemo(() => {
    return options.reduce(
      (acc, option) => ({
        ...acc,
        ['' + option.value]: option,
      }),
      {},
    );
  }, [options]);

  useEffect(() => {
    if (isOpen) setSearch('');
  }, [isOpen]);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const groups = useMemo(() => {
    if (search) {
      return [
        {
          name: '',
          order: 0,
          items: options.filter(
            item =>
              item.text?.toLowerCase()?.includes(search.toLowerCase()) ||
              String(item.value).toLowerCase().includes(search.toLowerCase()),
          ),
        },
      ];
    }

    const groupByName: Record<
      string,
      {
        name: string;
        order: number;
        items: Option[];
      }
    > = {};

    options.forEach(option => {
      const groupName = option.group || 'undefined';
      if (!groupByName[groupName]) {
        groupByName[groupName] = {
          name: groupName,
          order: option.groupOrder || 0,
          items: [],
        };
      }
      groupByName[groupName].items.push(option);
    });

    const result = Object.values(groupByName);
    result.sort((a, b) => b.order - a.order);

    return result;
  }, [options, search]);

  const renderOptions = useCallback(
    (currentValue: unknown) => {
      const components: ReactNode[] = [];

      groups.forEach(group => {
        if (groups.length > 1) {
          components.push(
            <SelectGroupName key={group.name}>
              {group.name === 'undefined' ? 'Other' : group.name}
            </SelectGroupName>,
          );
        }
        group.items.forEach(option => {
          const isOptionChecked = props.multiple
            ? (currentValue as string[]).includes(String(option.value))
            : currentValue === option.value;
          components.push(
            <DropdownOption
              key={option.value}
              disabled={option.disabled}
              value={option.value}
              onClick={props.multiple ? undefined : hide}
              multiple={props.multiple}
              {...getDataTestId(`select-option`)}
            >
              {option.icon && (
                <ListItemIcon>
                  <Icon name={option.icon} size={20} />
                </ListItemIcon>
              )}
              <ListItemText>
                <Typography
                  component="span"
                  variant={isOptionChecked ? 'body2Bold' : 'body2'}
                >
                  {option.text || option.value}
                </Typography>
              </ListItemText>
              {isOptionChecked && (
                <OptionCheckIcon>
                  <Icon
                    name="check-mini"
                    color={theme => theme.palette.primaries[500]}
                  />
                </OptionCheckIcon>
              )}
            </DropdownOption>,
          );
        });
      });

      return components;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groups],
  );

  const renderSelectValue = useCallback(
    (_value: unknown, defaultValue: unknown) => {
      const currentValue = _value || defaultValue;
      if (Array.isArray(currentValue)) {
        const valueTexts = currentValue.map(
          item => optionByValue[String(item)]?.text || String(item),
        );

        if (!valueTexts.length) {
          return (
            <Typography
              component="span"
              variant="body2"
              color={theme => theme.palette.grey[700]}
            >
              {props.placeholder}
            </Typography>
          );
        }

        return (
          <MultipleSelectedOptions {...getDataTestId(`select-option-selected`)}>
            {renderMultipleSelectedOption
              ? currentValue.map(value => renderMultipleSelectedOption(value))
              : valueTexts.map(value => <Chip key={value} label={value} />)}
          </MultipleSelectedOptions>
        );
      } else {
        const currentOption = optionByValue[String(currentValue)] || {};

        return (
          <SelectedOption {...getDataTestId(`select-option-selected`)}>
            {currentOption.icon && (
              <ListItemIcon>
                <Icon name={currentOption.icon} size={20} />
              </ListItemIcon>
            )}
            <ListItemText>
              <Typography component="span" variant="body2">
                {currentOption.text ||
                  currentOption.value ||
                  (!!currentValue && String(currentValue)) ||
                  props.placeholder}
              </Typography>
            </ListItemText>
          </SelectedOption>
        );
      }
    },
    [optionByValue, props.placeholder, renderMultipleSelectedOption],
  );

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, formState }) => (
        <StyledFormControl fullWidth={props.fullWidth} error={props.error}>
          {props.label && <Label error={props.error}>{props.label}</Label>}
          <MUISelect
            {...getDataTestId(`${name}-select`)}
            displayEmpty
            className={color}
            fullWidth
            onChange={onChange}
            defaultValue={formState.defaultValues?.[name]}
            onBlur={onBlur}
            value={value}
            open={isOpen}
            onOpen={show}
            onClose={hide}
            IconComponent={() => (
              <FieldArrowButton
                onClick={isOpen ? hide : show}
                disabled={props.disabled}
              >
                <FieldArrow name={isOpen ? 'arrow-up' : 'arrow-down'} />
              </FieldArrowButton>
            )}
            renderValue={selected =>
              renderSelectValue(selected, formState.defaultValues?.[name])
            }
            {...props}
          >
            {withSearchBar && (
              <SelectDrawerSearchBar className="SelectDrawerSearchBar">
                <TextInput
                  template="search"
                  value={search}
                  onKeyDown={e => e.stopPropagation()}
                  onClick={e => e.stopPropagation()}
                  autoFocus
                  onChange={onSearchChange}
                />
              </SelectDrawerSearchBar>
            )}
            {renderOptions(value)}
          </MUISelect>
          {helperText && (
            <>
              {props.error ? (
                <FormHelperText error>
                  <Stack gap={0.5} component="span">
                    <Icon
                      name="alert"
                      size={20}
                      color={theme => theme.palette.error.main}
                    />
                    {helperText}
                  </Stack>
                </FormHelperText>
              ) : (
                <FormHelperText>{helperText}</FormHelperText>
              )}
            </>
          )}
        </StyledFormControl>
      )}
    />
  );
}
