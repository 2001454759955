import { Avatar } from './Avatar';
import { MouseEvent, useState } from 'react';
import { AvatarButton } from './User.styled';
import { UserMenu } from './UserMenu';
import { UserMenuOption } from './User.interface';
import { AvatarModal } from './AvatarModal';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/constants';
import { getDataTestId } from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { User } from '@/types';
import { signOut } from '@/api';

type LoggedInUserButtonProps = {
  user: User;
};

export function LoggedInUserButton({ user }: LoggedInUserButtonProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    value: isChangingAvatar,
    setTrue: showAvatarModal,
    setFalse: hideAvatarModal,
  } = useBoolean();

  const onTriggerButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onOptionClick = (option: UserMenuOption) => {
    switch (option) {
      case 'change-avatar':
        showAvatarModal();
        break;
      case 'change-password':
        navigate(ROUTE.CHANGE_PASSWORD);
        break;
      case 'sign-out':
        signOut();
        break;
    }
    onClose();
  };

  return (
    <>
      <AvatarButton
        onClick={onTriggerButton}
        {...getDataTestId('user-header-avatar')}
      >
        <Avatar src={user.picture} dotVariant="dark-border">
          {user.fullName}
        </Avatar>
      </AvatarButton>
      <UserMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        onOptionClick={onOptionClick}
        loggedInUser={user}
      />
      <AvatarModal isOpen={isChangingAvatar} onClose={hideAvatarModal} />
    </>
  );
}
