import { Box, Typography, useTheme } from '@mui/material';
import { LinearProgress } from './DataDisplay.styled';
import { ColorExtractFn } from '@/types';

type ProgressBarProps = {
  color?: ColorExtractFn;
  bgColor?: ColorExtractFn;
  value: number;
  total: number;
  currentText?: (
    currentValue: number,
    percentage: number,
    total: number,
  ) => string;
  totalText?: (currentValue: number) => string;
};

export function ProgressBar({
  color,
  bgColor,
  value,
  total,
  currentText,
  totalText,
}: ProgressBarProps) {
  const theme = useTheme();

  const percentage = Math.round(total ? (value / total) * 100 : 0);

  return (
    <Box>
      <Box mb={1}>
        {currentText && (
          <Typography variant="caption2Bold">
            {currentText(value, percentage, total)}
            {totalText && (
              <Typography
                variant="caption2Bold"
                component="span"
                color={theme => theme.palette.grey[600]}
              >
                /{totalText(total)}
              </Typography>
            )}
          </Typography>
        )}
      </Box>
      <LinearProgress
        variant="determinate"
        valueColor={color?.(theme)}
        bgColor={bgColor?.(theme)}
        value={percentage > 100 ? 100 : percentage}
      />
    </Box>
  );
}
