import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { SigningDocument } from '@/types';
import {
  fetchLocalDocumentFromS3,
  fetchLocalDocumentFromServer,
  fetchLocalDocumentFromUrl,
  fetchSigningForms,
  setLocalDocument,
} from './actions';

interface DocumentsState {
  signingForms: SigningDocument[];
  documentResource: Record<
    string,
    {
      uri: string;
      localUrl?: string;
      isLoading: boolean;
      isError?: boolean;
    }
  >;
}

const initialState: DocumentsState = {
  signingForms: [],
  documentResource: {},
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSigningForms.fulfilled, (state, { payload }) => {
      state.signingForms = payload || [];
    });

    builder.addCase(
      setLocalDocument,
      (state, { payload: { uri, localUrl } }) => {
        state.documentResource[uri] = {
          uri,
          localUrl,
          isLoading: false,
          isError: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(
        fetchLocalDocumentFromS3.pending,
        fetchLocalDocumentFromServer.pending,
        fetchLocalDocumentFromUrl.pending,
      ),
      (state, { meta: { arg } }) => {
        state.documentResource[arg] = {
          isLoading: true,
          uri: arg,
          localUrl: undefined,
          isError: undefined,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchLocalDocumentFromS3.fulfilled,
        fetchLocalDocumentFromServer.fulfilled,
        fetchLocalDocumentFromUrl.fulfilled,
      ),
      (state, { meta: { arg }, payload: localUrl }) => {
        state.documentResource[arg] = {
          ...state.documentResource[arg],
          isLoading: false,
          isError: false,
          localUrl,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchLocalDocumentFromS3.rejected,
        fetchLocalDocumentFromServer.rejected,
        fetchLocalDocumentFromUrl.rejected,
      ),
      (state, { meta: { arg } }) => {
        state.documentResource[arg] = {
          uri: arg,
          isLoading: false,
          isError: true,
          localUrl: undefined,
        };
      },
    );
  },
});

export const documentsReducer = documentsSlice.reducer;
