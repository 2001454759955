import { InputValueHelperRule } from '@/types';

export const UserDBFields: Record<string, string> = {
  updatedAt: 'updated_at',
  firstName: 'first_name',
};

export const PASSWORD_RULES: InputValueHelperRule[] = [
  {
    text: 'At least 8 characters',
    isMatched: value => !!value && String(value).length >= 8,
  },
  {
    text: 'Include uppercase, lowercase characters and numbers',
    isMatched: value =>
      !!value &&
      new RegExp(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{3,}/g).test(
        String(value),
      ),
  },
  {
    text: 'Include special characters',
    isMatched: value =>
      !!value &&
      new RegExp(/[#?!@$%^&*\-.[\]{}()"/\\,><':;|_~`+=]+/g).test(String(value)),
  },
];
