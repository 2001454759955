import {
  COUNTRY_NAME_BY_VALUE,
  defaultConfigs,
  DocumentTypeText,
  MIME_EXCEL_PATTERN,
  MIME_IMAGE_PATTERN,
  MIME_PDF_PATTERN,
  URL_PATTERN,
} from '@/constants';
import { Client, DocumentType } from '@/types';
import { Path, createSearchParams } from 'react-router-dom';
import { getDisplayField } from './api';

export function getAvatarLetters(name: string) {
  if (!name || name.length === 1) return name;

  const words = name.split(' ').filter(i => !!i);

  if (words.length === 1) {
    return `${name[0]}${name[1]}`;
  }

  return `${words[0][0]}${words[1][0]}`;
}

export function getNavigateObject(
  path: string,
  params?: {
    id?: string | number;
    query?: Record<string, string>;
  },
): Partial<Path> {
  return {
    pathname: `${path}${params?.id ? '/' + params?.id : ''}`,
    ...(params?.query
      ? { search: createSearchParams(params?.query).toString() }
      : {}),
  };
}

export function getClientAddress(client: Client) {
  const address = client.address?.replace('/,|\\.$/', '');
  return (
    address +
    ', ' +
    (COUNTRY_NAME_BY_VALUE[client.country || ''] || client.country)
  );
}

export function isImageContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_IMAGE_PATTERN, 'g').test(contentType);
}

export function isPDFContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_PDF_PATTERN, 'g').test(contentType);
}

export function isExcelContentType(contentType?: string) {
  if (!contentType) return false;

  return new RegExp(MIME_EXCEL_PATTERN, 'g').test(contentType);
}

export function getDisplayedPhone(phone?: string) {
  if (!phone) return '';

  return phone.startsWith('+') ? phone : `+${phone}`;
}

export function htmlizeText(text: string) {
  return text
    .replaceAll(/(\r?\n)/g, '<br />')
    .replaceAll(
      new RegExp(URL_PATTERN, 'g'),
      '<a target="_blank" href="$&">$&</a>',
    );
}

export function replaceRequestDocumentTemplate({
  message,
  client,
  documentType,
}: {
  message: string;
  client?: Client;
  documentType: DocumentType;
}) {
  return message
    .replaceAll(
      '{client_name}',
      getDisplayField(client, {
        nameOnly: true,
      }),
    )
    .replaceAll(/\s+,/g, ',')
    .replaceAll('{document}', DocumentTypeText[documentType]);
}

export function formatCurrency(
  amount: number,
  options: {
    locale?: string;
    currency?: string;
  } = {},
) {
  return amount.toLocaleString(options.locale || defaultConfigs.locale, {
    style: 'decimal',
    currency: options.currency || defaultConfigs.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}
