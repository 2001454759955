import { ReactNode } from 'react';
import { FormHeaderContainer, FormHeaderDivider } from './Layout.styled';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { Icon } from '../Icon';
import { getDataTestId } from '@/utils';

type FormHeaderProps = {
  title: ReactNode;
  onClose?: () => void;
  disabled?: boolean;
  withDivider?: boolean;
} & Omit<BoxProps, 'title'>;

export function FormHeader({
  title,
  disabled,
  onClose,
  withDivider,
  ...props
}: FormHeaderProps) {
  return (
    <Box {...props}>
      <FormHeaderContainer className="FormHeader">
        <Typography variant="h4">{title}</Typography>
        {onClose && (
          <IconButton
            onClick={() => onClose()}
            disabled={disabled}
            {...getDataTestId('close-button')}
          >
            <Icon name="close" color={theme => theme.palette.grey[700]} />
          </IconButton>
        )}
      </FormHeaderContainer>
      {withDivider && <FormHeaderDivider className="FormDivider" />}
    </Box>
  );
}
