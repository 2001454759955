export const ISO_CODE: Record<string, string> = {
  bvi: 'VGB',
  cayman: 'CYM',
  'sint-maarten': 'SXM',
  anguilla: 'AIA',
  barbados: 'BRB',
  dominica: 'DMA',
  ghana: 'GHA',
  grenada: 'GRD',
  guyana: 'GUY',
  'saint-kitts-and-nevis': 'KNA',
  'saint-lucia': 'LCA',
  'saint-vincent-and-the-grenadines': 'VCT',
  suriname: 'SUR',
  'trinidad-and-tobago': 'TTO',
};

export const DATE_FORMAT_TEMPLATE = 'dd/MM/yyyy';
