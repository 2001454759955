import { BoxProps, Stack, TextFieldProps, Typography } from '@mui/material';
import {
  FullHeightTextFieldBorder,
  FullHeightTextFieldContainer,
  FullHeightTextarea,
} from './Form.styled';
import { ChangeEventHandler, FocusEventHandler, useCallback } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { getDataTestId } from '@/utils';
import { Icon } from '../Icon';

type FullHeightTextFieldProps<FormPayload extends FieldValues> = {
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  control?: Control<FormPayload>;
  name?: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
} & Omit<BoxProps, 'onChange' | 'onBlur'>;

export function FullHeightTextField<FormPayload extends FieldValues>({
  placeholder,
  value,
  onChange,
  onBlur,
  control,
  name,
  rules,
  disabled,
  error,
  helperText,
  ...props
}: FullHeightTextFieldProps<FormPayload>) {
  const render = useCallback(
    ({
      value,
      onChange,
      onBlur,
      defaultValue,
      isDirty,
    }: {
      value: string;
      onChange: TextFieldProps['onChange'];
      onBlur: TextFieldProps['onBlur'];
      defaultValue?: string;
      isDirty?: boolean;
    }) => (
      <FullHeightTextFieldContainer {...props}>
        <FullHeightTextFieldBorder error={error}>
          <FullHeightTextarea
            {...getDataTestId(`${name}full-height-textarea`)}
            placeholder={placeholder}
            value={value || isDirty ? value : defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </FullHeightTextFieldBorder>
        {helperText && error ? (
          <Stack gap={0.5} component="span">
            <Icon
              name="alert"
              size={20}
              color={theme => theme.palette.error.main}
            />
            <Typography
              variant="captionBold"
              color={theme => theme.palette.error.main}
            >
              {helperText}
            </Typography>
          </Stack>
        ) : helperText ? (
          <Typography variant="captionBold">{helperText}</Typography>
        ) : null}
      </FullHeightTextFieldContainer>
    ),
    [disabled, error, helperText, name, placeholder, props],
  );

  if (control && name) {
    return (
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { isDirty },
          formState: { defaultValues },
        }) =>
          render({
            value,
            onChange,
            onBlur,
            defaultValue: defaultValues?.[name],
            isDirty,
          })
        }
      />
    );
  }

  return render({ value: value || '', onChange, onBlur });
}
