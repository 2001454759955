import { AbilityAction, AbilitySubject, PermissionName, User } from '@/types';
import { AbilityBuilder, PureAbility, AbilityClass } from '@casl/ability';

export type AppAbility = PureAbility<[AbilityAction, AbilitySubject]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

export default function defineRulesFor(user: User) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  const permissions = user.permissions || [];

  if (permissions.includes(PermissionName.ClientAssignBulk)) {
    can('assign', 'onboarding_application');
  }
  if (permissions.includes(PermissionName.ClientAssignSelf)) {
    can('assign', 'onboarding_application_self');
  }
  if (permissions.includes(PermissionName.ClientUnassign)) {
    can('unassign', 'onboarding_application');
  }
  if (permissions.includes(PermissionName.ClientUnassignSelf)) {
    can('unassign', 'onboarding_application_self');
  }
  if (permissions.includes(PermissionName.ClientViewNotes)) {
    can('view', 'onboarding_application_notes');
  }
  if (permissions.includes(PermissionName.ClientEditNotes)) {
    can('edit', 'onboarding_application_notes');
  }
  if (permissions.includes(PermissionName.ClientEdit)) {
    can('edit', 'onboarding_application');
  }
  if (permissions.includes(PermissionName.ClientApprove)) {
    can('review', 'onboarding_application');
  }

  if (permissions.includes(PermissionName.DocumentApprove)) {
    can('review', 'document');
  }
  if (permissions.includes(PermissionName.DocumentEdit)) {
    can('edit', 'document');
  }

  if (permissions.includes(PermissionName.DocumentView)) {
    can('view', 'document');
  }

  if (permissions.includes(PermissionName.DocumentRequest)) {
    can('request', 'document');
  }

  if (permissions.includes(PermissionName.ClientView)) {
    can('view', 'client');
  }

  if (permissions.includes(PermissionName.ClientExport)) {
    can('export', 'client');
  }

  if (permissions.includes(PermissionName.AuditView)) {
    can('view', 'report');
  }

  if (
    permissions.includes(PermissionName.UserView) ||
    permissions.includes(PermissionName.RoleView)
  ) {
    can('view', 'administration');
  }

  if (permissions.includes(PermissionName.UserView)) {
    can('view', 'administration_users');
  }

  if (permissions.includes(PermissionName.RoleView)) {
    can('view', 'administration_roles');
  }

  if (permissions.includes(PermissionName.UserAdd)) {
    can('add', 'administration_users');
  }

  if (permissions.includes(PermissionName.ScheduleJobView)) {
    can('view', 'schedule_job');
  }

  if (permissions.includes(PermissionName.ScheduleJobRun)) {
    can('run', 'schedule_job');
  }

  if (permissions.includes(PermissionName.RoleEdit)) {
    can('edit', 'administration_roles');
  }

  if (permissions.includes(PermissionName.UserEdit)) {
    can('edit', 'administration_users');
  }

  return rules;
}

export function buildAbilityFor(user: User): AppAbility {
  return new AppAbility(defineRulesFor(user));
}
