import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from './redux';
import {
  fetchLocalDocumentFromS3,
  fetchLocalDocumentFromServer,
  fetchLocalDocumentFromUrl,
  selectLocalDocument,
} from '@/redux';
import { Document } from '@/types';
import { useDownloadDocument } from './useDownloadDocument';
import { VITE_BASE_API_URL } from '@/constants';

export function useDocument({
  document,
  disabledCache,
}: { document?: Document; disabledCache?: boolean } = {}) {
  const data = useSelector(selectLocalDocument(document?.uri));
  const dispatch = useDispatch();
  const downloadDocument = useDownloadDocument({ disabledCache });

  useEffect(() => {
    if (data?.isLoading || (data?.localUrl && !disabledCache)) {
      return;
    }

    if (document?.uri) {
      if (document.uri.startsWith('s3://')) {
        dispatch(fetchLocalDocumentFromS3(document.uri));
      } else if (document.uri.startsWith(VITE_BASE_API_URL)) {
        dispatch(fetchLocalDocumentFromServer(document.uri));
      } else {
        dispatch(fetchLocalDocumentFromUrl(document.uri));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const download = useCallback(() => {
    if (document) {
      downloadDocument(document);
    }
  }, [document, downloadDocument]);

  if (!document) {
    return {
      data: undefined,
      download: () => {},
    };
  }

  return {
    data,
    download,
  };
}
