import { IconName, Option } from './components';

export type AppConfigurations = {
  locale: string;
  isoCode: string;
  dateFormat: string;
  name: string;
  flag?: IconName;
  currency: string;
  confirmationOfAddressRequired: boolean;
  idDocumentTypes: Option[];
};

export enum FeatureName {
  SecondID = 'second_id',
  LivenessSystem = 'liveness_system',
}

export type Feature = {
  id: string;
  feature: FeatureName;
  value: string;
  enabled: boolean;
};
