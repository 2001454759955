import {
  CenterSlot,
  Container,
  HeaderIcon,
  LeftSlot,
  NavBadge,
  NavLink,
  RightSlot,
} from './Header.styled';
import { ROUTE } from '@/constants';
import { useLocation } from 'react-router-dom';
import { LoggedInUserButton } from '../User';
import { IconButton, Typography } from '@mui/material';
import { DotBadge } from '../DataDisplay';
import { useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import { getDataTestId } from '@/utils';
import { Can } from '../Can';
import { BoundCanProps } from '@casl/react';
import { AppAbility } from '@/utils/defineAbility';
import { Fragment } from 'react';
import { TenantSelect } from './TenantSelect';

const navItems = [
  {
    to: ROUTE.HOME,
    text: 'Home',
    isComingSoon: false,
  },
  {
    to: ROUTE.CLIENTS,
    text: 'Clients',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'client',
    },
  },
  {
    to: ROUTE.DOCUMENTS,
    text: 'Documents',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'document',
    },
  },
  {
    to: ROUTE.ACTIVITY_LOGS,
    text: 'Activity Logs',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'report',
    },
  },
  // {
  //   to: ROUTE.REPORTS,
  //   text: 'Reports',
  //   isComingSoon: false,
  // },
  {
    to: ROUTE.ADMINISTRATION,
    text: 'Administration',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'administration',
    },
  },
];

export function Header() {
  const location = useLocation();
  const user = useSelector(selectLoggedInUser);

  if (!user) {
    return null;
  }

  const renderNavItems = (to: string, text: string, isComingSoon: boolean) =>
    isComingSoon ? (
      <Typography
        {...getDataTestId(`nav-item-${to === ROUTE.HOME ? 'home' : to}`)}
        variant="body1Medium"
        color={theme => theme.palette.grey[500]}
      >
        {text}
        <NavBadge label="Coming soon" ml={1} />
      </Typography>
    ) : (
      <NavLink
        to={to}
        {...getDataTestId(`nav-item-${to === ROUTE.HOME ? 'home' : to}`)}
        isHighlighted={
          to === '/'
            ? location.pathname === to
            : location.pathname.startsWith(to)
        }
      >
        {text}
      </NavLink>
    );
  return (
    <Container>
      <LeftSlot>
        <HeaderIcon name="logo" size={44} />
        <TenantSelect user={user} />
      </LeftSlot>
      <CenterSlot>
        {navItems.map(({ to, text, isComingSoon, permission }) =>
          permission ? (
            <Can key={to} {...(permission as BoundCanProps<AppAbility>)}>
              {renderNavItems(to, text, isComingSoon)}
            </Can>
          ) : (
            <Fragment key={to}>
              {renderNavItems(to, text, isComingSoon)}
            </Fragment>
          ),
        )}
      </CenterSlot>
      <RightSlot>
        <IconButton>
          <HeaderIcon name="search" />
        </IconButton>
        <IconButton>
          <HeaderIcon name="question-circle" />
        </IconButton>
        <IconButton>
          <DotBadge darkBorder>
            <HeaderIcon name="bell" />
          </DotBadge>
        </IconButton>
        <LoggedInUserButton user={user} />
      </RightSlot>
    </Container>
  );
}
