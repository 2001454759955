import {
  PermissionDetails,
  PermissionName,
  RoleDetails,
  UserRole,
} from '@/types';
import { axios } from './axios';
import { REQUEST } from '@/constants';

export const getUserRoles = async () => {
  const result = await axios.get<UserRole[]>(REQUEST.ROLES.ROOT);
  return result.data;
};

export const getPermissions = async () => {
  const result = await axios.get<PermissionDetails[]>(
    REQUEST.ROLES.PERMISSIONS,
  );

  // @TODO: remove this filter
  return (result.data || []).filter(
    permission => permission.name !== PermissionName.DocumentEdit,
  );
};

export const getRoles = async (id: string) => {
  const result = await axios.get<RoleDetails>(REQUEST.ROLES.ROLE_DETAIL(id));

  return result.data;
};

export const updateRolePermissions = async ({
  id,
  permissions,
}: {
  id: string;
  permissions: string[];
}) => {
  const result = await axios.put(
    REQUEST.ROLES.UPDATE_ROLE_PERMISSIONS(id),
    permissions,
  );

  return result.data;
};
