import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { standardizeDocumentsToSign } from '@/utils';
import { AccountTypeDetails } from '@/types';

export const selectDocumentsState = createSelector(
  (state: RootState) => state.documents,
  data => ({ ...data }),
);

export const selectSigningDocuments = (
  clientAccountTypes?: AccountTypeDetails[],
) =>
  createSelector(
    (state: RootState) => state.documents,
    data =>
      standardizeDocumentsToSign(data.signingForms, clientAccountTypes || []),
  );

export const selectLocalDocuments = createSelector(
  (state: RootState) => state.documents,
  data => data.documentResource,
);

export const selectLocalDocument = (uri?: string) =>
  createSelector(
    (state: RootState) => state.documents.documentResource,
    data => (uri ? data[uri] : null),
  );
