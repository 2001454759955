import { Components, createTheme } from '@mui/material';
import { typographyConfigs } from '../typography';
import { paletteConfigs } from '../palette';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const avatarConfigs: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.primaries[400],
      fontSize: theme.typography.body2Bold.fontSize,
      fontWeight: theme.typography.body2Bold.fontWeight,
      textTransform: 'uppercase',
    },
  },
};
