import { Components, createTheme } from '@mui/material';
import { typographyConfigs } from '../typography';
import { paletteConfigs } from '../palette';
import { elevationLevel } from '../constants';
import { spacing } from '../constants/layout';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const accordionConfigs: Components['MuiAccordion'] = {
  defaultProps: {
    elevation: elevationLevel.s,
  },
  styleOverrides: {
    root: {
      borderRadius: 12,
      '::before': {
        display: 'none',
      },

      '.MuiAccordionSummary-root': {
        borderBottom: `1px solid transparent`,
        transition: theme.transitions.create('border-color'),
        padding: spacing * 2,
        margin: 0,
        minHeight: 0,

        '.MuiAccordionSummary-content': {
          margin: 0,
        },

        '&.Mui-expanded': {
          margin: 0,
          minHeight: 0,
          borderColor: theme.palette.grey[300],
        },
      },

      '.MuiAccordionDetails-root': {
        padding: spacing * 2,
      },

      '&.Mui-expanded': {
        margin: 0,
        minHeight: 0,
      },
    },
  },
};
