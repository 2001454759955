import {
  DocumentAccordion,
  Heading,
  ProgressSteps,
  Status,
} from '@/components';
import { useClientCalculation, useDispatch, useSelector } from '@/hooks';
import { selectClientDetails, showDocumentReviewDrawer } from '@/redux';
import { Document, DocumentType } from '@/types';
import { Stack, Typography } from '@mui/material';
import { BasicInfoAccordion } from './BasicInfoAccordion';
import { AdditionalDocumentAccordion } from './AdditionalDocumentAccordion';
import { DocumentSubTypeText } from '@/constants';
import { formatTime } from '@/utils';

export function SectionOnboarding() {
  const client = useSelector(selectClientDetails);
  const dispatch = useDispatch();

  const { documentsGroups, documentsByType, onboardProgress } =
    useClientCalculation();

  const onReviewClick = (document: Document) =>
    dispatch(
      showDocumentReviewDrawer({
        client,
        document,
      }),
    );

  const clientDocumentGroups = documentsGroups.filter(
    item =>
      item.type !== DocumentType.Internal &&
      item.type !== DocumentType.Generated,
  );

  const internalDocumentGroup = documentsByType[DocumentType.Internal];
  const generatedDocumentGroup = documentsByType[DocumentType.Generated];

  return (
    <Stack direction="column" alignItems="stretch" gap={2}>
      <Heading
        isSubSection
        title="Onboarding"
        mb={0}
        actionSlot={
          <ProgressSteps
            width={240}
            value={onboardProgress.finished}
            total={onboardProgress.total}
          />
        }
      />
      <BasicInfoAccordion />
      {clientDocumentGroups.map(item => (
        <DocumentAccordion
          key={item.type}
          title={item.title}
          status={item.generalStatus}
          description={item.description}
          documents={item.items}
          onReviewClick={onReviewClick}
          disabled={!item.items.length}
          renderDocumentItemTitle={
            item.type === DocumentType.DocumentSign
              ? doc => (
                  <>
                    <Typography variant="body2Medium" mb={0.75}>
                      {doc.formName ||
                        DocumentSubTypeText[doc.docType as string] ||
                        doc.docType ||
                        'Document'}
                    </Typography>
                    <Status size="small" value={doc.status} display="block" />
                  </>
                )
              : undefined
          }
        />
      ))}
      {client?.eligible === false && (
        <AdditionalDocumentAccordion
          client={client}
          documents={internalDocumentGroup?.items || []}
        />
      )}
      {generatedDocumentGroup?.items.length && (
        <DocumentAccordion
          title={generatedDocumentGroup.title}
          status={generatedDocumentGroup.generalStatus}
          description={generatedDocumentGroup.description}
          documents={generatedDocumentGroup.items}
          disableDocumentCache
          onReviewClick={onReviewClick}
          renderDocumentItemTime={item => (
            <>
              <Typography
                variant="caption"
                color={theme => theme.palette.grey[700]}
              >
                Last updated
              </Typography>
              <Typography variant="body2">
                {formatTime(item.updatedAt)}
              </Typography>
            </>
          )}
        />
      )}
    </Stack>
  );
}
