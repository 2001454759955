import { Box, keyframes, styled } from '@mui/material';
import { Icon } from './Icon';
import { doNotForwardTheseProps } from '@/styles';

const spinAnim = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(Icon)(() => ({
  animation: `${spinAnim} linear 2.5s infinite`,
  display: 'inline-block',
  transformOrigin: 'center',
}));

export const LoopDotsContainer = styled('span')(() => ({
  display: 'inline-block',
  width: 16,
}));

export const DecorateCell = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('color', 'size'),
})<{ color?: string; size?: number }>(({ theme, color, size = 96 }) => ({
  height: Math.sqrt((size * size) / 2) * 2,
  width: Math.sqrt((size * size) / 2) * 2,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',

  '::before': {
    content: '""',
    backgroundColor: color || theme.palette.primaries[400],
    borderRadius: 20,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    height: size,
    width: size,
  },

  '.MuiSvgIcon-root': {
    zIndex: 0,
  },
}));
