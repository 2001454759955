import { AbilityContext, Heading, Loader, LoadingButton } from '@/components';
import { RoleNameText, ROUTE } from '@/constants';
import { useDispatch } from '@/hooks';
import { getDataTestId } from '@/utils';
import { Button, Container, Stack, Tab, Tabs } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TabPermissionSettings from './TabPermissionSettings';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fetchRoleDetails,
  resetRoleDetails,
  selectRoleDetail,
  updateInitialRoleDetailPermission,
} from '@/redux/role-details';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { updateRolePermissions } from '@/api';
import { showToast } from '@/redux';
import UsersTab from './UsersTab';
import { useBoolean } from 'usehooks-ts';

export function RoleDetail() {
  const { id } = useParams();
  const search = useLocation().search;
  const activeTab = new URLSearchParams(search).get('activeTab');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);
  const { roleDetails, initialized, initialPermissions } =
    useSelector(selectRoleDetail);
  const [tab, setTab] = useState(0);
  const { value: isDirty, setFalse, setTrue } = useBoolean(false);
  useEffect(() => {
    activeTab === 'user' && setTab(1);
  }, [activeTab]);

  useEffect(() => {
    dispatch(fetchRoleDetails(String(id)));

    return () => {
      dispatch(resetRoleDetails());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (roleDetails && initialPermissions) {
      if (
        JSON.stringify(roleDetails.permissions.slice().sort()) ===
        JSON.stringify(initialPermissions.slice().sort())
      ) {
        setFalse();
      } else {
        setTrue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleDetails, initialPermissions]);

  const { mutate, isSuccess, error, reset, data, isPending } = useMutation({
    mutationFn: updateRolePermissions,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateInitialRoleDetailPermission(roleDetails?.permissions || []),
      );
      dispatch(
        showToast({
          message: data.message,
          severity: 'success',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: 'Error while updating permissions.',
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onTabChange = (_: SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };
  const goBack = () => navigate(ROUTE.ADMINISTRATION);
  const onSave = () => {
    reset();
    mutate({
      id: String(id),
      permissions: roleDetails?.permissions || [],
    });
  };

  const renderTab = useCallback(() => {
    switch (tab) {
      case 0:
        return <TabPermissionSettings roleName={String(roleDetails?.name)} />;
      case 1:
        return <UsersTab roleDetails={roleDetails} />;
    }
  }, [tab, roleDetails]);

  if (!initialized) {
    return (
      <Container>
        <Loader justifyContent="center" />
      </Container>
    );
  }

  return (
    <Container>
      <Heading
        onBack={goBack}
        title={RoleNameText[String(roleDetails?.name)]}
        actionSlot={
          <Stack gap={1}>
            <Button
              {...getDataTestId(`cancel-role-detail`)}
              variant="outlined"
              onClick={goBack}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              {...getDataTestId(`save-role-detail`)}
              onClick={onSave}
              disabled={isPending || !isDirty}
            >
              Save
            </LoadingButton>
          </Stack>
        }
      />
      <Tabs
        {...getDataTestId(`role-detail-tab-bar`)}
        value={tab}
        onChange={onTabChange}
      >
        {ability.can('view', 'administration_roles') && (
          <Tab
            {...getDataTestId(`tab-role-detail-permission-settings`)}
            label={`Permissions & Settings`}
            value={0}
          />
        )}
        {ability.can('view', 'administration_users') && (
          <Tab
            {...getDataTestId(`tab-role-detail-users`)}
            label={'Users'}
            value={1}
          />
        )}
      </Tabs>
      {renderTab()}
    </Container>
  );
}
