import { AppConfigurations, DocumentSubType } from '@/types';
import { defaultConfigs } from './default';
import { DocumentSubTypeText } from '../mapper';

export const grenadaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-GD',
  isoCode: 'GRD',
  currency: 'XCD',
  name: 'Grenada',
  flag: 'flag-grenada',
  confirmationOfAddressRequired: true,
  idDocumentTypes: [
    {
      value: DocumentSubType.Id,
      text: DocumentSubTypeText[DocumentSubType.Id],
    },
    {
      value: DocumentSubType.Passport,
      text: DocumentSubTypeText[DocumentSubType.Passport],
    },
    {
      value: DocumentSubType.Nis,
      text: DocumentSubTypeText[DocumentSubType.Nis],
    },
  ],
};
