import { getUsers, toggleUserStatus, updateUserRoles } from '@/api';
import {
  Can,
  ClientBlock,
  Filter,
  Icon,
  MenuButton,
  SectionCard,
  SectionCardCallout,
  Select,
  Status,
  Table,
  TextInput,
} from '@/components';
import {
  DEFAULT_PAGINATION,
  RoleNameText,
  USER_ROLE_TYPES,
  USER_STATUSES,
} from '@/constants';
import { useDispatch, useForm, useSelector, useURLParams } from '@/hooks';
import {
  clearModalRefreshState,
  selectAddUserModalState,
  showChangeUserRoleDrawer,
  showToast,
  showUpdateUserDrawer,
} from '@/redux';
import { fetchUserRoles, selectUserRoles } from '@/redux/users';
import { fontWeight } from '@/styles';
import { ModalName, Option, RoleDetails, User, UserStatus } from '@/types';
import { getAPIErrorMessage, getDataTestId, getDisplayField } from '@/utils';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';

type UserTabProps = {
  isAdministration?: boolean;
  selectedGroup?: string;
  roleDetails?: RoleDetails;
};

type PagedData = {
  pages: {
    data: User[];
    pagination: {
      size: number;
      page: number;
    };
  }[];
};
const UsersTab = ({ isAdministration, roleDetails }: UserTabProps) => {
  const { updateSearchParams, getValue } = useURLParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userRoles = useSelector(selectUserRoles);
  const { shouldRefreshData } = useSelector(selectAddUserModalState);

  const userRoleOptions: Option[] = userRoles.roles.map(role => ({
    value: role.id,
    text: role.name,
  }));

  const { control, setValue } = useForm({
    defaultValues: {
      userIds: getValue('userIds', 'array') as string[],
      role: roleDetails
        ? [roleDetails?.name]
        : (getValue('role', 'array') as string[]),
      status: getValue('status', 'array') as string[],
      sortBy: (getValue('sortBy', 'string') || 'firstName') as string,
      keyword: getValue('keyword', 'string') as string,
    },
  });
  const filter = useWatch({ control });
  const modifiedFilter = useMemo(
    () => ({
      ...filter,
      keyword: filter.keyword?.trim() || '',
    }),
    [filter],
  );
  const [debouncedFilter] = useDebounceValue(modifiedFilter, 500);

  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    isFetched,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['users', debouncedFilter],
    queryFn: ({ pageParam }) =>
      getUsers({
        pagination: { ...DEFAULT_PAGINATION, page: pageParam },
        query: debouncedFilter.keyword?.trim(),
        sort: debouncedFilter.sortBy,
        filter: {
          status: debouncedFilter.status?.map(i => String(i)) || [],
          role: debouncedFilter.role?.map(i => String(i)) || [],
        },
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      return lastPage.data.length < lastPage.pagination.size
        ? null
        : lastPage.pagination.page !== undefined
          ? lastPage.pagination.page + 1
          : 0;
    },
    gcTime: 0,
  });

  const users = data?.pages.reduce(
    (acc, i) => [...acc, ...i.data],
    [] as User[],
  );

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
  });

  useEffect(() => {
    if (isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, isIntersecting, isFetched]);

  useEffect(() => {
    updateSearchParams(debouncedFilter);
  }, [debouncedFilter, updateSearchParams]);

  useEffect(() => {
    if (shouldRefreshData) {
      refetch();
      dispatch(clearModalRefreshState(ModalName.AddUser));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshData]);

  const { mutate: updateStatus } = useMutation({
    mutationFn: toggleUserStatus,
    onSuccess: (response, request) => {
      queryClient.setQueryData<PagedData>(
        ['users', debouncedFilter],
        oldData => {
          if (!oldData || !oldData.pages) {
            return oldData;
          }

          const updatedData = oldData.pages.map(page => ({
            ...page,
            data: page.data.map(user =>
              user.id === request.id
                ? {
                    ...user,
                    status: request.payload.status,
                  }
                : user,
            ),
          }));

          return {
            ...oldData,
            pages: updatedData,
          };
        },
      );

      dispatch(
        showToast({
          message: response.message,
          severity: 'success',
        }),
      );
    },
    onError: error => {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    },
  });

  const { mutate: updateRole, isPending: roleUpdateIsPending } = useMutation({
    mutationFn: updateUserRoles,
    onSuccess: (response, request) => {
      queryClient.setQueryData<PagedData>(
        ['users', debouncedFilter],
        oldData => {
          if (!oldData || !oldData.pages) {
            return oldData;
          }

          const updatedData = oldData.pages.map(page => ({
            ...page,
            data: page.data.filter(user => user.id !== request.id),
          }));

          return {
            ...oldData,
            pages: updatedData,
          };
        },
      );
      dispatch(fetchUserRoles());
      dispatch(
        showToast({
          message: response.message,
          severity: 'success',
        }),
      );
    },
    onError: error => {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    },
  });

  const onSortChange = (dataKey: string) => {
    setValue('sortBy', dataKey || 'firstName');
  };

  const onChangeRole = (selectedUser: User) =>
    dispatch(
      showChangeUserRoleDrawer({
        user: selectedUser,
      }),
    );

  const onMenuItemClick = (e: Option, selectedUser: User) => {
    if (['unlock', 'lock'].includes(String(e.value))) {
      updateStatus({
        id: selectedUser.id,
        payload: {
          ...selectedUser,
          status: e.value === 'unlock' ? UserStatus.Active : UserStatus.Locked,
        },
      });
    } else if (String(e.value) === 'edit') {
      dispatch(
        showUpdateUserDrawer({
          user: selectedUser,
        }),
      );
    } else if (String(e.value) === 'remove') {
      updateRole({
        id: String(selectedUser.id),
        payload:
          selectedUser.roles?.filter(
            roleItem => roleItem !== roleDetails?.name,
          ) || [],
      });
    }
  };

  const getStatusOptions = (user: User) => {
    const statusOptions = [
      {
        text: 'Edit',
        icon: 'user-gear',
        value: 'edit',
      },
      {
        text: user.status === UserStatus.Active ? 'Lock' : 'Unlock',
        icon: user.status === UserStatus.Active ? 'lock' : 'unlock',
        value: user.status === UserStatus.Active ? 'lock' : 'unlock',
      },
    ] as Option[];
    if (roleDetails) {
      statusOptions.push({
        text: 'Remove from group',
        icon: 'delete',
        value: 'remove',
      });
    }
    return statusOptions;
  };

  return (
    <Box>
      <SectionCard>
        <Stack justifyContent="space-between" mb={1}>
          <Box width={300}>
            {isAdministration ? (
              <Select
                autoWidth
                options={userRoleOptions}
                label="All Roles"
                fullWidth
                control={control}
                name="role"
              />
            ) : (
              <div>
                <Typography variant={'h4'}>
                  Users{' '}
                  {data && (
                    <Typography
                      component="span"
                      variant="h4"
                      fontWeight={fontWeight.light}
                      {...getDataTestId(`user-list-length`)}
                    >
                      ({data.pages[0].pagination.total})
                    </Typography>
                  )}
                </Typography>
              </div>
            )}
          </Box>
          <Box width={260}>
            <TextInput control={control} name="keyword" template="search" />
          </Box>
        </Stack>
        <SectionCardCallout>
          <Table
            sortBy={filter.sortBy}
            isLoading={isFetching || isFetchingNextPage || roleUpdateIsPending}
            rows={users || []}
            onSortChange={onSortChange}
            columns={[
              {
                header: 'Full Name',
                widthRatio: 20,
                allowSort: true,
                dataKey: 'firstName',
                cell: row => {
                  return (
                    <ClientBlock
                      avatar={row.picture}
                      name={getDisplayField(row)}
                    />
                  );
                },
              },
              {
                header: 'Status',
                dataKey: 'status',
                widthRatio: 16,
                cell: row => <Status value={row?.status || ''} />,
                filter: (
                  <Filter
                    name="status"
                    options={USER_STATUSES}
                    control={control}
                  />
                ),
              },
              {
                header: 'Group',
                dataKey: 'roles',
                widthRatio: 44,
                cell: row => {
                  return (
                    <Typography variant="body2">
                      {roleDetails
                        ? row.roles
                            ?.filter(roleItem => roleItem !== roleDetails?.name)
                            .map(roleItem => RoleNameText[roleItem])
                            ?.join(', ')
                        : row.roles
                            ?.map(roleItem => RoleNameText[roleItem])
                            .join(', ')}

                      {roleDetails && (
                        <Typography
                          variant="body2Bold"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {RoleNameText[roleDetails?.name]}
                        </Typography>
                      )}
                    </Typography>
                  );
                },
                filter: (
                  <Filter
                    name="role"
                    options={USER_ROLE_TYPES}
                    control={control}
                  />
                ),
              },
              {
                widthRatio: 20,
                cell: row => (
                  <Stack justifyContent="flex-end" gap={1}>
                    <Can do="edit" on="administration_users">
                      <Button
                        onClick={() => onChangeRole(row)}
                        variant="text"
                        startIcon={
                          <Icon
                            name="switch-role"
                            color={theme => theme.palette.grey[700]}
                          />
                        }
                      >
                        Change Group
                      </Button>
                    </Can>
                    <Can do="edit" on="administration_users">
                      <MenuButton
                        name={`${row.id}-menu`}
                        icon={
                          <Icon
                            name="more"
                            color={theme => theme.palette.grey[700]}
                          />
                        }
                        options={getStatusOptions(row)}
                        onSelect={e => onMenuItemClick(e, row)}
                      />
                    </Can>
                  </Stack>
                ),
                cellProps: {
                  align: 'right',
                },
              },
            ]}
          />
        </SectionCardCallout>
        <Box ref={ref} />
      </SectionCard>
    </Box>
  );
};

export default UsersTab;
