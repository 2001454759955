import { Box, Button, Modal, Stack } from '@mui/material';
import { FormAction, FormCard } from '../Layout';
import { useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import { Icon } from '../Icon';
import { AvatarPreview } from './User.styled';
import { getDataTestId } from '@/utils';

type AvatarModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function AvatarModal({ onClose, isOpen }: AvatarModalProps) {
  const { picture } = useSelector(selectLoggedInUser) || {};

  return (
    <Modal {...getDataTestId('avatar-modal')} open={isOpen} onClose={onClose}>
      <Box>
        <FormCard title="Profile picture" onClose={onClose} center>
          <Stack direction="column" gap={3}>
            <AvatarPreview
              src={picture}
              {...getDataTestId('avatar-modal-preview')}
            >
              {' '}
            </AvatarPreview>
            {picture ? (
              <>
                <FormAction align="stretch">
                  <Button
                    variant="outlined"
                    startIcon={<Icon name="edit-outline" />}
                    {...getDataTestId('avatar-modal-action-change-button')}
                  >
                    Change
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<Icon name="file-x" />}
                    {...getDataTestId('avatar-modal-action-remove-button')}
                  >
                    Remove
                  </Button>
                </FormAction>
              </>
            ) : (
              <>
                <Button {...getDataTestId('avatar-modal-action-add-button')}>
                  Add profile picture
                </Button>
              </>
            )}
          </Stack>
        </FormCard>
      </Box>
    </Modal>
  );
}
