import { Option } from '@/types';
import { getDataTestId } from '@/utils';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import { useCallback } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';

type ToggleGroupProps<FormPayload extends FieldValues> = {
  options: Option[];
  control?: Control<FormPayload>;
  name?: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
} & ToggleButtonGroupProps;

export function ToggleGroup<FormPayload extends FieldValues>({
  value,
  onChange,
  options,
  name,
  control,
  rules,
  ...props
}: ToggleGroupProps<FormPayload>) {
  const renderSelect = useCallback(
    (
      value: ToggleButtonGroupProps['value'],
      onChange: ToggleButtonGroupProps['onChange'],
    ) => (
      <ToggleButtonGroup
        {...getDataTestId(`${name}-toggle-group`)}
        size="small"
        value={value}
        exclusive
        onChange={onChange}
        {...props}
      >
        {options.map(option => (
          <ToggleButton
            key={`toggle-${option.value}`}
            {...getDataTestId(`${name}-option-${option.value}`)}
            value={option.value}
            disabled={value === option.value}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ),
    [name, options, props],
  );

  if (control && name) {
    return (
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) =>
          renderSelect(value, onChange)
        }
      />
    );
  }

  return renderSelect(value, onChange);
}
