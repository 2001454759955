import { Heading } from '@/components';
import { Container, Grid } from '@mui/material';
import { SectionSummary } from './SectionSummary';
import { SectionAccountTrend } from './SectionAccountTrend';
import { SectionMyTasks } from './SectionMyTasks';
import { SectionActivity } from './SectionActivity';
import { getDisplayField } from '@/utils';
import { selectAuthState } from '@/redux';
import { useSelector } from '@/hooks';

export function HomePage() {
  const { user } = useSelector(selectAuthState);

  return (
    <Container>
      <Heading title={`Hello, ${getDisplayField(user)}!`} />
      <Grid container spacing={3} alignItems="stretch">
        <Grid
          item
          xs={6}
          container
          spacing={2}
          direction="column"
          justifyContent="stretch"
        >
          <Grid item>
            <SectionSummary />
          </Grid>
          <Grid item flex={1}>
            <SectionAccountTrend />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          spacing={2}
          direction="column"
          justifyContent="stretch"
        >
          <Grid item>
            <SectionMyTasks />
          </Grid>
          <Grid item flex={1}>
            <SectionActivity />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
