import { Box, styled } from '@mui/material';
import { OverlayScroller } from '../../Layout';

export const LoaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
  flex: 1,
  minHeight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const RelativeContainer = styled(Box)(() => ({
  position: 'relative',
  flex: 1,
  minHeight: 0,
}));

export const PEPReportContainer = styled(OverlayScroller)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
  overflowX: 'hidden',
  padding: theme.spacing(1.5),
  '.simplebar-content': {
    height: 'unset',
  },
}));
