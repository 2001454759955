import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputProps,
  Menu,
  TextField,
  styled,
} from '@mui/material';
import { Icon } from '../Icon';
import { doNotForwardTheseProps } from '@/styles';
import { OverlayScroller } from '../Layout/OverlayScroller';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

export const FullHeightTextFieldContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  minHeight: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const FullHeightTextFieldBorder = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('error'),
})<{ error?: boolean }>(({ theme, error }) => ({
  backgroundColor: theme.palette.background.paper,
  borderColor: error ? theme.palette.error.main : theme.palette.grey[600],
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  flex: 1,
  minHeight: 0,
  overflow: 'hidden',
  transition: theme.transitions.create('border-color'),

  ':hover': {
    borderColor: error ? theme.palette.error.main : theme.palette.grey[1000],
  },

  ':has(> textarea:focus)': {
    borderColor: error ? theme.palette.error.main : theme.palette.grey[1000],
    outline: 'none',
  },
}));

export const FullHeightTextarea = styled('textarea')(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: 'transparent',
  border: 'none',
  resize: 'none',
  width: '100%',
  height: '100%',
  paddingBottom: theme.spacing(1.5),
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  ':focus': {
    outline: 'none',
  },
}));

export const SearchTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    borderRadius: 50,
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],

    '&:not(.MuiInputBase-multiline)': {
      height: 40,
    },

    '.MuiInputBase-input': {
      paddingLeft: 0,
    },

    '.MuiOutlinedInput-notchedOutline': {
      opacity: 0,
    },
  },
}));
SearchTextField.defaultProps = {
  placeholder: 'Search',
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <Icon
          name="search"
          size={20}
          color={theme => theme.palette.grey[700]}
        />
      </InputAdornment>
    ),
  },
};

export const FilterMenu = styled(Menu)(() => ({}));
FilterMenu.defaultProps = {
  slotProps: {
    paper: {
      sx: theme => ({
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        '.MuiList-root': {
          padding: 0,
        },
      }),
    },
  },
};

export const FilterOptions = styled(OverlayScroller)(() => ({
  maxHeight: 250,
  width: 292,
}));

export const FilterOption = styled(Box)(({ theme }) => ({
  ':not(:last-of-type)': {
    marginBottom: theme.spacing(3),
  },
}));

export const CheckboxContainer = styled('div', {
  shouldForwardProp: doNotForwardTheseProps('checked'),
})<{ checked?: boolean }>(({ theme, checked }) => ({
  height: 28,
  width: 28,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 10,

  '.MuiSvgIcon-root': {
    fill: theme.palette.common.white,
  },

  ...(checked
    ? {
        backgroundColor: theme.palette.primaries[400],
      }
    : {
        backgroundColor: theme.palette.common.white,
        boxShadow: `inset 0 0 0 2px ${theme.palette.grey[600]}`,
      }),
}));

export const StyledCheckboxFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  '.MuiTypography-root': {
    userSelect: 'none',
  },
}));
export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1.5),
}));

export const FieldArrowButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  ':hover': {
    backgroundColor: 'transparent',
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0.5),
  },
}));

export const FieldArrow = styled(Icon)(({ name }) => ({
  marginLeft: 'auto',
  ...(name === 'arrow-up'
    ? {
        transform: `translateY(-2px)`,
      }
    : {}),
}));
FieldArrow.defaultProps = {
  size: 20,
  color: theme => theme.palette.grey[700],
};

export const FilterBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(2.5),
  rowGap: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export const FilterBarItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(0.125),
  paddingBottom: theme.spacing(0.125),
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(0.25),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.25),

  '.MuiIconButton-root': {
    padding: theme.spacing(0.5),
  },
}));

export const StyledLoadingButton = styled(Button, {
  shouldForwardProp: doNotForwardTheseProps('isLoading'),
})<{ isLoading?: boolean }>(({ isLoading, variant, theme }) => ({
  position: 'relative',
  ...(isLoading
    ? {
        color: 'transparent',
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          '.MuiSvgIcon-root': {
            fill: 'transparent',
          },
        },
        '.MuiCircularProgress-root': {
          color:
            variant === 'contained'
              ? theme.palette.common.white
              : theme.palette.text.primary,
        },

        '&.Mui-disabled': {
          color: 'transparent',
          '.MuiButton-startIcon, .MuiButton-endIcon': {
            '.MuiSvgIcon-root': {
              fill: 'transparent',
            },
          },
          '.MuiCircularProgress-root': {
            color: theme.palette.grey[400],
          },
        },
      }
    : {}),
}));

export const ButtonLoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const VisuallyHiddenFileInput = styled('input')<InputProps>(
  ({ disabled }) => ({
    opacity: 0,
    cursor: disabled ? 'default' : 'pointer',
    position: 'absolute',
    bottom: 0,
    left: 0,
    '::file-selector-button': {
      display: 'none',
    },
  }),
);
VisuallyHiddenFileInput.defaultProps = {
  type: 'file',
};

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<Date>>(
  ({ theme }) => ({
    '.MuiInputBase-root': {
      paddingRight: theme.spacing(0.5),
      '.MuiInputBase-input': {
        padding: 0,
      },
    },
  }),
);
