import { createSlice } from '@reduxjs/toolkit';
import { RoleDetails } from '@/types';
import {
  fetchRoleDetails,
  resetRoleDetails,
  toggleAllPermissions,
  toggleCategoryPermission,
  togglePermission,
  updateInitialRoleDetailPermission,
} from './actions';

interface RoleDetailsState {
  roleDetails?: RoleDetails;
  initialPermissions?: string[];
  isMasterChecked: boolean;
  initialized: boolean;
  error?: string;
}

const initialState: RoleDetailsState = {
  initialized: false,
  isMasterChecked: false,
};

export const roleDetailsSlice = createSlice({
  name: 'role-details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(toggleCategoryPermission, (state, { payload }) => {
      if (state.roleDetails?.permissions) {
        if (payload.checked) {
          state.roleDetails.permissions = Array.from(
            new Set([
              ...state.roleDetails.permissions,
              ...payload.categoryPermissions,
            ]),
          );
        } else {
          state.roleDetails.permissions = state.roleDetails.permissions.filter(
            item => !payload.categoryPermissions.includes(item),
          );
        }
      }
    });
    builder.addCase(toggleAllPermissions, (state, { payload }) => {
      if (state?.roleDetails?.permissions) {
        state.isMasterChecked = payload.checked;
        state.roleDetails.permissions = payload.checked
          ? payload.permissions
          : [];
      }
    });
    builder.addCase(togglePermission, (state, { payload }) => {
      if (state?.roleDetails?.permissions) {
        state.roleDetails.permissions = state.roleDetails?.permissions.includes(
          payload,
        )
          ? state.roleDetails.permissions.filter(
              permission => permission !== payload,
            )
          : state.roleDetails.permissions.concat(payload);
      }
    });
    builder.addCase(updateInitialRoleDetailPermission, (state, { payload }) => {
      state.initialPermissions = payload;
    });
    builder.addCase(fetchRoleDetails.pending, state => {
      state.error = undefined;
    });
    builder.addCase(fetchRoleDetails.fulfilled, (state, { payload }) => {
      state.roleDetails = payload;
      state.initialPermissions = payload.permissions;
      state.initialized = true;
    });
    builder.addCase(fetchRoleDetails.rejected, (state, { error }) => {
      state.error = error.message;
    });
    builder.addCase(resetRoleDetails, state => {
      state.roleDetails = initialState.roleDetails;
      state.initialPermissions = initialState.initialPermissions;
      state.initialized = initialState.initialized;
      state.isMasterChecked = initialState.isMasterChecked;
    });
  },
});

export const roleDetailsReducer = roleDetailsSlice.reducer;
