import { doNotForwardTheseProps } from '@/styles';
import { FilterButton } from '../../Form';
import {
  styled,
  TableCell as MUITableCell,
  TableContainer as MUITableContainer,
  TableSortLabel,
  Box,
  IconButton,
} from '@mui/material';

export const RelativeBox = styled(Box)(() => ({
  position: 'relative',
}));
export const ColumnFilterButton = styled(FilterButton)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  transform: `translate(calc(-100% + ${theme.spacing(-2)}), ${theme.spacing(0.5)})`,
  border: `1px solid ${theme.palette.grey[400]}`,
}));

export const TableContainer = styled(MUITableContainer)(() => ({
  overflowX: 'initial',
}));

export const TableCell = styled(MUITableCell, {
  shouldForwardProp: doNotForwardTheseProps('isExpanded', 'noBorder'),
})<{ isExpanded?: boolean; noBorder?: boolean }>(
  ({ theme, isExpanded, noBorder }) => ({
    borderColor: !noBorder ? theme.palette.grey[200] : 'transparent',
    lineHeight: theme.typography.body1.lineHeight,
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    wordBreak: 'break-word',

    ':first-of-type, :last-of-type': {
      borderColor: 'transparent',
      padding: 0,
      width: theme.spacing(2),
    },

    ':nth-of-type(2)': {
      paddingLeft: 0,
    },
    ':nth-last-of-type(2)': {
      paddingRight: 0,
    },

    ...(isExpanded
      ? {
          borderColor: 'transparent',
        }
      : {}),

    '&.MuiTableCell-head': {
      borderBottomWidth: 4,
      color: theme.palette.grey[700],
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      paddingBottom: theme.spacing(0.75),
      paddingTop: theme.spacing(0.75),
      wordBreak: 'keep-all',

      ':not(:first-of-type):not(:last-of-type):not(:nth-last-of-type(2))': {
        ':before': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          width: 1,
          height: '60%',
          backgroundColor: theme.palette.grey[300],
        },
      },

      '&.MuiTableCell-stickyHeader': {
        backgroundColor: theme.palette.background.paper,
        top: theme.layout.headerHeight.xs,
      },
    },
  }),
);

export const LoaderCell = styled(MUITableCell)(({ theme }) => ({
  border: 'none',
  paddingTop: theme.spacing(3),
}));

export const SortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&.MuiTableSortLabel-root': {
    color: theme.palette.grey[700],
    '.Mui-active': {
      color: theme.palette.grey[700],
    },
  },
}));

export const ExpandButton = styled(IconButton, {
  shouldForwardProp: doNotForwardTheseProps('isExpanded'),
})<{ isExpanded?: boolean }>(({ theme, isExpanded }) => ({
  transition: theme.transitions.create('transform'),
  transform: `rotate(${isExpanded ? '0' : '90deg'})`,
}));

export const ExpandContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));
