import { createSlice } from '@reduxjs/toolkit';
import { PermissionDetails } from '@/types';
import { fetchPermissions } from './actions';

interface UIState {
  permissions: PermissionDetails[];
}

const initialState: UIState = {
  permissions: [],
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPermissions.fulfilled, (state, { payload }) => {
      state.permissions = payload;
    });
  },
});

export const permissionsReducer = permissionsSlice.reducer;
