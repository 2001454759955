import { FormCard, LoadingButton } from '@/components';
import { doNotForwardTheseProps } from '@/styles';
import { Box, Container, styled } from '@mui/material';

export const Background = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('isBlocked'),
})<{ isBlocked?: boolean }>(({ theme, isBlocked }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  overflow: 'hidden',

  '::before': {
    content: '""',
    backgroundColor: isBlocked
      ? theme.palette.secondaryPink[100]
      : theme.palette.primaries[100],
    borderRadius: '50%',
    height: '100vw',
    width: '100vw',
    position: 'absolute',
    left: '-39%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export const LoginContainer = styled(Container)(({ theme }) => ({
  height: 580,
  marginTop: 'auto',
  marginBottom: 'auto',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  position: 'relative',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    height: 612,
  },
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  width: 300,

  [theme.breakpoints.up('md')]: {
    width: 375,
  },
}));

export const LoginFormCard = styled(FormCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: 480,
  gap: theme.spacing(1.5),
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(12),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  alignSelf: 'flex-start',

  [theme.breakpoints.up('md')]: {
    width: 552,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(15.5),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
}));

export const Logo = styled('img')(() => ({
  width: 174,
  alignSelf: 'center',
}));
Logo.defaultProps = {
  src: '/logo-with-text.svg',
};

export const StyledLoadingButton = styled(LoadingButton)(() => ({
  height: 44,
}));
