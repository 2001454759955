import { ReactNode } from 'react';

export enum ModalName {
  ManageDocuments = 'manage-documents',
  RequestDocument = 'request-document',
  ReviewDocument = 'review-document',
  AssignClient = 'assign-client',
  ExportClient = 'export-client',
  ApproveAccount = 'approve-account',
  PEPReport = 'pep-report',
  AddUser = 'add-user',
}

export type ModalState<T = undefined> = {
  isOpen: boolean;
  shouldRefreshData?: boolean;
  payload?: T;
};

export type ToastState = {
  isOpen: boolean;
  message: ReactNode;
  severity?: 'info' | 'error' | 'success';
  autoHide?: boolean;
};

type CollapsibleSwitchItem = {
  label: string;
  value: string;
  checked: boolean;
  readOnly?: boolean;
};

export type CollapsibleSwitch = {
  categoryName: string;
  checked: boolean;
  items: CollapsibleSwitchItem[];
  noOfCheckedItems: number;
  readOnly?: boolean;
};
