import { getRoles } from '@/api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const togglePermission = createAction<string>(
  'role-detail/toggle-permission',
);

export const updateInitialRoleDetailPermission = createAction<string[]>(
  'role-detail/update-initial-role-detail-permission',
);

export const toggleCategoryPermission = createAction<{
  checked: boolean;
  categoryPermissions: string[];
}>('role-detail/toggle-category-permission');

export const toggleAllPermissions = createAction<{
  checked: boolean;
  permissions: string[];
}>('role-detail/toggle-all-permission');

export const fetchRoleDetails = createAsyncThunk(
  'role-details/fetch-role-details',
  getRoles,
);

export const resetRoleDetails = createAction('role-details/reset');
