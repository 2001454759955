import { Grid, Typography } from '@mui/material';
import { SectionRolePermissions } from './SectionRolePermissions';
import { RoleDetailBlock } from './RoleDetail.styled';
import { Heading } from '@/components';
import { useSelector } from '@/hooks';
import { selectRoleDetail } from '@/redux';
import { GroupDescriptionContainer } from './GroupDescriptionContainer.styled';

type TabPermissionSettingsProps = {
  roleName: string;
};

const TabPermissionSettings = ({ roleName }: TabPermissionSettingsProps) => {
  const { roleDetails } = useSelector(selectRoleDetail);

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={6}>
        <Grid container spacing={4} direction="row">
          <Grid item xs={12}>
            <RoleDetailBlock>
              <Heading title="Group description" isSubSection />
              <GroupDescriptionContainer>
                <Typography variant="body2">
                  {roleDetails?.description}
                </Typography>
              </GroupDescriptionContainer>
            </RoleDetailBlock>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <SectionRolePermissions roleName={roleName}/>
      </Grid>
    </Grid>
  );
};

export default TabPermissionSettings;
