import {
  Form,
  Icon,
  LoadingButton,
  PasswordPolicy,
  TextInput,
} from '@/components';
import { ChangePasswordForm } from '@/types';
import { Alert, Box, Button, Typography } from '@mui/material';
import { StyledFormCard } from './ChangePassword.styled';
import { useDispatch, useForm, useNavigateBack, useSelector } from '@/hooks';
import { selectLoggedInUser, showToast } from '@/redux';
import {
  getAPIErrorMessage,
  getDataTestId,
  passwordNotIncludeEmail,
} from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { changePassword } from '@/api';
import { useEffect } from 'react';
import { PASSWORD_PATTERN, ROUTE } from '@/constants';

export function ChangePasswordPage() {
  const goBack = useNavigateBack(ROUTE.HOME);
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedInUser);
  const {
    control,
    handleSubmit,
    formState: { isValid },
    visibleErrors: errors,
    watch,
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const newPassword = watch('password');

  const { isSuccess, mutate, isPending, error, reset } = useMutation({
    mutationFn: changePassword,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: 'Password changed!',
          severity: 'success',
        }),
      );
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = handleSubmit(
    form => {
      reset();
      mutate({
        oldPassword: form.currentPassword,
        newPassword: form.password,
      });
    },
    () => {},
  );

  const onCancel = () => {
    goBack();
  };

  if (!user) return null;

  return (
    <StyledFormCard center>
      <Typography variant="h3" textAlign="center">
        Change password
      </Typography>
      <PasswordPolicy mt={1.5} />
      <Form onSubmit={onSubmit} alignItems="stretch" gap={3} mt={3}>
        <TextInput
          control={control}
          name="currentPassword"
          type="password"
          label="Current password"
          error={!!errors.currentPassword}
          helperText={errors.currentPassword?.message}
          rules={{
            required: 'Current password is required',
          }}
        />
        <TextInput
          type="password"
          label="New password"
          name="password"
          control={control}
          error={!!errors.password}
          helperText={errors.password?.message}
          rules={{
            required: 'Password is required',
            pattern: {
              value: new RegExp(PASSWORD_PATTERN, 'g'),
              message: 'Incorrect password format',
            },
            validate: {
              notIncludeUsername: value =>
                passwordNotIncludeEmail(value, user.email),
            },
          }}
          disabled={isPending}
        />
        <TextInput
          control={control}
          name="passwordConfirm"
          type="password"
          label="Confirm new password"
          error={!!errors.passwordConfirm}
          helperText={errors.passwordConfirm?.message}
          rules={{
            validate: {
              isTheSameWithCurrent: value =>
                value === newPassword
                  ? true
                  : `Password confirmation doesn't match`,
            },
          }}
        />
        {error && (
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        )}
        <Box>
          <LoadingButton
            {...getDataTestId(`change-password-action-save-button`)}
            type="submit"
            fullWidth
            disabled={!isValid || isPending}
            isLoading={isPending}
          >
            Reset password
          </LoadingButton>
          <Box mt={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onCancel}
              {...getDataTestId(`change-password-action-cancel-button`)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Form>
    </StyledFormCard>
  );
}
