import { doNotForwardTheseProps } from '@/styles';
import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItemAvatar,
  Menu,
  styled,
} from '@mui/material';

export const AvatarContainer = styled(Box)(() => ({
  position: 'relative',
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: doNotForwardTheseProps('big'),
})<{
  big?: boolean;
}>(({ big = false }) => ({
  ...(big
    ? {
        height: 64,
        width: 64,
      }
    : {
        height: 40,
        width: 40,
      }),
}));

export const AvatarButton = styled(IconButton)(() => ({
  padding: 0,
}));

export const AvatarProgressContainer = styled(Box)(() => ({
  height: 'calc(100% + 10px)',
  width: 'calc(100% + 10px)',
  transform: 'scale(-1, 1)',
  position: 'absolute',
  top: -5,
  left: -5,
}));

export const AvatarProgress = styled(CircularProgress, {
  shouldForwardProp: doNotForwardTheseProps('progressColor'),
})<{ progressColor?: string }>(({ theme, progressColor }) => ({
  color: progressColor || theme.palette.green[500],
  position: 'absolute',
  top: 0,
  left: 0,
}));
AvatarProgress.defaultProps = {
  thickness: 1.3,
  variant: 'determinate',
  size: '100%',
};

export const ClientBlockRow = styled('a', {
  shouldForwardProp: doNotForwardTheseProps('center'),
})<{
  center?: boolean;
}>(({ href, center }) => ({
  display: 'flex',
  cursor: href ? 'pointer' : 'auto',
  padding: 0,
  alignItems: center ? 'center' : 'flex-start',
  textDecoration: 'none',
  color: 'initial',

  '.MuiListItemText-root': {
    margin: 0,
  },
}));

export const ClientBlockAvatar = styled(ListItemAvatar, {
  shouldForwardProp: doNotForwardTheseProps('big'),
})<{ big?: boolean }>(({ theme, big }) => ({
  minWidth: 0,
  marginRight: theme.spacing(big ? 2 : 1.5),
}));

export const AvatarPreview = styled(Avatar)<AvatarProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  height: 240,
  width: 240,
}));

export const StyledMenu = styled(Menu)(() => ({}));
StyledMenu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  slotProps: {
    root: {
      sx: theme => ({
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
      }),
    },
    paper: {
      sx: () => ({
        padding: 0,
        '.MuiList-root': {
          padding: 0,
          width: 280,
        },
      }),
    },
  },
};

export const MenuDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

export const MenuButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    fontWeight: theme.typography.body2.fontWeight,
    justifyContent: 'flex-start',
  },
}));
MenuButton.defaultProps = {
  variant: 'text',
  fullWidth: true,
};
