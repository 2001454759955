import { styled, Tabs as MUITabs, Tab as MUITab } from '@mui/material';
import { DocumentDrawer } from '../DocumentDrawer.styled';

export const Drawer = styled(DocumentDrawer)(() => ({
  '.MuiDrawer-paper': {
    '.FormHeader': {
      marginBottom: 0,
    },
    '.FormDivider': {
      display: 'none',
    },
  },
}));

export const Tabs = styled(MUITabs)(() => ({}));

export const Tab = styled(MUITab)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: theme.typography.body2Medium.fontSize,
  fontWeight: theme.typography.body2Medium.fontWeight,
}));
