import {
  getLocalDocumentUrl,
  getLocalDocumentUrlFromS3,
  getLocalPrivateDocumentUrl,
  getSigningForms,
} from '@/api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSigningForms = createAsyncThunk(
  'documents/fetch-signing-forms',
  getSigningForms,
);

export const fetchLocalDocumentFromS3 = createAsyncThunk(
  'documents/fetch-local-document-from-s3',
  getLocalDocumentUrlFromS3,
);
export const fetchLocalDocumentFromServer = createAsyncThunk(
  'documents/fetch-local-document-from-server',
  getLocalPrivateDocumentUrl,
);
export const fetchLocalDocumentFromUrl = createAsyncThunk(
  'documents/fetch-local-document-from-url',
  getLocalDocumentUrl,
);
export const setLocalDocument = createAction<{
  uri: string;
  localUrl: string;
}>('documents/set-local-document');
