import { FormAction } from '../Layout';
import { Form } from './Modals.styled';
import { DrawerProps } from './Modals.interface';
import { AddUserForm, ModalName, Option, User, UserStatus } from '@/types';
import { Icon } from '../Icon';
import { getAPIErrorMessage, getDataTestId, getDisplayField } from '@/utils';
import { useEffect } from 'react';
import { useDispatch, useForm, useModal, useSelector } from '@/hooks';
import { fetchUserRoles, selectUserRoles, showToast } from '@/redux';
import { useMutation } from '@tanstack/react-query';
import { addUser, updateUser, updateUserRoles } from '@/api';
import { LoadingButton, Select, TextInput } from '../Form';
import { Button, Stack } from '@mui/material';
import {
  UserDrawer,
  UserDrawerFooter,
  UserPermissionContainer,
} from './UserDrawer.styled';
import { EMAIL_PATTERN, RoleNameText } from '@/constants';
import { EditUserDrawerRoleInfo } from './EditUserDrawerRoleInfo';
import { ClientBlock } from '../User';

type AddUserDrawerProps = {
  user?: User;
  isEdit?: boolean;
  isChangeRole?: boolean;
} & DrawerProps;

export function AddUserDrawer({
  user,
  isEdit,
  isChangeRole,
  ...props
}: AddUserDrawerProps) {
  const dispatch = useDispatch();
  const { hide, hideAndRefresh } = useModal({ name: ModalName.AddUser });
  const userRoles = useSelector(selectUserRoles);
  const {
    control,
    reset,
    handleSubmit,
    visibleErrors: errors,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<AddUserForm>({
    defaultValues: {
      firstName: '',
      email: '',
      lastName: '',
      roles: [],
      status: '',
    },
  });

  const userStatus = watch('status');
  const selectedUserRoles = watch('roles');
  const { mutate, isSuccess, error, isPending } = useMutation({
    mutationFn: addUser,
  });

  const {
    mutate: updateRoleRequest,
    isSuccess: isRoleUpdated,
    error: updateRoleError,
    isPending: roleUpdateIsPending,
    data: updateRoleResponseData,
  } = useMutation({
    mutationFn: updateUserRoles,
  });

  const {
    mutate: updateUserRequest,
    isSuccess: isUserUpdated,
    error: updateUserError,
    isPending: isUserUpdatePending,
    data: updateUserResponseData,
  } = useMutation({
    mutationFn: updateUser,
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', String(user?.firstName));
      setValue('lastName', String(user?.lastName));
      setValue('email', String(user?.email));
      setValue('roles', user?.roles || []);
      setValue('status', String(user?.status));
    }

    return () => reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (isRoleUpdated) {
      dispatch(fetchUserRoles());
      dispatch(
        showToast({
          message: updateRoleResponseData.message,
          severity: 'success',
        }),
      );
      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleUpdated]);

  useEffect(() => {
    if (updateRoleError) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(updateRoleError),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoleError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchUserRoles());
      dispatch(
        showToast({
          message: 'User added successfully!',
          severity: 'success',
        }),
      );
      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isUserUpdated) {
      dispatch(
        showToast({
          message: updateUserResponseData.message,
          severity: 'success',
        }),
      );
      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserUpdated]);

  useEffect(() => {
    if (updateUserError) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(updateUserError),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserError]);

  const onSubmit = handleSubmit(payload => {
    if (isChangeRole) {
      return updateRoleRequest({
        id: String(user?.id),
        payload: payload.roles,
      });
    }
    if (isEdit) {
      return updateUserRequest({
        id: String(user?.id),
        payload: {
          ...user,
          ...payload,
        } as User,
      });
    }
    return mutate(payload);
  });

  const userRoleOptions: Option[] = userRoles.roles.map(role => ({
    value: role.name,
    text: RoleNameText[role.name],
  }));

  const toggleUserStatus = () => {
    setValue(
      'status',
      userStatus === UserStatus.Active ? UserStatus.Locked : UserStatus.Active,
    );
  };

  const getDrawerTitle = () => {
    if (isChangeRole) {
      return 'Change Group';
    }
    if (isEdit) {
      return 'Update User';
    }
    return 'Add New User';
  };

  return (
    <UserDrawer
      {...getDataTestId('add-user-drawer')}
      {...props}
      title={getDrawerTitle()}
      onClose={hide}
    >
      <Form onSubmit={onSubmit}>
        {user && String(user?.id) && (
          <Stack justifyContent="space-between" mb={2}>
            <ClientBlock avatar={user.picture} name={getDisplayField(user)} />
            {isEdit && (
              <Button
                variant="outlined"
                onClick={toggleUserStatus}
                startIcon={
                  <Icon
                    name={userStatus === UserStatus.Active ? 'lock' : 'unlock'}
                  />
                }
              >
                {userStatus === UserStatus.Active ? 'Lock' : 'Unlock'}
              </Button>
            )}
          </Stack>
        )}
        <Stack alignItems="flex-start" mt={1}>
          <TextInput
            control={control}
            name="firstName"
            label="First Name"
            disabled={isChangeRole}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            rules={{
              required: 'First name is required',
            }}
          />
          <TextInput
            control={control}
            name="lastName"
            label="Last Name"
            rules={{
              required: 'Last name is required',
            }}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            disabled={isChangeRole}
          />
        </Stack>
        <TextInput
          control={control}
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors.email?.message}
          rules={{
            required: 'Email is required',
            pattern: {
              value: new RegExp(EMAIL_PATTERN, 'g'),
              message: 'Invalid email format',
            },
          }}
          disabled={isChangeRole || isEdit}
        />
        <Select
          options={userRoleOptions}
          control={control}
          label="Select user group"
          name="roles"
          error={!!errors.roles}
          helperText={errors.roles?.message}
          rules={{
            required: 'User group is required',
          }}
          fullWidth
          multiline
          multiple
        />

        <UserPermissionContainer>
          <EditUserDrawerRoleInfo
            permissions={user?.permissions || []}
            roles={selectedUserRoles}
          />
        </UserPermissionContainer>
        <UserDrawerFooter>
          <FormAction align="stretch">
            <Button
              variant="outlined"
              {...getDataTestId(`add-user-modal-cancel-button`)}
              onClick={hide}
              disabled={isPending || roleUpdateIsPending || isUserUpdatePending}
            >
              Cancel
            </Button>
            <LoadingButton
              startIcon={<Icon name="check" />}
              type="submit"
              disabled={
                !isValid ||
                isPending ||
                roleUpdateIsPending ||
                isUserUpdatePending
              }
              isLoading={
                isPending || roleUpdateIsPending || isUserUpdatePending
              }
              {...getDataTestId(`add-user-button`)}
            >
              {isChangeRole || isEdit ? 'Update User' : 'Add User'}
            </LoadingButton>
          </FormAction>
        </UserDrawerFooter>
      </Form>
    </UserDrawer>
  );
}
