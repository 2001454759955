import { FormAction, FormCard, Icon, LoadingButton } from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import { fetchClientDetails, selectConfigsState, showToast } from '@/redux';
import { Client, OperationReviewForm, Tenant, DocumentType } from '@/types';
import { getDataTestId, getDisplayField, hasMandateDocument } from '@/utils';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Modal,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';

type ReviewButtonProps = {
  type: 'approve' | 'reject';
  text: string;
  client: Client;
  onClick: (payload: OperationReviewForm) => Promise<void>;
} & Omit<ButtonProps, 'type' | 'onClick'>;

export function ReviewButton({
  type,
  text,
  client,
  onClick,
  ...props
}: ReviewButtonProps) {
  const dispatch = useDispatch();
  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: onClick,
  });
  const { tenant } = useSelector(selectConfigsState);

  const {
    value: isConfirming,
    setTrue: showConfirmModal,
    setFalse: hideConfirmModal,
  } = useBoolean(false);

  const [payload, setPayload] = useState<OperationReviewForm>({
    clientId: client?.id || '',
    operationId: client?.operationId || '',
    message: '',
  });

  const warning = useMemo(
    () => getAccountWarning(type, client, tenant),
    [type, client, tenant]
  );

  const handleOnClick = () => {
    mutate(payload);
    hideConfirmModal();
  };

  useEffect(() => {
    if (isSuccess) {
      const status = type === 'approve' ? 'approved' : 'rejected';
      const severity = type === 'approve' ? 'success' : 'error';
      dispatch(
        showToast({
          message: `${getDisplayField(client)} account has been ${status}!`,
          severity,
        }),
      );
      hideConfirmModal();
      dispatch(fetchClientDetails(String(client.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isConfirming) {
      setPayload({
        clientId: client.id,
        operationId: client.operationId,
        message: '',
      });
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirming]);

  return (
    <>
      <LoadingButton
        startIcon={type === 'approve' ? <Icon name="check" /> : undefined}
        onClick={showConfirmModal}
        {...getDataTestId(`${type}-account-button`)}
        isLoading={isPending}
        {...props}
      >
        {text}
      </LoadingButton>
      <Modal {...getDataTestId(`${type}-account-modal`)} open={isConfirming}>
        <Box>
          <FormCard title={`${text} account`} onClose={hideConfirmModal} center>
            {warning && (
              <Box mb={4}>
                <Alert icon={<Icon name="alert" />} color="warning">
                  {warning}
                </Alert>
              </Box>
            )}
            <Typography variant="body2" mb={4}>
              Are you sure you want to {type}{' '}
              <Typography variant="body2Medium" component="span">
                {getDisplayField(client)}
              </Typography>{' '}
              account?
            </Typography>
            {error && (
              <Box mb={2}>
                <Alert
                  icon={<Icon name="alert" />}
                  color="error"
                  variant="standard"
                >
                  {error.message}
                </Alert>
              </Box>
            )}
            <FormAction>
              <Button
                variant="outlined"
                {...getDataTestId(`confirm-${type}-modal-cancel-button`)}
                onClick={hideConfirmModal}
              >
                Cancel
              </Button>
              <Button
                color={type === 'approve' ? 'primary' : 'error'}
                onClick={handleOnClick}
                {...getDataTestId(`confirm-${type}-modal-${type}-button`)}
              >
                {text}
              </Button>
            </FormAction>
          </FormCard>
        </Box>
      </Modal>
    </>
  );
}

const getAccountWarning = (
  type: 'approve' | 'reject',
  client: Client,
  tenant: Tenant
): string | null => {
  if (type !== 'approve') return null;

  const hasInternalDocument = (client.documents ?? []).some(
    item => item.type === DocumentType.Internal
  );

  if (client.eligible === false && !hasInternalDocument) {
    return 'This customer has been advised to visit the branch to complete their application.';
  }

  if (tenant === Tenant.StLucia && !hasMandateDocument(client)) {
    return 'Mandate document does not exist.';
  }

  return null;
};