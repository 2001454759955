import { FileSelector, LoadingButton, Select, TextInput } from '../../Form';
import { FileContainer, Form } from '../Modals.styled';
import { Button } from '@mui/material';
import { Icon } from '../../Icon';
import { FormAction } from '../../Layout';
import {
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  getDocSubTypesByType,
} from '@/utils';
import { useDispatch, useForm, useSelector } from '@/hooks';
import {
  Client,
  DocumentSubType,
  DocumentType,
  DocumentUploadForm,
  FileFrameActionItem,
  ModalName,
  Option,
  SigningDocument,
} from '@/types';
import { useEffect, useMemo, useState } from 'react';
import { addClientDocument } from '@/api';
import { useMutation } from '@tanstack/react-query';
import {
  hideModal,
  selectClientAccountTypes,
  selectConfigsState,
  selectSigningDocuments,
  showToast,
} from '@/redux';
import {
  DOCUMENT_TYPES,
  DocumentTypeText,
  MAX_DOCUMENT_SIZE,
  SUPPORTED_MANUAL_UPLOAD_DOCUMENT_EXTENSIONS,
} from '@/constants';
import { FileFrame } from '../../File';
import { createMockDocument } from '@/test-utils/createMock';

type UploadDocumentFormProps = {
  client: Client;
  modalName: ModalName;
  onSetDisabled?: (value: boolean) => void;
};

export function UploadDocumentForm({
  client,
  modalName,
  onSetDisabled,
}: UploadDocumentFormProps) {
  const {
    control,
    handleSubmit,
    setValue,
    visibleErrors: errors,
    formState: { isValid },
    watch,
    resetField,
  } = useForm<DocumentUploadForm>({
    defaultValues: {
      clientId: client?.id || '',
      type: '',
      docType: '',
    },
  });
  const [documentSubTypes, setDocumentSubTypes] = useState<Option[]>([]);
  const [file, setFile] = useState<File>();
  const type = watch('type');

  const {
    mutate: upload,
    isPending,
    isSuccess,
    error: uploadError,
  } = useMutation({
    mutationFn: addClientDocument,
  });

  const accountTypes = useSelector(selectClientAccountTypes);
  const formsToSign = useSelector(selectSigningDocuments(accountTypes));
  const { isoCode } = useSelector(selectConfigsState);
  const dispatch = useDispatch();

  const formByFormName: Record<string, SigningDocument> = useMemo(
    () =>
      formsToSign.reduce(
        (acc, item) => ({
          ...acc,
          [item.formName || item.name]: item,
        }),
        {},
      ),
    [formsToSign],
  );

  const documentTypes: Option[] = useMemo(() => {
    return [
      ...DOCUMENT_TYPES,
      {
        value: DocumentType.DocumentSign,
        text: DocumentTypeText[DocumentType.DocumentSign],
      },
      {
        value: DocumentType.Internal,
        text: 'Branch approval documents',
      },
    ].sort((a, b) => (a.text || '').localeCompare(b.text || ''));
  }, []);

  useEffect(() => {
    switch (type) {
      case DocumentType.DocumentSign:
      case DocumentType.Identity:
        setValue('docType', '');
        resetField('docType', { defaultValue: '' });
        break;
      default:
        resetField('docType', { defaultValue: DocumentSubType.None });
        break;
    }

    setDocumentSubTypes(
      getDocSubTypesByType(type as DocumentType, {
        tenantISO: isoCode,
        formsToSign,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    onSetDisabled?.(isPending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const wrappedDocument = useMemo(
    () =>
      file
        ? createMockDocument({
            uri: URL.createObjectURL(file),
            contentType: file.type,
          })
        : undefined,
    [file],
  );

  useEffect(() => {
    if (client?.id) {
      setValue('clientId', client.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: 'Upload document success',
          severity: 'success',
        }),
      );
      dispatch(hideModal({ modalName, shouldRefreshData: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (uploadError) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(uploadError),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const fileFrameAction = useMemo(
    () => (defaultActions: FileFrameActionItem[]) => [
      ...defaultActions.filter(item => item.icon !== 'download'),
      {
        icon: 'reset',
        title: 'Change file',
        onClick: () => {
          setFile(undefined);
        },
      } as FileFrameActionItem,
    ],
    [],
  );

  const onSubmit = handleSubmit(payload => {
    if (!file) return;

    upload({
      clientId: payload.clientId,
      file: file,
      type: payload.type as DocumentType,
      ...(payload.docType !== DocumentSubType.None
        ? { subType: payload.docType as DocumentSubType }
        : {}),
      ...(payload.type === DocumentType.DocumentSign &&
      formByFormName[payload.docType]?.id
        ? {
            adobeSignWidgetId: formByFormName[payload.docType].id,
          }
        : {}),
    });
  });

  const onClose = () => {
    dispatch(hideModal({ modalName }));
  };

  return (
    <Form onSubmit={onSubmit} pb={2}>
      <TextInput label="Client" value={getDisplayField(client)} disabled />
      <Select
        options={documentTypes}
        label="Document type"
        control={control}
        name="type"
        fullWidth
        rules={{
          required: 'Document type is required',
        }}
        error={!!errors.type}
        helperText={errors.type?.message}
        disabled={isPending}
      />
      <Select
        options={documentSubTypes}
        label="Document sub-type"
        control={control}
        name="docType"
        fullWidth
        error={!!errors.docType}
        helperText={errors.docType?.message}
        disabled={isPending || !type || documentSubTypes.length <= 1}
        rules={{
          required: 'Document sub-type is required',
        }}
      />
      <FileContainer>
        {!wrappedDocument ? (
          <FileSelector
            acceptExtensions={SUPPORTED_MANUAL_UPLOAD_DOCUMENT_EXTENSIONS}
            maxBytes={MAX_DOCUMENT_SIZE}
            description={`Only ${SUPPORTED_MANUAL_UPLOAD_DOCUMENT_EXTENSIONS.join(', ')}, 50 MB or less`}
            onSetFile={setFile}
          />
        ) : (
          <FileFrame document={wrappedDocument} actionItems={fileFrameAction} />
        )}
      </FileContainer>
      <FormAction align="stretch">
        <Button
          {...getDataTestId(`upload-document-action-cancel-button`)}
          onClick={onClose}
          variant="outlined"
          disabled={isPending}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={!isValid || !file}
          isLoading={isPending}
          startIcon={<Icon name="upload" />}
          {...getDataTestId(`upload-document-action-upload-button`)}
        >
          Upload
        </LoadingButton>
      </FormAction>
    </Form>
  );
}
