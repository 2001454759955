import { AddCommentForm, Client, NoteType } from '@/types';
import { Alert } from '@mui/material';
import { Form, FormAction } from '../../../../components/Layout';
import { getAPIErrorMessage, getDataTestId } from '@/utils';
import { LoadingButton, TextInput } from '../../../../components/Form';
import { useForm } from '@/hooks';
import { addComment } from '@/api';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Icon } from '../../../../components/Icon';
import { TabNoteItem } from '@/pages/clients/ClientDetails/TabNotes/TabNotes.styled';

type AddCommentSectionProps = {
  client: Client;
  isEdit?: boolean;
  onSuccess: () => void;
};

export function AddCommentSection({
  client,
  isEdit,
  onSuccess,
}: AddCommentSectionProps) {
  const { setTrue, setFalse, value } = useBoolean(false);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
  } = useForm<AddCommentForm>({
    defaultValues: {
      content: '',
      category: NoteType.BasicInfo,
    },
  });

  const content = watch('content');

  const { isSuccess, mutate, isPending, error } = useMutation({
    mutationFn: addComment,
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
      setFalse();
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, reset]);

  const onSubmit = handleSubmit(form => {
    mutate({
      clientId: String(client.id),
      payload: form,
    });
  });

  const onCancel = () => {
    reset();
    setFalse();
  };

  return (
    <TabNoteItem>
      <Form onSubmit={onSubmit}>
        <TextInput
          onFocus={() => setTrue()}
          onBlur={() => setFalse()}
          type="text"
          placeholder="Content"
          name="content"
          control={control}
          rules={{
            required: 'Content is required',
          }}
          disabled={isPending}
          multiline
          rows={4}
        />
        {error && (
          <Alert icon={<Icon name="alert" />} color="error" variant="standard">
            {getAPIErrorMessage(error)}
          </Alert>
        )}
        {(value || content) && !isEdit && (
          <FormAction align="right">
            <LoadingButton
              variant="outlined"
              {...getDataTestId(`add-comment-section-cancel-button`)}
              onClick={onCancel}
              disabled={isPending}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              type="submit"
              {...getDataTestId(`add-comment-section-add-button`)}
              disabled={!isValid || isPending}
              isLoading={isPending}
            >
              Add
            </LoadingButton>
          </FormAction>
        )}
      </Form>
    </TabNoteItem>
  );
}
