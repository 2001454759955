import {
  ConfirmResetPasswordForm,
  LoginForm,
  NewPasswordRequiredForm,
  RequestResetPasswordForm,
  Tenant,
  User,
} from '@/types';
import {
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  resetPassword as amplifyResetPassword,
  confirmResetPassword as amplifyConfirmResetPassword,
  fetchAuthSession,
  confirmSignIn,
  updatePassword,
  signInWithRedirect,
} from 'aws-amplify/auth';
import { axios } from './axios';
import {
  LS_TENANT_KEY,
  REQUEST,
  VITE_COGNITO_EXTERNAL_PROVIDER_NAME,
} from '@/constants';
import { readLocal, saveLocal } from '@/utils';

export const getCurrentUser = async () => {
  const session = await fetchAuthSession();

  if (session && !session.tokens) {
    return null;
  }

  axios.defaults.headers.common['Authorization'] =
    `Bearer ${session.tokens?.idToken?.toString()}`;

  const { data: tenants = [] } = await axios.get<Tenant[]>(
    REQUEST.USER.TENANTS,
    {
      headers: {
        'x-tenant': undefined,
      },
    },
  );

  const savedTenant = readLocal(LS_TENANT_KEY);

  if (savedTenant && tenants.includes(savedTenant)) {
    axios.defaults.headers['x-tenant'] = savedTenant;
  } else if (tenants[0]) {
    axios.defaults.headers['x-tenant'] = tenants[0];
    saveLocal(LS_TENANT_KEY, tenants[0]);
  } else {
    await signOut();
    throw new Error(`This account doesn't belong to any territory.`);
  }

  const result = await axios.get<User>(REQUEST.USER.INFO);

  return {
    session,
    user: {
      ...result.data,
      tenants,
    },
  };
};

export const initPassword = async (payload: NewPasswordRequiredForm) => {
  return await confirmSignIn({
    challengeResponse: payload.password,
  });
};

export const signIn = async (payload: LoginForm) => {
  await amplifySignOut({ global: true });
  return amplifySignIn({
    username: payload.email,
    password: payload.password,
    options: {
      authFlowType: 'USER_PASSWORD_AUTH',
    },
  });
};

export const resetPassword = async ({ email }: RequestResetPasswordForm) => {
  return amplifyResetPassword({ username: email });
};

export const confirmResetPassword = async ({
  email,
  confirmationCode,
  newPassword,
}: ConfirmResetPasswordForm) => {
  return amplifyConfirmResetPassword({
    username: email,
    confirmationCode,
    newPassword,
  });
};

export const signInWithAzure = async () => {
  return signInWithRedirect({
    provider: {
      custom: VITE_COGNITO_EXTERNAL_PROVIDER_NAME,
    },
  });
};

export const signOut = async () => {
  axios.defaults.headers.common['Authorization'] = undefined;
  axios.defaults.headers.common['x-tenant'] = undefined;
  return amplifySignOut();
};

export const changePassword = async (payload: {
  oldPassword: string;
  newPassword: string;
}) => {
  return updatePassword(payload);
};
