import { createSlice } from '@reduxjs/toolkit';
import { UserRole } from '@/types';
import { fetchUserRoles } from './actions';

interface UIState {
  roles: UserRole[];
}

const initialState: UIState = {
  roles: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchUserRoles.fulfilled, (state, { payload }) => {
      state.roles = payload.sort((a, b) => a.displayOrder - b.displayOrder);
    });
  },
});

export const usersReducer = usersSlice.reducer;
