import { PropsWithChildren } from 'react';
import { MetadataViewerContainer } from './DataDisplay.styled';

export function MetadataViewer({ children }: PropsWithChildren) {
  return (
    <MetadataViewerContainer>
      <code>{children}</code>
    </MetadataViewerContainer>
  );
}
