import {
  Can,
  Filter,
  Icon,
  InfiniteTablePage,
  MetadataViewer,
  Table,
  ToggleGroup,
} from '@/components';
import { AuditActorType, AuditFilter, AuditLog, AuditLogStatus } from '@/types';
import {
  AUDIT_ACTOR_TYPES,
  AUDIT_LOG_STATUSES,
  AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS,
} from '@/constants';
import { formatTime } from '@/utils';
import { getAuditLogs } from '@/api';
import { useInfiniteTable } from '@/hooks';
import { Tooltip } from '@mui/material';
import { MouseEvent, useMemo } from 'react';

type AuditLogsProps = {
  actorType: AuditActorType;
  onActorTypeChange: (value: AuditActorType) => void;
};

export function AuditLogs({ actorType, onActorTypeChange }: AuditLogsProps) {
  const {
    rows: logs,
    control,
    debouncedFilter,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteTable<AuditLog, AuditFilter>({
    queryFn: getAuditLogs,
    getQueryFilter: filter => ({
      filter: {
        success: filter.success || [],
        actor_type: actorType
          ? [
              actorType,
              ...(actorType === AuditActorType.BackOffice
                ? [AuditActorType.System]
                : []),
            ]
          : [],
      },
    }),
    doNotPassFilterToUrl: true,
    queryKeys: [actorType],
    onModifyFilter: filter => ({
      success:
        !filter.success?.length || filter.success.length >= 2
          ? undefined
          : filter.success.every(item => item === AuditLogStatus.Success)
            ? ['true']
            : ['false'],
    }),
  });
  const sortBy = watch('sortBy');

  const onActorChange = (_: MouseEvent<HTMLElement>, value: AuditActorType) => {
    onActorTypeChange(value);
  };

  const newFilter = useMemo(
    () => ({
      actorType,
      ...debouncedFilter,
    }),
    [actorType, debouncedFilter],
  );

  return (
    <InfiniteTablePage
      title="Activity logs"
      filterControl={control}
      error={error}
      fetchTriggerRef={nextPageTriggerRef}
      slots={{
        quickFilter: (
          <Can
            do="view"
            on="schedule_job"
            passThrough
            children={allowed => {
              const filteredOptions = allowed
                ? AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS
                : AUDIT_ACTOR_TYPES;
              return (
                <ToggleGroup
                  name="actor-type"
                  value={actorType}
                  onChange={onActorChange}
                  options={filteredOptions}
                />
              );
            }}
          />
        ),
      }}
    >
      <Table
        sortBy={sortBy}
        isLoading={isFetching || isFetchingNextPage}
        rows={logs || []}
        onSortChange={onSortChange}
        withColumnFilter
        filter={newFilter}
        defaultFilteredColumns={[
          'timestamp',
          'actor',
          'event',
          'object',
          'status',
        ]}
        columns={[
          {
            header: 'Time',
            dataKey: 'timestamp',
            allowSort: true,
            id: 'timestamp',
            cell: row => formatTime(row.timestamp),
          },
          {
            header: 'Actor',
            dataKey: 'actor',
            allowSort: true,
            id: 'actor',
          },
          {
            header: 'Actor ID',
            dataKey: 'actor_id',
            id: 'actor_id',
          },
          {
            header: 'Event',
            dataKey: 'event',
            allowSort: true,
            id: 'event',
            widthRatio: 30,
          },
          {
            header: 'Event type',
            dataKey: 'event_type',
            id: 'event_type',
          },
          {
            header: 'Object',
            dataKey: 'object',
            id: 'object',
          },
          {
            header: 'Object ID',
            dataKey: 'object_id',
            id: 'object_id',
          },
          {
            header: 'Source',
            dataKey: 'source',
            id: 'source',
            widthRatio: 30,
          },
          {
            header: 'Status',
            id: 'status',
            filter: (
              <Filter
                name="success"
                options={AUDIT_LOG_STATUSES}
                control={control}
              />
            ),
            cell: row => (
              <Tooltip
                title={row.success ? 'Success' : 'Error'}
                placement="top"
                arrow
                enterDelay={500}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8],
                        },
                      },
                    ],
                  },
                }}
              >
                <span>
                  {row.success ? (
                    <Icon
                      name="check-mini"
                      color={theme => theme.palette.success.main}
                    />
                  ) : (
                    <Icon
                      name="warning"
                      color={theme => theme.palette.error.main}
                    />
                  )}
                </span>
              </Tooltip>
            ),
          },
        ]}
        renderRowExpand={row => (
          <MetadataViewer>{JSON.stringify(row, null, 2)}</MetadataViewer>
        )}
      />
    </InfiniteTablePage>
  );
}
