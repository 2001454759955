import { DrawerProps } from '../Modals.interface';
import { Client, ModalName } from '@/types';
import { getDataTestId } from '@/utils';
import { DocumentDrawer } from '../DocumentDrawer.styled';
import { Can } from '../../Can';
import { RequestDocumentForm } from './RequestDocumentForm';
import { useModal } from '@/hooks';

type RequestDocumentDrawerProps = {
  payload?: Client;
} & DrawerProps;

export function RequestDocumentDrawer({
  payload,
  ...props
}: RequestDocumentDrawerProps) {
  const { resetKey, hide } = useModal({
    open: props.open,
    name: ModalName.RequestDocument,
  });

  return (
    <Can do="request" on="document">
      <DocumentDrawer
        key={resetKey}
        {...getDataTestId('document-request-drawer')}
        {...props}
        title="Document Request"
        width={516}
        onClose={hide}
      >
        <RequestDocumentForm
          client={payload}
          modalName={ModalName.RequestDocument}
        />
      </DocumentDrawer>
    </Can>
  );
}
