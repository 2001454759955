import { Box, Chip, Paper, Stack, styled } from '@mui/material';
import { Icon } from '../Icon';
import { Link } from 'react-router-dom';
import { doNotForwardTheseProps, elevationLevel } from '@/styles';

export const Container = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary1000.main,
  borderRadius: '0 0 24px 24px',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: theme.layout.headerHeight.xs,
  zIndex: theme.zIndex.appBar,
  display: 'flex',
  alignItems: 'stretch',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  gap: theme.spacing(3),
}));
Container.defaultProps = {
  elevation: elevationLevel.header,
};

export const LeftSlot = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const RightSlot = styled(LeftSlot)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}));

export const CenterSlot = styled(LeftSlot)(({ theme }) => ({
  gap: theme.spacing(1),
  flex: 1,
  justifyContent: 'center',
}));

export const HeaderIcon = styled(Icon)({});
HeaderIcon.defaultProps = {
  color: theme => theme.palette.common.white,
};

export const NavLink = styled(Link, {
  shouldForwardProp: doNotForwardTheseProps('isHighlighted'),
})<{ isHighlighted?: boolean }>(({ theme, isHighlighted }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.typography.body1Medium.fontSize,
  fontWeight: theme.typography.body1Medium.fontWeight,
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textDecoration: 'none',
  transition: theme.transitions.create(['background-color', 'color']),

  ...(isHighlighted
    ? {
        color: theme.palette.primary800.contrastText,
        backgroundColor: theme.palette.primary800.main,
      }
    : {
        color: theme.palette.grey[500],
      }),

  ':hover': {
    color: theme.palette.primary.contrastText,
  },
}));

export const NavBadge = styled(Chip, {
  shouldForwardProp: doNotForwardTheseProps('mr', 'ml'),
})<{ mr?: number; ml?: number }>(({ theme, mr = 0, ml = 0 }) => ({
  backgroundColor: theme.palette.primaries[100],
  color: theme.palette.primaries[500],
  fontSize: theme.typography.caption2Bold.fontSize,
  fontWeight: theme.typography.caption2Bold.fontWeight,
  borderRadius: 16,
  height: 16,
  marginLeft: theme.spacing(ml),
  marginRight: theme.spacing(mr),
  textTransform: 'uppercase',
  '.MuiChip-label': {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));
NavBadge.defaultProps = {
  size: 'small',
};

export const RegionSelectContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 264,
  },
}));
