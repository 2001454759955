import { createSlice } from '@reduxjs/toolkit';
import { Option, RiskLevel, User } from '@/types';
import {
  fetchReviewers,
  fetchRiskLevels,
  fetchTerritories,
  resetPicklists,
} from './actions';
import { Territory } from '@/constants/places';

interface UIState {
  reviewers: User[];
  reviewerById: Record<User['id'], User>;
  riskLevels: RiskLevel[];
  riskLevelOptions: Option[];
  riskLevelByCode: Record<string, RiskLevel>;
  territories: Territory[];
}

const initialState: UIState = {
  reviewers: [],
  reviewerById: {},
  riskLevels: [],
  riskLevelOptions: [],
  riskLevelByCode: {},
  territories: [],
};

export const picklistsSlice = createSlice({
  name: 'picklists',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchReviewers.fulfilled, (state, { payload }) => {
      state.reviewers = payload;
      state.reviewerById = payload.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {},
      );
    });
    builder.addCase(fetchRiskLevels.fulfilled, (state, { payload }) => {
      state.riskLevels = payload;

      const riskLevelByCode: typeof state.riskLevelByCode = {};
      state.riskLevelOptions = payload.map(level => {
        riskLevelByCode[level.code] = level;
        return {
          value: level.code,
          text: level.value,
        } as Option;
      });
      state.riskLevelByCode = riskLevelByCode;
    });
    builder.addCase(fetchTerritories.fulfilled, (state, { payload }) => {
      state.territories = payload;
    });
    builder.addCase(resetPicklists, state => {
      state.reviewers = initialState.reviewers;
      state.territories = initialState.territories;
      state.riskLevels = initialState.riskLevels;
      state.riskLevelOptions = initialState.riskLevelOptions;
      state.riskLevelByCode = initialState.riskLevelByCode;
    });
  },
});

export const picklistsReducer = picklistsSlice.reducer;
