import { Components, Fade } from '@mui/material';
import { elevationLevel } from '../constants/paper';

export const menuConfigs: Components['MuiMenu'] = {
  defaultProps: {
    TransitionComponent: Fade,
    slotProps: {
      paper: {
        elevation: elevationLevel.l,
        sx: theme => ({
          border: `1px solid ${theme.palette.grey[200]}`,
        }),
      },
    },
  },
};
