import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const caymanConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-KY',
  isoCode: 'CYM',
  currency: 'KYD',
  name: 'Cayman Islands',
  flag: 'flag-cayman-islands',
};
