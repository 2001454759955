import { Components, createTheme } from '@mui/material';
import { typographyConfigs } from '../typography';
import { paletteConfigs } from '../palette';
import { spacing, headerHeight } from '../constants/layout';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const snackbarConfigs: Components['MuiSnackbar'] = {
  styleOverrides: {
    root: {
      width: 'fit-content',
      zIndex: theme.zIndex.tooltip,

      '&.MuiSnackbar-anchorOriginTopCenter': {
        top: headerHeight.xs + spacing * 3,
        left: '50%',
        right: 'unset',
        transform: 'translateX(-50%)',
      },

      '.MuiSnackbarContent-root': {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        flexGrow: 'initial',
        flexDirection: 'row',
        padding: spacing * 2,

        '.MuiSnackbarContent-message': {
          padding: 0,
          alignItems: 'center',
          display: 'flex',
          gap: spacing * 1,

          '.MuiIconButton-root': {
            padding: spacing * 0.5,
            marginRight: -spacing * 0.5,

            '.MuiSvgIcon-root': {
              fill: theme.palette.common.white,
              height: 20,
              width: 20,
            },
          },
        },
      },

      '&.toast-info .MuiSnackbarContent-root': {
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '.MuiSvgIcon-root': {
          fill: theme.palette.info.contrastText,
        },
      },
      '&.toast-error .MuiSnackbarContent-root': {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '.MuiSvgIcon-root': {
          fill: theme.palette.error.contrastText,
        },
      },
      '&.toast-success .MuiSnackbarContent-root': {
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        '.MuiSvgIcon-root': {
          fill: theme.palette.success.contrastText,
        },
      },
    },
  },
};
