import { Can } from '@/components';
import { AbilityAction, AbilitySubject } from '@/types';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

type AbilityRouteHandlerProps = {
  permission: {
    do: AbilityAction;
    on: AbilitySubject;
  };
} & PropsWithChildren;

export function AbilityRouteHandler(props: AbilityRouteHandlerProps) {
  const navigate = useNavigate();

  return (
    <Can
      {...props.permission}
      passThrough
      children={allowed => {
        !allowed && navigate('/');
        return props.children;
      }}
    />
  );
}
