import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useURLParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (searchState: object) => {
      setSearchParams(
        params => {
          Object.entries(searchState).forEach(([key, value]) => {
            if (['', undefined, null, false].includes(value)) {
              params.delete(key);
            } else if (Array.isArray(value)) {
              if (!value.length) {
                params.delete(key);
              } else {
                params.set(key, value.join(','));
              }
            } else if (typeof value === 'boolean') {
              params.set(key, 'true');
            } else if (['number', 'string'].includes(typeof value)) {
              params.set(key, String(value));
            }
          });

          return params;
        },
        { replace: false },
      );
    },
    [setSearchParams],
  );

  const getValue = useCallback(
    (
      key: string,
      castType: 'array' | 'string' | 'number' | 'boolean' = 'string',
    ) => {
      switch (castType) {
        case 'array':
          return searchParams.get(key)?.split(',') || [];
        case 'boolean':
          return searchParams.get(key)?.toLowerCase() === 'true';
        case 'number': {
          const value = searchParams.get(key);
          return value ? parseInt(value) : undefined;
        }
        case 'string':
          return searchParams.get(key) || '';
        default:
          return searchParams.get(key);
      }
    },
    [searchParams],
  );

  const clearParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return {
    searchParams,
    getValue,
    updateSearchParams,
    clearParams,
  };
}
