import { ColorExtractFn } from '@/types';
import { IconProps } from './Icon.interface';
import { DecorateCell } from './Icon.styled';
import { BoxProps, useTheme } from '@mui/material';
import { Icon } from './Icon';

type IconCellProps = {
  bgColor?: ColorExtractFn;
  containerProps?: Omit<BoxProps, 'color'>;
  size?: number;
  iconSize?: number;
} & Omit<IconProps, 'size'>;

export function IconCell({
  className,
  bgColor,
  name,
  containerProps,
  color = theme => theme.palette.common.white,
  size,
  iconSize = 44,
}: IconCellProps) {
  const theme = useTheme();

  return (
    <DecorateCell
      className={className}
      color={bgColor?.(theme)}
      size={size}
      {...containerProps}
    >
      <Icon name={name} color={color} size={iconSize} />
    </DecorateCell>
  );
}
