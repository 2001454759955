import {
  CheckboxProps as MUICheckboxProps,
  TypographyProps,
} from '@mui/material';
import { Icon } from '../Icon';
import {
  CheckboxContainer,
  StyledCheckbox,
  StyledCheckboxFormControlLabel,
} from './Form.styled';
import { getDataTestId } from '@/utils';

type CheckboxProps = {
  label?: string;
  labelProps?: TypographyProps;
} & MUICheckboxProps;

export function Checkbox({ label, labelProps, ...props }: CheckboxProps) {
  return (
    <StyledCheckboxFormControlLabel
      control={
        <StyledCheckbox
          {...getDataTestId(`${label}-checkbox`)}
          icon={<CheckboxContainer />}
          checkedIcon={
            <CheckboxContainer checked>
              <Icon name="check-mini" />
            </CheckboxContainer>
          }
          {...props}
        />
      }
      label={label}
      slotProps={{
        typography: {
          variant: 'body2',
          ...labelProps,
        },
      }}
    />
  );
}
