import { IconButton, Modal, styled } from '@mui/material';

export const TooltipChild = styled('span')(() => ({
  lineHeight: 0,
}));

export const StatusActionButton = styled(IconButton)(() => ({
  padding: 0,
}));

export const ConfirmReRunModal = styled(Modal)(() => ({
  '.MuiPaper-root': {
    width: 480,
  },
}));
