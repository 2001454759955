import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const ghanaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-GH',
  isoCode: 'GHA',
  currency: 'GHS',
  name: 'Ghana',
  flag: 'flag-ghana',
};
