import { Fragment, useEffect } from 'react';
import { Field } from '@/components';
import { Client } from '@/types';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/hooks';
import { fetchClientAccountTypes, selectClientAccountTypes } from '@/redux';

type AccountTypeFieldProps = {
  client: Client;
};

export function AccountTypeField({ client }: AccountTypeFieldProps) {
  const accountTypes = useSelector(selectClientAccountTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClientAccountTypes(client.id));
  }, [client.id, dispatch]);

  return (
    <Field
      label="Product type"
      content={
        <>
          {accountTypes.map((item, index) => (
            <Fragment key={item.id}>
              {accountTypes.length > 1 && (
                <Typography
                  component="span"
                  color={theme => theme.palette.grey[500]}
                >
                  {'\u2022 '}
                </Typography>
              )}
              {item.currency} {item.name}
              {index < accountTypes.length - 1 && <br />}
            </Fragment>
          ))}
        </>
      }
    />
  );
}
