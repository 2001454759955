import { CollapsibleSwitch } from '@/types';
import { Box, Typography } from '@mui/material';
import { MenuDivider } from '../User/User.styled';
import { CollapsibleGroupSwitchItem } from './CollapsibleGroupSwitchItem';
import {
  StyledCollapseListItem,
  StyledCollapseSwitch,
  StyledReadOnlyIcon,
} from './Collapse.styled';

type CollapsibleGroupSwitchProps = {
  label?: string;
  checked?: boolean;
  canTogglePermission?: boolean;
  singleItemCategoryExpand?: boolean;
  data: CollapsibleSwitch[];
  onToggleAllCategory: (isChecked: boolean) => void;
  onCategoryToggle: (isChecked: boolean, checkedItems: string[]) => void;
  onItemCheck: (value: string) => void;
};

export function CollapsibleGroupSwitch({
  label,
  checked,
  data,
  canTogglePermission,
  onToggleAllCategory,
  onCategoryToggle,
  onItemCheck,
  singleItemCategoryExpand,
}: CollapsibleGroupSwitchProps) {
  const hasReadOnlyItems = data.filter(item => item.readOnly).length > 0;
  return (
    <Box>
      <MenuDivider />
      {label && canTogglePermission && (
        <>
          <StyledCollapseListItem>
            <Typography flex={1} variant="body2Bold">
              {label}
            </Typography>
            {hasReadOnlyItems ? (
              <StyledReadOnlyIcon
                name={'minus-circle'}
                color={theme => theme.palette.red[400]}
                size={24}
              />
            ) : (
              <StyledCollapseSwitch
                checked={checked}
                onChange={e => onToggleAllCategory(e.currentTarget.checked)}
              />
            )}
          </StyledCollapseListItem>
          <MenuDivider />
        </>
      )}
      {data.map(item => (
        <CollapsibleGroupSwitchItem
          key={`collapsible-category-${item.categoryName}-parent`}
          data={item}
          singleItemCategoryExpand={singleItemCategoryExpand}
          canTogglePermission={canTogglePermission}
          onCategoryToggle={onCategoryToggle}
          onItemCheck={onItemCheck}
        />
      ))}
    </Box>
  );
}
