import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';

const theme = createTheme({
  palette: paletteConfigs,
});

export const dividerConfigs: Components['MuiDivider'] = {
  styleOverrides: {
    root: {
      borderColor: theme.palette.grey[300],
    },
  },
};
