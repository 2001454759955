import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';

const theme = createTheme({
  palette: paletteConfigs,
});

export const modalConfigs: Components['MuiModal'] = {
  defaultProps: {
    disableScrollLock: false,
    slotProps: {
      root: {},
    },
  },
  styleOverrides: {
    root: {
      ':not(MuiPopover-root):not(.MuiMenu-root)': {
        '.MuiModal-backdrop': {
          backgroundColor: theme.palette.grey.overlayOnLight,
        },
      },
    },
  },
};
