import { axios, signOut as doSignOut } from '@/api';
import { User } from '@/types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setLoggedInUser = createAction<User | null | undefined>(
  'auth/set-logged-in-user',
);

export const setLoginResponseError = createAction<string>(
  'auth/set-login-response-error',
);

export const resetLoginResponseError = createAction(
  'auth/reset-login-response-error',
);

export const signOut = createAsyncThunk('auth/sign-out', async () => {
  doSignOut();
  axios.defaults.headers.common['Authorization'] = undefined;
});

export const notifyUserChanged = createAction('auth/notify-user-change');
export const notifyUserChangedSolved = createAction(
  'auth/notify-user-change-solved',
);

export const resetAuth = createAction('auth/reset');
