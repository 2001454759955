import { RoleDetailBlock } from './RoleDetail.styled';
import { AbilityContext, Heading } from '@/components';
import { CollapsibleGroupSwitch } from '@/components/CollapsibleGroupSwitch/CollapsibleGroupSwitch';
import { useDispatch, useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import {
  selectRoleDetail,
  toggleAllPermissions,
  toggleCategoryPermission,
  togglePermission,
} from '@/redux/role-details';
import { selectPermissions } from '@/redux/role-permissions';
import { RoleName } from '@/types';
import { getGroupedPermission } from '@/utils';
import { useContext, useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

type SectionRolePermissionsProps = {
  roleName: string;
};
export function SectionRolePermissions({
  roleName,
}: SectionRolePermissionsProps) {
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);
  const user = useSelector(selectLoggedInUser);
  const rolePermission = useSelector(selectPermissions);
  const { roleDetails } = useSelector(selectRoleDetail);
  const {
    setFalse: unSetFullControl,
    setTrue: setFullControl,
    value: isFullControl,
  } = useBoolean(false);

  useEffect(() => {
    roleDetails?.permissions.length === rolePermission.permissions.length
      ? setFullControl()
      : unSetFullControl();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleDetails?.permissions]);

  const onToggleAllCategory = (checked: boolean) => {
    dispatch(
      toggleAllPermissions({
        checked,
        permissions: rolePermission.permissions.map(
          permissionItem => permissionItem.name,
        ),
      }),
    );
  };

  return (
    <RoleDetailBlock>
      <Heading title="Permissions" isSubSection />
      <CollapsibleGroupSwitch
        label={roleName === RoleName.Admin ? undefined : "Full access"}
        canTogglePermission={ability.can('edit', 'administration_roles')}
        checked={isFullControl}
        singleItemCategoryExpand
        data={getGroupedPermission(
          Boolean(user?.isAdmin),
          roleName === RoleName.Admin,
          rolePermission.permissions,
          roleDetails?.permissions,
        )}
        onCategoryToggle={(checked, categoryPermissions) =>
          dispatch(
            toggleCategoryPermission({
              checked,
              categoryPermissions,
            }),
          )
        }
        onToggleAllCategory={onToggleAllCategory}
        onItemCheck={checkedValue => dispatch(togglePermission(checkedValue))}
      />
    </RoleDetailBlock>
  );
}
