import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Icon } from '../Icon';
import { getDataTestId } from '@/utils';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { SearchTextField } from './Form.styled';

type TextInputProps<FormPayload extends FieldValues> = {
  value?: string;
  control?: Control<FormPayload>;
  name?: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  template?: 'search';
} & Omit<TextFieldProps, 'variant' | 'name'>;

export function TextInput<FormPayload extends FieldValues>({
  control,
  rules,
  name,
  template,
  error,
  helperText,
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  ...props
}: TextInputProps<FormPayload>) {
  const [passwordType, setPasswordType] =
    useState<TextFieldProps['type']>('password');

  const togglePasswordVisibility = () =>
    setPasswordType(prev => (prev === 'password' ? 'text' : 'password'));

  const render = useCallback(
    ({
      value,
      onChange,
      onBlur,
      defaultValue,
      isDirty,
    }: {
      value: TextFieldProps['value'];
      onChange: TextFieldProps['onChange'];
      onBlur: TextFieldProps['onBlur'];
      defaultValue?: TextFieldProps['defaultValue'];
      isDirty?: boolean;
    }) => {
      const isPasswordInput = type === 'password';

      const textFieldProps: TextFieldProps = {
        ...getDataTestId(`${placeholder || name || 'text'}-input`),
        name,
        type: isPasswordInput ? passwordType : type,
        value:
          (value && typeof value === 'string') || isDirty
            ? value
            : defaultValue,
        onChange: onChange,
        onBlur: onBlur,
        error,
        helperText: error ? (
          <Stack gap={0.5} component="span">
            <Icon
              name="alert"
              size={20}
              color={theme => theme.palette.error.main}
            />
            {helperText}
          </Stack>
        ) : (
          helperText
        ),
        InputProps: {
          startAdornment:
            template === 'search' ? (
              <InputAdornment position="start">
                <Icon
                  name="search"
                  size={20}
                  color={theme => theme.palette.grey[700]}
                />
              </InputAdornment>
            ) : undefined,
          endAdornment: isPasswordInput ? (
            <InputAdornment position="end">
              <IconButton
                {...getDataTestId(
                  `${placeholder || name || 'password'}-visibility-toggle`,
                )}
                title={
                  passwordType === 'password'
                    ? 'Show password'
                    : 'Hide password'
                }
                onClick={togglePasswordVisibility}
                edge="end"
                size="small"
              >
                <Icon
                  name={passwordType === 'password' ? 'eye-open' : 'eye-close'}
                  color={theme => theme.palette.grey[700]}
                />
              </IconButton>
            </InputAdornment>
          ) : null,
        },
        placeholder,
        ...props,
      };

      return template === 'search' ? (
        <SearchTextField {...textFieldProps} />
      ) : (
        <TextField {...textFieldProps} />
      );
    },
    [error, helperText, name, passwordType, placeholder, props, template, type],
  );

  if (control && name) {
    return (
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { isDirty },
          formState: { defaultValues },
        }) =>
          render({
            value,
            onChange,
            onBlur,
            defaultValue: defaultValues?.[name],
            isDirty,
          })
        }
      />
    );
  }
  return render({ value, onChange, onBlur });
}
