import { VITE_ENVIRONMENT } from '@/constants';
import { CollapsibleSwitch, Environment, PermissionDetails } from '@/types';
const groupByComponent = (
  _: boolean,
  isAdminRoleCard: boolean,
  permissions: PermissionDetails[],
  activePermissions?: string[],
) => {
  const isProduction = VITE_ENVIRONMENT === Environment.Production;
  const activeAdminCategories = ['groups', 'users', 'feature_flags'];
  const readOnlyAdminCategories = ['groups', 'users'];
  const filteredPermissions = isProduction
    ? isAdminRoleCard
      ? permissions.filter(item =>
          activeAdminCategories.includes(String(item.component).toLowerCase()),
        )
      : permissions.filter(
          item =>
            !activeAdminCategories.includes(
              String(item.component).toLowerCase(),
            ),
        )
    : permissions;

  const roleTypes = filteredPermissions.map(item => item.component);
  const modifiedItems = [...new Set(roleTypes)].map(roleCategory => {
    const categoryPermissions = filteredPermissions.filter(
      item => item.component === roleCategory,
    );
    const modifiedObject = {
      categoryName: String(roleCategory).replaceAll('_', ' '),
      checked: false,
      readOnly: false,
      noOfCheckedItems: 0,
      items: categoryPermissions.map(item => ({
        label: String(item.display).replaceAll('_', ' '),
        value: item.name,
        checked: activePermissions?.includes(item.name),
        readOnly: isProduction
          ? readOnlyAdminCategories.includes(
              String(item.component).toLowerCase(),
            ) && isAdminRoleCard
          : false,
      })),
    };
    const total = modifiedObject.items.reduce(
      (acc, item) => (activePermissions?.includes(item.value) ? acc + 1 : acc),
      0,
    );
    modifiedObject.noOfCheckedItems = modifiedObject.items.filter(
      item => item.checked,
    ).length;
    modifiedObject.checked = modifiedObject.items.length === total;
    modifiedObject.readOnly = isProduction
      ? modifiedObject.items.filter(item => item.readOnly).length > 0
      : false;

    return modifiedObject;
  });
  return modifiedItems;
};

export const getGroupedPermission = (
  isAdminRole: boolean,
  isAdminRoleCard: boolean,
  permissions: PermissionDetails[],
  assignedPermissions?: string[],
) => {
  return groupByComponent(
    isAdminRole,
    isAdminRoleCard,
    permissions,
    assignedPermissions,
  ) as CollapsibleSwitch[];
};

export const getPermissionsToUpdate = () => {};
