import { PEPHit } from '@/types';
import {
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Icon } from '../../Icon';
import { getDataTestId } from '@/utils';
import { Fragment, PropsWithChildren, useCallback } from 'react';
import {
  StyledAccordion,
  StyledAccordionDetails,
} from './PEPHitAccordion.styled';
import { ClientBlock } from '../../User';

type PEPHitAccordionProps = {
  data: PEPHit;
  defaultExpanded?: boolean;
} & PropsWithChildren;

export function PEPHitAccordion({
  data,
  defaultExpanded,
}: PEPHitAccordionProps) {
  const entries = Object.entries(data).filter(
    ([key]) => !['list_date'].includes(key),
  );

  const renderEntryLabel = useCallback((key: string) => {
    const text = key.split('_').join(' ');

    return (
      <Typography variant="body2" color={theme => theme.palette.grey[700]}>
        {text.charAt(0).toUpperCase() + text.slice(1)}
      </Typography>
    );
  }, []);

  const renderEntryValue = useCallback((value: number | string | string[]) => {
    if (typeof value === 'number' || typeof value === 'string') {
      return (
        <Typography variant="body2" color={theme => theme.palette.text.primary}>
          {value}
        </Typography>
      );
    }

    if (value.some(text => typeof text === 'string' && text.length > 50)) {
      return (
        <Box component="ul" p={0} pl={1.5} m={0}>
          {value.map(text => (
            <Typography
              key={text}
              component="li"
              variant="body2"
              color={theme => theme.palette.text.primary}
              mb={0.5}
            >
              {text}
            </Typography>
          ))}
        </Box>
      );
    } else {
      return (
        <Typography variant="body2" color={theme => theme.palette.text.primary}>
          {value.join(', ')}
        </Typography>
      );
    }
  }, []);

  return (
    <StyledAccordion
      {...getDataTestId(`pep-hit-accordion`)}
      defaultExpanded={defaultExpanded}
      disabled={!entries.length}
    >
      <AccordionSummary
        expandIcon={
          <Icon name="arrow-down" color={theme => theme.palette.grey[700]} />
        }
      >
        <ClientBlock
          name={data.name}
          nameProps={{ variant: 'body2Bold' }}
          direction="row"
        />
      </AccordionSummary>
      <StyledAccordionDetails>
        <Grid container rowSpacing={2}>
          {entries.map(([key, value], index) => (
            <Fragment key={`pep-hit-field-${key}`}>
              <Grid item container xs={12}>
                <Grid item xs={3}>
                  {renderEntryLabel(key)}
                </Grid>
                <Grid item xs={9}>
                  {renderEntryValue(value)}
                </Grid>
                {index < entries.length - 1 && (
                  <Grid item xs={12} pt={2}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
