import { Box, styled } from '@mui/material';

export const GroupDescriptionContainer = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey[500],
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.63, 1.5, 1.63, 2),
  borderWidth: 1,
  height: 98,
  transition: theme.transitions.create('border-color'),
}));
