import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const sintMaartenConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-SX',
  isoCode: 'SXM',
  currency: 'ANG',
  name: 'Sint Maarten',
  flag: 'flag-sint-maarten',
};
