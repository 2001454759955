import { DocumentStatus } from '@/types';

export const DocumentDBFields: Record<string, string> = {
  updatedAt: 'updated_at',
};

export const ABOUT_TO_EXPIRE_DURATION_MILISECONDS = 1000 * 60 * 60 * 24 * 30;

export const DOCUMENT_STATUS_STEP: Record<string, number> = {
  [DocumentStatus.Approved]: 1,
  [DocumentStatus.Signing]: 2,
  [DocumentStatus.Pending]: 3,
  [DocumentStatus.InReview]: 4,
  [DocumentStatus.Expired]: 5,
  [DocumentStatus.Empty]: 6,
  [DocumentStatus.Rejected]: 7,
};

export const SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
];
export const SUPPORTED_MANUAL_UPLOAD_DOCUMENT_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
];
export const MAX_DOCUMENT_SIZE = 52428800;
