import { doNotForwardTheseProps } from '@/styles';
import { Box, alpha, styled } from '@mui/material';

export const ImageContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  position: 'relative',

  '.MuiAlert-root': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const StyledImage = styled('img', {
  shouldForwardProp: doNotForwardTheseProps('isHidden', 'rotate'),
})<{ isHidden?: boolean; rotate?: number }>(({ isHidden, rotate, theme }) => ({
  display: 'block',
  objectFit: 'scale-down',
  width: '100%',
  height: '100%',
  opacity: isHidden ? 0 : 1,
  transform: `rotate(${rotate}deg)`,
  transformOrigin: 'center',
  transition: theme.transitions.create(['transform']),
}));

export const ImageLoader = styled(Box)(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const FileThumbnailContainer = styled(Box)(({ onClick }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  overflowY: 'auto',
  cursor: onClick ? 'pointer' : 'default',
}));
FileThumbnailContainer.defaultProps = {
  className: 'FileThumbnailContainer',
};

export const PDFContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 1400,
  margin: '0 auto',

  '.react-pdf__Document': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),

    '.react-pdf__message': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));

export const FileViewerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: 8,
  border: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',
  height: '100%',
  width: '100%',
}));

export const FileFrameAction = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('buttonColor'),
})<{ buttonColor?: 'primary' | 'white' }>(
  ({ theme, buttonColor = 'primary' }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    zIndex: 4,

    '.MuiIconButton-root': {
      backgroundColor:
        buttonColor === 'primary'
          ? theme.palette.primaries[400]
          : theme.palette.background.paper,
      borderRadius: 12,
      color: theme.palette.common.white,
      opacity: 0.5,
      transition: theme.transitions.create(['opacity', 'background-color']),

      '.MuiSvgIcon-root': {
        fill:
          buttonColor === 'primary'
            ? theme.palette.common.white
            : theme.palette.grey[700],
      },

      ':hover': {
        backgroundColor:
          buttonColor === 'primary'
            ? theme.palette.primaries[400]
            : theme.palette.background.paper,
        opacity: 1,

        '.MuiSvgIcon-root': {
          fill:
            buttonColor === 'primary'
              ? theme.palette.common.white
              : theme.palette.grey[700],
        },
      },
    },
  }),
);

export const FileViewerAction = styled(FileFrameAction)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(3),
  zIndex: theme.zIndex.modal + 1,
}));

export const Lightbox = styled(Box)(({ theme }) => ({
  backgroundColor: alpha('' + theme.palette.primaries[800], 0.95),
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  left: 0,
  top: 0,
  overflowY: 'auto',
  zIndex: theme.zIndex.modal,

  '.PDFContainer': {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    '.react-pdf__Document': {
      gap: theme.spacing(4),
      padding: theme.spacing(4),
    },
  },
}));

export const ImageViewerContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  position: 'relative',

  '.react-transform-wrapper': {
    height: '100%',
    width: '100%',
  },
}));

export const ViewerCenterSlot = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
}));
