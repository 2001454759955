import { ApiFilter, DataList, Job } from '@/types';
import { axios } from './axios';
import { REQUEST, ScheduleJobDBFields } from '@/constants';
import { getRequestFilterParams } from '@/utils';

export const getJobById = async (id: string) => {
  const result = await axios.get<Job>(REQUEST.JOB.INFO(id));

  return result.data;
};

export const runJobById = async (id: string) => {
  const result = await axios.post(REQUEST.JOB.RUN(id));

  return result.data;
};

export const getJobs = async (filter: ApiFilter) => {
  const params = getRequestFilterParams(filter, ScheduleJobDBFields);

  const result = await axios.get<DataList<Job>>(REQUEST.JOB.ROOT, {
    params,
  });

  return {
    data: result.data.data.map(job => {
      const payload =
        typeof job.payload === 'string' ? JSON.parse(job.payload) : job.payload;

      let response: unknown;

      try {
        response =
          typeof job.response === 'string'
            ? JSON.parse(job.response)
            : job.response;
      } catch (e) {
        response = job.response;
      }

      return {
        ...job,
        payload,
        response: response as string,
      };
    }),
    size: result.data.size,
    total: result.data.total,
    page: result.data.page,
  };
};
