import {
  CircularProgress,
  TabProps as MUITabProps,
  Typography,
} from '@mui/material';
import { StyledTab, TabChip } from './DataDisplay.styled';
import { ReactNode } from 'react';
import { isNil } from '@/utils';

type TabProps = {
  isLoading?: boolean;
  chipContent?: ReactNode;
} & MUITabProps;

export function Tab({ isLoading, chipContent, label, ...props }: TabProps) {
  return (
    <StyledTab
      label={
        <>
          {typeof label === 'string' ? (
            <Typography variant="body2">{label}</Typography>
          ) : (
            label
          )}
          {isLoading ? (
            <CircularProgress size={14} />
          ) : !isNil(chipContent) ? (
            <TabChip label={chipContent} />
          ) : null}
        </>
      }
      {...props}
    />
  );
}
