import {
  CircularProgress,
  CircularProgressProps,
  useTheme,
} from '@mui/material';
import { FullPageBackground, FullPageOverlayBackground } from './Layout.styled';
import { PropsWithChildren } from 'react';
import { ColorExtractFn } from '@/types';

type FullPageLoaderProps = {
  className?: string;
  color?: CircularProgressProps['color'];
} & PropsWithChildren;

export function FullPageLoader({
  className,
  color,
  children,
}: FullPageLoaderProps) {
  return (
    <FullPageBackground className={className}>
      <CircularProgress color={color} />
      {children}
    </FullPageBackground>
  );
}

type FullPageLoaderOverlayProps = {
  bgColor?: ColorExtractFn;
} & FullPageLoaderProps;

export function FullPageLoaderOverlay({
  className,
  bgColor,
  color,
}: FullPageLoaderOverlayProps) {
  const theme = useTheme();
  return (
    <FullPageOverlayBackground className={className} bgColor={bgColor?.(theme)}>
      <CircularProgress color={color} />
    </FullPageOverlayBackground>
  );
}
