import { signIn, signInWithAzure } from '@/api';
import { Form, Icon, TextInput } from '@/components';
import { EMAIL_PATTERN, VITE_ENVIRONMENT } from '@/constants';
import { useDispatch, useForm, useSelector } from '@/hooks';
import { Environment, LoginForm as LoginFormType, SignInStep } from '@/types';
import { getAPIErrorMessage, getDataTestId } from '@/utils';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { StyledLoadingButton } from './Login.styled';
import { resetLoginResponseError, selectAuthState } from '@/redux';

type LoginFormProps = {
  onSetStep: (value: SignInStep, payload?: { email: string }) => void;
  onBlocked?: () => void;
};

export function LoginForm({ onSetStep, onBlocked }: LoginFormProps) {
  const dispatch = useDispatch();
  const [attempts, setAttempts] = useState(0);
  const [isBlocked, setBlocked] = useState(false);
  const { loginResponseError } = useSelector(selectAuthState);
  const {
    handleSubmit,
    control,
    visibleErrors: errors,
    watch,
  } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const {
    data: result,
    mutate,
    isPending,
    error,
    reset,
  } = useMutation({
    mutationFn: signIn,
  });

  const email = watch('email');

  const shouldShowEmailPasswordLogin =
    VITE_ENVIRONMENT !== Environment.Production;

  useEffect(() => {
    if (result) {
      const { isSignedIn, nextStep } = result;

      if (!isSignedIn) {
        onSetStep(nextStep.signInStep as SignInStep, { email });
      }
    }
  }, [email, onSetStep, result]);

  useEffect(() => {
    if (error && error.message === 'Password attempts exceeded') {
      setBlocked(true);
      onBlocked?.();
    }
  }, [error, onBlocked]);

  const onSubmit = handleSubmit(async payload => {
    reset();
    dispatch(resetLoginResponseError());
    setAttempts(prev => prev + 1);
    mutate(payload);
  });

  const onCannotLogin = () => {
    onSetStep(SignInStep.ForgotPassword);
  };

  return (
    <Form alignItems="stretch" onSubmit={onSubmit}>
      <Stack
        direction="column"
        gap={1.5}
        mb={shouldShowEmailPasswordLogin ? 0 : 14}
      >
        <Typography variant="h3">Login to your account</Typography>
        <Typography
          variant="body2"
          color={theme => theme.palette.grey[800]}
          textAlign="center"
        >
          To access the Back Office, you need login credentials provided by your
          administrator
        </Typography>
      </Stack>
      {shouldShowEmailPasswordLogin && (
        <>
          <TextInput
            placeholder="Email"
            name="email"
            control={control}
            error={!!errors.email}
            helperText={errors.email?.message}
            rules={{
              required: 'Email is required',
              pattern: {
                value: new RegExp(EMAIL_PATTERN, 'g'),
                message: 'Invalid email format',
              },
            }}
            disabled={isPending || isBlocked}
          />
          <TextInput
            type="password"
            placeholder="Password"
            name="password"
            control={control}
            error={!!errors.password}
            helperText={errors.password?.message}
            rules={{
              required: 'Password is required',
            }}
            disabled={isPending || isBlocked}
          />
        </>
      )}
      {error || loginResponseError ? (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {error ? getAPIErrorMessage(error) : loginResponseError}
        </Alert>
      ) : attempts >= 2 ? (
        <Alert
          icon={<Icon name="warning" />}
          color="warning"
          variant="standard"
        >
          Incorrect login attempts can result in the security block of your
          account
        </Alert>
      ) : null}
      <Stack direction="column" alignItems="stretch">
        {shouldShowEmailPasswordLogin && (
          <StyledLoadingButton
            {...getDataTestId(`login-form-action-login-button`)}
            type="submit"
            disabled={isPending || isBlocked}
            isLoading={isPending}
          >
            Log in
          </StyledLoadingButton>
        )}
        <StyledLoadingButton
          {...getDataTestId(`login-form-action-sign-in-with-azure`)}
          disabled={isPending}
          onClick={signInWithAzure}
          startIcon={<Icon name="entra-id" />}
        >
          Continue with Microsoft Entra ID
        </StyledLoadingButton>
      </Stack>
      {shouldShowEmailPasswordLogin && (
        <Button
          {...getDataTestId(`login-form-action-forgot-password-button`)}
          variant="linkBold"
          disabled={isPending}
          onClick={onCannotLogin}
        >
          Can't login?
        </Button>
      )}
    </Form>
  );
}
