import { Option } from '@/types';
import { getDataTestId } from '@/utils';
import { IconButton, ListItemIcon, MenuItem } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';
import { Icon } from '../Icon';
import { StyledMenu } from './Action.styled';

type MenuButtonProps = {
  options: Option[];
  onSelect: (option: Option) => void;
  name?: string;
  icon?: ReactNode;
};

export function MenuButton({ name, icon, options, onSelect }: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onTriggerButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onItemSelect = (option: Option) => {
    onSelect(option);
    onClose();
  };

  return (
    <>
      <IconButton
        onClick={onTriggerButton}
        {...getDataTestId(`${name || 'menu'}-button`)}
      >
        {icon || <Icon name="more" />}
      </IconButton>
      <StyledMenu
        {...getDataTestId(`${name || 'menu'}-dropdown`)}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {options.map(option => (
          <MenuItem
            key={`menu-item-${option.value}`}
            onClick={() => onItemSelect(option)}
            {...getDataTestId(`menu-item-${option.value}`)}
          >
            {option.icon && (
              <ListItemIcon>
                <Icon name={option.icon} />
              </ListItemIcon>
            )}
            {option.text}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
