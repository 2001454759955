import { useBoolean } from 'usehooks-ts';
import { Icon, Loader } from '../Icon';
import { ImageContainer, ImageLoader, StyledImage } from './File.styled';
import { Alert } from '@mui/material';
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactEventHandler,
  forwardRef,
} from 'react';

type ImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  hideLoader?: boolean;
  rotateDegree?: number;
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(function Image(
  { src, alt, onLoad, onError, hideLoader, rotateDegree, ...props }: ImageProps,
  ref,
) {
  const { value: isLoaded, setTrue: setLoaded } = useBoolean(false);
  const { value: isError, setTrue: setError } = useBoolean(false);

  const handleLoad: ReactEventHandler<HTMLImageElement> = event => {
    setLoaded();
    onLoad?.(event);
  };

  const handleError: ReactEventHandler<HTMLImageElement> = event => {
    setError();
    setLoaded();
    onError?.(event);
  };

  return (
    <ImageContainer>
      {isError ? (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          Failed to load image.
        </Alert>
      ) : (
        <StyledImage
          ref={ref}
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          isHidden={!isLoaded}
          rotate={rotateDegree}
          {...props}
        />
      )}
      {!hideLoader && !isLoaded && (
        <ImageLoader>
          <Loader />
        </ImageLoader>
      )}
    </ImageContainer>
  );
});
