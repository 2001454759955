import { Components, createTheme } from '@mui/material';
import { typographyConfigs } from '../typography';
import { paletteConfigs } from '../palette';
import { spacing } from '../constants/layout';

const theme = createTheme({
  palette: paletteConfigs,
  typography: typographyConfigs,
});

export const alertConfigs: Components['MuiAlert'] = {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: spacing * 1.5,
      paddingRight: spacing * 1.5,
      paddingTop: spacing * 1,
      paddingBottom: spacing * 1,

      '.MuiAlert-icon': {
        marginRight: spacing * 0.75,
        padding: 0,

        '.MuiSvgIcon-root': {
          height: 20,
          width: 20,
        },
      },

      '.MuiAlert-message': {
        fontSize: theme.typography.captionBold.fontSize,
        fontWeight: theme.typography.captionBold.fontWeight,
        padding: 0,
        overflow: 'visible',
      },
    },
    filled: {
      '.MuiAlert-icon': {
        '.MuiSvgIcon-root': {
          fill: theme.palette.common.white,
        },
        '.MuiAlert-message': {
          color: theme.palette.common.white,
        },
      },
    },
    standardError: {
      backgroundColor: theme.palette.red[100],
      '.MuiSvgIcon-root': {
        fill: theme.palette.red[500],
      },

      '.MuiAlert-message': {
        color: theme.palette.red[600],
      },
    },
    standardWarning: {
      backgroundColor: theme.palette.orange[100],
      '.MuiSvgIcon-root': {
        fill: theme.palette.orange[500],
      },

      '.MuiAlert-message': {
        color: theme.palette.orange[600],
      },
    },
    standardInfo: {
      backgroundColor: theme.palette.primaries[100],
      '.MuiSvgIcon-root': {
        fill: theme.palette.primaries[400],
      },

      '.MuiAlert-message': {
        color: theme.palette.primaries[600],
      },
    },
    standardSuccess: {
      backgroundColor: theme.palette.green[100],
      '.MuiSvgIcon-root': {
        fill: theme.palette.green[500],
      },

      '.MuiAlert-message': {
        color: theme.palette.green[600],
      },
    },
  },
};
