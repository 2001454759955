import { Alert, Tab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Cell, ValueType, Workbook } from 'exceljs';
import { useMemo, useState, SyntheticEvent } from 'react';
import { Container, SheetGrid, SheetListBar } from './ExcelRenderer.styled';
import { getAPIErrorMessage } from '@/utils';
import { Icon, Loader } from '../../Icon';
import { ViewerCenterSlot } from '../File.styled';
import { ExcelCell } from './ExcelCell';

type ExcelRendererProps = {
  file: string;
};

export function ExcelRenderer({ file }: ExcelRendererProps) {
  const [currentSheet, setCurrentSheet] = useState(0);
  const [colSpans, setColSpans] = useState<Record<string, number>>({});
  const [rowSpans, setRowSpans] = useState<Record<string, number>>({});

  const {
    data: workbook,
    error,
    isLoading,
  } = useQuery({
    queryKey: [file],
    queryFn: async () => {
      const data = await fetch(file);
      const arrayBuffer = await data.arrayBuffer();

      const workbook = new Workbook();
      await workbook.xlsx.load(arrayBuffer, {});
      return workbook;
    },
  });

  const worksheet = useMemo(() => {
    if (!workbook) return;

    const sheet = workbook.worksheets[currentSheet];
    const colSpans: Record<string, number> = {};
    const rowSpans: Record<string, number> = {};

    if (!sheet) return;

    let maxCols = 0;
    const grid: Cell[][] = [];

    sheet.eachRow({ includeEmpty: true }, row => {
      const rowData: Cell[] = [];
      row.eachCell({ includeEmpty: true }, cell => {
        rowData.push(cell);

        if (cell.type === ValueType.Merge) {
          if (cell.row !== cell.master?.row && cell.col === cell.master?.col) {
            rowSpans[cell.master.address] = rowSpans[cell.master.address]
              ? rowSpans[cell.master.address] + 1
              : 2;
          } else if (
            cell.row === cell.master?.row &&
            cell.col !== cell.master?.col
          ) {
            colSpans[cell.master.address] = colSpans[cell.master.address]
              ? colSpans[cell.master.address] + 1
              : 2;
          }
        }
      });

      if (maxCols < rowData.length) {
        maxCols = rowData.length;
      }

      grid.push(rowData);
    });

    setColSpans(colSpans);
    setRowSpans(rowSpans);

    return sheet;
  }, [currentSheet, workbook]);

  const onSheetChange = (_: SyntheticEvent, newSheet: number) => {
    setCurrentSheet(newSheet);
  };

  if (isLoading) {
    return (
      <ViewerCenterSlot>
        <Loader />
      </ViewerCenterSlot>
    );
  }

  if (error) {
    return (
      <ViewerCenterSlot>
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      </ViewerCenterSlot>
    );
  }

  if (!workbook || !worksheet) return null;

  return (
    <Container>
      <SheetListBar onChange={onSheetChange} value={currentSheet}>
        {workbook.worksheets.map((sheet, index) => (
          <Tab key={`sheet-${index}`} value={index} label={sheet.name} />
        ))}
      </SheetListBar>
      <SheetGrid>
        <tbody>
          {[...Array(worksheet.rowCount)].map((_, row) => (
            <tr key={`excel-row-${row + 1}`}>
              {[...Array(worksheet.columnCount)].map((_, col) => (
                <ExcelCell
                  key={`excel-cell-${row + 1}-${col + 1}`}
                  worksheet={worksheet}
                  row={row + 1}
                  col={col + 1}
                  colSpans={colSpans}
                  rowSpans={rowSpans}
                />
              ))}
              <td />
            </tr>
          ))}
          <tr>
            {[...Array(worksheet.columnCount)].map((_, col) => (
              <td key={`excel-cell-${worksheet.rowCount + 1}-col-${col}`} />
            ))}
            <td />
          </tr>
        </tbody>
      </SheetGrid>
    </Container>
  );
}
