import { Stack, StackProps, Typography } from '@mui/material';
import { IconProps } from './Icon.interface';
import { LoopDotsContainer, Spinner } from './Icon.styled';
import { useCountdown } from 'usehooks-ts';
import { useEffect } from 'react';

type LoaderProps = {
  text?: string;
  iconProps?: Partial<IconProps>;
} & StackProps;

export function Loader({ text, iconProps, ...props }: LoaderProps) {
  return (
    <Stack gap={1} {...props}>
      <Spinner
        name="spinner"
        size={32}
        color={theme => theme.palette.grey[600]}
        {...iconProps}
      />
      {text && (
        <Typography variant="body2" color={theme => theme.palette.grey[700]}>
          {text}
          <LoopDots />
        </Typography>
      )}
    </Stack>
  );
}

function LoopDots() {
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 4,
    intervalMs: 500,
  });

  useEffect(() => {
    if (count === 0) {
      resetCountdown();
    } else if (count === 4) {
      startCountdown();
    }
  }, [count, resetCountdown, startCountdown]);

  return (
    <LoopDotsContainer>
      {Array(4 - count)
        .fill('.')
        .join('')}
    </LoopDotsContainer>
  );
}
