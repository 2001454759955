import { getClient, getClientAccountTypes, getPepResult } from '@/api';
import { Client, Document } from '@/types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchClientDetails = createAsyncThunk(
  'client-details/fetch-client-details',
  getClient,
);

export const patchClientDetails = createAction<
  { id: Client['id'] } & Partial<Client>
>('client-details/patch-client-details');

export const patchClientDocument = createAction<
  { clientId: Client['id'] } & { document: Document }
>('client-details/patch-client-document');

export const fetchClientPEPResult = createAsyncThunk(
  'client-details/fetch-pep-result',
  getPepResult,
);

export const resetClientDetails = createAction('client-details/reset');

export const fetchClientAccountTypes = createAsyncThunk(
  'client-details/fetch-account-types',
  getClientAccountTypes,
);
