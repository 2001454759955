import { ReactNode } from 'react';
import { DrawerProps } from './Modals.interface';
import {
  BasicDrawerContent,
  BasicDrawerHeader,
  StyledBasicDrawer,
} from './Modals.styled';

type BasicDrawerProps = {
  title: ReactNode;
  width?: number;
  disabledCloseButton?: boolean;
} & DrawerProps;

export function BasicDrawer({
  title,
  width,
  children,
  disabledCloseButton,
  onClose,
  ...props
}: BasicDrawerProps) {
  return (
    <StyledBasicDrawer {...props} onClose={onClose} width={width}>
      <BasicDrawerContent>
        <BasicDrawerHeader
          title={title}
          onClose={onClose}
          disabled={disabledCloseButton}
        />
        {children}
      </BasicDrawerContent>
    </StyledBasicDrawer>
  );
}
