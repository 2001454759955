import { REQUEST } from '@/constants';
import { ApiFilter, AuditActorType, AuditLog, DataList } from '@/types';
import { axios } from './axios';
import { getRequestFilterParams } from '@/utils';

const isFilterByClient = (filter: ApiFilter) => {
  const actorTypes = filter.filter?.actor_type as AuditActorType[] ?? [];
  const objectId = filter.filter?.object_id?.[0];

  return actorTypes.includes(AuditActorType.Onboarding) && objectId;
};

export const getAuditLogs = async (filter: ApiFilter) => {
  let url: string, params: Record<string, string|number|undefined>;

  if (isFilterByClient(filter)) {
    const objectId = filter.filter?.object_id?.[0];
    url = `${REQUEST.AUDIT.CLIENT}/${objectId}`;
    params = getRequestFilterParams({ ...filter, filter: undefined });
  } else {
    url = REQUEST.AUDIT.ROOT;
    params = getRequestFilterParams(filter);
  }

  const result = await axios.get<DataList<AuditLog>>(url, {
    params,
  });

  return {
    data: result.data.data.map(log => {
      const metadataObj =
        typeof log.metadata === 'string'
          ? JSON.parse(log.metadata)
          : log.metadata;

      return {
        ...log,
        metadata: metadataObj,
      };
    }),
    size: result.data.size,
    total: result.data.total,
    page: result.data.page,
  };
};
