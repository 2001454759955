import { IconName, Option } from '@/types';
import { countries, getCountryData, TCountryCode } from 'countries-list';
import nationalities from 'i18n-nationality';
import nationalityEn from 'i18n-nationality/langs/en.json';

nationalities.registerLocale(nationalityEn);

export const modifiedCountries = Object.entries(countries)
  .filter(
    ([, item]) =>
      ![
        'antarctica',
        // 'bouvet island',
        // 'christmas island',
        // 'eswatini',
        // 'french guiana',
        // 'french southern territories',
        // 'guadeloupe',
        // 'heard island and mcdonald islands',
        // 'mayotte',
        // 'new caledonia',
        // 'north macedonia',
        // 'reunion',
        // 'russia',
        // 'saint helena',
        // 'saint pierre and miquelon',
        // 'south georgia and the south sandwich islands',
        // 'svalbard and jan mayen',
        // 'turks and caicos islands',
        // 'u.s. minor outlying islands',
        // 'wallis and futuna',
        // 'western sahara',
      ].includes(item.name.toLowerCase()),
  )
  .map(([code, value]) => ({
    ...value,
    code: getCountryData(code as TCountryCode).iso3,
    name: standardizeCountryName(value.name),
  }));

modifiedCountries.sort((a, b) => a.name.localeCompare(b.name));

export const PHONE_CODES: Option[] = modifiedCountries.map(value => {
  return {
    text: '+' + value.phone[0],
    value: '+' + value.phone[0],
  };
});

export const COUNTRY_NAME_BY_VALUE: Record<string, string> = {};

export const COUNTRIES: Option[] = modifiedCountries.map(value => {
  const countryFlag = value.name.toLowerCase().replace(/\s+/g, '-');

  COUNTRY_NAME_BY_VALUE[value.code] = value.name;

  return {
    icon: `flag-${countryFlag}` as IconName,
    text: value.name,
    value: value.code,
  };
});
COUNTRIES.sort((a, b) => (a.text || '').localeCompare(b.text || ''));

export const NATIONALITY_BY_VALUE: Record<string, string> = {};

export const NATIONALITIES: Option[] = modifiedCountries
  .filter(value => !!nationalities.getName(value.code, 'en'))
  .concat({
    name: 'Anguilla',
    native: 'Anguilla',
    phone: [1264],
    continent: 'NA',
    capital: 'The Valley',
    currency: ['XCD'],
    languages: ['en'],
    code: 'AIA',
  })
  .map(value => {
    const countryText = standardizeCountryName(
      nationalities.getName(value.code, 'en') || value.name,
    );
    const countryFlag = value.name.toLowerCase().replace(/\s+/g, '-');

    NATIONALITY_BY_VALUE[value.code] = countryText;

    return {
      icon: `flag-${countryFlag}` as IconName,
      text: countryText,
      value: value.code,
    };
  });

NATIONALITIES.sort((a, b) => (a.text || '').localeCompare(b.text || ''));

function standardizeCountryName(name: string) {
  switch (name) {
    case 'Aland':
      return 'Aland Islands';
    case 'Cocos (Keeling) Islands':
      return 'Cocos Islands';
    case 'U.S. Virgin Islands':
      return 'Virgin Islands';
    case 'Myanmar (Burma)':
      return 'Myanmar';
    case 'Anguilla':
      return 'Anguilla, British';
    default:
      return name;
  }
}

export const CURRENCY_SIGN: Record<string, string> = {
  USD: 'USD',
  usd: 'USD',
  KYD: 'KYD',
  kyd: 'KYD',
  ANG: 'ANG',
  ang: 'ANG',
  BBD: 'BBD',
  bbd: 'BBD',
  XCD: 'XCD',
  xcd: 'XCD',
  GHS: 'GHS',
  ghs: 'GHS',
  GYD: 'GYD',
  gyd: 'GYD',
  SRD: 'SRD',
  srd: 'SRD',
  TTD: 'TTD',
  ttd: 'TTD',
};
