import { Components, createTheme } from '@mui/material';
import { paletteConfigs } from '../palette';
import { spacing } from '../constants/layout';

const theme = createTheme({
  palette: paletteConfigs,
});

export const tabsConfigs: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      minHeight: 0,
      position: 'relative',
      marginBottom: spacing * 3,

      '::before': {
        content: '""',
        height: 1,
        width: '100%',
        backgroundColor: theme.palette.grey[300],
        position: 'absolute',
        bottom: 0,
      },

      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primaries[400],
        height: 4,
      },
    },
  },
};
