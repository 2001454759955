import { isNil } from '@/utils';
import { Box, SxProps, Theme } from '@mui/material';
import { CellRichTextValue, ValueType, Worksheet } from 'exceljs';
import { CSSProperties, useCallback, useMemo } from 'react';

type ExcelCellProps = {
  worksheet?: Worksheet;
  row: number;
  col: number;
  rowSpans?: Record<string, number>;
  colSpans?: Record<string, number>;
};

export function ExcelCell({
  worksheet,
  row,
  col,
  rowSpans,
  colSpans,
}: ExcelCellProps) {
  const cell = useMemo(
    () => worksheet?.getCell(row, col),
    [col, row, worksheet],
  );

  const getCellStyleFn = useCallback(() => {
    if (!cell) return;

    const { font, alignment } = cell.style;

    const sxFn: SxProps<Theme> = theme => {
      const styles: CSSProperties = {
        color: font?.color?.argb || theme.palette.text.primary,
        fontSize: font?.size || theme.typography.body2Bold.fontSize,
        whiteSpace: 'pre-wrap',
        textAlign:
          alignment?.horizontal &&
          ['center', 'left', 'right'].includes(alignment?.horizontal)
            ? (alignment.horizontal as CSSProperties['textAlign'])
            : 'left',
        verticalAlign:
          alignment?.vertical &&
          ['bottom', 'top', 'middle'].includes(alignment?.vertical)
            ? alignment.vertical
            : 'top',
      };

      if (font?.bold) {
        styles.fontWeight = theme.typography.body2Bold.fontWeight;
      }
      if (font?.underline) {
        styles.textDecoration = 'underline';
      }
      if (cell.model.style?.border?.bottom) {
        styles.borderBottom = `2px solid ${theme.palette.grey[1000]} !important`;
      }
      if (cell.model.style?.border?.top) {
        styles.borderTop = `2px solid ${theme.palette.grey[1000]} !important`;
      }
      if (cell.model.style?.border?.left) {
        styles.borderLeft = `2px solid ${theme.palette.grey[1000]} !important`;
      }
      if (cell.model.style?.border?.right) {
        styles.borderRight = `2px solid ${theme.palette.grey[1000]} !important`;
      }

      return styles;
    };

    return sxFn;
  }, [cell]);

  const renderCellContent = useCallback(() => {
    if (!cell) return '';

    switch (cell.type) {
      case ValueType.RichText: {
        const cellValue = cell.value as CellRichTextValue;
        if (!cellValue.richText) {
          return '';
        }

        return (
          <>
            {cellValue.richText.map(({ text, font = {} }, index) => (
              <Box
                key={`cell-${cell.address}-value-${index}`}
                component="span"
                sx={theme => ({
                  color: font.color || theme.palette.text.primary,
                  fontWeight: font.bold
                    ? theme.typography.body2Bold
                    : undefined,
                  fontSize: font.size || theme.typography.body2Bold.fontSize,
                  textDecoration: font.underline ? 'underline' : undefined,
                  whiteSpace: 'pre-wrap',
                })}
              >
                {text}
              </Box>
            ))}
          </>
        );
      }
      case ValueType.Formula:
        return !isNil(cell.result) ? String(cell.result) : '';
      default:
        return String(cell.text);
    }
  }, [cell]);

  if (!cell) {
    return <td key={`cell-${row}-${col}`} />;
  }

  if (cell.type === ValueType.Merge) {
    return null;
  }

  return (
    <Box
      component="td"
      key={`cell-${row}-${col}`}
      colSpan={colSpans?.[cell.address]}
      rowSpan={rowSpans?.[cell.address]}
      sx={getCellStyleFn()}
    >
      {renderCellContent()}
    </Box>
  );
}
