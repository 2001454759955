import { ColorExtractFn } from '@/types';
import { useTheme } from '@mui/material';
import {
  StackBarContainer,
  StackBarElement,
  StackBarTotal,
} from './DataDisplay.styled';

type StackBarProps = {
  data: {
    title?: string;
    count: number;
    color?: ColorExtractFn;
  }[];
};

export function StackBar({ data }: StackBarProps) {
  const theme = useTheme();

  const total = data.reduce((acc, { count }) => acc + count, 0);

  return (
    <StackBarContainer>
      {total ? <StackBarTotal>{total}</StackBarTotal> : null}
      {data.map(({ count, title, color }, index) => (
        <StackBarElement
          key={`stackbar-element-${index}`}
          color={color?.(theme)}
          ratio={count}
          title={title}
        />
      ))}
    </StackBarContainer>
  );
}
