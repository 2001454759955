export const ROUTE = {
  HOME: '/',
  CLIENTS: '/clients',
  CLIENT_DETAILS: '/clients/:id',
  DOCUMENTS: '/documents',
  REPORTS: '/reports',
  ACTIVITY_LOGS: '/activity-logs',
  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  ADMINISTRATION: '/administration',
  ADMINISTRATION_ROLE_DETAIL: '/administration/role/:id',
};
