import { initPassword } from '@/api';
import {
  Form,
  FormAction,
  Icon,
  LoadingButton,
  PasswordPolicy,
  TextInput,
} from '@/components';
import { PASSWORD_PATTERN } from '@/constants';
import { useForm } from '@/hooks';
import {
  NewPasswordRequiredForm as NewPasswordRequiredFormType,
  SignInStep,
} from '@/types';
import {
  getAPIErrorMessage,
  getDataTestId,
  passwordNotIncludeEmail,
} from '@/utils';
import { Alert, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

type NewPasswordRequiredFormProps = {
  email: string;
  onSetStep: (value: SignInStep) => void;
};

export function NewPasswordRequiredForm({
  email,
  onSetStep,
}: NewPasswordRequiredFormProps) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
    visibleErrors: errors,
  } = useForm<NewPasswordRequiredFormType>({
    defaultValues: {
      password: '',
    },
  });
  const {
    data: result,
    mutate,
    isPending,
    error,
    reset,
  } = useMutation({
    mutationFn: initPassword,
  });

  useEffect(() => {
    if (result) {
      const { isSignedIn, nextStep } = result;

      if (!isSignedIn) {
        onSetStep(nextStep.signInStep as SignInStep);
      }
    }
  }, [onSetStep, result]);

  const onSubmit = handleSubmit(async payload => {
    reset();
    mutate(payload);
  });

  return (
    <Form alignItems="stretch" onSubmit={onSubmit}>
      <Stack direction="column" gap={1.5}>
        <Typography variant="h3">New Password Required</Typography>
        <PasswordPolicy />
      </Stack>
      <TextInput
        type="password"
        placeholder="New password"
        name="password"
        control={control}
        error={!!errors.password}
        helperText={errors.password?.message}
        rules={{
          required: 'Password is required',
          pattern: {
            value: new RegExp(PASSWORD_PATTERN, 'g'),
            message: 'Incorrect password format',
          },
          validate: {
            notIncludeUsername: value => passwordNotIncludeEmail(value, email),
          },
        }}
        disabled={isPending}
      />
      {error && (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      )}
      <FormAction align="stretch">
        <LoadingButton
          {...getDataTestId(`new-password-required-form-action-update-button`)}
          type="submit"
          disabled={!isValid || isPending}
          isLoading={isPending}
        >
          Update password
        </LoadingButton>
      </FormAction>
    </Form>
  );
}
