import {
  FullHeightTextField,
  LoadingButton,
  SearchSelect,
  Select,
  TextInput,
} from '../../Form';
import { Form } from '../Modals.styled';
import { ClientBlock } from '../../User';
import { Button } from '@mui/material';
import { Icon } from '../../Icon';
import { FormAction } from '../../Layout';
import {
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  htmlizeText,
  replaceRequestDocumentTemplate,
} from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { useDispatch, useForm } from '@/hooks';
import {
  Client,
  ClientStatus,
  DocumentRequestForm,
  DocumentType,
  ModalName,
  Option,
} from '@/types';
import { useCallback, useEffect, useRef } from 'react';
import {
  getClients,
  getRequestDocumentTemplate,
  requestClientDocument,
} from '@/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { hideModal, showToast } from '@/redux';
import { DOCUMENT_TYPES, DocumentTypeText } from '@/constants';

type RequestDocumentFormProps = {
  client?: Client;
  modalName: ModalName;
  onSetDisabled?: (value: boolean) => void;
};

export function RequestDocumentForm({
  client,
  modalName = ModalName.RequestDocument,
  onSetDisabled,
}: RequestDocumentFormProps) {
  const {
    value: isTemplatedUpdated,
    setTrue: templateUpdated,
    setFalse: resetTemplateChanges,
  } = useBoolean(false);
  const {
    control,
    handleSubmit,
    setValue,
    visibleErrors: errors,
    watch,
    formState: { dirtyFields, isValid },
    trigger,
  } = useForm<DocumentRequestForm>({
    defaultValues: {
      clientId: client?.id || '',
      type: DocumentType.ProofOfAddress,
      message: '',
    },
  });
  const selectedClient = useRef<Client>();

  const clientId = watch('clientId');
  const message = watch('message');
  const documentType = watch('type');

  const { data: messageTemplate, isLoading: isLoadingTemplate } = useQuery({
    queryKey: ['request-document-message-template'],
    queryFn: getRequestDocumentTemplate,
  });

  const {
    mutate: sendRequest,
    isSuccess: isSentSuccess,
    isPending: isSending,
    error: requestError,
    data: requestResponseData,
  } = useMutation({
    mutationFn: requestClientDocument,
  });
  const dispatch = useDispatch();

  const searchClients = useCallback((keyword: string) => {
    return getClients({
      pagination: {
        page: 0,
        size: 20,
        total: 20,
      },
      query: keyword,
      filter: {
        status: [
          ClientStatus.InReview,
          ClientStatus.PendingApproval,
          ClientStatus.Draft,
        ],
      },
      excludeFields: ['documents'],
    });
  }, []);

  const clientToOption = useCallback(
    (client: unknown) =>
      ({
        value: (client as Client)?.id,
        text: getDisplayField(client as Client),
      }) as Option,
    [],
  );

  const onClientSelected = useCallback((_clientId: string, client: unknown) => {
    selectedClient.current = client as Client;
  }, []);

  useEffect(() => {
    if (client?.id) {
      setValue('clientId', client.id);
    }
    selectedClient.current = client;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (
      (!message.length && isTemplatedUpdated) ||
      !message ||
      (message && !dirtyFields['message'])
    ) {
      setValue(
        'message',
        replaceRequestDocumentTemplate({
          message: messageTemplate || '',
          documentType,
          client: selectedClient.current,
        }),
      );
      resetTemplateChanges();

      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageTemplate, clientId, documentType]);

  useEffect(() => {
    if (
      dirtyFields['message'] &&
      message.length > 0 &&
      messageTemplate &&
      message !== messageTemplate
    ) {
      templateUpdated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    onSetDisabled?.(isSending);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSending]);

  useEffect(() => {
    if (isSentSuccess) {
      dispatch(
        showToast({
          message: requestResponseData.message,
          severity: 'success',
        }),
      );
      dispatch(hideModal({ modalName }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSentSuccess]);

  useEffect(() => {
    if (requestError) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(requestError),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestError]);

  const onSubmit = handleSubmit(payload =>
    sendRequest({
      ...payload,
      message: htmlizeText(payload.message).replaceAll(
        new RegExp(`(${Object.values(DocumentTypeText).join('|')})`, 'g'),
        '<b>$&</b>',
      ),
    }),
  );

  const onClose = () => {
    dispatch(hideModal({ modalName }));
  };

  return (
    <Form onSubmit={onSubmit}>
      {client ? (
        <TextInput label="Client" value={getDisplayField(client)} disabled />
      ) : (
        <SearchSelect
          control={control}
          name="clientId"
          searchQueryKey="request-document-client-id"
          rules={{
            required: 'Select client',
          }}
          searchFn={searchClients}
          valueToOption={clientToOption}
          onSelected={onClientSelected}
          textFieldProps={{
            label: 'Client',
            error: !!errors.clientId,
            helperText: errors.clientId?.message,
          }}
          defaultValues={client ? [client] : []}
          renderOption={(_, item) => (
            <ClientBlock
              avatar={(item as Client)?.picture}
              name={getDisplayField(item as Client)}
              description={(item as Client)?.email}
            />
          )}
          disabled={isSending}
        />
      )}
      <Select
        options={DOCUMENT_TYPES}
        label="Document type"
        control={control}
        name="type"
        fullWidth
        error={!!errors.type}
        helperText={errors.type?.message}
        disabled={isSending}
      />
      <FullHeightTextField
        name="message"
        control={control}
        rules={{
          required: 'Message is required',
        }}
        placeholder={
          isLoadingTemplate
            ? 'Loading template...'
            : 'Write request messages here'
        }
        disabled={isLoadingTemplate || isSending}
        error={!!errors.message}
        helperText={errors.message?.message}
      />
      <FormAction align="stretch">
        <Button
          {...getDataTestId(`request-document-action-cancel-button`)}
          onClick={onClose}
          variant="outlined"
          disabled={isSending}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={!isValid}
          isLoading={isSending}
          startIcon={<Icon name="paper-plane" />}
          {...getDataTestId(`request-document-action-send-button`)}
        >
          Send request
        </LoadingButton>
      </FormAction>
    </Form>
  );
}
